import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useDispatch
} from 'react-redux'
import SalesChannelShipment from '../components/sales-channel-generated-shipment/sales-channel-shipment.jsx'
import ShipmentDetail from '../components/sales-channel-generated-shipment/shipment-details.jsx'
import ShippingDetails from '../components/sales-channel-generated-shipment/shipping-details.jsx'
import { createShipment, getOrdersApi } from '../sales.apis'
import { getSingleOrderDetails } from '../store/store'

const CreateShipmentWithSalesChannel = (props) => {
  const { orderID, shippingDetailsData, handleEditDetailsModal } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const queryClient = useQueryClient()

  // functions
  const handleOrderDetailsData = () => {
    dispatch(getSingleOrderDetails(orderID))
  }

  // queries
  const { mutate: handleCreateShipmentWithSalesChannel, data: shipmentDetails, isPending: craeteShipmentLoading } = useMutation({
    mutationKey: [...createShipment.apiKey],
    mutationFn: (data) => {
      const body = {
        shipment_type: 'sales_channel_generated',
        order_details: {
          number_of_boxes: Number(data.number_of_boxes),
          remarks: data.remarks
        }
      }
      return axiosInstance.post(getApiUrl(createShipment.url, { orderId: orderID }), body)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getOrdersApi.apiKey] })
    }
  })

  return (
    <div className="mx-1">
      <ShippingDetails
        shippingDetailsData={shippingDetailsData}
        shipmentCreated={!!(shipmentDetails?.data?.data)}
        handleEditDetailsModal={handleEditDetailsModal}
        ability={ability}
        t={t}
      />
      {shipmentDetails?.data?.data || shippingDetailsData.entity_data.shipment_already_created ?
        <ShipmentDetail
          shipmentDetails={shippingDetailsData.entity_data.shipment_already_created ? shippingDetailsData.entity_data.shipment_res : shipmentDetails.data.data}
          handleOrderDetailsData={handleOrderDetailsData}
        />
        :
        <SalesChannelShipment
          shippingDetailsData={shippingDetailsData}
          craeteShipmentLoading={craeteShipmentLoading}
          handleCreateShipmentWithSalesChannel={handleCreateShipmentWithSalesChannel}
        />
      }
    </div>
  )
}
export default CreateShipmentWithSalesChannel
