import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import noSalesChannelImage from '@src/assets/images/omniful/no_sales_channel_image.svg'
import { INVENTORY_ROUTES } from '@src/views/inventory/inventory.constants'
import { PATH_NAME } from '@src/views/sales/sales.constant'
import { Plus, RefreshCcw } from 'react-feather'
import Edit from '@src/assets/images/icons/Edit'
import TagsMapper from '@src/@core/components/ui/tags-mapper'
import Button from '@src/@core/components/ui/button'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import classNames from 'classnames'
import { Info } from 'react-feather'

export const RenderStoRequestId = ({ orderDetails, handleShowSTODetail }) => {
  const currentPathname = window.location.pathname

  return (
    <CopyOnClick
      id={orderDetails?.stock_transfer_request_details?.id}
      handleDetails={currentPathname === PATH_NAME.stockTransferOrder || currentPathname === INVENTORY_ROUTES.STOCK_TRANSFER || !orderDetails.stock_transfer_request_details.id ? null : handleShowSTODetail}
      columnKey='sto_request_id'
    >
      {orderDetails?.stock_transfer_request_details?.display_id}
    </CopyOnClick>
  );
};


export const RenderSalesChannel = ({ orderDetails }) => {
  return (
    <span className='text-black'>
      <img
        width={60}
        height={20}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = noSalesChannelImage;
        }}
        className="object-fit-contain rounded shadow-1"
        src={orderDetails.sales_channel?.logo_url || noSalesChannelImage}
        alt={orderDetails.sales_channel?.store_name}
      />
      <span className="ms-50">{orderDetails.sales_channel?.store_name}</span>
    </span>
  );
};

export const RenderTags = ({ orderDetails, hasEditOrderDetailsPermission, toggleAddLocationAttributesModal }) => {
  return (
    <div className="d-flex align-items-center flex-wrap gap-8px">
      {orderDetails?.custom_labels?.length > 0 ? (
        <>
          {hasEditOrderDetailsPermission && (
            <div
              className="text-primary txt-sub-rg cursor-pointer bg-primary-lighter width-24px height-24px flex-center-center rounded-12px"
              onClick={toggleAddLocationAttributesModal}
            >
              <Plus size={16} />
            </div>
          )}
          <TagsMapper
            tags={orderDetails.custom_labels}
            viewItems={2}
            showCount={true}
            size="md"
          />
        </>
      ) : (
        <>
          {hasEditOrderDetailsPermission ? (
            <Button className="p-0 shadow-none" ofStyle="textOnly" onClick={toggleAddLocationAttributesModal}>
              {t('Add Tag')}
            </Button>
          ) : (
            '-'
          )}
        </>
      )}
    </div>
  );
};

export const RenderPaymentStatus = ({ orderDetails, syncButtons, handleSyncPaymentStatus }) => {
  const sales_loading = useSelector((state) => state.sales.loading)

  return (
    <div className="flex-center-start gap-12px">
      {orderDetails?.payment_method} 
      {syncButtons?.sync_payment_status && (
        <div>
          <Button
            id="sync_payment_status"
            className="p-4px rounded-4px"
            ofStyle="noBackground"
            onClick={handleSyncPaymentStatus}
            disabled={sales_loading.syncOrderPaymentStatus}
          >
            <RefreshCcw
              className={classNames({ 'sync-rotate-icon': sales_loading.syncOrderPaymentStatus })}
              size={16}
              color={sales_loading.syncOrderPaymentStatus ? 'var(--bs-primary-light)' : 'var(--bs-primary)'}
            />
          </Button>
          <UncontrolledTooltip offset={[0, 7]} autohide={false} target="sync_payment_status">
            {t(`Syncing updates payment status and invoice details from ${orderDetails.sales_channel?.store_name}`)}
          </UncontrolledTooltip>
        </div>
      )}
    </div>
  );
};

export const RenderSeller = ({orderDetails}) => {
  const { name, code } = orderDetails?.seller || {};

  const formattedSellerName = name ? `${name}${code ? ` (${code})` : ''}` : '-'

  return (
    <div className="d-flex align-items-center gap-8px omniful-button p-0 shadow-none omniful-regular-btn bg-transparent">
      <span>{formattedSellerName}</span>
      <span className="p-0 cursor-pointer d-flex align-items-center">
        <Info size={16} id="seller_info_basic_card" />
        <UncontrolledTooltip offset={[0, 5]} target="seller_info_basic_card">
          <div className="text-start fw-bold">
            <span className="me-10px">{t('Seller Name')}:</span> {name || '-'}<br />
            <span className="me-10px">{t('Seller Code')}:</span> {code || '-'}
          </div>
        </UncontrolledTooltip>
      </span>
    </div>
  );
};

export const RenderAttachment = ({
  orderDetails,
  handleToggleAttachmentSidebar,
  hasEditOrderDetailsPermission,
  hasEditAttachmentButton,
}) => {
  return (
    orderDetails?.attachments?.length > 0 ? (
      <span 
        className="text-primary cursor-pointer flex-center-start gap-4px" 
        onClick={handleToggleAttachmentSidebar}
      >
        <span>{`${orderDetails.attachments.length} File${orderDetails.attachments.length > 1 ? 's' : ''}`}</span>
        {hasEditOrderDetailsPermission && hasEditAttachmentButton && <Edit width={16} height={16} color='var(--bs-primary)' />}
      </span>
    ) : (
      hasEditOrderDetailsPermission && hasEditAttachmentButton && (
        <Button 
          className="p-0 shadow-none" 
          ofStyle='textOnly' 
          onClick={handleToggleAttachmentSidebar}
        >
          {t('Attach Files')}
        </Button>
      )
    )
  );
};

export const RenderOrderTags = ({ orderDetails }) => {
  return (
    <div className="d-flex flex-wrap gap-8px">
      <TagsMapper
        tags={orderDetails?.tags}
        viewItems={3}
        showCount={true}
        size="md"
      />
    </div>
  );
};

export const RenderRefreshSalesChannel = ({ orderDetails }) => {
  return (
    <div>
      <img
        width={60}
        height={20}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = noSalesChannelImage; // Fallback image
        }}
        className="object-fit-contain rounded shadow-1"
        src={orderDetails.sales_channel.logo_url || noSalesChannelImage}
        alt={orderDetails.sales_channel.name}
        loading='lazy'
      />
      <span className="ms-50">{orderDetails.sales_channel.name}</span>
    </div>
  );
};

