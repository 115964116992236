import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'

const CategoryListViewModal = ({ data = [], toggleSidebar, modalOpen }) => {

  const { t } = useTranslation()
  const categoryColumns = [
    {
      id: 1,
      name: t('S. No.'),
      minWidth: '50px',
      maxWidth: '60px',
      selector: row => row,
      cell: (row, index) => <div className="w-100 text-center ">{index + 1}</div>
    },
    {
      id: 2,
      name: t('Categories'),
      selector: row => row.categoryName
    }
  ]

  const meta = {
    current_page: 1, last_page: 1, per_page: data.length, total: data.length
  }

  return (
    <SideSheet
      modalClassName='modal-slide-in category-list-side-view-modal'
      size='sm'
      isOpen={modalOpen}
      toggle={toggleSidebar}
      title='Categories'
    >

      <ModalBody className='p-24px overflow-y-auto'>
        <ExpandableCustomTable
          data={data}
          columns={categoryColumns}
          showPagination={false}
          meta={meta}
          showColumnsTableHeader={false}
        />
      </ModalBody>

    </SideSheet>
  )
}

export default CategoryListViewModal 