import { ORDER_LOG_EVENT_BY } from '@src/views/sales/sales.constant';
import classNames from 'classnames';
import { FC, memo } from 'react';
import { ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { LOGS_LOGO } from '../constants';
import { iOrderLog, iToggleOrderLogDetailsSideSheet } from '../types';

// TODO: need to write styles for this component as it is not following the new design system for gap, padding, margin, etc. also layout is not correct

interface iLogItemProps {
  item: iOrderLog
  index: number
  totalLogs: number
  // eslint-disable-next-line no-unused-vars
  onWaveClick: (item: iOrderLog, index: number) => void
  isLoading?: boolean
  selectedWaveIndex?: number
  // eslint-disable-next-line no-unused-vars
  toggleOrderLogDetailsSideSheet: ({ item, status }: iToggleOrderLogDetailsSideSheet) => void
}

/**
 * Component for displaying individual log entries
 * Shows event type, timestamp, and user information
 * Handles wave-related interactions
 * @component
 */
const LogItem: FC<iLogItemProps> = ({ 
  item, 
  index, 
  totalLogs, 
  onWaveClick, 
  isLoading, 
  selectedWaveIndex, 
  toggleOrderLogDetailsSideSheet 
}) => {
  const { t } = useTranslation();

  const renderLogIcon = () => (
    <div className='logo-container d-flex flex-column align-items-center gap-16px'>
      {LOGS_LOGO[item.event as keyof typeof LOGS_LOGO] ? (
        <img src={LOGS_LOGO[item.event as keyof typeof LOGS_LOGO]} alt={item.event} height={24} width={24} />
      ) : (
        <div className='show-circle' />
      )}
      {index < totalLogs - 1 && <div className='style-vertical-line' />}
    </div>
  );

  const renderEventTitle = () => (
    <div className='d-flex flex-column gap-4px'>
      <p className='txt-body-md text-dark m-0'>{t(item.event)}</p>
      {item.metadata?.show_updates && (
        <p 
          className='flex-center-start text-primary cursor-pointer m-0 txt-sub-rg' 
          onClick={() => toggleOrderLogDetailsSideSheet({ item, status: true })}
        >
          <span>{t('View Updates')}</span>
          <span><ChevronRight size={16} /></span>
        </p>
      )}
    </div>
  );

  const renderEventDetails = () => {
    const isWaveEvent = item.event_updated_by_user_type === ORDER_LOG_EVENT_BY.WAVE.key;
    const isLoadingWave = isWaveEvent && isLoading;
    const showSpinner = selectedWaveIndex === index && isLoading;

    return (
      <div className='d-flex flex-column gap-4px'>
        <p
          className={classNames('d-flex gap-4px txt-sub-sb m-0', {
            'text-primary cursor-pointer': isWaveEvent,
            'text-primary-light cursor-not-allowed': isLoadingWave,
            'text-dark-6': !isWaveEvent && !isLoadingWave
          })}
          onClick={() => onWaveClick(item, index)}
        >
          {!isWaveEvent ? (
            <span>{t('By')}</span>
          ) : (
            showSpinner && <Spinner size='sm' className='me-8px' />
          )}
          {item.event_updated_by}
        </p>
        <p className='txt-asst-rg text-dark-5 m-0'>{item.event_updated_at}</p>
        {item.note && <p className='txt-sub-rg text-dark-5 m-0'>{item.note}</p>}
      </div>
    );
  };

  return (
    <div className='d-flex gap-24px'>
      {renderLogIcon()}
      <div className='d-flex flex-column gap-8px'>
        {renderEventTitle()}
        {renderEventDetails()}
      </div>
    </div>
  );
};

export default memo(LogItem);
