export const DATETIME_TEMPLATE = (options = {}) => {
  const omitKeys = options.omitKeys || [];

  const values = [
    {
      key_name: 'today',
      display_name: 'Today',
      default: options.defaultToday || false
    },
    {
      key_name: 'last_24_hours',
      display_name: 'Last 24 Hours',
      default: options.default24Hours || false
    },
    {
      key_name: 'last_7_days',
      display_name: 'Last 7 Days',
      default: options.default7Days || false
    },
    {
      key_name: 'last_30_days',
      display_name: 'Last 30 Days',
      default: options.default30Days || false
    },
    {
      key_name: 'custom',
      display_name: 'Custom'
    }
  ];

  const filteredValues = values.filter(value => !omitKeys.includes(value.key_name));

  return {
    key_name: 'datetime',
    display_name: 'Datetime',
    input_source: 'datetime_picker',
    datatype: 'string_array',
    is_required: true,
    values: filteredValues,
    doesNotRequireTime: options.doesNotRequireTime || false
  }
}

export const DEFAULT_ANALYTICS_GLOBAL_DATETIME = DATETIME_TEMPLATE({
  default24Hours: true,
  omitKeys: ['today']
})

export const FULFILMENT_ANALYTICS_DATETIME = DATETIME_TEMPLATE({
  defaultToday: true,
  doesNotRequireTime: true,
  omitKeys: ['last_24_hours','custom']
})

export const LASTMILE_ANALYTICS_DATETIME = DATETIME_TEMPLATE({
  default7Days: true,
  doesNotRequireTime: true,
  omitKeys: ['last_24_hours','custom']
})

export const POS_ANALYTICS_DATETIME = DATETIME_TEMPLATE({
  default24Hours: true,
  omitKeys: ['today']
})