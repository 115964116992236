import InputField from '@src/@core/components/ui/input-field'
import Modal from '@src/@core/components/ui/modal/Modal'
import { MODAL_TYPES } from '@src/@core/components/ui/modal/modal.constant'
import { Pause } from '@src/assets/images/icons/Modal/GeneralIcons'
import { putOrderOnHold } from '@src/views/sales/store/store'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form, ModalBody } from 'reactstrap'

const OnHoldConfirmationPopUp = (props) => {
  const { isPopUpOpen, setIsOnHoldOrderCofirmationPopUpOpen, order_id } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      reason: ''
    },
    mode: 'onChange'
  })

  const handleClosePopUp = () => {
    setIsOnHoldOrderCofirmationPopUpOpen(false)
    reset()
  }
  const handleFormSubmit = (data) => {
    handleClosePopUp()
    const body = {
      reason:data.reason
    }
    dispatch(putOrderOnHold({ order_id, body }))
  }

  return (
    <Modal
      isOpen={isPopUpOpen}
      closeModalHandler={handleClosePopUp}
      title={t('Are you sure you want to put this order on hold?')}
      type={MODAL_TYPES.GENERAL}
      icon={Pause}
      primaryButtonText={t('Confirm')}
      primaryButtonHandler={handleSubmit(handleFormSubmit)}
      secondaryButtonText={t('Close')}
      secondaryButtonHandler={handleClosePopUp}
      isTranslationRequired={false}
    >
      <ModalBody className='p-0'>
        <div className='txt-body-rg text-dark-6 mb-24px'>{t('Placing this order on hold will require approval to proceed with processing.')}</div>
        <Form>
          <InputField
            name='reason'
            label='Enter Reason'
            {...register('reason', {
              required:'This field is required'
            })}
            errors={errors}
            isClearable
            isRequired
          />
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default OnHoldConfirmationPopUp