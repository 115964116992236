import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { ORDER_TYPES } from '@src/App.constants'
import trackingImage from '@src/assets/images/orders/trackingOrder.svg'
import CancelOrderPopup from '@src/views/sales/components/CancelOrderPopup'
import MultipleAwbSideSheet from '@src/views/sales/components/MultipleAwbSideSheet/MultipleAwbSideSheet'
import ReassignHubModal from '@src/views/sales/components/reassignHubModal'
import TransactionsSideSheet from '@src/views/sales/components/transactions-sidesheet'
import { TRANSACTION_HANDLING_TYPES } from '@src/views/sales/components/transactions-sidesheet/constants'
import ReorderModal from '@src/views/sales/create-order/components/reorder-modal'
import CancelShipmentPopUp from '@src/views/sales/create/CancelShipmentPopUp'
import { EDIT_MODAL_TYPE } from '@src/views/sales/sales.constant'
import STODetailSidebar from '@src/views/sales/StockTransferOrder/components/storeDetail/stoDetailSidebar'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import EditCustomerShippingModal from '../../../../components/EditCustomerShippingModal'
import OnHoldConfirmationPopUp from '../../../components/OnHoldConfirmationPopUp/OnHoldConfirmationPopUp'
import Attachments from '../../createOrder/attachments'
import OrderDetailsTable from '../../ordersForApproval/OrderDetailsTable'
import SplittedOrderDetailsSideSheet from '../../splittedOrders/components/splittedOrderDetailsSideSheet'
import BasicDetailsCard from '../components/basicDetailsCard/basicDetailsCard'
import ButtonSection from '../components/buttonSection/buttonSection'
import CustomerDetailsCard from '../components/customerDetailsCard/customerDetailsCard'
import DestinationHubDetailsCard from '../components/destinationHubDetailsCard/destinationHubDetailsCard'
import OrderInfobanner from '../components/orderInfoBanner/orderInfobanner'
import ShipmentDetailsCard from '../components/shipmentDetailsCard/shipmentDetailsCard'
import ShippingAddressCard from '../components/shippingAddressCard/shippingAddressCard'
import { useItemDetail} from './hooks/useItemDetail'
import './ItemDetails.scss'

const ItemDetails = ({ setShipmentSlider, setIsModalOpen, handleGetParentData }) => {
  const {
    deleteModalOpen,
    setDeleteModalOpen,
    isEditDetailsModalOpen,
    setIsEditDetailsModalOpen,
    isSplittedOrderDetailsSideSheetOpen,
    multipleAwbModalOpen,
    multipleAwbList,
    isCancelOrderPopupOpen,
    setIsCancelOrderPopupOpen,
    isOnHoldOrderCofirmationPopUpOpen,
    setIsOnHoldOrderCofirmationPopUpOpen,
    isStoDetailsOpen,
    isReassignHubModalOpen,
    setReassignHubModalOpen,
    reorderModal,
    setReorderModal,
    transactionSideSheet,
    attachmentSidebar,
    addedAttachments,
    isOpenAddLocationAttributesModal,
    syncButtons,
    editButtons,
    hasEditAttachmentButton,
    hasEditOrderDetailsPermission,
    isUneditedSalesChannelOrder,
    orderDetails,
    sales_loading,
    handleToggleAttachmentSidebar,
    handleNavigateToReturnOrders,
    handleSyncPaymentStatus,
    handleReorder,
    closeTransactionSheet,
    handleEditDetailsModal,
    togglemultipleAwbModal,
    handleOpenSplittedOrderDetailSideSheet,
    handleCloseSplittedOrderDetailSideSheet,
    handleCancelOrderSuccess,
    handleReassignHubSuccess,
    onSaveAttachments,
    handleGetButtonArray,
    handleShowSTODetail,
    openTransactionSheet,
    toggleAddLocationAttributesModal,
    uploadDetails,
    setAddedAttachments,
    isEditAttachmentPending
  } = useItemDetail({ setIsModalOpen, handleGetParentData })

  const { t } = useTranslation()

  return <div className="mb-2 order-item-detail w-100">
    {orderDetails && !sales_loading.getSingleOrderDetails ? (
      <div>
        
        <OrderInfobanner orderDetails={orderDetails} />

        <div className="flex-center-between gap-16px">
          <div className="status-wrapper bg-white rounded-8px d-flex align-items-center gap-12px flex-grow-1">
            <div className="status-label rounded-start text-secondary txt-h3-md py-8px px-16px">
              {t('Status')}
            </div>
            {orderDetails.status && (
              <div className="d-flex gap-8px pe-12px">
                <CustomLabel title={orderDetails.status} className="order-status-tag text-uppercase" />
              </div>
            )}
          </div>

          <ButtonSection
            orderDetails={orderDetails}
            setIsModalOpen={setIsModalOpen}
            setShipmentSlider={setShipmentSlider}
            setDeleteModalOpen={setDeleteModalOpen}
            setIsOnHoldOrderCofirmationPopUpOpen={setIsOnHoldOrderCofirmationPopUpOpen}
            setIsCancelOrderPopupOpen={setIsCancelOrderPopupOpen}
            handleGetButtonArray={handleGetButtonArray}
            setReassignHubModalOpen={setReassignHubModalOpen}
            handleReorder={handleReorder}
            openTransactionSheet={openTransactionSheet}
          />
        </div>

        <BasicDetailsCard
          orderDetails={orderDetails}
          hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
          toggleAddLocationAttributesModal={toggleAddLocationAttributesModal}
          isOpenAddLocationAttributesModal={isOpenAddLocationAttributesModal}
          syncButtons={syncButtons}
          handleSyncPaymentStatus={handleSyncPaymentStatus}
          handleNavigateToReturnOrders={handleNavigateToReturnOrders}
          handleOpenSplittedOrderDetailSideSheet={handleOpenSplittedOrderDetailSideSheet}
          handleToggleAttachmentSidebar={handleToggleAttachmentSidebar}
          hasEditAttachmentButton={hasEditAttachmentButton}
          handleShowSTODetail={handleShowSTODetail}
        />

        <div className="my-24px details-card-container">
          <div className={classNames({ 'd-flex flex-column gap-24px': orderDetails?.shipment?.awb_number })}>
            <CustomerDetailsCard
              customer={orderDetails.customer}
              editButtons={editButtons}
              handleEditDetailsModal={handleEditDetailsModal}
              orderType={orderDetails.type}
              hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
            />

            {orderDetails?.shipment?.awb_number && (
              <ShipmentDetailsCard orderDetails={orderDetails} trackingImage={trackingImage} />
            )}
          </div>

          {
            orderDetails.type === ORDER_TYPES.STO.value ? (
              <DestinationHubDetailsCard
                shipping_address={orderDetails.shipping_address}
                destination_hub={orderDetails.destination_hub}
              />
            ) : (
              <ShippingAddressCard
                shipping_address={orderDetails.shipping_address}
                editButtons={editButtons}
                handleEditDetailsModal={handleEditDetailsModal}
                hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
              />
            )
          }

        </div>

        <OrderDetailsTable orderDetails={orderDetails} editButtons={editButtons} />
      </div>
    ) : (
      <ComponentSpinner className="m-5" />
    )}

    {orderDetails && <CancelShipmentPopUp shipmentOrderID={orderDetails.shipment_order_id} deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen} shipping_partner_tag={orderDetails.shipment.shipping_partner_tag} awb_number={orderDetails.shipment.awb_number} />}

    {orderDetails && <EditCustomerShippingModal isModalOpen={isEditDetailsModalOpen} setIsModalOpen={setIsEditDetailsModalOpen} orderId={orderDetails.id} editDetails={(isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key || isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.RECIPIENT_DETAILS.key) ? orderDetails.customer : orderDetails.shipping_address} />}

    <SplittedOrderDetailsSideSheet isModalOpen={isSplittedOrderDetailsSideSheetOpen} handleCloseSideSheet={handleCloseSplittedOrderDetailSideSheet} order_id={orderDetails?.is_split_order ? orderDetails?.parent_order_details.id : orderDetails?.id} isSplitOrderCreated={orderDetails?.is_split_order} />

    <MultipleAwbSideSheet modalOpen={multipleAwbModalOpen} data={multipleAwbList} toggleSidebar={togglemultipleAwbModal} />

    <CancelOrderPopup
      isCancelOrderPopupOpen={isCancelOrderPopupOpen}
      setIsCancelOrderPopupOpen={setIsCancelOrderPopupOpen}
      orderDetail={{ ...orderDetails, sellerOrderId: orderDetails?.seller_sales_channel_order_id }}
      setOrderDetail={() => { }}
      handleCancelOrderSuccess={handleCancelOrderSuccess}
    />

    <OnHoldConfirmationPopUp isPopUpOpen={isOnHoldOrderCofirmationPopUpOpen} setIsOnHoldOrderCofirmationPopUpOpen={setIsOnHoldOrderCofirmationPopUpOpen} order_id={orderDetails?.id} />
    <Attachments
      isOpen={attachmentSidebar.isOpen}
      toggle={handleToggleAttachmentSidebar}
      addedAttachments={addedAttachments}
      setAddedAttachments={setAddedAttachments}
      mode={attachmentSidebar.mode}
      onSaveAttachments={onSaveAttachments}
      isLoading={isEditAttachmentPending}
      uploadDetails={uploadDetails}
    />
        
    <STODetailSidebar
      isShowSTODetail={isStoDetailsOpen}
      handleShowSTODetail={handleShowSTODetail}
      stoRequestId={orderDetails?.sto_request_id}
    />
        
    <ReassignHubModal
      isOpen={isReassignHubModalOpen}
      setIsOpen={setReassignHubModalOpen}
      orderDetail={orderDetails}
      handleReassignHubSuccess={handleReassignHubSuccess}
    />

    <ReorderModal
      isOpen={reorderModal.isOpen}
      setIsOpen={() => setReorderModal({ isOpen: false, recreationType: '' })}
      handleContinue={handleReorder}
      preloadData={{
        orderId: orderDetails?.seller_sales_channel_order_id,
        assignedHubId: orderDetails?.hub_id,
        recreationType:reorderModal.recreationType
      }}
    />
    
    {transactionSideSheet.isOpen && <TransactionsSideSheet
      isOpen={transactionSideSheet.isOpen}
      handleClose={closeTransactionSheet}
      newInvoice={{
        total: orderDetails.invoice.total,
        finalOrderTotal: transactionSideSheet.finalOrderTotal,
        currency: orderDetails.invoice.currency,
        orderTotalType: transactionSideSheet.orderTotalType
      }}
      isSalesChannelOrder={isUneditedSalesChannelOrder}
      orderId={orderDetails.id}
      transactionHandlingType={TRANSACTION_HANDLING_TYPES.PENDING_TRANSACTION}
    />}
    
  </div>
}

export default ItemDetails