import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ability from '@src/configs/acl/ability'
import { useSelector } from 'react-redux'

const SkuChildTable = ({ sku, t, defaultImg, setSingleChild, setIsSimpleSkuModalOpen, loading, isArchive }) => {
  
  const error = useSelector((state) => state.catalog.error.bundleChildSku)
  const success = useSelector((state) => state.catalog.success.bundleChildSku)

  const handleSkuDetailsSidebar = (row) => {
    setSingleChild(row)
    setIsSimpleSkuModalOpen(true)
  }

  const columns = [
    {
      id: 1,
      name: <div className='w-100 text-center'>{t('S. No.')}</div>,
      width: '60px',
      key: 'sno.',
      reorder: true,
      cell: (row, index) => {
        return <div className='w-100 text-center'>{index + 1}</div>
      }
    },
    {
      id: 2,
      name: t('SKU Details'),
      minWidth: '250px',
      maxWidth: '340px',
      key: 'skuName',
      omit: (isArchive),
      cell: row => {
        const image = row.images?.find((img) => img.default)?.default || null
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.id,
              image: {
                src: image || defaultImg,
                alt: row.name
              },
              details: {
                name: row.name,
                handleSkuDetails: () => !isArchive && handleSkuDetailsSidebar(row),
                skuCode: row.seller_sku_code,
                columnKey: 'sku_details'
              }
            }}
          />
        )
      }
    },
    {
      id: 3,
      name: <div className='w-100'>{t('SKU Code')}</div>,
      minWidth: '150px',
      key: 'sku_code',
      reorder: true,
      omit: (!isArchive),
      cell: (row) => {
        return <div className={'txt-sub-rg text-secondary w-100'}>
          {row.seller_sku_code || '-'}
        </div>
      }
    },
    {
      id: 4,
      name: <div className='w-100 text-end'>{t('Quantity')}</div>,
      minWidth: '150px',
      key: 'quantity',
      reorder: true,
      cell: (row) => {
        return <div className={'txt-sub-rg text-secondary w-100 text-end'}>
          {row.quantity || '-'}
        </div>
      }
    },
    {
      id: 5,
      name: <div className='w-100 text-end'>{t('Retail Price')}</div>,
      minWidth: '150px',
      key: 'retailPrice',
      reorder: true,
      omit: isArchive || !ability.can(abilityMap.product.view_price.action, abilityMap.product.view_price.resource),
      cell: row => {
        return <div className='w-100 text-end'><span className='txt-sub-rg'> {row.retail_price ? ` ${(row.retail_price * (sku.child_skus?.find((ele) => ele.id === row.id)?.quantity || 0)).toFixed(2)} ${row.currency}` : '-'}</span></div>
      }
    },
    {
      id: 6,
      minWidth: '150px',
      name: <div className='w-100 text-end'>{t('Selling Price')}</div>,
      key: 'sellingPrice',
      reorder: true,
      omit: isArchive || !ability.can(abilityMap.product.view_price.action, abilityMap.product.view_price.resource),
      cell: row => {
        return <div className='w-100 text-end'><span className='txt-sub-rg'> {row.selling_price ? ` ${(row.selling_price * (sku.child_skus?.find((ele) => ele.id === row.id)?.quantity || 0)).toFixed(2)} ${row.currency}` : '-'}</span></div>
      }
    }
  ]
  return (
    <div className='w-100'>
      <ExpandableCustomTable
        columns={columns}
        error={error}
        success={success}
        data={sku?.child_skus || []}
        useReactPaginate={false}
        showPagination={false}
        showColumnsTableHeader={false}
        loading={loading || false}
        isTableFixedHeader={false}
      />
    </div>
  )
}

export default SkuChildTable
