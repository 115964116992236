import Button from '@src/@core/components/ui/button'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import { SKU_TYPES } from '@src/App.constants'
import { BUNDLE } from '@src/views/catalog/catalog.constants'
import { RETURN_ORDER_TABS, RETURN_STATUS } from '@src/views/returns/returns.constants'
import { ORDER_ITEM_SOURCES } from '@src/views/sales/sales.constant'
import { showSkuTypeTag } from '@src/views/sales/sales.utils'
import { t } from 'i18next'
import { useMemo } from 'react'

const useReturnItemsTableColumns = ({ 
  order_items, 
  returnOrderDetail, 
  handleSelectItem, 
  handleReturnReasonsPopUp, 
  handleBundleSkuDetailsSidebar, 
  handleSkuDetailsSidebar 
}) => {
  return useMemo(() => {
    return [
      {
        id: 1,
        name: t('SKU Details'),
        minWidth: '340px',
        maxWidth: '500px',
        cell: (row) => {
          const hasFreeItem = row.source === ORDER_ITEM_SOURCES.AUTOMATION_RULE.value && row.unit_price === 0
          const hasBundle = !!showSkuTypeTag(row.seller_sku.type)

          const customTagsArray = [
            hasFreeItem && {
              className: 'text-purple bg-purple-light',
              title: showSkuTypeTag(SKU_TYPES.GIFT.key),
            },
            hasBundle && {
              className: 'text-info bg-info-light',
              title: showSkuTypeTag(row.seller_sku.type),
            },
          ].filter(Boolean)

          return (
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: true,
                id: row.id,
                image: {
                  src: row.seller_sku.images?.[0]?.default,
                  alt: row.seller_sku.name,
                },
                details: {
                  name: row.seller_sku.name,
                  skuCode: row.seller_sku_code,
                  columnKey: 'returns_sku_details',
                  handleSkuDetails: () => {
                    const { type } = row.seller_sku
                    return type === BUNDLE ? handleBundleSkuDetailsSidebar(row) : handleSkuDetailsSidebar(row)
                  },
                },
                customTags: customTagsArray,
              }}
            />
          )
        },
      },
      {
        id: 2,
        name: <div className='w-100 text-end'>{returnOrderDetail?.type === RETURN_ORDER_TABS.COURIER_PARTNER_RETURN.name ? t('Shipped Qty') : t('Delivered Qty')}</div>,
        minWidth: '120px',
        omit: returnOrderDetail?.type === RETURN_ORDER_TABS.OFF_SYSTEM_RETURN.name,
        cell: (row) => <div className='w-100 text-end'>{row.delivered_quantity || 0}</div>,
      },
      {
        id: 3,
        name: <div className='w-100 text-end'>{t('Return Qty')}</div>,
        minWidth: '120px',
        cell: (row) => <div className='w-100 text-end'>{row.return_quantity || 0}</div>,
      },
      {
        id: 4,
        name: <div className='w-100 text-end'>{t('Return Weight')}</div>,
        minWidth: '120px',
        omit: !returnOrderDetail?.is_weighted,
        cell: (row) => <div className='w-100 text-end'>{row.display_return_weight || '-'}</div>,
      },
      {
        id: 5,
        name: <div className='w-100 text-end'>{t('Passed Qty')}</div>,
        minWidth: '120px',
        omit: !(
          returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name ||
          returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name ||
          returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name
        ),
        cell: (row) => <div className='w-100 text-end text-success'>{row.pass_quantity || 0}</div>,
      },
      {
        id: 6,
        name: <div className='w-100 text-end'>{t('Failed Qty')}</div>,
        omit: !(
          returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name ||
          returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name ||
          returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name
        ),
        minWidth: '120px',
        cell: (row) => <div className='w-100 text-end text-danger'>{row.fail_quantity || 0}</div>,
      },
      {
        id: 7,
        name: <div className='w-100 text-end'>{t('Refunded Qty')}</div>,
        omit: returnOrderDetail?.status !== RETURN_STATUS.RETURN_COMPLETED.name,
        minWidth: '120px',
        cell: (row) => <div className='w-100 text-end'>{row.refunded_quantity || 0}</div>,
      },
      {
        id: 8,
        name: <div className='w-100 text-end'>{t('Unit Price')}</div>,
        minWidth: '160px',
        cell: (row) => (
          <div className='w-100 text-end'>
            {row.unit_price.toFixed(2)}&nbsp;
            {returnOrderDetail?.invoice.currency}
          </div>
        ),
      },
      {
        id: 9,
        name: <div className='w-100 text-end'>{t('Discount')}</div>,
        minWidth: '160px',
        cell: (row) => (
          <div className='w-100 text-end'>
            {row.discount.toFixed(2)}&nbsp;
            {returnOrderDetail?.invoice.currency}
          </div>
        ),
      },
      {
        id: 10,
        name: <div className='w-100 text-end'>{t('Tax(%)')}</div>,
        minWidth: '120px',
        cell: (row) => <div className='w-100 text-end'>{row.tax_percent}</div>,
      },
      {
        id: 11,
        name: <div className='w-100 text-end'>{t('Tax Amount')}</div>,
        minWidth: '160px',
        cell: (row) => (
          <div className='w-100 text-end'>
            {row.tax.toFixed(2)}&nbsp;
            {returnOrderDetail?.invoice.currency}
          </div>
        ),
      },
      {
        id: 12,
        name: <div className='w-100 text-end'>{t('Total Price')}</div>,
        minWidth: '160px',
        cell: (row) => (
          <div className='w-100 text-end'>
            {row.total.toFixed(2)}&nbsp;
            {returnOrderDetail?.invoice.currency}
          </div>
        ),
      },
      {
        id: 13,
        name: t('Failed Reason'),
        minWidth: '120px',
        omit: !(
          returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name ||
          returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name ||
          returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name
        ),
        cell: (row) => {
          return (
            <div className='flex-center-center w-100'>
              {row.qc_fail_reasons?.length > 0 ? (
                <Button ofType='compressed' ofStyle='noBackground' className='txt-sub-rg' onClick={() => handleSelectItem(row)}>
                  {t('More Details')}
                </Button>
              ) : (
                '-'
              )}
            </div>
          )
        },
      },
      {
        id: 14,
        name: t('Return Reasons'),
        minWidth: '160px',
        omit:
          returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name ||
          returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name ||
          returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name,
        cell: (row) => {
          return (
            <div className='flex-center-center w-100'>
              {row.return_reasons?.length > 0 ? (
                <Button ofType='compressed' ofStyle='noBackground' className='txt-sub-rg' onClick={() => handleReturnReasonsPopUp({ reasonsList: row.return_reasons })}>
                  {t('More Details')}
                </Button>
              ) : (
                '-'
              )}
            </div>
          )
        },
      },
    ]
  }, [order_items, returnOrderDetail, handleSelectItem, handleReturnReasonsPopUp, handleBundleSkuDetailsSidebar, handleSkuDetailsSidebar])
}

export default useReturnItemsTableColumns 