import Button from '@src/@core/components/ui/button'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import { MAX_FILE_SIZE_ALLOWED_IN_BYTES, MIN_FILE_SIZE_ALLOWED_IN_BYTES } from '@src/App.constants'
import { BulkUploadSvg, NoAttatchmentsIllustration } from '@src/assets/data/assets'
import image_fallback from '@src/assets/images/omniful/image_fallback.svg'
import RefreshTableIcon from '@src/assets/images/svg/table/refreshTableIcon'
import classNames from 'classnames'
import { Fragment } from 'react'
import { Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Col, Progress, Row, Spinner } from 'reactstrap'
import AddDescription from './addDescription'
import FileIcon from './file-icon'
import useUploadAttachments from './hooks/useUploadAttachments'
import Preview from './preview/preview'

const EmptyState = () => {
  const { t } = useTranslation()
  return (
    <div className='border border-light-4 rounded-8px flex-center-center attachments-empty-state'>
      <div className='d-flex flex-column gap-24px width-fit-content align-items-center'>
        <div>
          <NoAttatchmentsIllustration />
        </div>
        <div className='d-flex flex-column gap-4px align-items-center'>
          <div className='txt-body-md text-dark'>{t('No Attachments Uploaded Yet')}</div>
          <div className='txt-sub-rg text-dark-6'>{t('You haven’t uploaded any files yet.')}</div>
        </div>
      </div>
    </div>
  )
}

const UploadAttachments = ({
  handleUploadFiles,
  isUploading = false,
  minFileSizeInBytes = MIN_FILE_SIZE_ALLOWED_IN_BYTES,
  maxFileSizeInBytes = MAX_FILE_SIZE_ALLOWED_IN_BYTES,
  formObject,
  uploadDetails
}) => {
  const { t } = useTranslation()
  const {
    filesAreBeingUploadedOnS3,
    getInputProps,
    getRootProps,
    handleAddDescription,
    handleRemoveDescription,
    handleRemoveFiles,
    handleRetryUpload,
    handleToggleDescription,
    handleTogglePreview,
    isDescriptionPopupOpen,
    previewingAttachment,
    selectedAttachment

  } = useUploadAttachments({
    isUploading,
    minFileSizeInBytes,
    maxFileSizeInBytes,
    formObject,
    uploadDetails
  })

  return (
    <div className="attachments-main-div w-100 d-flex flex-column gap-24px">
      <Fragment>
        <Row className='bulk-upload'>
          <Col sm='12'>
            <Card>
              <CardBody className="d-flex flex-column container-border"
              >
                <div className="mb-24px">
                  <BulkUploadSvg primary="var(--bs-primary)" width={140} height={124} />
                </div>
                <Row>
                  <Col sm='12'>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <div className="sample-upload d-flex flex-column align-items-center">
                        <h2 className="txt-h2-md m-0">
                          {t('Drag Your File Here Or')}{' '}
                          <a href='/' onClick={e => e.preventDefault()} className={classNames('text-primary', { 'opacity-50': isUploading, 'cursor-pointer': !isUploading })}>
                            {t('Browse Files')}
                          </a>
                        </h2>
                        <p className="txt-sub-rg m-0 text-dark-5">
                          {`(${t('Acceptable files are documents and images, and the file size should not exceed 10MB.')})`}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment> 
      <div className='d-flex flex-column gap-8px'>
        <div className='txt-body-rg text-dark py-8px' >{t('Attachments to be uploaded')}</div>
        {formObject.watch('uploadedFiles')?.length ? (
          <div className='d-flex flex-column gap-16px'>
            {
              formObject.watch('uploadedFiles')?.map((item, index) => {
                const fileType = item.file.type.split('/')[0] // Get the file type (image, video, text, etc.)
                const fileName = item.file.name
                const fileUrl = item.preview_url || item.file_url
                return (
                  <div key={index} className={classNames('rounded-8px border border-dark-2 bg-white p-10px flex-center-between upload_file_details', {'border-danger-light': item.error})}>
                    <div className={classNames('flex-center-start gap-10px', {
                      'width-80-per': !item.uploading,
                      'w-100': item.uploading
                    })}>
                      {fileType === 'image' ?
                        <div className='file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2'>
                          <img
                            src={fileUrl || image_fallback}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null
                              currentTarget.src = image_fallback
                            }}
                            alt={fileName}
                            className='rounded-4px object-fit-contain w-100 h-100'
                          />
                        </div>
                        :
                        <div className="rounded-4px bg-light-2 flex-center-center file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2">
                          <FileIcon fileType={fileType} size={24} />
                        </div>
                      }
                      <div className={classNames('d-flex flex-column width-90-per', {
                        'gap-4px': item.description,
                        'gap-12px': !item.description
                      })}>
                        <div className='d-flex flex-column gap-2px w-100'>
                          <div
                            className="txt-sub-md text-dark w-100 text-truncate"
                          >
                            {fileName}
                          </div>
                          {!item.uploading && !item.error && item.description && <div className="txt-sub-rg text-dark-6 w-100 text-truncate">{item.description}</div>}
                        </div>
                        {!item.uploading && !item.error ?
                          (item.description ?
                            <div className='flex-center-start gap-16px'>
                              <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleToggleDescription(item, index)}>
                                {t('Edit')}
                              </Button>
                              <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleRemoveDescription(index, '')}>
                                {t('Remove')}
                              </Button>
                            </div> :
                            <div>
                              <Button ofType="compressed" ofStyle="noBackground" className="txt-sub-md p-0 shadow-none" onClick={() => handleToggleDescription(item, index)}>
                                { t('Add Description')}
                              </Button>
                            </div>

                          ) : null
                        }
                        {item.uploading && <div>
                          <Progress color="var(--bs-primary)" value={item.progress} className="height-4px" />
                        </div>}
                        {item.error && <div className='flex-center-start gap-2px cursor-pointer' onClick={() => handleRetryUpload(item)}>
                          <div className='txt-asst-md text-danger'>{t('Failed')}</div>
                          <RefreshTableIcon width={16} height={16} color="var(--bs-danger)" />
                        </div>}
                      </div>
                    </div>
                    <div className="flex-center-end gap-16px flex-shrink-0">
                      {!item.uploading && <Trash2 
                        size={16} color="var(--bs-danger)" 
                        className="cursor-pointer"
                        onClick={() => handleRemoveFiles(index)}
                      />}
                    </div>
                  </div>
                )
              })
            }
          </div>
        ) : <EmptyState isDirty={formObject.formState.isDirty} />}
      </div>

      {formObject.watch('uploadedFiles')?.length ?
        <SidesheetFooter>
          <div className="flex-center-end gap-12px">
            <Button onClick={handleUploadFiles} disabled={isUploading || filesAreBeingUploadedOnS3} icon={isUploading ? Spinner : null} iconSize="sm">
              {t('Upload')}
            </Button>
          </div>
        </SidesheetFooter> : null}
      <Preview 
        isOpen={!!previewingAttachment} 
        toggle={handleTogglePreview} 
        previewingAttachment={previewingAttachment}
      />
      <AddDescription 
        isOpen={isDescriptionPopupOpen}
        toggle={handleToggleDescription}
        index={selectedAttachment?.index}
        description={selectedAttachment?.description}
        handleDescription={handleAddDescription} 
      />
    </div>
  )
}

export default UploadAttachments