import { PRICE_BREAK_DOWN_SECTION } from '@src/App.constants'
import { isObjEmpty } from '@src/utility/Utils'
import { useState } from 'react'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import AdditionalCharges from './additional-charges'
import InclusiveTaxModal from './InclusiveTaxModal'
import './PriceBreakDownSection.scss'
import TotalAmount from './total-amount'

export const TAX_TYPES = {
  SHIPPING_TAX: { key: 'Shipping Tax', value: 'shipping_tax' },
  SUB_TOTAL:{key:'Sub Total', value:'sub_total'}
}

export const PriceBreakDownSection = ({ orderDetails, orderType, show_price_section_keys }) => {
  const { t } = useTranslation()

  const [taxModal, setTaxModal] = useState(false)
  const [taxType, setTaxType] = useState()

  const subTotalInclusiveDiscount = orderDetails.invoice.sub_total_discount_inclusive && orderDetails.invoice.discount
  const subTotalInclusiveTax = orderDetails.invoice.sub_total_tax_inclusive && orderDetails.invoice.tax_percent
  const shippingInclusiveTax = orderDetails.invoice.shipping_tax
  
  const currency = orderDetails.invoice.currency
  const { order_currency, store_currency } = orderDetails.invoice.total_due_price_set

  const showTotalDuePriceSet = (order_currency.amount && store_currency.amount) && (order_currency.currency_code !== store_currency.currency_code)
  const adjustmentFees = (orderDetails?.invoice.adjustment_fees && orderDetails.invoice.adjustment_fees[0]) || {};

  const show_keys = {
    show_sub_total:true,
    show_total_discount:true,
    show_total_tax:true,
    show_additional_charges:true,
    show_shipping_cost:true,
    show_shipping_tax:true,
    show_horizontal_divider:true,
    show_grand_total:true,
    show_total_amount_paid:true,
    show_paid_amounts:true,
    show_total_refund:true,
    show_refunded_amounts: true,
    show_total_due: true,
    show_additional_charges_section: false,
    show_total_refund_section: false,
    show_items_total: false,
    ...show_price_section_keys
  }
  
  return <>
    {orderDetails ? <div className="d-flex justify-content-end price-break-down-wrapper">
      <div className="width-35-per">

        {show_keys.show_sub_total && <Row>
          <Col className="col-6 text-start">
            <span className="txt-h3-rg">{t('Sub Total')}:</span>
          </Col>
          <Col className="col-6 text-end">
            <span className="text-dark txt-h3-rg">
              {orderDetails.invoice.subtotal
                ? orderDetails.invoice.subtotal.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}
            </span>
            <br/>
            {(orderDetails.invoice.sub_total_discount_inclusive ||
              orderDetails.invoice.sub_total_tax_inclusive) && (
              <span
                className="text-primary cursor-pointer"
                onClick={() => {
                  setTaxModal(true)
                  setTaxType(TAX_TYPES.SUB_TOTAL.value)
                }}
              >
                {orderDetails.invoice.sub_total_discount_inclusive &&
                    !orderDetails.invoice.sub_total_tax_inclusive &&
                    t('Incl. of Discount')}
                {orderDetails.invoice.sub_total_tax_inclusive &&
                    !orderDetails.invoice.sub_total_discount_inclusive &&
                    t('Incl. of Tax')}
                {orderDetails.invoice.sub_total_discount_inclusive &&
                    orderDetails.invoice.sub_total_tax_inclusive &&
                    t('Incl. of Discount and Tax')}
              </span>
            )}
          </Col>
        </Row>}

        {show_keys.show_items_total && (
          <div className='mt-1'>
            <TotalAmount
              itemsTotal={orderDetails.invoice.items_total.toFixed(2)}
              subtotal={orderDetails.invoice.subtotal}
              tax={orderDetails.invoice.tax}
              currency={currency}
            />
          </div>
        )}

        {show_keys.show_total_discount && !orderDetails.invoice.sub_total_discount_inclusive && <Row className="mt-1 text-start">
          <Col className="col-6">
            <span className="txt-h3-rg">{t('Total Discount')}:</span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h3-rg text-success">
              {orderDetails.invoice.discount
                ? orderDetails.invoice.discount.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}
            </span>
          </Col>
        </Row>}

        {show_keys.show_total_tax && !orderDetails.invoice.sub_total_tax_inclusive && <Row className="mt-1 text-start">
          <Col className="col-6">
            <span className="txt-h3-rg">{t('Total Tax')}:</span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h3-rg text-dark">
              {orderDetails.invoice.tax
                ? orderDetails.invoice.tax.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}{' '}
            </span>
          </Col>
        </Row>}

        {show_keys.show_additional_charges && orderType !== PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value && orderDetails.invoice.additional_charges?.length > 0 &&
          orderDetails.invoice.additional_charges.map((charge) => (
            <Row key={charge.display_name} className="mt-1">
              <Col className="col-6 text-start">
                <span className="txt-h3-rg">{charge.display_name}:</span>
              </Col>
              <Col className="col-6 text-end">
                <span className="txt-h3-rg text-dark">
                  {charge.value.toFixed(2)} {orderDetails.invoice.currency}
                </span>
              </Col>
            </Row>
          ))}

        {show_keys.show_shipping_cost && orderType !== PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value && <Row className="mt-1">
          <Col className="col-6 text-start">
            <span className="txt-h3-rg">{t('Shipping Cost')}:</span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h3-rg text-dark">
              {orderDetails.invoice.shipping_price
                ? orderDetails.invoice.shipping_price.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}{' '}
            </span>
            <br />
            {orderDetails.invoice.shipping_tax_inclusive && (
              <span
                className="text-primary cursor-pointer"
                onClick={() => {
                  setTaxModal(true)
                  setTaxType(TAX_TYPES.SHIPPING_TAX.value)
                }}
              >
                {t('Incl of Tax')}
              </span>
            )}
          </Col>
        </Row>}

        {show_keys.show_shipping_tax && orderType !== PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value && !orderDetails.invoice.shipping_tax_inclusive && <Row className="mt-1 ">
          <Col className="col-6 text-start">
            <span className="txt-h3-rg">{t('Shipping Tax')}:</span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h3-rg text-dark">
              {orderDetails.invoice.shipping_tax
                ? orderDetails.invoice.shipping_tax.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}{' '}
            </span>
          </Col>
        </Row>}

        {show_keys.show_additional_charges_section && (
          <div className='mt-1'>
            <AdditionalCharges
              additionalCharges={orderDetails.invoice.additional_charges}
              currency={currency}
            />
          </div>
        )}

        {orderType === PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value &&  !isObjEmpty(adjustmentFees) && <Row className="mt-1 ">
          <Col className="col-6 text-start">
            <span className="txt-h3-rg text-dark-5">{t('Refund Adjustment')}:</span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h3-rg text-dark">
              {(adjustmentFees.amount|| 0).toFixed(2)}{' '}
              {orderDetails.invoice.currency}{' '}
            </span>
          </Col>
        </Row>}

        {show_keys.show_horizontal_divider && <div className="border-top my-20px"></div>}

        {show_keys.show_grand_total && <Row className="mt-1">
          <Col className="col-6 text-start">
            <span className="txt-h1-md text-dark w-auto">
              {t('Grand Total')}:
            </span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h1-md text-dark">
              {orderDetails.invoice.total
                ? orderDetails.invoice.total.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}{' '}
            </span>
          </Col>
        </Row>}

        {show_keys.show_total_refund_section && <Row className="mt-1">
          <Col className="col-6 text-start">
            <span className="txt-h1-md text-dark w-auto">
              {t('Total Refund')}:
            </span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h1-md text-dark">
              {orderDetails.invoice.total
                ? orderDetails.invoice.total.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}{' '}
            </span>
          </Col>
        </Row>}

        {show_keys.show_total_amount_paid && orderType !== PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value && <Row className="mt-1">
          <Col className="col-6 text-start">
            <span className="txt-h3-rg">{t('Total Paid Amount')}:</span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h3-rg text-dark">
              {orderDetails.invoice.total_paid_amount
                ? orderDetails.invoice.total_paid_amount.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}{' '}
            </span>
          </Col>
        </Row>}

        {orderType !== PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value && <Col className="ps-10px">
          {orderDetails.invoice.paid_amounts?.map((amount) => <Row key={amount.display_name} className="mt-1">
            <Col className="col-6 text-start">
              <span className="txt-body-rg">{t(amount.display_name)}:</span>
            </Col>
            <Col className="col-6 text-end">
              <span className="txt-body-rg text-dark">{amount.value.toFixed(2)} {orderDetails.invoice.currency}</span>
            </Col>
          </Row>)}
        </Col>}

        {show_keys.show_total_refund && <Row className="mt-1">
          <Col className="col-6 text-start">
            <span className="txt-h3-rg">{t('Total Refund')}:</span>
          </Col>
          <Col className="col-6 text-end">
            <span className="txt-h3-rg text-dark">
              {orderDetails.invoice.total_refunded
                ? orderDetails.invoice.total_refunded.toFixed(2)
                : '0'}{' '}
              {orderDetails.invoice.currency}{' '}
            </span>
          </Col>
        </Row>}

        {show_keys.show_refunded_amounts && orderDetails.invoice.refunded_amounts?.length > 0 && <Col className="ps-10px">
          {orderDetails.invoice.refunded_amounts.map((amount) => <Row key={amount.display_name} className="mt-1">
            <Col className="col-6 text-start">
              <span className="txt-body-rg">{t(amount.display_name)}:</span>
            </Col>
            <Col className="col-6 text-end">
              <span className="txt-body-rg text-dark">{amount.value.toFixed(2)} {orderDetails.invoice.currency}</span>
            </Col>
          </Row>)}
        </Col>}

        {show_keys.show_total_due && orderType !== PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value ?
          showTotalDuePriceSet ? <Row className="mt-1">
            <Col className="col-6 flex-center-start gap-4px text-start txt-h3-rg">
              <span>{t('Total Due')}:</span>
              <Info id='total_due_info' size={16} className="cursor-pointer" />
              <UncontrolledTooltip offset={[0, 5]} target='total_due_info' style={{ textAlign: 'left' }}>
                {t(`This shows the order's currency and the store's currency (in brackets), based on the exchange rate provided by  ${orderDetails.sales_channel.name}`)}
              </UncontrolledTooltip>
            </Col>
            <Col className="col-6 text-end text-dark">
              <span className="txt-h3-rg">
                {order_currency.amount}{' '}
                {order_currency.currency_code}
              </span>
              {' '}
              (
              <span className="txt-h3-rg">
                {store_currency.amount}{' '}
                {store_currency.currency_code}
              </span>
              )
            </Col>
          </Row>
            :
            <Row className="mt-1">
              <Col className="col-6 text-start">
                <span className="txt-h3-rg">{t('Total Due')}:</span>
              </Col>
              <Col className="col-6 text-end">
                <span className="txt-h3-rg text-dark">
                  {orderDetails.invoice.total_due
                    ? orderDetails.invoice.total_due.toFixed(2)
                    : '0'}{' '}
                  {orderDetails.invoice.currency}{' '}
                </span>
              </Col>
            </Row>
          :
          null
        }
      </div>
    </div> : null}
    <InclusiveTaxModal
      taxModal={taxModal}
      setTaxModal={setTaxModal}
      subTotalInclusiveDiscount={subTotalInclusiveDiscount}
      subTotalInclusiveTax={subTotalInclusiveTax}
      shippingInclusiveTax={shippingInclusiveTax}
      currency={currency}
      taxType={taxType}
    />
  </>
}