import Button from '@src/@core/components/ui/button'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import { RETURN_STATUS } from '@src/views/returns/returns.constants'
import { t } from 'i18next'
import { useMemo } from 'react'

const useReturnItemsChildTableColumns = ({ 
  order_items, 
  returnOrderDetail, 
  handleSelectItem, 
  handleSkuDetailsSidebar 
}) => {
  return useMemo(() => {
    return [
      {
        id: 1,
        name: t('SKU Details'),
        minWidth: '200px',
        maxWidth: '300px',
        cell: (row) => {
          return (
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: true,
                id: row.id,
                image: {
                  src: row.images?.[0]?.default,
                  alt: row.name,
                },
                details: {
                  name: row.name,
                  skuCode: row.seller_sku_code,
                  columnKey: 'sku_details',
                  handleSkuDetails: row.id ? () => handleSkuDetailsSidebar(row) : null,
                },
              }}
            />
          )
        },
      },
      {
        id: 2,
        name: <div className='w-100 text-end'>{t('Qty Per Kit')}</div>,
        minWidth: '120px',
        cell: (row) => <div className='w-100 text-end'>{row.quantity || 0}</div>,
      },
      {
        id: 3,
        name: <div className='w-100 text-end'>{t('Return Qty')}</div>,
        minWidth: '120px',
        cell: (row) => <div className='w-100 text-end'>{row.return_quantity || 0}</div>,
      },
      {
        id: 4,
        name: <div className='w-100 text-end'>{t('Return Weight')}</div>,
        minWidth: '120px',
        omit: !returnOrderDetail?.is_weighted,
        cell: (row) => <div className='w-100 text-end'>{row.display_return_weight || '-'}</div>,
      },
      {
        id: 5,
        name: <div className='w-100 text-end'>{t('Passed Qty')}</div>,
        minWidth: '120px',
        omit: !(
          returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name ||
          returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name ||
          returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name
        ),
        cell: (row) => <div className='w-100 text-end text-success'>{row.pass_quantity || 0}</div>,
      },
      {
        id: 6,
        name: <div className='w-100 text-end'>{t('Failed Qty')}</div>,
        omit: !(
          returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name ||
          returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name ||
          returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name
        ),
        minWidth: '120px',
        cell: (row) => <div className='w-100 text-end text-danger'>{row.fail_quantity || 0}</div>,
      },
      {
        id: 7,
        name: <div className='w-100 text-end'>{t('Refunded Qty')}</div>,
        omit: returnOrderDetail?.status !== RETURN_STATUS.RETURN_COMPLETED.name,
        minWidth: '120px',
        cell: (row) => <div className='w-100 text-end'>{row.refunded_quantity || 0}</div>,
      },
      {
        id: 8,
        name: <div className='w-100 text-end'>{t('Unit Price')}</div>,
        minWidth: '160px',
        cell: (row) => (
          <div className='w-100 text-end'>
            {row.unit_price?.toFixed(2)}&nbsp;
            {returnOrderDetail?.invoice.currency}
          </div>
        ),
      },
      {
        id: 9,
        width: '120px',
        omit: !(
          returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name ||
          returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name ||
          returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name
        ),
        cell: (row) => {
          return (
            <div className='flex-center-center w-100'>
              {row.qc_fail_reasons?.length > 0 ? (
                <Button ofType='compressed' ofStyle='noBackground' onClick={() => handleSelectItem(row)}>
                  {t('More Details')}
                </Button>
              ) : (
                '-'
              )}
            </div>
          )
        },
      },
    ]
  }, [order_items, returnOrderDetail, handleSelectItem, handleSkuDetailsSidebar])
}

export default useReturnItemsChildTableColumns 