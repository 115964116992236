import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import Modal from '@src/@core/components/ui/modal/Modal'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { API_ENDPOINTS } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { ORDER_DETAILS_BUTTON_TYPE } from '@src/views/sales/sales.constant'
import { clearCreateOrderItems, clearInvoiceData, getOrderLogs, resetSuccess, syncOrderStatus } from '@src/views/sales/store/store'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { RefreshCcw } from 'react-feather'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import { clearOrderLogs } from '../../../store/store'
import { OrderLogs } from '../../pages/orderDetails/components/order-logs'
import OnHoldItemDetails from './components/Tabs/OnHoldItemDetails'
import './styles.scss'

const componentMap = {
  item_details: OnHoldItemDetails,
  order_logs: OrderLogs
}

/**
 * Renders the side sheet for displaying details of an on-hold order.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - isSideSheetOpen: A boolean indicating whether the side sheet is open.
 *   - orderId: The selected order id.
 *   - handleSideSheetClose: A function to handle closing the side sheet.
 *   - handleGetParentData: A function to handle getting parent data.
 * @return {JSX.Element} The rendered side sheet component.
 */

const OnHoldOrderDetailsSideSheet = (props) => {
  const { isSideSheetOpen, orderId, handleSideSheetClose, handleGetParentData = () => { } } = props

  const ability = useContext(AbilityContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const syncOrderStatusLoading = useSelector(store => store.sales.loading.syncOrderStatus)
  const syncOrderStatusSuccess = useSelector(store => store.sales.success.syncOrderStatus)

  const [isSyncConfirmationPopupOpen, setSyncConfirmationPopupOpen] = useState(false)

  const ON_HOLD_ORDER_DETAILS_TABS = [
    { id: 'item_details', name: 'Item Details' },
    ability.can(abilityMap.order.view_log.action, abilityMap.order.view_log.resource) && { id: 'order_logs', name: 'Order Log' }
  ]

  const [activeTab, setActiveTab] = useState(ON_HOLD_ORDER_DETAILS_TABS[0].id)
  const ActiveComponent = componentMap[activeTab]
  const handleOnTabChange = tab => {
    setActiveTab(tab.id)
  }

  const handleOnClosed = () => {
    setActiveTab(ON_HOLD_ORDER_DETAILS_TABS[0].id)
    dispatch(clearOrderLogs())
    dispatch(clearCreateOrderItems())
  }

  const {data: onHoldOrderDetails, isFetching, isError, isSuccess, refetch: getSingleOnHoldOrderDetails} = useQuery({
    queryKey: ['single-hold-order-details', orderId],
    queryFn: () => {
      const url = getApiUrl(`${API_ENDPOINTS.ORDERS.GET_SINGLE_ON_HOLD_ORDER}`, { orderId:orderId })
      return axiosInstance.get(url)
    },
    select: ({ data }) => {
      if (data?.data) {
        return data.data
      } else return null
    },
    enabled: !!isSideSheetOpen
  })

  const subTextData = []
  if (onHoldOrderDetails?.order_alias) {
    subTextData.push(`${t('Reference Order ID')}: ${onHoldOrderDetails.order_alias}`)
  }

  const handleSyncConfirmationPopup = () => {
    setSyncConfirmationPopupOpen(!isSyncConfirmationPopupOpen)
  }

  const handleOrderSync = () => {
    const params = {order_id: orderId}
    dispatch(syncOrderStatus(params))
  }
  const handleToastSuccessMessage = (data) => {
    const {items_added, items_removed} = data
    if (items_added && items_removed) {
      return `${items_added} new item(s) added and ${items_removed} item(s) removed from this order.`
    } else if (items_added) {
      return `${items_added} new item(s) added to this order.`
    } else if (items_removed) {
      return `${items_removed} item(s) removed from this order.`
    } else {
      return ''
    }    
  }
  const destroyCustomToast = () => {
    toast.dismiss()
  }
  

  useEffect(() => {
    if (syncOrderStatusSuccess && isSideSheetOpen) {
      handleSyncConfirmationPopup()
      CustomToast(
        `Order Synced Successfully.\n${handleToastSuccessMessage(syncOrderStatusSuccess.data)}`,
        {
          my_type: 'success',
          duration: Infinity, 
          handleCloseToast: destroyCustomToast
        }
      );
      dispatch(resetSuccess());
      

      if (activeTab === ON_HOLD_ORDER_DETAILS_TABS[1]?.id) dispatch(getOrderLogs(orderId))
      getSingleOnHoldOrderDetails()
      dispatch(resetSuccess())
      dispatch(clearInvoiceData())
    }
  }, [syncOrderStatusSuccess])
  

  const isSyncOrderPresent = onHoldOrderDetails?.buttons?.find(action => action.key === ORDER_DETAILS_BUTTON_TYPE.SYNC_BUTTON.key)
  const storeName = onHoldOrderDetails?.sales_channel?.store_name

  return (
    <>
      <SideSheet
        isOpen={isSideSheetOpen}
        toggle={handleSideSheetClose}
        unmountOnClose={true}
        onClosed={handleOnClosed}
        modalClassName="modal-slide-in sidebar-todo-modal on-hold-order-details-sidesheet-container"
        contentClassName="p-0 bg-white"
        size="lg"
        title={<>{t('Order ID')}: {onHoldOrderDetails?.seller_sales_channel_order_id} {isSyncOrderPresent && <span className="flex-center-start gap-12px pending-actions">
          <Button onClick={handleSyncConfirmationPopup} disabled={syncOrderStatusLoading} id="sync_pending_order_status" ofStyle="outlined" ofType="compressed" className="rounded-12px txt-sub-md px-12px">
            <RefreshCcw className={classNames({'sync-rotate-icon': syncOrderStatusLoading })} size={10} />
            {t('Sync Order')}
          </Button>
          <UncontrolledTooltip placement="top" target='sync_pending_order_status' offset={[0, 5]} trigger="hover" autoFocus={false}>
            {`Fetch order details from ${storeName}.`}
          </UncontrolledTooltip>
        </span>}</>}
        subTextData={subTextData}
        hasClearButton
        customBackground
        headerWithTabs
        tabDataList={ON_HOLD_ORDER_DETAILS_TABS}
        onTabChange={handleOnTabChange}
        selectedTabId={activeTab}
      >
        <div className='side-sheet-body-wrapper'>
          <ActiveComponent
            orderId={orderId}
            isSideSheetOpen={isSideSheetOpen}
            onHoldOrderDetails={onHoldOrderDetails} 
            isFetching={isFetching}
            isError={isError}
            isSuccess={isSuccess}
            getSingleOnHoldOrderDetails={getSingleOnHoldOrderDetails}
            handleGetParentData={handleGetParentData}
            handleSideSheetClose={handleSideSheetClose}
          />
        </div>
      </SideSheet>
      <Modal
        key='sync_order_modal_pending_sidesheet'
        isOpen={isSyncConfirmationPopupOpen}
        isTranslationRequired={false}
        closeModalHandler={handleSyncConfirmationPopup}
        secondaryButtonHandler={handleSyncConfirmationPopup}
        primaryButtonHandler={handleOrderSync}
        secondaryButtonText='Cancel'
        primaryButtonText='Yes, Update'
        title='Are you sure you want to Sync the order?'
        subTitle='Syncing the order will update it with the latest changes, except for any modifications to the shipping address'
        primaryButtonProps={{
          loading: syncOrderStatusLoading
        }}
      />
    </>
  )
}

OnHoldOrderDetailsSideSheet.propTypes = {
  isSideSheetOpen: PropTypes.bool,
  handleSideSheetClose: PropTypes.func,
  orderId: PropTypes.string,
  handleGetParentData: PropTypes.func
}

export default OnHoldOrderDetailsSideSheet