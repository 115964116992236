import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, COURIER_RETURN_OPTIONS, GET_RETURN_STATUS_WISE_FILTERS, RETURN_ORDER_COLUMNS_LIST, RETURN_ORDER_TABS, RETURN_STATUS, STATUS_FILTER_DROPDOWN_STYLE, getCourierPartnerReturnColumns } from '../../../returns.constants'
import { getReturnOrderDetail, getReturnOrders, markOrdersAsReturnedTohHub, resetSuccess } from '../../../store'
import ReturnOrderDetail from '../return-order-details'
// import ReverseShipment from "../create-reverse-shipment"
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import CustomFilter from '@src/@core/components/ui/customTableFilterComponent'
import { DROPDOWN_TYPES } from '@src/@core/components/ui/customTableFilterComponent/customTableFilterComponent.constant'
import DateFilter from '@src/@core/components/ui/date-filter'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import CustomDropdownOption from '@src/@core/components/ui/dropdown/customDropdownOption'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { COLLAPSED_CONTAINER_LIST, HUBS_CONFIGURATION, TENANT_TYPE } from '@src/App.constants'
import { loadAllSellersConfig, loadCourierPartnersConfig, loadSellerSalesChannelConfig } from '@src/app.apis'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { ROUTES_KEYS } from '@src/router/route-keys'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'
import { getOptionsFetcher } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters'
import { checkActionColumnVisibility, getReturnOrderCustomFilters } from '@src/views/returns/returns.utils'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import CreateShipmentSideSheet from '@src/views/sales/live-orders/pages/allOrders/CreateShipmentSideSheet'
import { SHIPMENT_STATUS_UPDATE_ENTITY } from '@src/views/sales/sales.constant'
import ShipmentCountHeader from '@src/views/sales/shipments/components/ShipmentCountHeader'
import ShipmentStatusUpdateModal from '@src/views/sales/shipments/components/confirmationPopup/ShipmentStatusUpdateModal'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { STRINGIFY } from '../../../../../utility/hooks/useFilters'
import TypeOfReturnsInformation from '../../../components/TypeOfReturnsInformation/index'
import CreateReturnOrderProcessing from '../../return-order-processing/create-return-order-processing'
import ReturnOrderCancelPopup from '@src/views/returns/components/popup/ReturnOrderCancelPopup'

const CourierPartnerReturnOrder = (props) => {
  const { activeTab, handleCreateReverseShipmentModal } = props
  
  // Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Stores
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const selfTenantSellerId = useSelector((state) => state.auth.userData.sellers[0]?.seller_id)
  const loading = useSelector((store) => store.returns.loading)
  const success = useSelector((store) => store.returns.success)
  const error = useSelector((store) => store.returns.error)
  const returnOrders = useSelector((store) => store.returns.returnOrders)
  const selectedHubConfiguration = useSelector(state => state.auth.selectedHubConfiguration) 
  
  // States
  const [tableData, setTableData] = useState([])
  const [isReturnOrderDetailModalOpen, setReturnOrderDetailModalOpen] = useState(false)
  const [isOrderDetailSidebarOpen, setOrderDetailSidebarOpen] = useState(false)
  const [returnOrderToShowDetail, setReturnOrderToShowDetail] = useState(null)
  const [isReturnOrderCancelPopupOpen, setIsCancelReturnOrderPopupOpen] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const { filter, setFilter } = useFilters({})
  const { searchQuery, setSearchQuery } = useSearch({})
  const {pagination, setPagination} = usePagination()
  const {t} = useTranslation()
  const ability = useContext(AbilityContext)
  const [isCreateReturnOrderProcessingModalOpen, setCreateReturnOrderProcessingModalOpen] = useState(false)
  const [shipmentSlider, setShipmentSlider] = useState(false)
  const [omsOrderId, setOmsOrderId] = useState('')
  const [shipmentStatusUpdateModal, setIsShipmentStatusUpdateModal] = useState({isOpen:false, status:null})
  
  // Variables
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)
  const hasReturnOrderEditPermission=ability.can(abilityMap.return.edit_order.action, abilityMap.return.edit_order.resource)
  const loadSellerSalesChannelOptions = getOptionsFetcher(loadSellerSalesChannelConfig);
  const isLocationInventoryEnabled = selectedHubConfiguration?.find((config) => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled
  const currentTabKey = RETURN_ORDER_TABS.COURIER_PARTNER_RETURN.key
  const isRowSelectable = filter.status?.value === RETURN_STATUS.INITIATED.key && hasReturnOrderEditPermission
  const loadCourierPartnersOptions = getOptionsFetcher(loadCourierPartnersConfig)

  const loadAllSellersOptions = getOptionsFetcher(loadAllSellersConfig())

  const handleReturnOrderDetailModal = useCallback(() => {
    setReturnOrderDetailModalOpen(prev => !prev)
  }, [isOrderDetailSidebarOpen])

  const handleOrderDetailSidebar = useCallback(() => {
    setOrderDetailSidebarOpen((prev) => !prev)
  }, [isOrderDetailSidebarOpen])

  const handleSetOrderToShowOrderDetail = useCallback((order) => {
    setOmsOrderId(order.order_id)
    setReturnOrderToShowDetail(order)
    handleOrderDetailSidebar()
  }, [returnOrderToShowDetail])

  const handleSetReturnOrderToShowDetail = useCallback((order) => {
    setReturnOrderToShowDetail(order)
    handleReturnOrderDetailModal()
  }, [returnOrderToShowDetail])

  const handleCreateReturnOrderProcessingModal = useCallback((params) => {
    if (params?.orderID) {
      dispatch(getReturnOrderDetail(params?.orderID))
    }
    setCreateReturnOrderProcessingModalOpen(prev => !prev)
  }, [isCreateReturnOrderProcessingModalOpen])


  const returnStatusWiseFilter = useMemo(() => {
    return GET_RETURN_STATUS_WISE_FILTERS(filter.status?.value)
  }, [])

  const handleSelectedData = ({ selectedRows }) => {
    setSelectedData(selectedRows)
  }

  const handleMarkOrderArrivedAtHub = () => {
    const body = {
      is_reverse: activeTab.key === RETURN_ORDER_TABS.CUSTOMER_RETURN.key,
      shipment_updates: selectedData.map((order) => {
        return {
          order_id: order.created_order_id,
          awb_number: order.awb_number,
          shipping_partner_tag: order.shipping_partner_tag
        }
      }),
      status: RETURN_STATUS.RETURN_TO_ORIGIN.key
    }
    dispatch(markOrdersAsReturnedTohHub({ body }))
  }

  const getAllAppliedFilters = (appliedFilters) => {
    setFilter(appliedFilters)
  }

  const filterHandler = ({ filterKey, filterValue }) => {
    // If the seller is changed or reset, update the store accordingly.
    if (filterKey === 'seller_id') {
      delete filter.seller_sales_channel_id
    }
    if (!filterValue) {
      delete filter[filterKey]
      setFilter(filter)
      return
    }
    if (filterKey === 'status') {
      setFilter({[filterKey]: filterValue})
      return
    }
    setFilter({ ...filter, [filterKey]: filterValue })
  }

  const dateFilterHandler = ({filterKey, dateRangeObject}) => {
    if (!dateRangeObject) {
      delete filter[filterKey]
      setFilter(filter)
      return
    }
    setFilter({...filter, [filterKey]: {label: `${dateRangeObject.start} to ${dateRangeObject.end}`, value: dateRangeObject}})
  }

  const handleSearch = ({searchVal, selectedColumn = null}) => {
    const searchQuery = searchVal ? {column: selectedColumn, query: searchVal} : null
    setSearchQuery(searchQuery)
  }

  const handlePagination = page => {
    const pagination = {page: page.selected, per_page: page.per_page ? page.per_page : 20 }
    setPagination(pagination)
  }

  const getReturnOrderWithFilter = () => {
    const additionalFilterParams = {}
    returnStatusWiseFilter?.forEach((element) => {
      if (element.type === DROPDOWN_TYPES.DATE_DROPDOWN) {
        additionalFilterParams[element.filterKey[0]] = filter[element.clientSideKey]?.value?.start
        additionalFilterParams[element.filterKey[element.filterKey.length - 1]] = filter[element.clientSideKey]?.value?.end
      } else {
        additionalFilterParams[element.filterKey] = filter[element.filterKey]?.value
      }
    })
    const filterParams = {
      status: filter.status?.value,
      seller_id: filter.seller_id?.value,
      seller_sales_channel_id: filter.seller_sales_channel_id?.value,
      shipping_partner_tag: filter.shipping_partner_tag?.value,
      courier_partner_tag: filter.courier_partner_tag?.value,
      ...additionalFilterParams
    }
    const searchParams = searchQuery.query ? {[searchQuery.column.id]: searchQuery.query} : {}
    dispatch(getReturnOrders({params: {...filterParams, ...searchParams, ...pagination, types: activeTab.key}}))
  }

  useEffect(() => {
    if (success.markOrdersAsReturnedTohHub) {
      setSelectedData([])
      if (shipmentStatusUpdateModal.isOpen) setIsShipmentStatusUpdateModal({ isOpen: false, status: null })
    }
    if (success.createReverseShipment || success.markOrdersAsReturnedTohHub || success.completeReturnOrderProcessing || success.completeRefundProcess || success.cancelReturnOrder) {
      getReturnOrderWithFilter()
      dispatch(resetSuccess())
      if (isReturnOrderDetailModalOpen) {
        handleReturnOrderDetailModal()
      }
    }
  }, [success])

  useEffect(() => {
    getReturnOrderWithFilter()
  }, [])

  useEffect(() => {
    if (returnOrders?.data) {
      const mappedData = returnOrders.data.map((data, index) => {
        return {
          ...data,
          serialNo: ((returnOrders.meta?.current_page - 1) * returnOrders.meta?.per_page) + index + 1,
          orderID: data.id,
          created_order_id: data.order_id,
          awb_number: data.shipment.awb_number,
          shipping_partner_name: data.shipment.shipping_partner_name,
          shipping_partner_tag: data.shipment.shipping_partner_tag,
          courier_partner: data.shipment.courier_partner,
          total_price: data.total,
          approved_by: data.approved_by.name,
          created_by: data.created_by.name,
          qc_processed_by: data.qc_processed_by?.name
        }
      })
      setTableData(mappedData)
    }
  }, [returnOrders])

  const handleShipmentsTracking = ({ awb_number, shipping_partner_name, shipping_partner_tag }) => {
    const searchQuery = STRINGIFY({
      column: null,
      query: awb_number
    })
    const searchFilter = STRINGIFY({
      shipping_partner: { label: shipping_partner_name, value: shipping_partner_tag }
    })
    navigate(`${ROUTES_MAPPING[ROUTES_KEYS.SHIPMENTS_TRACKING]}?q=${searchQuery}&f=${searchFilter}`)
  }
  const handleCancelReturnOrderPopup = (row) => {
    setIsCancelReturnOrderPopupOpen(prev => !prev)
    setReturnOrderToShowDetail(row)
  }

  const handleCancelOrderSuccess = () => {
    getReturnOrderWithFilter()
    handleCancelReturnOrderPopup();
  }

  const isActionColumnVisible = checkActionColumnVisibility({ ability, tableData });

  return (
    <div className="return-order">
      <div className="p-1 pb-0 d-flex gap-1">
        <DropdownWithTitle
          value={!filter.status ? {label: 'All', value: ''} : filter.status}
          isClearable={!!filter.status}
          title="Status"
          options={COURIER_RETURN_OPTIONS(isLocationInventoryEnabled)}
          selectOptionHandler={(value) => filterHandler({ filterKey: 'status', filterValue: value })}
          externalStyles={STATUS_FILTER_DROPDOWN_STYLE}
        />
        {returnStatusWiseFilter && returnStatusWiseFilter.map((element) => {
          return (
            element.type === DROPDOWN_TYPES.DATE_DROPDOWN
              ? <DateFilter 
                value={
                  filter[element.clientSideKey]?.value 
                    ? [filter[element.clientSideKey].value.start, filter[element.clientSideKey].value.end]
                    : null
                }
                onChange={(dateRangeObject) => dateFilterHandler({filterKey: element.clientSideKey, dateRangeObject})} 
                placeholder={t(element.displayName)}
              />
              : <DropdownWithTitle 
                isAsync = {filter.isAsync} 
                isClearable={!!filter[element.filterKey]} 
                value={!filter[element.filterKey] ? {label: 'All', value: ''} : filter[element.filterKey]}
                title={filter.displayName}
                loadOptionsHandler={filter.isAsync && filter.options}
                options={!filter.isAsync && filter.options}
                selectOptionHandler={(value) => filterHandler({filterKey: filter.filterKey, filterValue: value})} 
                externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
              />
          )
        })}
      </div>
      <div className="p-1 flex-center-start flex-wrap gap-1">
        <CustomFilter
          filterData={getReturnOrderCustomFilters()}
          defaultSelectedOption={filter}
          getAllAppliedFilters={getAllAppliedFilters}
        />
        {((ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource) && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.seller_id} 
            value={!filter.seller_id ? {label: 'All', value: ''} : filter.seller_id}
            title="Sellers"
            loadOptionsHandler={loadAllSellersOptions} 
            selectOptionHandler={(value) => filterHandler({filterKey: 'seller_id', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
          />
        </div>}
        {ability.can(abilityMap.sales_channel_app.view.action, abilityMap.sales_channel_app.view.resource) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.seller_sales_channel_id} 
            value={!filter.seller_sales_channel_id ? {label: 'All', value: ''} : filter.seller_sales_channel_id}
            title="Store Name"
            loadOptionsHandler={loadSellerSalesChannelOptions}
            additional={{
              seller: filter.seller_id?.value || selfTenantSellerId
            }}
            selectOptionHandler={(value) => filterHandler({filterKey: 'seller_sales_channel_id', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            isDisabled={(!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed)}
            tooltipId={(!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed && 'seller_sales_channel_id')}
            tooltipText={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && 'Select a seller first'}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>}
        {ability.can(abilityMap.shipping_app.view.action, abilityMap.shipping_app.view.resource) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.courier_partner_tag} 
            value={!filter.courier_partner_tag ? {label: t('All'), value: ''} : filter.courier_partner_tag}
            title={t('Courier Partners')}
            loadOptionsHandler={loadCourierPartnersOptions} 
            selectOptionHandler={(value) => filterHandler({filterKey: 'courier_partner_tag', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>}
      </div>
      <div className='mb-1 mx-1'>
        <TypeOfReturnsInformation returnInfo={COLLAPSED_CONTAINER_LIST.COURIER_RETURN} />
      </div>
      <div className="return-order-table px-1">
        <ShipmentCountHeader selectedData={selectedData} entity={SHIPMENT_STATUS_UPDATE_ENTITY.RETURN_ORDER}/>
        <ExpandableCustomTable
          loading={loading.getReturnOrders}
          success={success.getReturnOrders}
          error={error.getReturnOrders}
          isFilterOrSearchApplied = {!!(Object.values(filter).length || Object.values(searchQuery).length  > 1)}
          data={tableData}
          columns={getCourierPartnerReturnColumns({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, handleCreateReturnOrderProcessingModal, status: filter.status?.value, isLocationInventoryEnabled, ability, t, tenantType, handleShipmentsTracking, currentTabKey, isActionColumnVisible, handleCancelReturnOrderPopup})}
          columnName={filter.status?.value || 'allCourierPartnerReturn'}
          searchcolumnsList={RETURN_ORDER_COLUMNS_LIST}
          search_column_query={searchQuery}
          handleQueryParams={handleSearch}
          handlePagination={handlePagination}
          meta={returnOrders?.meta || {}}
          useReactPaginate={false}
          selectable={isRowSelectable}
          handleSelectedRowsChange={handleSelectedData}
          showSelected={true}
          showColumnsTableHeader={true}
          TableHeaderComponent={
            isRowSelectable && <div className="d-flex gap-1 ps-14px bulk-cancel-order">
              <Button
                onClick={() => {
                  if (selectedData.length === 0) {
                    CustomToast('Please select one or more orders', { my_type: 'info' })
                  } else {
                    setIsShipmentStatusUpdateModal({ isOpen: true, status:  RETURN_STATUS.RETURN_TO_ORIGIN })
                  }
                }}
              >
                {t('Mark as Return To Origin')}
              </Button>
            </div>
          }
        />
      </div>
      <ReturnOrderDetail isReturnOrderDetailModalOpen={isReturnOrderDetailModalOpen} handleReturnOrderDetailModal={handleReturnOrderDetailModal} handleCreateReverseShipmentModal={handleCreateReverseShipmentModal} handleOrderDetailSidebar={handleOrderDetailSidebar} returnOrderToShowDetail={returnOrderToShowDetail} handleCreateReturnOrderProcessingModal={handleCreateReturnOrderProcessingModal} fetchParentData={getReturnOrderWithFilter}/>
      <OrderDetailsSideSlider
        sellerId={returnOrderToShowDetail?.created_order_id}
        isModalOpen={isOrderDetailSidebarOpen}
        setIsModalOpen={setOrderDetailSidebarOpen}
        setShipmentSlider={setShipmentSlider}
      />
      <CreateShipmentSideSheet sellerId={omsOrderId} shipmentSlider={shipmentSlider} setShipmentSlider={setShipmentSlider} />
      <CreateReturnOrderProcessing isCreateReturnOrderProcessingModalOpen={isCreateReturnOrderProcessingModalOpen} handleCreateReturnOrderProcessingModal={handleCreateReturnOrderProcessingModal}/>

      <ShipmentStatusUpdateModal
        isOpen={shipmentStatusUpdateModal.isOpen}
        setIsOpen={setIsShipmentStatusUpdateModal}
        handleConfirm={handleMarkOrderArrivedAtHub}
        selectedData={selectedData}
        shipmentStatus={shipmentStatusUpdateModal.status?.name}
        entity={SHIPMENT_STATUS_UPDATE_ENTITY.RETURN_ORDER}
      />
      <ReturnOrderCancelPopup
        isReturnOrderCancelPopupOpen={isReturnOrderCancelPopupOpen}
        setIsReturnOrderCancelPopupOpen={setIsCancelReturnOrderPopupOpen}
        orderDetail={returnOrderToShowDetail}
        setOrderDetail={setReturnOrderToShowDetail}
        handleCancelOrderSuccess={handleCancelOrderSuccess}
      />
    </div>
  )
}
export default memo(CourierPartnerReturnOrder)