import Modal from '@src/@core/components/ui/modal/Modal'
import { MODAL_TYPES } from '@src/@core/components/ui/modal/modal.constant'
import { Notes } from '@src/assets/images/icons/Modal/GeneralIcons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const CustomerNoteModal = (prop) => {
  const { isCustomerNoteShow, setIsCustomerNoteShow, customerNote } = prop
  const { t } = useTranslation()

  const handleToggle = () => {
    setIsCustomerNoteShow(prev => !prev)
  }

  return (
    <Modal
      isOpen={isCustomerNoteShow}
      modalClassNames="custom-customer-note-modal"
      toggle={handleToggle}
      title={t('Customer Note')}
      type={MODAL_TYPES.GENERAL}
      closeModalHandler={handleToggle}
      icon={Notes}
      secondaryButtonText={t('Close')}
      secondaryButtonHandler={handleToggle}
      isTranslationRequired={false}
    >
      <div className="content-body">
        <div className="txt-body-rg text-dark customer-note-content">
          {customerNote}
        </div>
      </div>
    </Modal>
  )
}

CustomerNoteModal.propTypes = {
  isCustomerNoteShow: PropTypes.bool,
  setIsCustomerNoteShow: PropTypes.func,
  customerNote: PropTypes.string
}

export default CustomerNoteModal
