import { API_ENDPOINTS } from '@src/api.urls'
import { ROUTES_KEYS } from '@src/router/route-keys'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'

export const MAX_ORDERS_IN_CLUSTER = 500

export const BULK_SHIP_PATH_NAMES = Object.freeze({
  CLUSTERS: ROUTES_MAPPING[ROUTES_KEYS.BULK_SHIP],
  PROCESS_CLUSTER: ROUTES_MAPPING[ROUTES_KEYS.BULK_SHIP_PROCESS_CLUSTER],
  VIEW_CLUSTER:  ROUTES_MAPPING[ROUTES_KEYS.BULK_SHIP_VIEW_CLUSTER]
})

export const mockAxiosInstance = {
  get: (url) => {
    switch (url) {
    case API_ENDPOINTS.BULK_SHIP.FETCH_SUGGESTED_CLUSTERS: return new Promise((res) => {
      setTimeout(() => {
        res(
          {
            data: {
              is_success: true,
              status_code: 200,
              data: [
                {
                  number_of_orders: 10,
                  order_hash: '6666666461633965',
                  order_items: [
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfa',
                        seller_sku_code: 'Rg1',
                        images: [{ url: 'https://m.media-amazon.com/images/I/71kOsITKSkL._SL1500_.jpg' }],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Philips Orbit 5w LED Table Lamp | 3 in 1 Colour Philips Orbit 5w LED Table Lamp',
                        is_weighted: false
                      },
                      quantity: 4,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '4'
                    },
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfb',
                        seller_sku_code: 'Rg2',
                        images: [
                          {
                            url: 'url/to/phone1.jpg',
                            type: ''
                          }
                        ],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg2',
                        is_weighted: false
                      },
                      quantity: 3,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '3'
                    }
                  ],
                  order_quantity: 7,
                  display_number_of_orders: '10',
                  display_order_quantity: '7'
                },
                {
                  number_of_orders: 15,
                  order_hash: '3636643963653137',
                  order_items: [
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfa',
                        seller_sku_code: 'Rg1',
                        images: [{ url: 'https://m.media-amazon.com/images/I/71kOsITKSkL._SL1500_.jpg' }],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg1',
                        is_weighted: false
                      },
                      quantity: 4,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '4'
                    }
                  ],
                  order_quantity: 4,
                  display_number_of_orders: '15',
                  display_order_quantity: '4'
                },
                {
                  number_of_orders: 8,
                  order_hash: '3537313133376134',
                  order_items: [
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfa',
                        seller_sku_code: 'Rg1',
                        images: [{ url: 'https://m.media-amazon.com/images/I/71kOsITKSkL._SL1500_.jpg' }],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg1',
                        is_weighted: false
                      },
                      quantity: 1,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '1'
                    },
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfb',
                        seller_sku_code: 'Rg2',
                        images: [
                          {
                            url: 'url/to/phone1.jpg',
                            type: ''
                          }
                        ],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg2',
                        is_weighted: false
                      },
                      quantity: 1,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '1'
                    },
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfd',
                        seller_sku_code: 'Rg3',
                        images: [
                          {
                            url: 'url/to/phone1.jpg',
                            type: ''
                          }
                        ],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg3',
                        is_weighted: false
                      },
                      quantity: 1,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '1'
                    },
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bff',
                        seller_sku_code: 'Rg4',
                        images: [],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg4',
                        is_weighted: false
                      },
                      quantity: 1,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '1'
                    }
                  ],
                  order_quantity: 4,
                  display_number_of_orders: '8',
                  display_order_quantity: '4'
                }
              ],
              meta: {
                current_page: 1,
                last_page: 1,
                per_page: 20,
                total: 3
              }
            }
          })
      }, 400)
    })
    case API_ENDPOINTS.BULK_SHIP.FETCH_SUGGESTED_CLUSTERS: return new Promise((res) => {
      setTimeout(() => {
        res(
          {
            data: {
              is_success: true,
              status_code: 200,
              data: [
                {
                  number_of_orders: 10,
                  order_hash: '6666666461633965',
                  order_items: [
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfa',
                        seller_sku_code: 'Rg1',
                        images: [{ url: 'https://m.media-amazon.com/images/I/71kOsITKSkL._SL1500_.jpg' }],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Philips Orbit 5w LED Table Lamp | 3 in 1 Colour Philips Orbit 5w LED Table Lamp',
                        is_weighted: false
                      },
                      quantity: 4,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '4'
                    },
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfb',
                        seller_sku_code: 'Rg2',
                        images: [
                          {
                            url: 'url/to/phone1.jpg',
                            type: ''
                          }
                        ],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg2',
                        is_weighted: false
                      },
                      quantity: 3,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '3'
                    }
                  ],
                  order_quantity: 7,
                  display_number_of_orders: '10',
                  display_order_quantity: '7'
                },
                {
                  number_of_orders: 15,
                  order_hash: '3636643963653137',
                  order_items: [
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfa',
                        seller_sku_code: 'Rg1',
                        images: [{ url: 'https://m.media-amazon.com/images/I/71kOsITKSkL._SL1500_.jpg' }],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg1',
                        is_weighted: false
                      },
                      quantity: 4,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '4'
                    }
                  ],
                  order_quantity: 4,
                  display_number_of_orders: '15',
                  display_order_quantity: '4'
                },
                {
                  number_of_orders: 8,
                  order_hash: '3537313133376134',
                  order_items: [
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfa',
                        seller_sku_code: 'Rg1',
                        images: [{ url: 'https://m.media-amazon.com/images/I/71kOsITKSkL._SL1500_.jpg' }],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg1',
                        is_weighted: false
                      },
                      quantity: 1,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '1'
                    },
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfb',
                        seller_sku_code: 'Rg2',
                        images: [
                          {
                            url: 'url/to/phone1.jpg',
                            type: ''
                          }
                        ],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg2',
                        is_weighted: false
                      },
                      quantity: 1,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '1'
                    },
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bfd',
                        seller_sku_code: 'Rg3',
                        images: [
                          {
                            url: 'url/to/phone1.jpg',
                            type: ''
                          }
                        ],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg3',
                        is_weighted: false
                      },
                      quantity: 1,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '1'
                    },
                    {
                      sku: {
                        seller_sku_id: '65390b728c0cbc5467791bff',
                        seller_sku_code: 'Rg4',
                        images: [],
                        attributes: null,
                        batch: null,
                        type: '',
                        barcode: '',
                        name: 'Rg4',
                        is_weighted: false
                      },
                      quantity: 1,
                      weight: {
                        uom: '',
                        value: 0
                      },
                      display_quantity: '1'
                    }
                  ],
                  order_quantity: 4,
                  display_number_of_orders: '8',
                  display_order_quantity: '4'
                }
              ],
              meta: {
                current_page: 1,
                last_page: 1,
                per_page: 20,
                total: 3
              }
            }
          })
      }, 400)
    })
    }
  }
}

export const BULK_SHIP_TABS_DATA = Object.freeze({
  SUGGESTIONS: { id: 'suggestions', name: 'Suggestions' },
  SAVED_SUGGESTIONS: { id: 'saved_suggestions', name: 'Saved Suggestions' },
  CLUSTERS: { id: 'clusters', name: 'Clusters' }
})
export const CLUSTER_STATUS_OPTIONS = [
  { label: 'Pending', value: 'pending' },
  { label: 'Packed', value: 'packed' },
  { label: 'Shipped', value: 'shipped' }
]
export const API_KEYS = Object.freeze({
  FETCH_SUGGESTED_CLUSTERS: 'FETCH_SUGGESTED_CLUSTERS',
  FETCH_DRAFT_CLUSTERS: 'FETCH_DRAFT_CLUSTERS',
  SAVE_CLUSTER: 'SAVE_CLUSTER',
  GENERATE_PICKING_WAVE: 'GENERATE_PICKING_WAVE',
  FETCH_PICKING_WAVE: 'FETCH_PICKING_WAVE',
  SAVE_PACKAGING_DETAILS: 'SAVE_PACKAGING_DETAILS',
  FETCH_CLUSTER_DETAILS: 'FETCH_CLUSTER_DETAILS',
  FETCH_WAVE_SHIPMENT_DATA: 'FETCH_WAVE_SHIPMENT_DATA',
  FETCH_PICKING_WAVE_WHEN_PUSHER_DOWN: 'FETCH_PICKING_WAVE_WHEN_PUSHER_DOWN'
})

export const SHIPMENT_TYPE = Object.freeze({
  SHIPPING_PARTNER: 'shipping_partner',
  AUTOMATION_RULE: 'automatic'
})

export const PROCESS_CLUSTER_STEPS = Object.freeze({
  GENERATE_PICKING_WAVE: {
    ID: 'GENERATE_PICKING_WAVE',
    HEADING: 'Generate Picking Wave',
    ORDER: '1'
  },
  PACKAGING_PREFERENCE: {
    ID: 'PACKAGING_PREFERENCE',
    HEADING: 'Packaging Preference',
    ORDER: '2'
  },
  SHIPMENT: {
    ID: 'SHIPMENT',
    HEADING: 'Shipment',
    ORDER: '3'
  }
})

export const BULK_SHIPMENT_STATUSES = Object.freeze({
  PENDING: {
    label: 'Pending AWB Creation',
    value: 'pending'
  },
  IN_PROGRESS: {
    label: 'Creating AWB',
    value: 'in_progress'
  },
  FAILED: {
    label: 'Shipment Error',
    value: 'failed'
  },
  CREATED: {
    label: 'AWB Created Successfully',
    value: 'created'
  },
  AWB_PRINTED: {
    label: 'AWB Printed',
    value: 'awb_printed'
  },
  CANCELLED: {
    label: 'Cancelled',
    value: 'cancelled'
  },
  NOT_APPLICABLE: {
    label: 'Not Applicable',
    value: 'not_applicable'
  }
})

export const TABLE_SEARCH_COLUMNS = [
  { id: 'query', name: 'Order' },
  { id: 'seller_sales_channel_order_id', name: 'Sales Channel Order ID' },
  { id: 'seller_sku_code', name: 'SKU Code' },
  { id: 'barcode', name: 'SKU Barcode' }
]