import { NAVBAR_HEIGHT, POWERED_BY_HEIGHT } from '@src/App.constants'
import useLocalStorageTableColumns from '@src/utility/hooks/useTableColumn'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Card } from 'reactstrap'
import AcrossSelectionBanner from '../components/AcrossSelectionBanner'
import TableCheckbox from '../components/TableCheckbox'
import TableHeader from '../components/TableHeader'
import TablePagination from '../components/TablePagination'
import TableSortingIcon from '../components/TableSortingIcon'
import { TableLoadingState, TableNoDataState } from '../components/TableStates'
import { TABLE_HEADER_HEIGHT, TABLE_ROW_STYLES, TABLE_SCROLL_THRESHOLD } from '../globalTable.constant'
import { handleCheckActionColumnPresent, useCustomTableStyles, useTransformedTableColumns } from '../globalTable.utils'
import '../styles.scss'
import useHeaderPositionCheck from '../useHeaderPositionCheck'
import useTableScrollVisibility from '../useTableScrollVisibility'


export const ExpandableCustomTable = ({
  loading = false,
  error = false,
  success = true,
  isFilterOrSearchApplied = false,
  data = [],
  columns = [],
  columnName,
  selectable = false,
  handleSelectedRowsChange = () => {},
  meta = {},
  showPagination = true,
  handlePagination = false,
  expandableRows = false,
  expandableRowsComponent,
  defaultSortFieldId,
  defaultSortAsc,
  handleSort,
  TableHeaderComponent = false,
  showColumnsTableHeader = true,
  showColumnsDropdown = true,
  isActionColumnSticky = true,
  hideTableSearch = false,
  hasSerialNumber = false,
  getActionColumnData,
  searchcolumnsList,
  handleQueryParams,
  search_column_query,
  handleRefreshTable,
  tableTitle = null,
  CustomFooter = null,
  customStyles = {},
  conditionalRowStyles = [],
  tableWrapperClassNames,
  tableClassNames,
  enableAcrossPagesSelection = false,
  onAcrossPageSelectionChange = () => { },
  CustomLoadingState = null,
  CustomErrorState = null,
  CustomFilterNoMatchState = null,
  CustomEmptyDataState = null,
  isTableFixedHeader = true,
  scrollUpTo,
  ...props
}) => {

  const hasTableHeaderVisible = Boolean(showColumnsTableHeader && (searchcolumnsList || handleRefreshTable || showColumnsDropdown || TableHeaderComponent))
  
  // Use the custom hooks instead of the inline functions and state
  const {isScrolled} = useTableScrollVisibility({
    tableSelector: '.common_table',
    offsetThreshold: TABLE_SCROLL_THRESHOLD
  });
  
  const isHeaderTouchingNav = useHeaderPositionCheck({
    tableContainerSelector: '#table-container-wrapper',
    navbarSelector: '.navbar'
  });

  const customTableStyles = useCustomTableStyles(customStyles, isTableFixedHeader, isScrolled);
  const transformedColumn = useTransformedTableColumns({columns, getActionColumnData, meta, hasSerialNumber})
  const {sortedColumn, setSortedColumn} = useLocalStorageTableColumns(columnName, transformedColumn)
  
  // useStates
  const [columnsToShow, setColumnsToShow] = useState(() => (showColumnsDropdown ? sortedColumn : transformedColumn))
  const [selectedData, setSelectedData] = useState(null)
  const [toggledClearRows, setToggleClearRows] = useState(false)
  const [isAcrossPagesSelected, setIsAcrossPagesSelected] = useState(false)

  // variables
  const tableHasActionColumn = isActionColumnSticky && handleCheckActionColumnPresent(columnsToShow)
  const shouldShowAcrossSelection = enableAcrossPagesSelection && selectedData?.allSelected && meta?.last_page > 1 && showColumnsTableHeader
  const isPartial = useMemo(() => {
    if (!selectedData) return false
    if (!selectedData.allSelected && selectedData.selectedCount > 0) {
      return true
    } else {
      return false
    }
  }, [selectedData])

  // functions

  const handleAcrossPageSelectionChange = (value) => {
    setIsAcrossPagesSelected(value)
    onAcrossPageSelectionChange(value)
  }

  const handleClearAllSelection = () => {
    setToggleClearRows(!toggledClearRows)
    setSelectedData(null)
    handleAcrossPageSelectionChange(false)
    onAcrossPageSelectionChange(false)
    handleSelectedRowsChange({ selectedCount: 0, selectedRows: [], allSelected: false })
  }

  const handleTableScrollIntoView = () => { 
    if (isHeaderTouchingNav) {
      const tableContainer = document.querySelector('#table-container-wrapper');
      if (data.length > 0 && scrollUpTo?.current) {
        const navbarOffset = NAVBAR_HEIGHT + 14;
        const headerElement = scrollUpTo.current;
        const elementPosition = headerElement.getBoundingClientRect().top;
        const offsetPosition = window.pageYOffset + elementPosition - navbarOffset;

        requestAnimationFrame(() => {
          window.scrollTo({
            top: offsetPosition
          });
        });
      } else if (tableContainer) {
        const tableCard = tableContainer;
        const cardPosition = tableCard.getBoundingClientRect().top;
        const offsetPosition = window.pageYOffset + cardPosition - NAVBAR_HEIGHT;

        requestAnimationFrame(() => {
          window.scrollTo({
            top: offsetPosition - 14
          });
        });
      }
    }
  }

  const onSelectedRowsChange = (data) => {
    setSelectedData(data)
    handleSelectedRowsChange(data)
    handleTableScrollIntoView()
    if (enableAcrossPagesSelection && isAcrossPagesSelected && !data.allSelected) {
      handleAcrossPageSelectionChange(false)
    }
  }

  const handleColumnSort = (selectedColumn, sortDirection) => {
    handleSort({field:selectedColumn.key, order:sortDirection})
  }
  
  const handleColumnOrderChange = useMemo(() => {
    return (reArrangedColumns) => {
      for (let i = 0; i < reArrangedColumns.length; i++) {
        if (!columnsToShow[i].reorder && reArrangedColumns[i].key !== columnsToShow[i].key) {
          setColumnsToShow([...columnsToShow])
          return
        }
      }
      setSortedColumn(columnName, reArrangedColumns)
    }
  }, [])

  const indexedData = useMemo(() => {
    if (!data?.length) return []
    return data.map((item, index) => ({ ...item, stripeIndex: index }))
  }, [data])

  // props
  const selectableProps = selectable ? {
    selectableRows: true,
    selectableRowsComponent: TableCheckbox,
    selectableRowsComponentProps: { isPartial, columnName },
    onSelectedRowsChange: onSelectedRowsChange
  } : {}

  const expandableProps = expandableRows ? {
    expandableRows: true,
    expandableRowsComponent: expandableRowsComponent
  } : {}

  const sortProps = handleSort ? {
    sortIcon: <TableSortingIcon />,
    onSort: handleColumnSort,
    defaultSortFieldId: defaultSortFieldId,
    defaultSortAsc: defaultSortAsc,
    sortServer: true
  } : {}

  const paginationProps = showPagination && meta ? {
    pagination: true,
    paginationComponent: handlePagination ? TablePagination : (CustomFooter ? CustomFooter : null),
    paginationComponentOptions: {meta, handlePagination},
    paginationServer: !!handlePagination
  } : {}

  // useEffects
  useEffect(() => {
    setColumnsToShow(showColumnsDropdown ? sortedColumn : transformedColumn)
  }, [columns])

  useEffect(() => {
    handleClearAllSelection()
  }, [data])

  return (
    <Card id='table-container-wrapper' className={classNames(tableWrapperClassNames, { 'table-has-action-column': tableHasActionColumn, 'hide-border-bottom': (!TableHeaderComponent && !showPagination) || !showPagination})}>
      <TableHeader
        loading={loading}
        columns={transformedColumn}
        columnsList={columnsToShow}
        setColumnsList={setColumnsToShow}
        TableHeaderComponent={TableHeaderComponent}
        defaultColumns={transformedColumn}
        showColumnsTableHeader={showColumnsTableHeader}
        showColumnsDropdown={showColumnsDropdown}
        meta={meta}
        searchcolumnsList={searchcolumnsList}
        search_column_query={search_column_query}
        handleQueryParams={handleQueryParams}
        handleRefreshTable={handleRefreshTable}
        columnName={columnName}
        setSortedColumn={setSortedColumn}
        tableTitle={tableTitle}
        hideTableSearch={hideTableSearch}
        shouldShowAcrossSelection={shouldShowAcrossSelection}
      />
      {shouldShowAcrossSelection &&
        <AcrossSelectionBanner
          isAcrossPagesSelected={isAcrossPagesSelected}
          meta={meta}
          selectedData={selectedData}
          handleClearAllSelection={handleClearAllSelection}
          handleAcrossPageSelectionChange={handleAcrossPageSelectionChange}
        />
      }
      
      <div className={classNames(`react-dataTable expandable-custom-table ${tableClassNames}`, {'top-radius' : !hasTableHeaderVisible, 'bottom-radius': !(showPagination && meta), 'rounded-16px': !hasTableHeaderVisible && !(showPagination && meta)})}>
        <DataTable
          persistTableHead
          fixedFooter
          progressPending={loading}
          progressComponent={null} 
          noDataComponent={null}
          noHeader
          data={indexedData}
          columns={columnsToShow}
          onColumnOrderChange={handleColumnOrderChange}
          clearSelectedRows={toggledClearRows}
          className={classNames('react-dataTable', {'common_table': isTableFixedHeader, 'header-checkbox-partial': isPartial})}
          customStyles={customTableStyles}
          {...selectableProps}
          {...expandableProps}
          {...sortProps}
          {...paginationProps}
          {...props}
          fixedHeader={isTableFixedHeader}
          fixedHeaderScrollHeight={`calc(100vh - (${NAVBAR_HEIGHT + POWERED_BY_HEIGHT + TABLE_HEADER_HEIGHT}px))`}
          conditionalRowStyles={[...TABLE_ROW_STYLES, ...(conditionalRowStyles ? conditionalRowStyles : [])]}
        />
        {loading ? CustomLoadingState ? <CustomLoadingState /> : <TableLoadingState /> : null}
        {!loading && indexedData.length === 0 &&
          <TableNoDataState
            success={success} 
            error={error}
            isFilterOrSearchApplied={isFilterOrSearchApplied}
            CustomErrorState={CustomErrorState}
            CustomFilterNoMatchState={CustomFilterNoMatchState}
            CustomEmptyDataState={CustomEmptyDataState}
          />
        }
      </div>
    </Card>
  )
}