import Button from '@src/@core/components/ui/button'
import { ENTITY_TYPES, INVOICE_TYPES, PRINT_MODES } from '@src/App.constants'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { getInvoiceDataApi } from '../../sales.apis'
import { InvoiceLayout } from './invoiceLayout'

const Invoice = ({ 
  printInvoiceRef, 
  order_id, 
  cleanupFunction, 
  buttonType = 'regular', 
  params = { invoice_type: INVOICE_TYPES.STANDARD, entity_type: ENTITY_TYPES.FORWARD }
}) => {
  const contentRef = useRef(null)
  const { t } = useTranslation()
  const [invoiceData, setInvoiceData] = useState()
  const [isPrintClicked, setIsPrintClicked] = useState(false)


  const handlePrint  =  useReactToPrint({
    content: () => contentRef.current,
    pageStyle: `@media print {
      @page  {
        size: ${PRINT_MODES.A_FOUR.size};   /* auto is the initial value */
        margin: ${PRINT_MODES.A_FOUR.margin};  /* this affects the margin in the printer settings */
        padding:${PRINT_MODES.A_FOUR.id && PRINT_MODES.A_FOUR.padding};
      }
    }`,
    onAfterPrint:() => setIsPrintClicked(false)
    
  })

  const { refetch: handleGetInvoiceData, isFetching: isGetInvoiceDataFetching } = useApi({
    apiKey: [getInvoiceDataApi.apiKey, order_id],
    apiFn: () => {
      const localParams = {
     
        entity_id: order_id,
        ...params,
      }
      return axiosInstance.get(getApiUrl(getInvoiceDataApi.url, { orderId: order_id }), { params: localParams });
    },
    onSuccess: (response) => {
      setInvoiceData(response.data)
      setIsPrintClicked(true)
      if (cleanupFunction) {
        cleanupFunction()
      }
    },
    enabled: false
  })

  useEffect(() => {
    if (invoiceData && isPrintClicked) handlePrint()
  }, [invoiceData, isPrintClicked])
  return (
    <>
      <div className='d-none'>
        <InvoiceLayout data={invoiceData} ref={contentRef} />
      </div>
      <Button
        onClick={handleGetInvoiceData}
        disabled={isGetInvoiceDataFetching}
        ref={printInvoiceRef}
        loading={isGetInvoiceDataFetching}
        ofType={buttonType}
      >
        {t('Print Invoice')}
      </Button>
    </>
  )
}

Invoice.propTypes = {
  printInvoiceRef: PropTypes.shape({ current: PropTypes.any }),
  order_id: PropTypes.string.isRequired,
  cleanupFunction: PropTypes.func,
  buttonType: PropTypes.string,
  params: PropTypes.shape({
    invoice_type: PropTypes.oneOf(Object.values(INVOICE_TYPES)),
    entity_type: PropTypes.string
  })
}

export default Invoice