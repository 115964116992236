import Filter from '@src/assets/images/icons/tableFilter/filter'
import { setTableFilterDropdownOpen } from '@src/redux/authentication'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import classNames from 'classnames'
import {
  useMemo,
  useRef, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import ComponentRenderer from './component/ComponentRenderer'
import { DROPDOWN_TYPES } from './customTableFilterComponent.constant'
import './style.scss'

const CustomFilter = ({ filterData = {}, defaultSelectedOption = {}, getAllAppliedFilters = () => { } }) => {

  const isValidFilter = (filter) => {
    return filter &&  filter.value && filter.label
  }
  
  // Clean defaultSelectedOption by removing invalid filters
  const validatedDefaultSelectedOption = useMemo(() => {
    return Object.entries(defaultSelectedOption).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        const validArrayValues = value.filter(isValidFilter)
        if (validArrayValues.length > 0) {
          acc[key] = validArrayValues
        }
      } else if (isValidFilter(value)) {
        acc[key] = value
      }
      return acc
    }, {})
  }, [defaultSelectedOption])
  
  const firstFilter = Object.values(filterData).find(value => value)
  if (!firstFilter) {
    return null
  }
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const defaultSelectedFilter = {
    filterKey: 'default',
    displayName: t('Applied Filter'),
    type: DROPDOWN_TYPES.APPLIED_DROPDOWN
  }
  // local state
 
  const [selectedFilter, setSelectedFilter] = useState(Object.values(validatedDefaultSelectedOption).length ? defaultSelectedFilter : firstFilter || defaultSelectedFilter)
  const [selectedOptions, setSelectedOptions] = useState(validatedDefaultSelectedOption)
  const [appliedOptions, setAppliedOptions] = useState(validatedDefaultSelectedOption)
  const filterMainContainerRef = useRef()
  const tableFilterDropdownOpen = useSelector((state) => state.auth.tableFilterDropdownOpen)

  // handler functions
  const handleSetSelectedFilter = (filter) => {
    setSelectedFilter(filter)
  }

  const handleSetSelectedOption = ({filterKey, option}) => {
    setSelectedOptions(prev => ({...prev, [filterKey]: option}))
  }

  // for toggling filter dropdown
  const toggleFilterDropdown = () => {
    setSelectedFilter(firstFilter || defaultSelectedFilter)
    setSelectedOptions(validatedDefaultSelectedOption)
    dispatch(setTableFilterDropdownOpen(!tableFilterDropdownOpen))
  }

  // cloase filter dropdown when clicking outside
  useOnClickOutside(filterMainContainerRef, () => {
    if (tableFilterDropdownOpen) {
      setSelectedFilter(firstFilter || defaultSelectedFilter)
      setSelectedOptions(validatedDefaultSelectedOption)
      dispatch(setTableFilterDropdownOpen(false))
    }
  })

  const handleApplyfilter = () => {
    setAppliedOptions(selectedOptions)
    getAllAppliedFilters(selectedOptions)
    dispatch(setTableFilterDropdownOpen(false))
  }

  const handleReset = (filterKey) => {
    const prevOptions = {...selectedOptions}
    if (filterKey === defaultSelectedFilter.filterKey) {
      setSelectedOptions({})
      setAppliedOptions({})
      getAllAppliedFilters({})
    } else {
      delete prevOptions[filterKey]
      setSelectedOptions(prevOptions)
      setAppliedOptions(prevOptions)
      getAllAppliedFilters(prevOptions)
    }
    dispatch(setTableFilterDropdownOpen(false))
  }

  const handleRemoveAppliedFilter = (data) => {
    let allAppliedFilters = {}
    if (Array.isArray(selectedOptions[data.filterKey])) {
      const filteredOptions = selectedOptions[data.filterKey].filter((element) => element.value !== data.value)
      allAppliedFilters = {...selectedOptions, [data.filterKey]: filteredOptions.length ? filteredOptions : undefined}
    } else {
      allAppliedFilters = {...selectedOptions}
      delete allAppliedFilters[data.filterKey]
    }
    setSelectedOptions(allAppliedFilters)
    setAppliedOptions(allAppliedFilters)
    getAllAppliedFilters(allAppliedFilters)
  }

  const getAppliedFiltersCount = (filter, selectedOptions) => {
    const value = selectedOptions[filter.filterKey]
    if (!value) return ''
    
    if (Array.isArray(value)) {
      return value.length ? `(${value.length})` : ''
    }
    return '(1)'
  }
  const filterKeys = Object.keys(validatedDefaultSelectedOption)
  let allFilters = []
  filterKeys.forEach(filterKey => {
    if (Array.isArray(validatedDefaultSelectedOption[filterKey])) {
      allFilters = [...allFilters, ...validatedDefaultSelectedOption[filterKey].map(el => ({...el, filterKey}))]
    } else {
      allFilters.push({...validatedDefaultSelectedOption[filterKey], filterKey})
    }
  })
  return (
    <div ref={filterMainContainerRef} className="filter-main-container zindex-2" >
      <Button className="filter-dropdown-toogle-btn position-relative" outline onClick={toggleFilterDropdown}>
        <Filter />
        {t('Filters')}
        {allFilters.length > 0 && <div className="applied-filter-detection"></div>}
      </Button>
      {tableFilterDropdownOpen && (
        <div className="filter-dropdown-container">
          <div className="left-side-container">
            <div className={classNames('position-relative filter-item cursor-pointer', {'active-item' : defaultSelectedFilter.filterKey === selectedFilter.filterKey})} onClick={() => handleSetSelectedFilter(defaultSelectedFilter)}>
              <span className="text-truncate" title={t('Applied filters')}>{t('Applied filters')}{' '}({allFilters.length})</span>
              {/* {allFilters.length > 0 && <div className="applied-filter-detection"></div>} */}
            </div>
            <div className="text-truncate all-filter-tag">
              <span className="text-truncate">{t('Filter Categories')}</span>
            </div>
            <div className="filter_categories_container">
              {Object.values(filterData).map((filter) => {
                return (
                  filter ? <div key={filter.filterKey} className={classNames('position-relative filter-item txt-sub-rg extra-padding cursor-pointer d-flex gap-4px', {'active-item' : filter.filterKey === selectedFilter.filterKey})} onClick={() => handleSetSelectedFilter(filter)}>
                    <span className={'text-truncate'} title={t(filter.displayName)}>
                      {t(filter.displayName)}</span>
                    <span>{getAppliedFiltersCount(filter, selectedOptions)}</span>
                    {/* {
                      ((filter.type === "multi_drop_down" || filter.type === "aync_multi_drop_down") 
                      ? selectedOptions[filter.filterKey]?.length > 0 
                      : selectedOptions[filter.filterKey]) && 
                      <div className="applied-filter-detection"></div>
                    } */}
                  </div> : null
                )
              })}
            </div>
          </div>
          <div className="right-side-container">
            {selectedFilter && <ComponentRenderer
              selectedFilter={selectedFilter}
              handleSetSelectedOption={handleSetSelectedOption}
              selectedOptions={selectedOptions}
              appliedOptions={appliedOptions}
              setSelectedOptions={setSelectedOptions}
              handleRemoveAppliedFilter={handleRemoveAppliedFilter}
              allFilters={allFilters}
              handleReset={handleReset}
              handleApplyfilter={handleApplyfilter}
              defaultSelectedFilter={defaultSelectedFilter}
            />
            }
          </div>
        </div>
      )}
    </div>
  )
}
export default CustomFilter