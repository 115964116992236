import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { axiosInstance } from '@src/network/AxiosInstance'
import { paramsSerializer } from '@src/utility/Utils'

export const getHubsAsyncData = async (search, loadedOptions, { page, is_external_hub }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params: { search_column: 'name', search_query: search, page, exclude_external_hub:is_external_hub } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        is_external_hub
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        is_external_hub
      }
    }
  }
}

export const getHubsAsyncDataWithAllOption = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data?.data
    const meta = response?.data?.meta

    if (page === 1 && Array.isArray(data)) {
      data.unshift({ id: 'all', name: 'All Hubs' })
    }

    return {
      options: data.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getCitiesAsyncData = async (search, loadedOptions, {page}) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/cities', {params: {search_column: 'name', search_query: search, page}})
    const data = response?.data
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getSalesChannel = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v2/scs/sales_channels', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc' } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.tag,
        label: ele.name,
        logo: ele.logo_url
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getSellersAsyncData = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/sellers', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc', is_active: true } })
    const data = response?.data
    const options = data?.data?.length ? data.data.map(ele => ({
      value: ele.id,
      label: ele.name,
      code: ele.code,
      ...ele
    })) : []

    return {
      options,
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getSellersAsyncDataUpdated = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/sellers', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc', is_active: true } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        sfda: ele.sfda_integrated
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getSellersWithAllOption = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/sellers', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc', is_active: true } })
    const data = response?.data

    const allOption = {value: 'all', label: 'All'}

    const otherOptions = data?.data?.map(ele => ({
      value: ele.id,
      label: ele.name
    }))
    return {
      options: page === 1 ? [allOption, ...otherOptions] : [...otherOptions],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getUnintegratedSellersAsyncData = async (search, loadedOptions, { is_active = true, page, exclude_ids, is_all_seller_mapped, is_edit_mode }) => {
  try {
    if (!is_edit_mode && is_all_seller_mapped) {
      throw new Error('No sellers available for mapping')
    }

    let params

    if (search === '') {
      params = {
        page,
        exclude_ids,
        sort: 'name_asc',
        is_active
      }
    } else {
      params = {
        page,
        exclude_ids,
        sort: 'name_asc',
        search_column: 'name',
        search_query: search,
        is_active
      }
    }

    const response = await axiosInstance.get(`/api/v1/tenant/sellers?${paramsSerializer(params)}`)
    const data = response?.data

    const allOption = {value: 'all', label: 'All Sellers'}

    const sellerOptions = data?.data?.map(ele => ({
      value: ele.id,
      label: ele.name
    }))

    return {
      options: sellerOptions.length > 0 ? (page === 1 ? [allOption, ...sellerOptions] : [...sellerOptions]) : [],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        exclude_ids,
        is_all_seller_mapped,
        is_edit_mode
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        exclude_ids,
        is_all_seller_mapped,
        is_edit_mode
      }
    }
  }
}

export const getRolesAsyncData = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/tenant/roles', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getBillingProfiles = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/billing/billing_profiles?is_draft=false', { params: { search_column: 'name', search_query: search, page, sort: 'name_asc' } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        ...ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getContracts = async (search, loadedOptions, { page, sellerId, startDate, endDate }) => {
  const params = {
    search_column: 'name',
    search_query: search,
    page,
    sort: 'active_desc',
    seller_id: sellerId,
    start_date: startDate,
    end_date: endDate
  }
  try {
    const response = await axiosInstance.get('/api/v1/billing/contracts', { params })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        isActive: ele.is_active
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        sellerId,
        startDate,
        endDate
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.warn('Error fetching contracts:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        sellerId,
        startDate,
        endDate
      }
    }
  }
}

export const getSkuOptionsForActions = async (search, _loadedOptions, {sellerId, params: restParams}) => {
  try {
    const  params = { search_value: search, ...restParams }
    const response = await axiosInstance.get(`/api/v1/products/sellers/${sellerId}/order_skus?${paramsSerializer({ ...params })}`)

    const data = response?.data?.data ?? []
    const options = data?.map(ele => ({
      ...ele,
      value: ele.sku.seller_sku_code,
      label: ele.sku.seller_sku_code,
      logo: ele.sku.images?.[0]?.default,
      type: ele.sku.type,
      uom: ele.sku.uom,
      is_weighted: ele.sku.is_weighted,
      name: ele.sku.name,
      weight: ele.sku.weight.value || 1,
      quantity: 1
    }))

    return {
      options: options,
      hasMore: restParams.page < response?.data?.meta?.last_page,
      additional: {
        page: restParams.page + 1,
        ...restParams
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
      }
    }
  }
}

export const getSkuOptionsForConditions = async (search, _loadedOptions, {sellerId, params: restParams}) => {
  try {
    const  params = { search_value: search, ...restParams }
    const response = await axiosInstance.get(`/api/v1/products/sellers/${sellerId}/order_skus?${paramsSerializer({ ...params })}`)

    const data = response?.data?.data ?? []
    const options = data?.map(ele => ({
      ...ele,
      value: ele.sku.id,
      label: ele.sku.seller_sku_code,
      logo: ele.sku.images?.[0]?.default,
      type: ele.sku.type,
      uom: ele.sku.uom,
      is_weighted: ele.sku.is_weighted,
      name: ele.sku.name,
      weight: ele.sku.weight.value,
      quantity: 1
    }))

    return {
      options: options,
      hasMore: restParams.page < response?.data?.meta?.last_page,
      additional: {
        page: restParams.page + 1,
        ...restParams
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
      }
    }
  }
}

export const getUsersListAsyncData = async (search, loadedOptions, { page, hub_id }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/tenant/hubs/${hub_id}/users`, { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}


export const getLocationListAsyncData = async (search, loadedOptions, { page, hub_id }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/wms//hubs/${hub_id}/locations`, { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.barcode
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncAdHoc = async (search, loadedOptions, { page, selectedAdHoc }) => {
  try {
    const response = await axiosInstance.get('/api/v1/billing/adhoc', {
      params: { query: search, page }
    })
    const data = response?.data

    if (selectedAdHoc?.length) {
      const filteredAdHoc = data?.data?.filter((el) => {
        if (!(selectedAdHoc.includes(el.name))) {
          return el
        }
      }) || []
      return {
        options: filteredAdHoc.map(ele => ({
          value: ele.name,
          label: ele.name,
          ...ele
        })),
        hasMore: page < data?.meta?.last_page,
        additional: {
          page: page + 1
        }
      }
    }
    
    return {
      options: data?.data?.map(ele => ({
        value: ele.name,
        label: ele.name,
        ...ele
      })) || [],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncAdhocActivities = async (search, prevOptions, { page }) => {
  try {
    const params = {
      search_column: search ? 'name' : undefined,
      search_query: search || undefined,
      page
    }
    const response = await axiosInstance.get('/api/v1/billing/adhoc', { params })
    const data = response?.data
    if (page === 1 && !search && !data?.data?.length) {
      CustomToast('You cannot add any ad hoc usage as there are no ad hoc activities currently in the system. Please create an ad hoc activity first to add usage against it.', { my_type: 'error', duration: 2000, audioRequired: false })
    }
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        ...ele
      })) || [],
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAsyncSkuConfigHubs = async (search, loadedOptions, { page, sellerSkuId, hubType, is_external_hub }) => {
  const params = search ? { search_column: 'name', search_query: search, type:hubType, seller_sku_id:sellerSkuId, exclude_external_hub:is_external_hub } : { page, type:hubType, seller_sku_id:sellerSkuId, exclude_external_hub:is_external_hub }
  try {
    const response = await axiosInstance.get('/api/v1/wms/skus/config/hubs', { params })
    const data = response?.data
    
    return {
      options: data?.data?.map(ele => ({
        value: ele.hub?.id,
        label: ele.hub?.name,
        ...ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        sellerSkuId,
        hubType
      }
    }
  } catch (error) {
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getVirtualHubsInSettings = async (search, loadedOptions, { page = 1, hasCreateHubPermission }) => {
  try {
    const response = await axiosInstance.get('/api/v1/oms/hubs/all', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data
    const modiFiedData = data?.data?.map(ele => ({
      value: {
        id: ele.id,
        type: ele.type,
        code: ele.hub_code
      },
      label: ele.name,
      address: ele.address
    }))
    const isHasMore = page < data?.meta?.last_page
    if (hasCreateHubPermission && page === 1 && Array.isArray(modiFiedData)) {
      modiFiedData.unshift({ value: 'add_new_virtual_hub', label: '+ Create Pickup Location', type: 'virtual' })
    }
    return {
      options: modiFiedData,
      hasMore: isHasMore,
      additional: {
        page: data?.meta?.current_page + 1,
        hasCreateHubPermission
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hasCreateHubPermission
      }
    }
  }
}

export const getOrderSkus = async (search, _loadedOptions, {sellerId, params: restParams, status='active'}) => {
  try {
    const  params = { search_value: search, ...restParams }
    const response = await axiosInstance.get(`/api/v1/products/sellers/${sellerId}/order_skus?${paramsSerializer({ ...params, status })}`)

    const data = response?.data?.data ?? []

    return {
      options: data,
      hasMore: restParams.page < response?.data?.meta?.last_page,
      additional: {
        page: restParams.page + 1,
        ...restParams
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
      }
    }
  }
}