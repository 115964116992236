import BulkActions from '@src/@core/components/ui/bulk-actions/BulkActions'
import Button from '@src/@core/components/ui/button'
import { CREATE_RETURN_REQUES_PATH } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { AbilityContext } from '@src/utility/context/Can'
import { printMultipleAwb } from '@src/utility/Utils'
import { getReturnOrderToCreateReturnRequest, setIsRedirectFromItemDetails } from '@src/views/returns/store'
import Invoice from '@src/views/sales/components/invoice/invoice'
import { PalletPackingList, PalletPackingSlip } from '@src/views/sales/components/palletPdf/palletPackingList/palletPackingList'
import { DELIVERY_TYPE, SHIPPING_ENTITY } from '@src/views/sales/constant/orders.constants'
import { ORDER_RECREATION_TYPE } from '@src/views/sales/create-order/create-order.constant'
import { FINAL_ORDER_TOTAL_LABELS } from '@src/views/sales/sales.constant'
import { categorizeOrderDetailsButtons, getRefundAbleAmount } from '@src/views/sales/sales.utils'
import { createBulkReturnOrder, fetchAWB } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import PrintAwb from '../../orderDetailsTabPages/PrintAwb/PrintAwb'
import PrintDeliveryNote from '../../orderDetailsTabPages/PrintDeliveryNote'

const ButtonSection = ({ orderDetails, setIsModalOpen, setShipmentSlider, setDeleteModalOpen, setIsOnHoldOrderCofirmationPopUpOpen, setIsCancelOrderPopupOpen, handleGetButtonArray, setReassignHubModalOpen, handleReorder, openTransactionSheet }) => {

  const ability = useContext(AbilityContext)
  const dispatch = useDispatch()

  const printInvoiceRef = useRef(null)
  const printListRef = useRef(null)
  const printSlipRef = useRef(null)
  const printManualAwbRef = useRef(null)
  const printDeliveryNoteRef = useRef(null)

  const userHubs = useSelector(state => state.auth.userHubs) || []
  const is_external_hub = userHubs.find(hub => hub.id === orderDetails.hub_id)?.is_external_hub
  const hasEditOrderDetailsPermission = ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource)

  const sales_loading = useSelector((state) => state.sales.loading)
  const { loading } = useSelector((state) => state.returns)

  const [printAwbLoading, setPrintAwbLoading] = useState(false)

  const shouldRenderPrimaryButton = (primaryActionButton) => {
    if (primaryActionButton &&
      !(primaryActionButton.name === 'PRINT_DELIVERY_NOTE' ||
        primaryActionButton.name === 'PRINT_MANUAL_AWB' ||
        primaryActionButton.name === 'PRINT_INVOICE')) {
      return true
    } else {
      return false
    }
  }

  const handlePrintAwb = async () => {
    if (orderDetails?.shipment?.awb_label) {
      printMultipleAwb(orderDetails.shipment.awb_label)
    } else {
      setPrintAwbLoading(true)
      const params = {
        entity_id: orderDetails.id,
        entity_type: SHIPPING_ENTITY.FORWARD_ORDER.value
      }
      const res = await fetchAWB({ params })
      if (res?.is_success && res?.data?.awb_label) {
        printMultipleAwb(res.data.awb_label)
      }
      setPrintAwbLoading(false)
    }
  }


  const handlePrintInvoice = () => {
    printInvoiceRef.current.click()
  }
  const handlePrintPackingList = () => {
    printListRef.current.click()
  }
  const handlePrintPackingSlip = () => {
    printSlipRef.current.click()
  }

  const handlePrintManualAwb = () => {
    printManualAwbRef.current.click()
  }

  const handlePrintDeliveryNote = () => {
    printDeliveryNoteRef.current.click()
  }

  const handleCreateReturnRequest = () => {
    if (location.pathname === CREATE_RETURN_REQUES_PATH) {
      dispatch(setIsRedirectFromItemDetails(true))
    }
    dispatch(
      getReturnOrderToCreateReturnRequest({
        seller_sales_channel_order_Id: orderDetails?.seller_sales_channel_order_id
      })
    )
  }

  const handleShipmentCreation = () => {
    setIsModalOpen(false)
    setShipmentSlider(true)
  }

  const handleCreateReturnOrder = () => {
    const body = {
      order_ids: [orderDetails.id]
    }
    dispatch(createBulkReturnOrder({ body }))
  }

  const handleCancelShipment = () => {
    setDeleteModalOpen(true)
  }

  // The order of buttons in the array is important; we arrange them in order of priority
  const buttonArray = [
    {
      id: 'create_return_request',
      name: 'CREATE_RETURN_REQUEST',
      onClick: handleCreateReturnRequest,
      hasPermission: ability.can(abilityMap.return.create_request.action, abilityMap.return.create_request.resource)
    },
    {
      id: 'create_shipment',
      name: 'CREATE_SHIPMENT',
      onClick: handleShipmentCreation,
      hasPermission: ability.can(abilityMap.order.create_shipment.action, abilityMap.order.create_shipment.resource)
    },
    {
      id: 'create_reshipment',
      name: 'CREATE_SHIPMENT',
      onClick: handleShipmentCreation,
      hasPermission: ability.can(abilityMap.order.create_shipment.action, abilityMap.order.create_shipment.resource)
    },
    {
      id: 'create_courier_return',
      name: 'CREATE_COURIER_RETURN',
      onClick: handleCreateReturnOrder,
      hasPermission: true
    },
    {
      id: 'print_awb',
      name: 'PRINT_AWB',
      onClick: handlePrintAwb,
      hasPermission: true
    },
    {
      id: 'print_manual_awb',
      name: 'PRINT_MANUAL_AWB',
      onClick: handlePrintManualAwb,
      hasPermission: true
    },
    {
      id: 'print_invoice',
      name: 'PRINT_INVOICE',
      onClick: handlePrintInvoice,
      hasPermission: true
    },
    {
      id: 'print_delivery_note',
      name: 'PRINT_DELIVERY_NOTE',
      onClick: handlePrintDeliveryNote,
      hasPermission: true
    },
    {
      id: 'cancel_shipment',
      name: 'CANCEL_SHIPMENT',
      onClick: handleCancelShipment,
      hasPermission: orderDetails?.delivery_type !== DELIVERY_TYPE.CLICK_AND_COLLECT.name
    },
    {
      id: 'put_on_hold',
      name: 'PUT_ON_HOLD',
      onClick: () => setIsOnHoldOrderCofirmationPopUpOpen(true),
      hasPermission: !is_external_hub && orderDetails?.forceful_onhold_allowed && ability.can(abilityMap.order.approve.action, abilityMap.order.approve.resource)
    },
    {
      id: 'cancel_order',
      name: 'CANCEL_ORDER',
      onClick: () => setIsCancelOrderPopupOpen(true),
      hasPermission: ability.can(abilityMap.order.cancel.action, abilityMap.order.cancel.resource)
    },
    {
      id: 'print_packing_list',
      name: 'PRINT_PACKING_LIST',
      onClick: handlePrintPackingList,
      hasPermission: true
    },
    {
      id: 'print_packing_slip',
      name: 'PRINT_PACKING_SLIP',
      onClick: handlePrintPackingSlip,
      hasPermission: true
    },
    {
      id: 'reassign_hub',
      name: 'REASSIGN_HUB',
      onClick: ()=>setReassignHubModalOpen(true),
      hasPermission: ability.can(abilityMap.order.reassign_hub.action, abilityMap.order.reassign_hub.resource)
    },
    {
      id: 'duplicate_order',
      name: 'DUPLICATE_ORDER',
      onClick: () => handleReorder({recreationType: ORDER_RECREATION_TYPE.DUPLICATE}),
      hasPermission: ability.can(abilityMap.order.create.action, abilityMap.order.create.resource)
    },
    {
      id: 'reorder',
      name: 'REORDER',
      onClick: () => handleReorder({recreationType: ORDER_RECREATION_TYPE.RE_ORDER}),
      hasPermission: ability.can(abilityMap.order.create.action, abilityMap.order.create.resource)
    },
    {
      id: 'mark_as_paid',
      name: 'MARK_AS_PAID',
      onClick: () => openTransactionSheet({ orderTotalType: FINAL_ORDER_TOTAL_LABELS.DUE_AMOUNT.value, finalOrderTotal: orderDetails.invoice.total_due }),
      hasPermission: hasEditOrderDetailsPermission
    },
    {
      id: 'mark_as_refunded',
      name: 'MARK_AS_REFUNDED',
      onClick: () => openTransactionSheet({ orderTotalType: FINAL_ORDER_TOTAL_LABELS.REFUND_DUE.value, finalOrderTotal: getRefundAbleAmount(orderDetails) }),
      hasPermission: hasEditOrderDetailsPermission
    }
  ]

  const primaryButtonLoading = {
    CREATE_SHIPMENT: false,
    CANCEL_SHIPMENT: sales_loading.cancelShipment,
    PRINT_MANUAL_AWB: false,
    PRINT_AWB: printAwbLoading,
    PRINT_DELIVERY_NOTE: false,
    PRINT_INVOICE: false,
    CREATE_RETURN_REQUEST: loading.getReturnOrderToCreateReturnRequest,
    PUT_ON_HOLD: sales_loading.putOrderOnHold,
    CREATE_COURIER_RETURN: sales_loading.createBulkReturnOrder,
    PRINT_PACKING_LIST: false,
    PRINT_PACKING_SLIP: false
  }

  // Filter action buttons
  const { actionButtons, syncButtons, editButtons } = useMemo(() => categorizeOrderDetailsButtons(orderDetails, buttonArray), [orderDetails, buttonArray])

  useEffect(() => {
    handleGetButtonArray({ actionButtons, syncButtons, editButtons })
  }, [])

  const primaryActionButton = actionButtons.shift()

  return (
    <>
      {(primaryActionButton || actionButtons.length > 0) && <div className="d-flex gap-12px">

        {shouldRenderPrimaryButton(primaryActionButton) &&
          <Button
            onClick={primaryActionButton.onClick}
            disabled={primaryButtonLoading[primaryActionButton.name]}
            icon={primaryButtonLoading[primaryActionButton.name] && Spinner}
            iconSize='sm'
          >
            {primaryActionButton.text}
          </Button>
        }

        {orderDetails && <>
          <span className={classNames({ 'd-none': primaryActionButton.name !== 'PRINT_DELIVERY_NOTE' })}>
            <PrintDeliveryNote printDeliveryNoteRef={printDeliveryNoteRef} orderDetails={orderDetails} />
          </span>
          <span className={classNames({ 'd-none': primaryActionButton.name !== 'PRINT_MANUAL_AWB' })}>
            <PrintAwb printManualAwbRef={printManualAwbRef} orderDetails={orderDetails} />
          </span>
          <span className={classNames({ 'd-none': primaryActionButton.name !== 'PRINT_INVOICE' })}>
            <Invoice printInvoiceRef={printInvoiceRef} order_id={orderDetails.id} />
          </span>
          <span className={classNames({ 'd-none': primaryActionButton.name !== 'PRINT_PACKING_LIST' })}>
            <PalletPackingList printListRef={printListRef} oms_order_id={orderDetails.id} hubId={orderDetails.hub_id} />
          </span>
          <span className={classNames({ 'd-none': primaryActionButton.name !== 'PRINT_PACKING_SLIP' })}>
            <PalletPackingSlip printSlipRef={printSlipRef} oms_order_id={orderDetails.id} hubId={orderDetails.hub_id}/>
          </span>
        </>}

        {actionButtons.length > 0 && <BulkActions actions={actionButtons} selectedButton={{ id: 'moreActions', text: 'More Actions' }} />}
      </div>}
    </>
  )
}

export default ButtonSection