import { DROPDOWN_TYPES } from '@src/@core/components/ui/customTableFilterComponent/customTableFilterComponent.constant'
import { getLoadHubsConfig, loadShippingPartnerConfig } from '@src/app.apis'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { getOptionsFetcher } from '@src/utility/Utils'
import { ACTION_BUTTONS_ID } from './pages/return-order/return-order-details/constant'
import { EDITABLE_RETURN_FEES, FORM_FIELD_TO_API_MAP, NUMBER_TYPE_KEYS } from './returns.constants'

export const formatAdditionalCharges = (additionalCharges) => {
  
  for (const key in additionalCharges) {
    if (NUMBER_TYPE_KEYS.includes(key)) {
      additionalCharges[key] = Number(additionalCharges[key])
    }
  }
  return additionalCharges
}

export const formatAdditionalChargesForApi = (actualFormat) => {
 
  const [returnFeeActualFormat, returnShipmentFeeActualFormat] = [actualFormat[EDITABLE_RETURN_FEES.RETURN_FEE.KEY], actualFormat[EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.KEY]]

  const additionalCharges = {
    [EDITABLE_RETURN_FEES.RETURN_FEE.KEY]: actualFormat[EDITABLE_RETURN_FEES.RETURN_FEE.KEY] ? {type: EDITABLE_RETURN_FEES.RETURN_FEE.KEY, tax_inclusive: true} : undefined, 
    [EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.KEY]: actualFormat[EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.KEY] ? {type: EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.KEY, tax_inclusive: true} : undefined
  }
  for (const key in actualFormat[EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.KEY]) {
    const isNumberType = NUMBER_TYPE_KEYS.includes(key)
    additionalCharges[EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.KEY][FORM_FIELD_TO_API_MAP[key]] = isNumberType ? +returnShipmentFeeActualFormat[key] || 0 : returnShipmentFeeActualFormat[key]
  }

  for (const key in actualFormat[EDITABLE_RETURN_FEES.RETURN_FEE.KEY]) {
    const isNumberType = NUMBER_TYPE_KEYS.includes(key)
    additionalCharges[EDITABLE_RETURN_FEES.RETURN_FEE.KEY][FORM_FIELD_TO_API_MAP[key]] = isNumberType ? +returnFeeActualFormat[key] || 0 : returnFeeActualFormat[key]
  }
  return Object.values(additionalCharges).filter(el => el)
}


export const getHubCustomFilter = () => {
  const loadHubOptions = getOptionsFetcher(getLoadHubsConfig({ includeAllOption: true }))
  return {
    filterKey: 'hub_id',
    displayName: 'Hubs',
    type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
    options: loadHubOptions
  }
}

export const getReturnOrderCustomFilters = () => {
  const loadShippingPartnerOptions = getOptionsFetcher(loadShippingPartnerConfig())
  return {
    shipping_partner_tag: {
      filterKey: 'shipping_partner_tag',
      displayName: 'Shipping Partners',
      type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
      options: loadShippingPartnerOptions
    }
  }
}

export const checkActionColumnVisibility = ({ability, tableData}) => {
  if (!ability.can(abilityMap.return.cancel_order.action, abilityMap.return.cancel_order.resource)) {
    return true;
  }  
  const isCancelReturnOrderButtonPresent = Array.isArray(tableData) && tableData.length > 0 && 
    tableData.some(row => 
      Array.isArray(row.buttons) && row.buttons.some(button => button.key === ACTION_BUTTONS_ID.CANCEL_ORDER)
    );
  return !isCancelReturnOrderButtonPresent;
}