import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { AbilityContext } from '@src/utility/context/Can'
import { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { CREATE_SHIPMENT_STATUS, SHIPPING_ENTITY, SHIPPING_OPTION } from '../../constant/orders.constants'
import CustomCourierPartnerOption from '../../create/customCourierPartnerOption'
import {
  clearGetPrioritisedShippingPartners,
  createShipment,
  createShipmentOrderShipment,
  fetchAWB,
  getAsyncParcelShop,
  getCouriersPartners,
  getShippingPartnersAccounts,
  getSingleOrderDetails,
  updatePrefilledNumberOfPackages,
  updatePrefilledWeight,
} from '../../store/store'
import { getCourierPartnerConfig, getOrderDetailsFieldConfigurations, getParcelShopsConfig, handleAwb, prepareShipmentData } from '../utils'
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap'
import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'

const useCreateShipmentComponents = ({ CustomDetailsOption, entity_name, omnifulGeneratedShipmentType, orderID, setOmnifulGeneratedShipmentType, shippingDetailsData }) => {
  // Hooks
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const printManualAwbRef = useRef(null)
  const printDeliveryNoteRef = useRef(null)
  const [searchParams] = useSearchParams({})

  // Selectors
  const success = useSelector((store) => store.sales.success)
  const loading = useSelector((store) => store.sales.loading)
  const shipmentDetails = useSelector((store) => store.sales.shipmentDetails)
  const singleOrderDetailsData = useSelector((store) => store.sales.singleOrderDetailsData)
  const tenantId = useSelector((store) => store.auth.userData.id)
  const getPrioritisedShippingPartnersResonse = useSelector((state) => state.sales.getPrioritisedShippingPartnersRes)
  const preFilledWeight = useSelector((state) => state.sales.preFilledWeight)
  const preFilledNumberOfPackages = useSelector((state) => state.sales.preFilledNumberOfPackages)

  // States
  const [shippingPartnerOptions, setShippingPartnerOptions] = useState([{}])
  const [shipmentCreated, setShipmentCreated] = useState(false)
  const [printAwbLoading, setPrintAwbLoading] = useState(false)
  const [DBOrderId, setDBOrderId] = useState()
  const [manualPrintLoadingState, setManualPrintLoadingState] = useState('')
  const [isRemarkDisabled, setIsRemarkDisabled] = useState(false)
  const [multipleAwbModalOpen, setMultipleAwbModalOpen] = useState(false)
  const [multipleAwbList, setMultipleAwbList] = useState([])
  const [packageData, setPackageData] = useState([])
  const [isCityEditing, setIsCityEditing] = useState(false)
  const [createShipmentStatus, setCreateShipmentStatus] = useState(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED)

  // Variables
  const orderDetails = singleOrderDetailsData?.data
  const defaultCountry = { value: shippingDetailsData.entity_data.hub.address.country_id, label: shippingDetailsData.entity_data.hub.address.country }
  const defaultCity = { value: shippingDetailsData.entity_data.hub.address.city_id, label: shippingDetailsData.entity_data.hub.address.city }
  const omnifulCountry = { value: shippingDetailsData.entity_data.shipping_address.omniful_country_id, label: shippingDetailsData.entity_data.shipping_address.omniful_country }
  const omnifulCity = { value: shippingDetailsData.entity_data.shipping_address.omniful_city_id, label: shippingDetailsData.entity_data.shipping_address.omniful_city }
  const weightFromShippingDetailsData = shippingDetailsData.entity_data.weight > 0 ? parseFloat(shippingDetailsData.entity_data.weight) : 1
  const numberOfBoxesFromShippingDetailsData = shippingDetailsData.entity_data.number_of_packages ? parseInt(shippingDetailsData.entity_data.number_of_packages) : 1
  const initialWeight = typeof preFilledWeight === 'number' && preFilledWeight > 0 ? preFilledWeight : weightFromShippingDetailsData
  const initialNumberOfBoxes = preFilledNumberOfPackages || numberOfBoxesFromShippingDetailsData

  const RenderDeliveryStatus = () => {
    return (
      <div id="shipment-details-delivery-status-accordian">
        {shipmentDetails.data.shipping_partner_status ? (
          <UncontrolledAccordion defaultOpen="delivery-status">
            <AccordionItem className="pt-0">
              <AccordionHeader targetId="delivery-status" className="pt-0">
                <CustomLabel title={shipmentDetails.data.status} className="bg-primary-light text-primary" />
              </AccordionHeader>
              <AccordionBody accordionId="delivery-status">
                <div className="text-secondary">{shipmentDetails.data.shipping_partner_status}</div>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        ) : (
          <CustomLabel title={shipmentDetails.data.status} className="bg-primary-light text-primary" />
        )}
      </div>
    )
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    control,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: {
      awb: '',
      weight: initialWeight,
      remarks: '',
      shipment_type: '',
      number_of_boxes: initialNumberOfBoxes,
      city: {},
      country: {},
      city_id_from: {},
      country_id_from: {},
      shipping_partner: null,
      shipping_reference: '',
      suggested_shipping_account: null,
    },
    mode: 'onChange',
  })

  const allFields = watch()
  const { shipping_partner: shippingPartner, shipment_type } = allFields
  const city_Id = typeof allFields.city?.value === 'string' ? +allFields.city?.value?.split('-')?.[0] : allFields.city?.value
  const shippingPartnerObj = shippingDetailsData.shipping_options.find((option) => option.key === watch('shipment_type'))
  const courierPartnerConfig = watch('shipping_partner')?.shipping_partner?.details?.configurations?.fetch_courier_partners
  const isCourierPartnerForShipping = courierPartnerConfig?.fetch
  const isCourierPartnerRequired = courierPartnerConfig?.required
  const shipping_partner_tag = shippingPartner?.shipping_partner?.tag || shippingPartnerObj?.shipping_partners?.[0]?.tag
  const loadingFetchDimensions = useSelector((state) => state.shipping.shippingPartnerMethods.loading)
  const isCityMappingRequired = watch('shipping_partner')?.shipping_partner.details?.is_city_mapping_required
  const dropCity = watch('shipping_partner')?.shipping_partner.city_mapping_details?.drop_city
  const isFetch_multi_dimensions = allFields?.shipping_partner?.shipping_partner.details?.fetch_multi_dimensions
  const isCreateShipmentDisabled = !!((isCityMappingRequired && !dropCity) || isCityEditing)

  const orderDetailsFieldConfigurations = getOrderDetailsFieldConfigurations({
    createShipmentStatus,
    omnifulGeneratedShipmentType,
    isRemarkDisabled,
  })

  // courier partner dropdown field
  const newConfigForCourierPartner = getCourierPartnerConfig({
    control,
    getCouriersPartners,
    isCourierPartnerRequired,
    watch,
    errors,
    omnifulGeneratedShipmentType,
    allFields,
    tenantId,
    shipping_partner_tag,
    shippingDetailsData,
    city_Id,
    shippingPartner,
    CustomCourierPartnerOption,
  })

  const configForParcelShops = getParcelShopsConfig({
    control,
    getAsyncParcelShop,
    errors,
    watch,
    shipping_partner_tag,
    shippingDetailsData,
    CustomDetailsOption,
  })

  // Handlers
  const handlePrintAwb = () => handleAwb({ shipmentDetails, entity_name, fetchAWB, setPrintAwbLoading })

  const handleOrderDetailsData = () => {
    dispatch(getSingleOrderDetails(DBOrderId))
  }
  const handleGetOrderDataForManualPrint = (printButtonDetail) => {
    setManualPrintLoadingState(printButtonDetail)
    dispatch(getSingleOrderDetails(DBOrderId))
  }

  const handleOrderFulfillment = (e) => {
    setOmnifulGeneratedShipmentType(e.target.value)
    clearErrors()
    setValue('shipping_partner', null)
    setValue('suggested_shipping_account', null)
    setValue('courier_partner', null)
    setValue('number_of_boxes', initialNumberOfBoxes)
    setValue('weight', initialWeight)
    setValue('awb', '')
    setValue('remarks', '')
    setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED)
    setValue('country', omnifulCountry.value ? omnifulCountry : '')
    setValue('city', omnifulCity.value ? omnifulCity : '')
    setIsRemarkDisabled(false)
  }

  const handleEditOrderDetails = () => {
    dispatch(clearGetPrioritisedShippingPartners())
    setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED)
    setIsRemarkDisabled(false)
    setValue('shipping_partner', null)
    setValue('suggested_shipping_account', null)
    setValue('shipping_reference', null)
    clearErrors('remarks')
    setIsCityEditing(false)
  }

  const onSubmit = (data) => {
    const shipmentData = prepareShipmentData({ data, omnifulGeneratedShipmentType, packageData, isFetch_multi_dimensions, watch, getPrioritisedShippingPartnersResonse })
    if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
      dispatch(createShipmentOrderShipment({ orderID, shipmentData }))
    } else {
      dispatch(createShipment({ orderID, shipmentData }))
    }
  }

  const handleGetShippingPartnerOrder = async () => {
    try {
      const isValidated = await trigger()
      if (!watch('country') && !watch('city')) {
        CustomToast('City and Country are required', { my_type: 'error', audioRequired: false })
      } else if (!watch('country')) {
        CustomToast('Country is required', { my_type: 'error', audioRequired: false })
      } else if (!watch('city')) {
        CustomToast('City is required', { my_type: 'error', audioRequired: false })
      } else if (isValidated) {
        const formValues = watch()
        const body = {
          order_details: {},
          order_address: {
            to: {},
            from: {
              city_id: `${defaultCity.value}`,
              city: defaultCity.label,
              country_id: `${defaultCountry.value}`,
              country: defaultCountry.label,
            },
          },
          entity_id: '',
          entity_type: '',
        }
        body.shipment_type = formValues.shipment_type
        body.order_address.to.city_id = `${formValues.city.value}`
        body.order_address.to.city = formValues.city.label
        body.order_address.to.country_id = `${formValues.country.value}`
        body.order_address.to.country = formValues.country.label
        body.order_details.number_of_boxes = +formValues.number_of_boxes
        body.order_details.weight = +formValues.weight
        body.order_details.remarks = formValues.remarks
        body.entity_id = orderID
        if (entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value) {
          body.entity_type = SHIPPING_ENTITY.SHIPMENT_ORDER.value
          dispatch(getShippingPartnersAccounts({ body }))
        } else {
          body.entity_type = SHIPPING_ENTITY.FORWARD_ORDER.value
          dispatch(getShippingPartnersAccounts({ body }))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const togglemultipleAwbModal = (awbData) => {
    setMultipleAwbList(awbData ? awbData : [])
    setMultipleAwbModalOpen((p) => !p)
  }

  useEffect(() => {
    setShipmentCreated(false)
    setValue('country_id_from', defaultCountry)
    setValue('city_id_from', defaultCity)
    return () => {
      setManualPrintLoadingState('')
      dispatch(updatePrefilledNumberOfPackages(null))
      dispatch(updatePrefilledWeight(null))
    }
  }, [])

  useEffect(() => {
    if (shipmentDetails) {
      setShipmentCreated(true)
      if (shipmentDetails.data.oms_order_id) setDBOrderId(shipmentDetails.data.oms_order_id)
    }
  }, [shipmentDetails])

  useEffect(() => {
    if (shipment_type === SHIPPING_OPTION.MANUAL_DISPATCH.name || shipment_type === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) {
      setValue('courier_partner', { label: 'Manual', value: 'manual' })
    }
  }, [shipment_type])

  useEffect(() => {
    if (success.createShipment || success.createShipmentOrderShipment) {
      setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED)
    }
  }, [success])

  useEffect(() => {
    if (getPrioritisedShippingPartnersResonse.is_success) {
      setCreateShipmentStatus(CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED)
      const shippingPartnerOption = getPrioritisedShippingPartnersResonse.data.data.shipping_accounts.map((ele) => {
        const storedObj = {
          ...ele,
          label: `${ele.account_name} (${ele.shipping_partner.name})`,
          value: ele.id,
          logo: ele.shipping_partner.logo,
        }
        return storedObj
      })
      setShippingPartnerOptions(shippingPartnerOption)

      const actionValue = getPrioritisedShippingPartnersResonse.data.data.action_value

      if (shippingPartnerOption[0]) {
        setValue('shipping_partner', shippingPartnerOption[0])
        setValue('suggested_shipping_account', shippingPartnerOption[0])
        const serviceTypes = shippingPartnerOption[0]?.shipping_partner?.details?.service_types
        const productType = shippingPartnerOption[0]?.shipping_partner?.product_type?.forward
        const productTypeOptions = productType?.value
        const detailsProductType = shippingPartnerOption[0]?.details?.[productType?.key_name]
        const defaultServiceType = serviceTypes?.find((item) => item.value === shippingPartnerOption[0]?.details?.service_type)
        setValue('service_type', defaultServiceType || null)
        if (productType?.key_name && productTypeOptions?.length) {
          const defaultProductType = actionValue?.product_type?.find((item) => item.id === shippingPartnerOption[0].id)
          const defaultDetailsProductType = productTypeOptions?.find((item) => item.value === detailsProductType)
          const defaultValue = defaultProductType?.value || defaultDetailsProductType || null
          setValue(productType.key_name, defaultValue)
        }
      }
    }
  }, [getPrioritisedShippingPartnersResonse])

  useEffect(() => {
    setValue('country', omnifulCountry.value ? omnifulCountry : '')
    setValue('city', omnifulCity.value ? omnifulCity : '')
    handleEditOrderDetails()
  }, [shippingDetailsData])

  useEffect(() => {
    if (createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED) {
      setIsRemarkDisabled(true)
    } else {
      setIsRemarkDisabled(false)
    }
  }, [isCourierPartnerForShipping, createShipmentStatus])

  useEffect(() => {
    if (omnifulGeneratedShipmentType !== watch('shipment_type')) {
      setValue('shipment_type', omnifulGeneratedShipmentType)
    }
  }, [omnifulGeneratedShipmentType])

  useEffect(() => {
    //get shipping partner automatically if getting shipping partner in shipping option
    if (shippingDetailsData.shipping_options[0].key === SHIPPING_OPTION.SHIPPING_PARTNER.name) {
      handleGetShippingPartnerOrder()
    }
  }, [shippingDetailsData])

  return {
    ability,
    clearErrors,
    configForParcelShops,
    control,
    createShipmentStatus,
    defaultCity,
    defaultCountry,
    errors,
    getPrioritisedShippingPartnersResonse,
    handleAwb: handlePrintAwb,
    handleEditOrderDetails,
    handleGetOrderDataForManualPrint,
    handleGetShippingPartnerOrder,
    handleOrderDetailsData,
    handleOrderFulfillment,
    handleSubmit,
    isCityEditing,
    isCourierPartnerForShipping,
    isCourierPartnerRequired,
    isCreateShipmentDisabled,
    loading,
    loadingFetchDimensions,
    manualPrintLoadingState,
    multipleAwbList,
    multipleAwbModalOpen,
    newConfigForCourierPartner,
    omnifulCity,
    onSubmit,
    orderDetails,
    orderDetailsFieldConfigurations,
    packageData,
    printAwbLoading,
    printDeliveryNoteRef,
    printManualAwbRef,
    register,
    searchParams,
    setIsCityEditing,
    setPackageData,
    setValue,
    shipmentCreated,
    shipmentDetails,
    shippingPartnerOptions,
    t,
    togglemultipleAwbModal,
    watch,
    RenderDeliveryStatus,
  }
}

export default useCreateShipmentComponents
