import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmationPopup from '../../../components/popup/confirmationPopup'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, ALL_RETURN_STATUS_COLUMN, ALL_RETURN_STATUS_FILTER_OPTIONS, DATE_FILTER_KEY, RETURN_ORDER_COLUMNS_LIST, RETURN_ORDER_TABS, STATUS_FILTER_DROPDOWN_STYLE } from '../../../returns.constants'
import { cancelReturnOrder, getReturnOrderDetail, getReturnOrders, resetSuccess } from '../../../store'
import ReturnOrderDetail from '../return-order-details'
// import ReverseShipment from "../create-reverse-shipment"
import CustomFilter from '@src/@core/components/ui/customTableFilterComponent'
import DateFilter from '@src/@core/components/ui/date-filter'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import CustomDropdownOption from '@src/@core/components/ui/dropdown/customDropdownOption'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import { COLLAPSED_CONTAINER_LIST, TENANT_TYPE } from '@src/App.constants'
import { loadAllSellersConfig, loadCourierPartnersConfig, loadSellerSalesChannelConfig } from '@src/app.apis'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { getOptionsFetcher, isObjEmpty } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters'
import { getReturnOrderCustomFilters } from '@src/views/returns/returns.utils'
import { getAllHubListAsyncData } from '@src/views/sales-channel/store'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import CreateShipmentSideSheet from '@src/views/sales/live-orders/pages/allOrders/CreateShipmentSideSheet'
import { createDateRangeObject, getLastNinetyDaysRange } from '@src/views/sales/sales.utils'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { STRINGIFY } from '../../../../../utility/hooks/useFilters'
import TypeOfReturnsInformation from '../../../components/TypeOfReturnsInformation/index'
import CreateReturnOrderProcessing from '../../return-order-processing/create-return-order-processing'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'
import { ROUTES_KEYS } from '@src/router/route-keys'

const AllReturnOrders = (props) => {

  const { handleCreateReverseShipmentModal } = props
  
  // Hooks
  const {t} = useTranslation()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const dispatch = useDispatch()

  // Stores
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const selfTenantSellerId = useSelector((state) => state.auth.userData.sellers[0]?.seller_id)
  const loading = useSelector((store) => store.returns.loading)
  const success = useSelector((store) => store.returns.success)
  const error = useSelector((store) => store.returns.error)
  const returnOrders = useSelector((store) => store.returns.returnOrders)
  
  const ninetyDaysRange = getLastNinetyDaysRange();
  const created = createDateRangeObject(ninetyDaysRange.from, ninetyDaysRange.to);

  // Custom Hooks
  const { filter, setFilter } = useFilters({created})
  const { searchQuery, setSearchQuery } = useSearch({})
  const {pagination, setPagination} = usePagination()

  // States
  const [tableData, setTableData] = useState([])
  const [isReturnOrderDetailModalOpen, setReturnOrderDetailModalOpen] = useState(false)
  const [isOrderDetailSidebarOpen, setOrderDetailSidebarOpen] = useState(false)
  const [returnOrderToShowDetail, setReturnOrderToShowDetail] = useState(null)
  const [isCancelReturnOrderPopupOpen, setCancelReturnOrderPopupOpen] = useState(false)
  const [returnOrderIdToCancel] = useState(null)
  const [isCreateReturnOrderProcessingModalOpen, setCreateReturnOrderProcessingModalOpen] = useState(false)
  const [shipmentSlider, setShipmentSlider] = useState(false)
  const [omsOrderId, setOmsOrderId] = useState('')

  // Variables
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)
  const loadSellerSalesChannelOptions = getOptionsFetcher(loadSellerSalesChannelConfig);
  const loadAllSellersOptions = getOptionsFetcher(loadAllSellersConfig())
  const loadCourierPartnersOptions = getOptionsFetcher(loadCourierPartnersConfig)

  const handleReturnOrderDetailModal = useCallback(() => {
    setReturnOrderDetailModalOpen(prev => !prev)
  }, [isOrderDetailSidebarOpen])

  const handleOrderDetailSidebar = useCallback(() => {
    setOrderDetailSidebarOpen((prev) => !prev)
  }, [isOrderDetailSidebarOpen])

  const handleSetOrderToShowOrderDetail = useCallback((order) => {
    setOmsOrderId(order.order_id)
    setReturnOrderToShowDetail(order)
    handleOrderDetailSidebar()
  }, [returnOrderToShowDetail])

  const handleSetReturnOrderToShowDetail = useCallback((order) => {
    setReturnOrderToShowDetail(order)
    handleReturnOrderDetailModal()
  }, [returnOrderToShowDetail])

  const handleCreateReturnOrderProcessingModal = useCallback((params) => {
    if (params?.orderID) {
      dispatch(getReturnOrderDetail(params?.orderID))
    }
    setCreateReturnOrderProcessingModalOpen(prev => !prev)
  }, [isCreateReturnOrderProcessingModalOpen])

  const getAllAppliedFilters = (appliedFilters) => {
    if (isObjEmpty(appliedFilters)) {
      setFilter({ [DATE_FILTER_KEY.CREATED]: null })
      return
    }
    setFilter(appliedFilters)
  }

  const filterHandler = ({ filterKey, filterValue }) => {
    const updatedFilter = { ...filter };
    // If the seller is changed or reset, update the store accordingly.
    if (filterKey === 'seller_id') {
      delete updatedFilter.seller_sales_channel_id
    }
    // If the filter value is empty:
    // - For the "status" filter, reset the filter to include only the "created" key with a default value.
    // - For other filters, remove the specified filter key from the updated filter object.
    if (!filterValue) {
      if (filterKey === 'status') {
        setFilter({created}); // Default the date set to 90 days.
      } else {
        delete updatedFilter[filterKey];
        setFilter(updatedFilter);
      }
      return;
    }
  
    if (filterKey === 'status') {
      if (filter.created) {
        setFilter({ created: filter.created, [filterKey]: filterValue });
      } else {
        setFilter({ [filterKey]: filterValue });
      }
      return;
    }
  
    setFilter({ ...updatedFilter, [filterKey]: filterValue });
  };

  const handleSearch = ({searchVal, selectedColumn = null}) => {
    const searchQuery = searchVal ? {column: selectedColumn, query: searchVal} : null
    setSearchQuery(searchQuery)
  }

  const handlePagination = page => {
    const pagination = {page: page.selected, per_page: page.per_page ? page.per_page : 20 }
    setPagination(pagination)
  }

  const handleCancelReturnOrderPopup = () => {
    setCancelReturnOrderPopupOpen(prev => !prev)
  }

  const handleCancelReturnOrder = () => {
    dispatch(cancelReturnOrder({orderID: returnOrderIdToCancel}))
  }

  const getReturnOrderWithFilter = () => {
    const filterParams = {
      status: filter.status?.value,
      seller_id: filter.seller_id?.value,
      seller_sales_channel_id: filter.seller_sales_channel_id?.value,
      shipping_partner_tag: filter.shipping_partner_tag?.value,
      courier_partner_tag: filter.courier_partner_tag?.value,
      hub_id: filter.hub_id?.value,
      created_at_start: filter.created?.value?.start,
      created_at_end: filter.created?.value?.end
    }
    const searchParams = searchQuery.query ? {[searchQuery.column.id]: searchQuery.query} : {}
    dispatch(getReturnOrders({params: {...filterParams, ...searchParams, ...pagination}}))
  }

  useEffect(() => {
    if (success.cancelReturnOrder) {
      handleCancelReturnOrderPopup()
    }
    if (success.createReverseShipment || success.markOrdersAsReturnedTohHub || success.completeReturnOrderProcessing || success.completeRefundProcess || success.cancelReturnOrder) {
      getReturnOrderWithFilter()
      dispatch(resetSuccess())
      if (isReturnOrderDetailModalOpen) {
        handleReturnOrderDetailModal()
      }
    }
  }, [success])

  useEffect(() => {
    getReturnOrderWithFilter()
  }, [])
  
  useEffect(() => {
    if (returnOrders?.data) {
      const mappedData = returnOrders.data.map((data, index) => {
        return {
          ...data,
          serialNo: ((returnOrders.meta?.current_page - 1) * returnOrders.meta?.per_page) + index + 1,
          orderID: data.id,
          created_order_id: data.order_id,
          awb_number: data.shipment.awb_number,
          shipping_partner_name: data.shipment.shipping_partner_name,
          shipping_partner_tag: data.shipment.shipping_partner_tag,
          courier_partner: data.shipment.courier_partner,
          total_price: data.total,
          approved_by: data.approved_by.name,
          created_by: data.created_by.name,
          qc_processed_by: data.qc_processed_by?.name
        }
      })
      setTableData(mappedData)
    }
  }, [returnOrders])

  const handleShipmentsTracking = ({ awb_number, shipping_partner_name, shipping_partner_tag }) => {
    const searchQuery = STRINGIFY({
      column: null,
      query: awb_number
    })
    const searchFilter = STRINGIFY({
      shipping_partner: { label: shipping_partner_name, value: shipping_partner_tag }
    })
    navigate(`${ROUTES_MAPPING[ROUTES_KEYS.SHIPMENTS_TRACKING]}?q=${searchQuery}&f=${searchFilter}`)
  }
  const datefilterHandler = ({ filterKey, dateRangeObject }) => {
    if (!dateRangeObject) {
      setFilter({...filter, [filterKey]: null})
      return
    }
    setFilter({...filter, [filterKey]: {label: `${dateRangeObject.start} to ${dateRangeObject.end}`, value: dateRangeObject}})
  }

  const currentTabKey = RETURN_ORDER_TABS.ALL.key

  return (
    <div className="return-order">
      <div className="status-filter-container p-1 pb-0 d-flex gap-1">
        <DropdownWithTitle
          value={!filter.status ? {label: 'All', value: ''} : filter.status}
          isClearable={!!filter.status}
          title="Status"
          options={ALL_RETURN_STATUS_FILTER_OPTIONS(true)}
          selectOptionHandler={(value) => filterHandler({ filterKey: 'status', filterValue: value })}
          externalStyles={STATUS_FILTER_DROPDOWN_STYLE}
        />
        <DateFilter
          value={
            filter[DATE_FILTER_KEY.CREATED]?.value
              ? [filter[DATE_FILTER_KEY.CREATED].value.start, filter[DATE_FILTER_KEY.CREATED].value.end]
              : null}
          onChange={(dateRangeObject) => datefilterHandler({ filterKey: DATE_FILTER_KEY.CREATED, dateRangeObject })}
          placeholder="Created Date"
        />
      </div>
      <div className="p-1 d-flex flex-wrap gap-1">
        <CustomFilter
          filterData={getReturnOrderCustomFilters()}
          defaultSelectedOption={filter}
          getAllAppliedFilters={getAllAppliedFilters}
        />
        {(ability.can(abilityMap.hub.view.action, abilityMap.hub.view.resource)) && <DropdownWithTitle
          isAsync
          isClearable={!!filter.hub_id}
          value={!filter.hub_id ? { label: t('All'), value: '' } : filter.hub_id}
          title={t('Hub')}
          loadOptionsHandler={getAllHubListAsyncData}
          selectOptionHandler={(value) => filterHandler({ filterKey: 'hub_id', filterValue: value })}
          externalStyles={{
            control: (baseStyles) => ({
              ...baseStyles,
              minWidth: '120px !important',
              maxWidth:'200px !important',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              textAlign: 'start',
              border: 'none',
              boxShadow: 'none'
            })
          }}
        />}
        {((ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource) && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.seller_id} 
            value={!filter.seller_id ? {label: 'All', value: ''} : filter.seller_id}
            title="Sellers"
            loadOptionsHandler={loadAllSellersOptions} 
            selectOptionHandler={(value) => filterHandler({filterKey: 'seller_id', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
          />
        </div>}
        {ability.can(abilityMap.sales_channel_app.view.action, abilityMap.sales_channel_app.view.resource) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.seller_sales_channel_id} 
            value={!filter.seller_sales_channel_id ? {label: 'All', value: ''} : filter.seller_sales_channel_id}
            title="Store Name"
            loadOptionsHandler={loadSellerSalesChannelOptions}
            additional={{
              seller: filter.seller_id?.value || selfTenantSellerId
            }}
            selectOptionHandler={(value) => filterHandler({filterKey: 'seller_sales_channel_id', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            isDisabled={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed}
            tooltipId={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed && 'seller_sales_channel_id'}
            tooltipText={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && 'Select a seller first'}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>}
        {ability.can(abilityMap.shipping_app.view.action, abilityMap.shipping_app.view.resource) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.courier_partner_tag} 
            value={!filter.courier_partner_tag ? {label: t('All'), value: ''} : filter.courier_partner_tag}
            title={t('Courier Partners')}
            loadOptionsHandler={loadCourierPartnersOptions} 
            selectOptionHandler={(value) => filterHandler({filterKey: 'courier_partner_tag', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>}
      </div>
      <div className='mb-1 mx-1'>
        <TypeOfReturnsInformation returnInfo={COLLAPSED_CONTAINER_LIST.ALL_RETURN_ORDERS} />
      </div>
      <div className="return-order-table px-1">
        <ExpandableCustomTable
          loading={loading.getReturnOrders}
          success={success.getReturnOrders}
          error={error.getReturnOrders}
          isFilterOrSearchApplied = {!!(Object.values(filter).length || Object.values(searchQuery).length  > 1)}
          data={tableData}
          columns={ALL_RETURN_STATUS_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})}
          columnName={ALL_COLUMN_NAME_MAPPING.OMS_ALL_RETURN_ORDER}
          searchcolumnsList={RETURN_ORDER_COLUMNS_LIST}
          search_column_query={searchQuery}
          handleQueryParams={handleSearch}
          handlePagination={handlePagination}
          meta={returnOrders?.meta || {}}
          useReactPaginate={false}
        />
      </div>
      {/* <ReverseShipment isCreateReverseShipmentModalOpen={isCreateReverseShipmentModalOpen} handleCreateReverseShipmentModal={handleCreateReverseShipmentModal}/> */}
      <ReturnOrderDetail isReturnOrderDetailModalOpen={isReturnOrderDetailModalOpen} handleReturnOrderDetailModal={handleReturnOrderDetailModal} handleCreateReverseShipmentModal={handleCreateReverseShipmentModal} handleOrderDetailSidebar={handleOrderDetailSidebar} returnOrderToShowDetail={returnOrderToShowDetail} handleCreateReturnOrderProcessingModal={handleCreateReturnOrderProcessingModal}/>
      <ConfirmationPopup
        isConfirmationPopupOpen={isCancelReturnOrderPopupOpen} 
        handlePopupToggle={handleCancelReturnOrderPopup} 
        isToggle={false}
        title={t('Cancel Return Order?')}
        primaryButtonText={t('Yes, Cancel')}
        primaryButtonHandler={handleCancelReturnOrder}
        secondaryButtonText={t('Take Me Back')}
        secondaryButtonHandler={handleCancelReturnOrderPopup}
        loading={loading.cancelReturnOrder}
        isTranslationRequired={false}
      >
        <div className="d-flex flex-column text-dark-6">
          <div className="paragraph-2">
            <div>You are going to <span className="fw-bolder text-bold">cancel</span> this order. This action cannot be reverted.</div>
          </div>
          <div className="paragraph-3">
            {t('Are you sure you want to continue?')}
          </div>
        </div>
      </ConfirmationPopup>
      <OrderDetailsSideSlider
        sellerId={returnOrderToShowDetail?.created_order_id}
        isModalOpen={isOrderDetailSidebarOpen}
        setIsModalOpen={setOrderDetailSidebarOpen}
        setShipmentSlider={setShipmentSlider}
      />
      <CreateShipmentSideSheet sellerId={omsOrderId} shipmentSlider={shipmentSlider} setShipmentSlider={setShipmentSlider} />
      <CreateReturnOrderProcessing isCreateReturnOrderProcessingModalOpen={isCreateReturnOrderProcessingModalOpen} handleCreateReturnOrderProcessingModal={handleCreateReturnOrderProcessingModal}/>
    </div>
  )
}
export default memo(AllReturnOrders)