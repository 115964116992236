import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';
import { ModalFooterProps } from './modal.constant';

const ModalFooter: FC<ModalFooterProps> = ({
  modalFooterClass,
  secondaryButtonHandler,
  secondaryButtonProps,
  secondaryButtonText,
  primaryButtonHandler,
  primaryButtonProps,
  primaryButtonText,
  loading,
  isTranslationRequired = true
}) => {
  const { t } = useTranslation();

  const hasModalFooterButtons = !!(secondaryButtonText || primaryButtonText);
  if (!hasModalFooterButtons) return null;
  
  return (
    <div
      className={classNames(`d-flex gap-12px px-24px pb-24px pt-16px border-top border-dark-1 ${modalFooterClass}`)}
    >
      {secondaryButtonText && (
        <Button
          className='w-100'
          ofStyle="outlined"
          onClick={secondaryButtonHandler}
          {...secondaryButtonProps}
        >
          {isTranslationRequired ? t(secondaryButtonText) : secondaryButtonText}
        </Button>
      )}
      {primaryButtonText && (
        <Button
          className='w-100'
          onClick={primaryButtonHandler}
          loading={loading}
          {...primaryButtonProps}
        >
          {isTranslationRequired ? t(primaryButtonText) : primaryButtonText}
        </Button>
      )}
    </div>
  );
};

export default ModalFooter; 