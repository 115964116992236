import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import { PRICE_BREAK_DOWN_SECTION, TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { isObjEmpty } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import DetailsCard from '@src/views/returns/components/detailsCard'
import MarkAsRefund from '@src/views/returns/components/mark-as-refund-sidesheet'
import ReturnOrderCancelPopup from '@src/views/returns/components/popup/ReturnOrderCancelPopup'
import { useReturnOrderDetails } from '@src/views/returns/hooks/useReturnOrderDetails'
import { REFUND_METHODS_CONFIG, RETURN_ORDER_TABS } from '@src/views/returns/returns.constants'
import CancelShipmentPopUp from '@src/views/sales/create/CancelShipmentPopUp'
import { PriceBreakDownSection } from '@src/views/sales/pending-actions/pages/ordersForApproval/PriceBreakDownSection'
import { resetSuccess } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { RETURN_ORDER_DETAIL_CARDS } from '../constant'
import { formatPickupDetails, getLocationInventoryStatus, getReturnOrderDetails } from '../utils'
import ReturnItemsDetailTable from './ReturnItemsDetailTable'
import ReturnOrderDetailSummary from './ReturnOrderDetailSummary'
import ButtonSection from './button-section'

const ItemDetail = (props) => {
  const {
    handleReturnOrderDetailModal,
    handleCreateReverseShipmentModal,
    handleOrderDetailSidebar,
    returnOrderToShowDetail,
    handleCreateReturnOrderProcessingModal,
    fetchParentData,
    excludeButtons,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const [searchParams] = useSearchParams()
  const activeReturnOrderPage = searchParams.get('currentTab')
  const [isCancelReturnOrderPopupOpen, setIsCancelReturnOrderPopupOpen] = useState(false)
  // Redux State
  const success = useSelector((state) => state.sales.success)
  const selectedHubConfiguration = useSelector((state) => state.auth.selectedHubConfiguration)
  const returnOrderHubDetail = useSelector((state) => state.returns.returnOrderHubDetail)
  const returnOrderDetail = useSelector((state) => state.returns.returnOrderDetail)
  const reverseShipmentDetail = useSelector((state) => state.returns.reverseShipmentDetail)
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const userHubs = useSelector((state) => state.auth.userHubs) || []

  // Custom hook for return order details logic
  const {
    cancelShipmentModal,
    setCancelShipmentModal,
    isMarkAsRefundOpen,
    setIsMarkAsRefundOpen,
    handleMarkOrderArrivedAtHub,
    openCancelShipmentModal,
    openMarksAsRefundSideSheet,
    openCreateReverseShipmentModal,
  } = useReturnOrderDetails({
    returnOrderDetail,
    handleReturnOrderDetailModal,
    handleCreateReverseShipmentModal,
    fetchParentData,
  })

  const isExternalHub = userHubs.find((hub) => hub.id === returnOrderDetail?.hub_id)?.is_external_hub
  const adjustmentFees = (returnOrderDetail?.invoice.adjustment_fees && returnOrderDetail.invoice.adjustment_fees[0]) || {}

  const isLocationInventoryEnabled = getLocationInventoryStatus(activeReturnOrderPage, returnOrderHubDetail, selectedHubConfiguration)

  const isViewOrderAllowed = ability.can(abilityMap.order.view.action, abilityMap.order.view.resource)

  const { pickup_name, pickup_address } = formatPickupDetails(returnOrderDetail?.pickup_address)

  const returnsOrderDetails = getReturnOrderDetails(returnOrderDetail)

  const getReturnsFilteredOrderDetails = (card) => returnsOrderDetails.filter((detail) => detail.card === card)

  const handleStartProcessing = () => {
    handleCreateReturnOrderProcessingModal(returnOrderToShowDetail)
  }
  const handleCancelReturnOrderSuccess = () => {
    fetchParentData()
    setIsCancelReturnOrderPopupOpen(false)
    handleReturnOrderDetailModal()
  }

  useEffect(() => {
    if (reverseShipmentDetail) {
      handleReturnOrderDetailModal()
      handleCreateReverseShipmentModal({ order: returnOrderDetail })
    }
  }, [reverseShipmentDetail])

  useEffect(() => {
    if (success.cancelShipment) {
      setCancelShipmentModal(false)
      handleReturnOrderDetailModal()
      fetchParentData()
      dispatch(resetSuccess())
    }
  }, [success.cancelShipment])

  return (
    <>
      <div className='flex-center-between gap-16px mb-16px'>
        <div className='border bg-white rounded-8px d-flex align-items-center gap-12px flex-grow-1'>
          <div className='bg-primary-lighter-global rounded-start text-secondary txt-h3-md py-8px px-16px'>{t('Status')}</div>
          <div className='d-flex gap-8px pe-12px'>
            {returnOrderDetail?.status && <CustomLabel title={returnOrderDetail.status} className='text-primary-global bg-primary-light-global text-uppercase' />}
          </div>
        </div>

        <ButtonSection
          returnOrderDetail={returnOrderDetail}
          openCancelShipmentModal={openCancelShipmentModal}
          openCreateReverseShipmentModal={openCreateReverseShipmentModal}
          handleStartProcessing={handleStartProcessing}
          handleMarkOrderArrivedAtHub={handleMarkOrderArrivedAtHub}
          isLocationInventoryEnabled={isLocationInventoryEnabled}
          isExternalHub={isExternalHub}
          openMarksAsRefundSideSheet={openMarksAsRefundSideSheet}
          excludeButtons={excludeButtons}
          setIsCancelReturnOrderPopupOpen={setIsCancelReturnOrderPopupOpen}
        />
      </div>
      <div className='mb-16px'>
        <ReturnOrderDetailSummary
          details={[
            returnOrderDetail?.type !== RETURN_ORDER_TABS.OFF_SYSTEM_RETURN.name && {
              key: t('Order ID'),
              value: (
                <div
                  onClick={isViewOrderAllowed ? () => handleOrderDetailSidebar(returnOrderDetail) : undefined}
                  className={classNames({ 'text-primary cursor-pointer': isViewOrderAllowed })}
                >
                  {returnOrderDetail?.seller_sales_channel_order_id}
                </div>
              ),
            },
            { key: t('Total Return Qty'), value: returnOrderDetail?.total_return_quantity },
            { key: t('Return Order Type'), value: returnOrderDetail?.type },
            { key: t('Hub'), value: returnOrderDetail?.hub?.name },
            tenantType !== TENANT_TYPE.SELF_USAGE && {
              key: t('Seller Name'),
              value: returnOrderDetail?.seller_name,
            },
            returnOrderDetail?.refund_details?.payment_reference_number && {
              key: t('Payment Reference No.'),
              value: returnOrderDetail?.refund_details?.payment_reference_number,
            },
            returnOrderDetail?.refund_details?.refunded_at && {
              key: t('Refunded At'),
              value: returnOrderDetail?.refund_details?.refunded_at,
            },
            !isObjEmpty(adjustmentFees) &&
              adjustmentFees.reason && {
              key: t('Refund Adjustment Reason'),
              value: adjustmentFees.reason,
            },
            returnOrderDetail?.refund_details?.refund_method && {
              key: t('Refund Method'),
              value: REFUND_METHODS_CONFIG[returnOrderDetail?.refund_details?.refund_method.toUpperCase()]?.label,
            },
          ].filter(Boolean)}
        />
      </div>
      <div className='return-customer-delivery-card-container'>
        <DetailsCard
          key={'Customer Details'}
          cardTitle={t('Customer Details')}
          details={[
            { key: t('Customer Name'), value: returnOrderDetail?.customer?.customer_name },
            { key: t('Mobile Number'), value: returnOrderDetail?.customer?.mobile },
            { key: t('Email ID'), value: returnOrderDetail?.customer?.email },
          ]}
        />
        <DetailsCard
          key={RETURN_ORDER_DETAIL_CARDS.RETURN_DETAILS}
          cardTitle={t(RETURN_ORDER_DETAIL_CARDS.RETURN_DETAILS)}
          details={getReturnsFilteredOrderDetails(RETURN_ORDER_DETAIL_CARDS.RETURN_DETAILS)}
        />
        <DetailsCard
          key={'Pickup Details'}
          cardTitle={t('Pickup Details')}
          details={[
            { key: t('Picker Name'), value: pickup_name },
            { key: t('Mobile Number'), value: returnOrderDetail?.pickup_address?.phone },
            { key: t('Email ID'), value: returnOrderDetail?.pickup_address?.email },
            { key: t('Address'), value: pickup_address },
          ]}
        />
        <DetailsCard
          key={RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS}
          cardTitle={t(RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS)}
          details={getReturnsFilteredOrderDetails(RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS)}
        />
      </div>
      <div className='return-order-item-details-table-container'>
        <ReturnItemsDetailTable order_items={returnOrderDetail?.order_items} returnOrderDetail={returnOrderDetail} />
      </div>

      {returnOrderDetail?.invoice && !isObjEmpty(returnOrderDetail.invoice) && (
        <div className='mt-24px'>
          <PriceBreakDownSection
            orderDetails={returnOrderDetail}
            orderType={PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value}
            show_price_section_keys={{
              show_additional_charges_section: true,
              show_grand_total: false,
              show_total_refund: false,
              show_total_refund_section: true,
              show_items_total: true,
              show_sub_total: false,
              show_total_discount: false,
              show_total_tax: false,
            }}
          />
        </div>
      )}

      <CancelShipmentPopUp
        shipmentOrderID={returnOrderDetail?.shipment.shipment_order_id}
        deleteModalOpen={cancelShipmentModal}
        setDeleteModalOpen={setCancelShipmentModal}
        shipping_partner_tag={returnOrderDetail?.shipment.shipping_partner_tag}
        awb_number={returnOrderDetail?.shipment.awb_number}
      />

      <ReturnOrderCancelPopup
        isReturnOrderCancelPopupOpen={isCancelReturnOrderPopupOpen}
        setIsReturnOrderCancelPopupOpen={setIsCancelReturnOrderPopupOpen}
        orderDetail={returnOrderToShowDetail}
        handleCancelOrderSuccess={handleCancelReturnOrderSuccess}
      />

      {returnOrderDetail && <MarkAsRefund isOpen={isMarkAsRefundOpen} setIsOpen={setIsMarkAsRefundOpen} preloadData={returnOrderDetail} />}
    </>
  )
}

export default ItemDetail
