import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import { PACKAGE_SKU_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import CategoryListViewModal from '@src/components/category-view-modal'
import { AbilityContext } from '@src/utility/context/Can'
import { useContext, useState } from 'react'
import { Plus } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import WeightedAverageCostModal from './WeightedAverageCostModal'

const MINIMUM_VISIBLE_BARCODE = 2
const MINIMUM_VISIBLE_LABEL = 3
const MINIMUM_VISIBLE_CATEGORY = 2

const SimpleSkuDetail = ({ t, sku, barcodeModalOpen, setBarcodeList, setBarcodeModalOpen, isWeightedAvgCost }) => {
  const ability = useContext(AbilityContext)
  const hasWeightedAverageCostViewPermission = ability.can(abilityMap.weighted_average_cost.view.action, abilityMap.weighted_average_cost.view.resource)
  const [isWeightedAverageCostModalOpen, setWeightedAverageCostModalOpen] = useState(false)
  const [categoryModalOpen, setCategoryModalOpen] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  
  const toggleBarcodeModal = (barcodes) => {
    if (!barcodeModalOpen) setBarcodeList(barcodes ? barcodes?.map(barcode => ({ barcodeValue: barcode })) : [])
    setBarcodeModalOpen(p => !p)
  }

  const toggleCategoryModal = (categories) => {
    if (!categoryModalOpen) setCategoryList(categories ? categories?.map(category => ({ categoryName: category })) : [])
    setCategoryModalOpen(p => !p)
  }

  const handleToggleWeightedAverageCostModal = () => {
    setWeightedAverageCostModalOpen(prev => !prev)
  }

  return (
    <div className='w-100 d-flex flex-column border border-dark-1 rounded-12px' id='simple-sku-details'>
      <div className='bg-primary-lighter-global border-primary-lighter-global simple-sku-desc-content txt-h3-md text-dark w-100 border-radius-tl-tr-10 mb-0'>
        {t('Basic Details')} 
      </div>
      <div className='simple-sku-details p-16px gap-16px'>
        <p className='txt-body-md text-dark-5'>{t('SKU Name')}</p>
        <p className='text-dark txt-body-md  text-truncate' title={sku.name}>
          {sku.name || '-'}
        </p>
        <p className='txt-body-md text-dark-5'>{t('SKU Code')}</p>
        <p className='text-dark txt-body-md  text-truncate'>
          {sku.seller_sku_code || '-'}
        </p>
        <p className='txt-body-md text-dark-5'>{t('Category(s)')}</p>
        <p className='text-dark txt-body-md  text-truncate'>
          {sku.categories ? <div className='d-flex gap-8px align-items-center'>
            {sku.categories.length <= MINIMUM_VISIBLE_CATEGORY ? <div className='d-flex gap-8px align-items-center'>
              {sku.categories.map((ele, index) => {
                return <CustomLabel key={index} title={
                  <div className='tw-truncate tw-max-w-[200px]' title={ele}>{ele}</div>
                } className='text-dark bg-dark-1' />
              })}
            </div> :
              <div className='d-flex gap-8px align-items-center sku-categories'>
                {sku.categories.map((ele, index) => {
                  if (index < MINIMUM_VISIBLE_CATEGORY) {
                    return <CustomLabel key={index} title={
                      <div className='tw-truncate tw-max-w-[100px]' title={ele}>{ele}</div>
                    } className='text-dark bg-dark-1 tw-max-w-[100px]' />
                  }
                })}
                <div className='tw-flex tw-items-center tw-gap-1 tw-cursor-pointer tw-rounded-lg tw-px-[6px] border border-primary text-primary' onClick={() => toggleCategoryModal(sku.categories)}>
                  <Plus size={16} color='var(--bs-primary)' />
                  {sku.categories.length - MINIMUM_VISIBLE_CATEGORY}
                </div>
              </div>}
          </div> : '-'}
        </p>
        <p className='txt-body-md text-dark-5'>{t('Unit of Measurement')}</p>
        <p className='text-dark txt-body-md  text-truncate'>
          {sku.uom || '-'}
        </p>
        {sku.package_type === PACKAGE_SKU_TYPE.UNIT.value ? <>
          <p className='txt-body-md text-dark-5'>
            {t('Is GTIN Enabled')}
          </p>
          <p className='text-dark txt-body-md'>
            {sku.is_gtin_picking_enabled ? 'Yes' :  'No'}
          </p>
          {sku.is_gtin_picking_enabled ? <> <p className='txt-body-md text-dark-5'>
            {t('GTIN')}
          </p>
          <p className='text-dark txt-body-md'>
            {sku.gtin || '-'}
          </p>
          </> : null}
        </> : null}
        <p className='txt-body-md text-dark-5'>{t('Is it temp. controlled')}</p>
        <p className='text-dark txt-body-md  text-truncate'>
          {sku.temp_controlled || t('No')}
        </p>
        <p className='txt-body-md text-dark-5'>{t('Is it perishable?')}</p>
        <p className='text-dark txt-body-md  text-truncate'>
          {sku.is_perishable ? t('Yes') : t('No')}
        </p>
        <p className='txt-body-md text-dark-5'>{t('Barcode(s)')}</p>
        <p className='text-truncate'>
          {sku.barcodes ? <div className='d-flex gap-8px align-items-center'>
            {sku.barcodes.length <= MINIMUM_VISIBLE_BARCODE ? <div className='d-flex gap-8px align-items-center'>
              {sku.barcodes.map((ele, index) => {
                return ele ? <CustomLabel key={index} title={
                  <div className='tw-truncate tw-max-w-[200px]' title={ele}>{ele}</div>
                } className='text-dark bg-dark-1' /> : '-'
              })}
            </div> :
              <div className='d-flex gap-8px align-items-center sku-barcodes'>
                {sku.barcodes.map((ele, index) => {
                  if (index < MINIMUM_VISIBLE_BARCODE) {
                    return ele ? <CustomLabel key={index} title={
                      <div className='tw-truncate tw-max-w-[100px]' title={ele}>{ele}</div>
                    } className='text-dark bg-dark-1 tw-max-w-[100px]' /> : '-'
                  }
                })}
                <div className='d-flex align-items-center gap-4px remaining-barcodes cursor-pointer' onClick={() => toggleBarcodeModal(sku.barcodes)}>
                  <Plus size={16} color='var(--bs-primary)' />
                  {sku.barcodes.length - MINIMUM_VISIBLE_BARCODE}
                </div>
              </div>}
          </div> : <span className='text-dark txt-body-md'>{'-'}</span>}
        </p>
        <p className='txt-body-md text-dark-5'>{t('SKU Tags')}</p>
        <p className='text-truncate labels'>
          {sku.labels ? <div className='d-flex gap-8px align-items-center'>
            {sku.labels.length <= MINIMUM_VISIBLE_LABEL ? <div className='d-flex gap-8px align-items-center'>
              {sku.labels.map((ele, index) => {
                return ele ? <CustomLabel key={index} title={ele.name} className='text-dark bg-dark-1' /> : '-'
              })}
            </div> :
              <div className='d-flex gap-8px align-items-center sku-barcodes'>
                {sku.labels?.map((ele, index) => {
                  if (index < MINIMUM_VISIBLE_LABEL) {
                    return ele ? <CustomLabel key={index} title={ele.name} className='text-dark bg-dark-1' /> : '-'
                  }
                })}
                {sku.labels?.length > MINIMUM_VISIBLE_LABEL && (
                  <UncontrolledDropdown direction="bottom-right">
                    <DropdownToggle className="cursor-pointer p-0 border-0">
                      <div className="d-flex w-fit justify-end align-items-center gap-4px border border-primary rounded-12px ps-6px pe-8px py-2px cursor-pointer height-18px">
                        <Plus size={12} color="var(--bs-primary)" />
                        <span className="text-primary txt-asst-rg">{sku.labels.length - MINIMUM_VISIBLE_LABEL}</span>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu container="simple-sku-details">
                      <PerfectScrollbar className="max-height-200">
                        {sku.labels.slice(MINIMUM_VISIBLE_LABEL)?.map((item, index) => (
                          <DropdownItem key={index} text className="d-flex justify-content-left px-8px py-4px w-100">
                            <div className="txt-sub-rg text-dark d-inline-block text-truncate width-100">{item.name}</div>
                          </DropdownItem>)
                        )}
                      </PerfectScrollbar>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </div>}
          </div> : <span className='text-dark txt-body-md'>{'-'}</span>}
        </p>
        {isWeightedAvgCost && hasWeightedAverageCostViewPermission && <>
          <p className='txt-body-md text-dark-5'>{t('Weighted Average Cost')}</p>
          <p className='text-dark txt-body-md  text-truncate'>
            <span className='avg-cost-check-details' onClick={handleToggleWeightedAverageCostModal}>{t('Check Details')}</span>
          </p>
        </>}
        <p className='txt-body-md text-dark-5'>
          {t('SKU Weight')}
        </p>
        <p className='text-dark txt-body-md'>
          {sku.weight?.value ? sku.weight.display_weight : '-'}
        </p>
      </div>

      <WeightedAverageCostModal
        isOpen={isWeightedAverageCostModalOpen}
        toggle={handleToggleWeightedAverageCostModal}
        sku={sku}
      />

      <CategoryListViewModal
        modalOpen={categoryModalOpen}
        toggleSidebar={() => setCategoryModalOpen(false)}
        data={categoryList}
      />
    </div>
  )
}

export default SimpleSkuDetail

