import { Error, Info, Success, Warning } from '@src/assets/images/icons/Modal/Illustration';
import { FC, ReactNode } from 'react';
import { ModalProps as ReactstrapModalProps } from 'reactstrap';

// Types
export type ModalSize = 'sm' | 'md' | 'lg'
export type ModalType = 'illustration' | 'general'

export interface ButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface BaseModalProps {
  isTranslationRequired?: boolean
}

export interface ModalHeaderProps extends BaseModalProps {
  type: ModalType
  icon: FC<{ size?: string; color?: string }> | string; 
  customIcon?: ReactNode
  title: string
  subTitle?: string
  closeButtonHidden?: boolean
  closeModalHandler: () => void,
  iconSize?: string
}

export interface ModalContentProps extends BaseModalProps {
  content?: string | ReactNode
  children?: ReactNode,
  type?: ModalType,
  isModalScrollable?: boolean,
  hasModalFooterButtons?: boolean
}

export interface ModalFooterProps extends BaseModalProps {
  loading?: boolean
  modalFooterClass?: string
  secondaryButtonHandler?: () => void
  secondaryButtonProps?: ButtonProps
  secondaryButtonText?: string
  primaryButtonHandler?: () => void
  primaryButtonProps?: ButtonProps
  primaryButtonText?: string
  icon?: string
  iconSize?: number
  alignIcon?: 'left' | 'right'
}

export interface ModalProps extends ReactstrapModalProps, BaseModalProps {
  // Header props
  title: string
  subTitle?: string
  icon?: string
  customIcon?: ReactNode
  closeButtonHidden?: boolean
  closeModalHandler: () => void
  
  // Content props
  children?: ReactNode
  bodyContent?: string | ReactNode
  
  // Footer props
  loading?: boolean
  primaryButtonText?: string
  primaryButtonHandler?: () => void
  primaryButtonProps?: ButtonProps
  secondaryButtonText?: string
  secondaryButtonHandler?: () => void
  secondaryButtonProps?: ButtonProps
  modalFooterClass?: string
  
  // Modal props
  type?: ModalType
  size?: ModalSize
  modalClassNames?: string
  modalLoadingComponent?: ReactNode
}

interface IconProps {
  size?: string
  color?: string
}

interface ModalTypeIcon {
  key: string
  icon: FC<IconProps>
}

interface GetActionIconProps {
  icon?: string
  size?: string
  color?: string
}

export const MODAL_SIZES: Record<string, ModalSize> = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg'
};

export const MODAL_TYPES: Record<string, ModalType> = {
  ILLUSTRATION: 'illustration',
  GENERAL: 'general',
};

export const MODAL_TYPES_ILLUSTRATION: ModalTypeIcon[] = [
  { key: 'warning', icon: Warning },
  { key: 'success', icon: Success },
  { key: 'error', icon: Error },
  { key: 'info', icon: Info }
];

export const getActionIcon = ({ icon = 'warning', size = '90' }: GetActionIconProps) => {
  const actionIcon = MODAL_TYPES_ILLUSTRATION.find((item) => item.key === icon);
  return actionIcon ? <actionIcon.icon size={size}/> : null;
}; 