import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { API_ENDPOINTS } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { ATTACHMENTS_SIDEBAR_MODE, EDIT_ORDER_ENTITY_TYPES, ORDER_OPERATIONAL_BUTTONS } from '@src/views/sales/constant/orders.constants'
import { ORDER_RECREATION_TYPE } from '@src/views/sales/create-order/create-order.constant'
import useOrderRecreation from '@src/views/sales/pending-actions/pages/orderDetails/components/useOrderRecreation'
import { DEFAULT_TRANSACTION_SIDE_SHEET, EDIT_MODAL_TYPE, FINAL_ORDER_TOTAL_LABELS, REASONS_FOR_ON_HOLD } from '@src/views/sales/sales.constant'
import { categorizeOrderDetailsButtons, checkUneditedSalesChannelOrder, getRefundAbleAmount } from '@src/views/sales/sales.utils'
import { editOrder, resetSuccess, setSuccess, syncOrderPaymentStatus } from '@src/views/sales/store/store'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useOnHoldItemDetail = (props) => {
  const { 
    orderId, 
    onHoldOrderDetails, 
    getSingleOnHoldOrderDetails, 
    handleGetParentData, 
    handleSideSheetClose 
  } = props;

  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const queryClient = useQueryClient()
  const { handleOrderRecreation } = useOrderRecreation();

  const [isForcefulHubRoutePopupOpen, setForcefulHubRoutePopupOpen] = useState(false)
  const [isStoDetailsOpen, setIsStoDetailsOpen] = useState(false)
  const [isReassignHubModalOpen, setReassignHubModalOpen] = useState(false)
  
  const handleToggleForcefulHubRoutePopup = () => {
    setForcefulHubRoutePopupOpen(p => !p)
  } 

  const { mutate:getDataOnChangeOfSelectedHub, isPending: tableDataPending, isError: tableDataError, isSuccess: tableDataSuccess } = useMutation({
    mutationKey: ['get-data-on-change-of-selected-hub', orderId],
    mutationFn: ({selectedHubId}) => {
      const url = getApiUrl(`${API_ENDPOINTS.ORDERS.GET_SINGLE_ON_HOLD_ORDER}`, { orderId: orderId })
      const params = { hub_selected: selectedHubId}
      return axiosInstance.get(url, { params })
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['single-hold-order-details', orderId], {data:data} )
    },
  })

  const { mutate: handleForcefulHubRouting, isPending: isForcefulHubRoutingPending } = useMutation({
    mutationKey: ['put-forceful-hub-routing'],
    mutationFn: () => {
      const url = getApiUrl(`${API_ENDPOINTS.ORDERS.FORCEFUL_HUB_ROUTE}`, { orderId: orderId, hubId: onHoldOrderDetails.selected_hub.id })
      return axiosInstance.put(url)
    },
    onSuccess: () => {
      CustomToast('Order has been successfully assigned to the hub.', { my_type: 'success' })
      handleToggleForcefulHubRoutePopup()
      handleSideSheetClose()
      dispatch(setSuccess({
        forcefulHubRouting: true
      }))
    }
  })

  // store
  const loading = useSelector(store => store.sales.loading)
  const success = useSelector(store => store.sales.success)
  const selectedGlobalHubId = useSelector((store) => store.auth.selectedGlobalHubId);

  // states
  const [isSplittedOrderDetailsSideSheetOpen, setIsSplittedOrderDetailsSideSheetOpen] = useState(false)
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState({ open: false, key: EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key })
  const [isApproveOrderPopUpOpen, setIsApproveOrderPopUpOpen] = useState(false)
  const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false)
  const [attachmentSidebar, setAttachmentSidebar] = useState({
    isOpen: false,
    mode: ATTACHMENTS_SIDEBAR_MODE.ADD.id
  })
  const [addedAttachments, setAddedAttachments] = useState([])
  const [isOpenAddLocationAttributesModal, setIsOpenAddLocationAttributesModal] = useState(false)
  const [reorderModal, setReorderModal] = useState({ isOpen: false, recreationType: '' })

  // variables
  const hasEditAttachmentButton = useMemo(() => { 
    return onHoldOrderDetails?.buttons?.find(btn => btn.key === ORDER_OPERATIONAL_BUTTONS.EDIT_ATTACHMENTS.id) 
  }, [onHoldOrderDetails])
  const hasForcefulRoutePermission = ability.can(abilityMap.order.forceful_route.action, abilityMap.order.forceful_route.resource)
  const hasEditOrderDetailsPermission = ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource)  
  const isUneditedSalesChannelOrder = checkUneditedSalesChannelOrder(onHoldOrderDetails)

  // functions
  const toggleAddLocationAttributesModal = () => {
    setIsOpenAddLocationAttributesModal(p => !p)
  }

  const handleToggleAttachmentSidebar = () => {
    const mode = hasEditOrderDetailsPermission && hasEditAttachmentButton
      ? onHoldOrderDetails.attachments?.length ? ATTACHMENTS_SIDEBAR_MODE.EDIT.id : ATTACHMENTS_SIDEBAR_MODE.ADD.id
      : ATTACHMENTS_SIDEBAR_MODE.VIEW.id
    setAttachmentSidebar(prev => ({ ...prev, isOpen: !prev.isOpen, mode }))
  }

  const handleWhenAttachmentEdited = (req) => {
    const message = req.toastMessage || 'Files saved successfully';
    CustomToast(message, {my_type: 'success'} )
    handleToggleAttachmentSidebar()
    getSingleOnHoldOrderDetails()
    handleGetParentData()
  }

  const { mutate: editAttachment, isPending: isEditAttachmentPending} = editOrder({
    onSuccess: handleWhenAttachmentEdited
  })

  const handleSyncPaymentStatus = () => {
    dispatch(syncOrderPaymentStatus({ seller_sales_channel_order_id: onHoldOrderDetails.seller_sales_channel_order_id, seller_id: onHoldOrderDetails.seller_id, seller_sales_channel_id: onHoldOrderDetails.seller_sales_channel_id }))
  }

  const handleOpenSplittedOrderDetailSideSheet = () => {
    setIsSplittedOrderDetailsSideSheetOpen(true)
  }

  const handleCloseSplittedOrderDetailSideSheet = () => {
    setIsSplittedOrderDetailsSideSheetOpen(false)
  }

  const handleEditDetailsModal = (key) => {
    setIsEditDetailsModalOpen({ open: true, key })
  }

  const handleHubChange = (newHub) => {
    getDataOnChangeOfSelectedHub({ selectedHubId: newHub.value })
  }

  const handleApproval = () => {
    setIsApproveOrderPopUpOpen(true)
  }

  const toggleApprovalPopup = () => {
    setIsApproveOrderPopUpOpen(!isApproveOrderPopUpOpen)
  }

  const handleCancelOrderSuccess = () => {
    handleGetParentData()
    setIsCancelOrderPopupOpen(false)
    handleSideSheetClose(false)
  }

  const handleReassignHubSuccess = () => {
    handleGetParentData()
    setReassignHubModalOpen(false)
    handleSideSheetClose(false)
  }

  const [transactionSideSheet, setTransactionSideSheet] = useState(DEFAULT_TRANSACTION_SIDE_SHEET);

  const onSaveAttachments = (files, toastMessage) => {
    const attachments = files.map((fileObj) => {
      return {
        file_url: fileObj.file_url,
        name: fileObj.file.name.split('.')[0],
        description: fileObj.description,
        mime_type: fileObj.file.type,
        should_upload: Boolean(fileObj.should_upload),
        attachment_entity: fileObj.attachment_entity,
        is_external_url: fileObj.is_external_url
      }
    })
    const body = {
      attachments,
      edit_entity_type: EDIT_ORDER_ENTITY_TYPES.ORDER_ATTACHMENTS
    }
    editAttachment({body, order_id: onHoldOrderDetails.id, toastMessage})
  }


  const handleShowSTODetail = () => {
    setIsStoDetailsOpen(p=>!p)
  }

  const handleReorder = ({ recreationType, hubId }) => {
    const hasHubMismatch = onHoldOrderDetails.assigned_hub.id !== selectedGlobalHubId
  
    if (hasHubMismatch && !reorderModal.isOpen) {
      setReorderModal({ isOpen: true, recreationType });
      return;
    }
  
    handleOrderRecreation({
      recreationType,
      hubId: hubId || onHoldOrderDetails.assigned_hub.id,
      orderId: onHoldOrderDetails.id,
      previousOrderDisplayId: onHoldOrderDetails.seller_sales_channel_order_id
    });
  };

  const hubOptions = useMemo(() => {
    return onHoldOrderDetails?.hubs.map(hub => ({
      label: hub.name,
      value: hub.id
    })) || []
  }, [onHoldOrderDetails])
  
  const show_inventory = useMemo(() => {
    const { reason } = onHoldOrderDetails || {}
    return hubOptions?.length > 0 && (
      reason === REASONS_FOR_ON_HOLD.INVENTORY_NOT_PRESENT.label || 
      reason === REASONS_FOR_ON_HOLD.SKU_NOT_VALID.label || 
      reason === REASONS_FOR_ON_HOLD.REQUIRE_MANUAL_APPROVAL.label ||
      reason === REASONS_FOR_ON_HOLD.NOT_ABLE_TO_ROUTE.label
    )
  }, [onHoldOrderDetails, hubOptions])

  const closeTransactionSheet = () => {
    setTransactionSideSheet(DEFAULT_TRANSACTION_SIDE_SHEET);
  };

  const openTransactionSheet = (data) => {
    setTransactionSideSheet({
      isOpen: true,
      orderTotalType: data.orderTotalType,
      finalOrderTotal: data.finalOrderTotal,
    });
  };

  // The order of buttons in the array is important; we arrange them in order of priority
  const buttonArray = [
    {
      id: 'approve',
      name: 'APPROVE_ORDER',
      onClick: handleApproval,
      hasPermission: ability.can(abilityMap.order.approve.action, abilityMap.order.approve.resource)
    },
    {
      id: 'cancel_order',
      name: 'CANCEL_ORDER',
      onClick: () => setIsCancelOrderPopupOpen(true),
      hasPermission: ability.can(abilityMap.order.cancel.action, abilityMap.order.cancel.resource)
    },
    {
      id: 'split_order',
      name: 'SPLIT_ORDER',
      onClick: handleOpenSplittedOrderDetailSideSheet,
      hasPermission: ability.can(abilityMap.split_order.create.action, abilityMap.split_order.create.resource)
    },
    {
      id: 'reassign_hub',
      name: 'REASSIGN_HUB',
      onClick: ()=>setReassignHubModalOpen(true),
      hasPermission: ability.can(abilityMap.order.reassign_hub.action, abilityMap.order.reassign_hub.resource)
    },
    {
      id: 'duplicate_order',
      name: 'DUPLICATE_ORDER',
      onClick: ()=>handleOrderRecreation({ recreationType: ORDER_RECREATION_TYPE.DUPLICATE, hubId: onHoldOrderDetails?.assigned_hub.id || selectedGlobalHubId, orderId: onHoldOrderDetails?.id, previousOrderDisplayId: onHoldOrderDetails.seller_sales_channel_order_id  }),
      hasPermission: ability.can(abilityMap.order.create.action, abilityMap.order.create.resource)
    },
    {
      id: 'reorder',
      name: 'REORDER',
      onClick: () => handleReorder({recreationType: ORDER_RECREATION_TYPE.RE_ORDER}),
      hasPermission: ability.can(abilityMap.order.create.action, abilityMap.order.create.resource)
    },
    {
      id: 'mark_as_paid',
      name: 'MARK_AS_PAID',
      onClick: () => openTransactionSheet({ orderTotalType: FINAL_ORDER_TOTAL_LABELS.DUE_AMOUNT.value, finalOrderTotal: onHoldOrderDetails.invoice.total_due }),
      hasPermission: hasEditOrderDetailsPermission
    },
    {
      id: 'mark_as_refunded',
      name: 'MARK_AS_REFUNDED',
      onClick: () => openTransactionSheet({ orderTotalType: FINAL_ORDER_TOTAL_LABELS.REFUND_DUE.value, finalOrderTotal: getRefundAbleAmount(onHoldOrderDetails) }),
      hasPermission: hasEditOrderDetailsPermission
    }
  ]

  // Filter action buttons
  const { actionButtons, syncButtons, editButtons } = useMemo(() => categorizeOrderDetailsButtons(onHoldOrderDetails, buttonArray), [onHoldOrderDetails, buttonArray])

  const primaryButtonLoading = {
    APPROVE_ORDER: loading.updateOrderStatus,
    CANCEL_ORDER: loading.cancelOrder,
    SPLIT_ORDER: false
  }
  const primaryActionButton = actionButtons.shift()

  useEffect(() => {
    if (success.editCustomerAndShippingDetails) {
      setIsEditDetailsModalOpen(prev => ({ ...prev, open: false }))
      dispatch(resetSuccess())
      if(transactionSideSheet.isOpen) closeTransactionSheet()
      if (onHoldOrderDetails) {
        getSingleOnHoldOrderDetails()
      }
      handleGetParentData()
    }
    
    if (success.syncOrderPaymentStatus) {
      getSingleOnHoldOrderDetails()
      dispatch(resetSuccess())
    }
  }, [success.editCustomerAndShippingDetails, success.syncOrderPaymentStatus])
  
  useEffect(() => {
    if (onHoldOrderDetails?.attachments?.length) {
      const attachments = onHoldOrderDetails.attachments.map(attachment => (
        {
          file: {
            name: attachment.name,
            type: attachment.mime_type
          },
          description: attachment.description,
          file_url: attachment.file_url,
          attachment_entity: attachment.attachment_entity,
          is_external_url: attachment.is_external_url
        }
      ))
      setAddedAttachments(attachments)
    } else {
      setAddedAttachments([])
    }
  }, [onHoldOrderDetails])


  const uploadDetails = {
    service: 'oms',
    usecase: 'order-attachments'
  }

  return {
    isForcefulHubRoutePopupOpen,
    isStoDetailsOpen,
    isReassignHubModalOpen,
    setReassignHubModalOpen,
    handleToggleForcefulHubRoutePopup,
    tableDataPending,
    tableDataError,
    tableDataSuccess,
    handleForcefulHubRouting,
    isForcefulHubRoutingPending,
    loading,
    isSplittedOrderDetailsSideSheetOpen,
    isEditDetailsModalOpen,
    setIsEditDetailsModalOpen,
    isApproveOrderPopUpOpen,
    isCancelOrderPopupOpen,
    setIsCancelOrderPopupOpen,
    attachmentSidebar,
    addedAttachments,
    setAddedAttachments,
    isOpenAddLocationAttributesModal,
    reorderModal,
    setReorderModal,
    hasEditAttachmentButton,
    hasForcefulRoutePermission,
    hasEditOrderDetailsPermission,
    isUneditedSalesChannelOrder,
    toggleAddLocationAttributesModal,
    handleToggleAttachmentSidebar,
    handleSyncPaymentStatus,
    handleOpenSplittedOrderDetailSideSheet,
    handleCloseSplittedOrderDetailSideSheet,
    handleEditDetailsModal,
    handleHubChange,
    toggleApprovalPopup,
    handleCancelOrderSuccess,
    handleReassignHubSuccess,
    transactionSideSheet,
    onSaveAttachments,
    handleShowSTODetail,
    handleReorder,
    hubOptions,
    show_inventory,
    closeTransactionSheet,
    isEditAttachmentPending,
    primaryActionButton,
    actionButtons,
    syncButtons,
    editButtons,
    primaryButtonLoading,
    uploadDetails
  }
}
