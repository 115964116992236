import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SortIcon from '/src/assets/images/svg/sortNew.svg'
import SortAscIcon from '/src/assets/images/svg/sortingGrey.svg'
import { UncontrolledTooltip } from 'reactstrap'
import { useRef } from 'react'
import { useSorting } from '@src/utility/hooks/useFilters'
import { SORTING_ORDER } from '@src/App.constants'

const SortHeaderColumn = (props) => {
  const { t } = useTranslation()
  const { title, className, icon, type, id } = props
  const { sort } = useSorting({})

  const ref = useRef(null)

  const getSortTooltip = () => {
    if (!sort || sort.field !== id) {
      if (type === 'time') {
        return 'Sort by oldest to newest'
      } else if (type === 'number') {
        return 'Sort by highest to lowest'
      } else if (type === 'string') {
        return 'Sort by Z-A'
      }
      return 'Sort'
    }

    if (type === 'time') {
      return sort.order === SORTING_ORDER.ASCENDING ? 'Sorted by oldest to newest' : 'Sorted by newest to oldest'
    } else if (type === 'number') {
      return sort.order === SORTING_ORDER.ASCENDING ? 'Sorted by lowest to highest' : 'Sorted by highest to lowest'
    } else if (type === 'string') {
      return sort.order === SORTING_ORDER.ASCENDING ? 'Sorted by A-Z' : 'Sorted by Z-A'
    }
    return 'Sort'
  }

  return (
    <>
      <div title={t(title)} className={classNames(`${className}`, { 'flex-center-start position-relative': !className })} ref={ref}>
        <span className="me-8px">{t(title)}</span>
        {icon && icon}
        {sort.field === id ? sort.order === 'asc' ? <span>
          <img src={SortAscIcon} alt='sort Asc icon' loading="lazy" />
        </span> : (sort.order === 'desc' && <span>
          <img src={SortAscIcon} alt='sort Dsc icon' style={{ transform: 'rotate(180deg)' }} loading="lazy" />
        </span>) : <span>
          <img src={SortIcon} alt='sort icon' loading="lazy" />
        </span>}
      </div>
      <UncontrolledTooltip offset={[0, 16]} target={ref}>{getSortTooltip()}</UncontrolledTooltip>
    </>
  )
}

SortHeaderColumn.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.element,
  type: PropTypes.oneOf(['time', 'string', 'number']),
  id: PropTypes.string,
}

SortHeaderColumn.defaultProps = {
  className: '',
  icon: null
}

export default SortHeaderColumn

// for reference 
// <SortHeaderColumn title="" className="" icon={} /> 
