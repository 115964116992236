import { PAYMENT_METHODS } from '@src/views/sales/create-order/create-order.constant';
import { FINAL_ORDER_TOTAL_LABELS } from '../../sales.constant';
import { iCheckCodChargesApplicableParams, iFinalOrderTotal, iGetFinalOrderTotalForPendingTransactionParams, iGetFinalOrderTotalParams, iGetTransactionSideSheetTitleParams } from './types';

export const getFinalOrderTotal = ({ newTotal, previousInvoice }: iGetFinalOrderTotalParams): iFinalOrderTotal => {
  const previousTotalPaid = previousInvoice.total_paid;
  const previousTotalRefund = previousInvoice.total_refunded;

  const effectPaidAmount = previousTotalPaid - previousTotalRefund;
  const finalOrderTotalValue = Math.abs(effectPaidAmount - newTotal);

  if (effectPaidAmount > newTotal) {
    return {
      label: FINAL_ORDER_TOTAL_LABELS.REFUND_DUE.label,
      value: Number(finalOrderTotalValue.toFixed(2)),
      transactionType: FINAL_ORDER_TOTAL_LABELS.REFUND_DUE.transactionType,
    };
  } else {
    return {
      label: FINAL_ORDER_TOTAL_LABELS.DUE_AMOUNT.label,
      value: Number(finalOrderTotalValue.toFixed(2)),
      transactionType: FINAL_ORDER_TOTAL_LABELS.DUE_AMOUNT.transactionType,
    };
  }
};

export const shouldShowPaymentDetails = (finalOrderTotalValue: number): boolean => {
  // Show payment details unless there are no changes in total
  return finalOrderTotalValue !== 0;
};

export const getFinalOrderTotalForPendingTransaction = ({
  orderTotalType,
  finalOrderTotal,
  codChargesAmount,
}: iGetFinalOrderTotalForPendingTransactionParams): iFinalOrderTotal => {
  let value = +finalOrderTotal;

  // For refund cases, we subtract COD charges since it reduces the refund amount
  // For due amount cases, we add COD charges since it increases the due amount
  if (orderTotalType === FINAL_ORDER_TOTAL_LABELS.REFUND_DUE.value) {
    value = value - codChargesAmount;
  } else {
    value += codChargesAmount;
  }
  const orderTotalKey = orderTotalType.toUpperCase() as keyof typeof FINAL_ORDER_TOTAL_LABELS;

  return {
    label: FINAL_ORDER_TOTAL_LABELS[orderTotalKey].label,
    value: Number(value.toFixed(2)),
    transactionType: FINAL_ORDER_TOTAL_LABELS[orderTotalKey].transactionType,
  };
};

export const checkCodChargesApplicable = ({ paymentMethod, finalOrderTotal }: iCheckCodChargesApplicableParams): boolean => {
  // when the previous payment method is prepaid, the new total is greater than the previous total, and the transaction type is "Edit Order Transactions".
  if (!paymentMethod) return false;
  const isPrepaid = paymentMethod === PAYMENT_METHODS.PREPAID.label;
  return isPrepaid && finalOrderTotal.label === FINAL_ORDER_TOTAL_LABELS.DUE_AMOUNT.label && finalOrderTotal?.value > 0;
};

export const getTransactionSideSheetTitle = ({ isEditOrderTransaction, isPendingTransaction, orderTotalType }: iGetTransactionSideSheetTitleParams) => {
  if (isEditOrderTransaction) return 'Confirm Changes';
  return isPendingTransaction && orderTotalType === FINAL_ORDER_TOTAL_LABELS.DUE_AMOUNT.value ? 'Mark as Paid' : 'Mark as Refunded';
};
