import { axiosInstance } from '@src/network/AxiosInstance'

export const getConfigurationValue = ({ configurations, configName }) => {
  const foundConfig = configurations?.find(config => config.config_name === configName)
  if (foundConfig) {
    return foundConfig.config_value.reduce((acc, config) => {
      acc[config.order_status] = config
      return acc
    }, {})
  } else {
    return {}
  }
}

export const getSellers = async (search = '', loadedOptions, { page, is_active = true }) => {
  const params = {
    is_active: true,
    sort: 'name_asc',
    page
  }
  if (search) {
    params.search_column = 'name'
    params.search_query = search
  }
  try {
    const res = await axiosInstance.get('/api/v1/tenant/sellers', {params})
    return {
      options: res.data.data.map((seller) => ({
        value: {...seller},
        label: seller.name
      })),
      hasMore: res?.data?.meta?.current_page < res?.data?.meta?.last_page,
      additional: {
        page: res?.data?.meta?.current_page + 1,
        is_active
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getAllHubListAsyncData = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', { params: { search_column: 'name', search_query: search, page } })
    const data = response?.data

    return {
      options: data?.data.map(ele => ({
        value: ele.id,
        label: ele.name
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.error('Error fetching sellers:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getShippingCodesAsyncData = async (search, _loadedOptions, { page, sellerId, sellerSalesChannelId, params}) => {
  try {
    const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels/${sellerSalesChannelId}/shipping_options`, {params})
    const data = response?.data
    const options = data.data.shipping_companies?.map(ele => ({
      value: ele.shipping_method_code,
      label: ele.display_name + ' ' + ele.shipping_method_code,
      //spreaded because it is not used in filters and all of the data is required for update
      ...ele
    })) || []
    if(page === 1){
      options.unshift({value: 'all', label: 'All', app_id: 'all', display_name: 'All', shipping_method_code: 'all'})
    }
    return {
      options,
      hasMore: false,
      additional: {
        sellerId,
        sellerSalesChannelId,
        params,
        page: page+1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    console.error('Error fetching Boxes:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        sellerId,
        sellerSalesChannelId,
        params,
        page: page+1
      }
    }
  }
}