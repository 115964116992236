import { getOptionsFetcher } from '@src/utility/Utils'
import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, ALL_RETURN_STATUS_FILTER_OPTIONS, RETURN_ORDER_COLUMNS_LIST, RETURN_ORDER_TABS, RETURN_STATUS, RETURN_TABLE_COLUMN_HEADER, STATUS_FILTER_DROPDOWN_STYLE, getOffSystemReturnColumns } from '../../../returns.constants'
import { getReturnOrderDetail, getReturnOrders, markOrdersAsReturnedTohHub, resetSuccess } from '../../../store'
import ReturnOrderDetail from '../return-order-details'
// import ReverseShipment from "../create-reverse-shipment"
import CustomFilter from '@src/@core/components/ui/customTableFilterComponent'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import CustomDropdownOption from '@src/@core/components/ui/dropdown/customDropdownOption'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import { loadAllSellersConfig, loadCourierPartnersConfig, loadSellerSalesChannelConfig } from '@src/app.apis'
import { COLLAPSED_CONTAINER_LIST, HUBS_CONFIGURATION, TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { AbilityContext } from '@src/utility/context/Can'
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters'
import ReturnOrderCancelPopup from '@src/views/returns/components/popup/ReturnOrderCancelPopup'
import { checkActionColumnVisibility, getReturnOrderCustomFilters } from '@src/views/returns/returns.utils'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import { useTranslation } from 'react-i18next'
import TypeOfReturnsInformation from '../../../components/TypeOfReturnsInformation/index'
import CreateReturnOrderProcessing from '../../return-order-processing/create-return-order-processing'

const OffSystemReturn = (props) => {
  const {handleCreateReverseShipmentModal, activeTab} = props

  const {t} = useTranslation()
  const ability = useContext(AbilityContext)

  // Stores
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  const selfTenantSellerId = useSelector((state) => state.auth.userData.sellers[0]?.seller_id)
  const dispatch = useDispatch()
  const loading = useSelector((store) => store.returns.loading)
  const success = useSelector((store) => store.returns.success)
  const error = useSelector((store) => store.returns.error)
  const returnOrders = useSelector((store) => store.returns.returnOrders)
  const selectedHubConfiguration = useSelector(state => state.auth.selectedHubConfiguration) 
  
  // States
  const [tableData, setTableData] = useState([])
  const [isReturnOrderDetailModalOpen, setReturnOrderDetailModalOpen] = useState(false)
  const [isOrderDetailSidebarOpen, setOrderDetailSidebarOpen] = useState(false)
  const [returnOrderToShowDetail, setReturnOrderToShowDetail] = useState(null)
  const [isCancelReturnOrderPopupOpen, setIsCancelReturnOrderPopupOpen] = useState(false)
  const [isCreateReturnOrderProcessingModalOpen, setCreateReturnOrderProcessingModalOpen] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const loadSellerSalesChannelOptions = getOptionsFetcher(loadSellerSalesChannelConfig);
  
  // Custom Hooks
  const { filter, setFilter } = useFilters({})
  const { searchQuery, setSearchQuery } = useSearch({})
  const { pagination, setPagination } = usePagination()
  
  // Variables
  const isLocationInventoryEnabled = selectedHubConfiguration?.find((config) => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled
  const isSellerViewAllowed = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource)
  const loadCourierPartnersOptions = getOptionsFetcher(loadCourierPartnersConfig)
  const loadAllSellersOptions = getOptionsFetcher(loadAllSellersConfig())

  const handleReturnOrderDetailModal = useCallback(() => {
    setReturnOrderDetailModalOpen(prev => !prev)
  }, [isOrderDetailSidebarOpen])

  const handleOrderDetailSidebar = useCallback(() => {
    setOrderDetailSidebarOpen((prev) => !prev)
  }, [isOrderDetailSidebarOpen])

  const handleSetOrderToShowOrderDetail = useCallback((order) => {
    setReturnOrderToShowDetail(order)
    handleOrderDetailSidebar()
  }, [returnOrderToShowDetail])

  const handleSetReturnOrderToShowDetail = useCallback((order) => {
    setReturnOrderToShowDetail(order)
    handleReturnOrderDetailModal()
  }, [returnOrderToShowDetail])

  const handleCreateReturnOrderProcessingModal = useCallback((params) => {
    if (params?.orderID) {
      dispatch(getReturnOrderDetail(params?.orderID))
    }
    setCreateReturnOrderProcessingModalOpen(prev => !prev)
  }, [isCreateReturnOrderProcessingModalOpen])

  const getAllAppliedFilters = (appliedFilters) => {
    setFilter(appliedFilters)
  }

  const filterHandler = ({ filterKey, filterValue }) => {
    // If the seller is changed or reset, update the store accordingly.
    if (filterKey === 'seller_id') {
      delete filter.seller_sales_channel_id
    }
    if (!filterValue) {
      delete filter[filterKey]
      setFilter(filter)
      return
    }
    if (filterKey === 'status') {
      setFilter({[filterKey]: filterValue})
      return
    }
    setFilter({ ...filter, [filterKey]: filterValue })
  }

  const handleSearch = ({searchVal, selectedColumn = null}) => {
    const searchQuery = searchVal ? {column: selectedColumn, query: searchVal} : null
    setSearchQuery(searchQuery)
  }

  const handlePagination = page => {
    const pagination = {page: page.selected, per_page: page.per_page ? page.per_page : 20 }
    setPagination(pagination)
  }

  const handleCancelReturnOrderPopup = (row) => {
    setIsCancelReturnOrderPopupOpen(prev => !prev)
    setReturnOrderToShowDetail(row)
  }


  const getReturnOrderWithFilter = () => {
    const filterParams = {
      status: filter.status?.value,
      seller_id: filter.seller_id?.value,
      seller_sales_channel_id: filter.seller_sales_channel_id?.value,
      shipping_partner_tag: filter.shipping_partner_tag?.value,
      courier_partner_tag: filter.courier_partner_tag?.value
    }
    const searchParams = searchQuery.query ? {[searchQuery.column.id]: searchQuery.query} : {}
    dispatch(getReturnOrders({params: {...filterParams, ...searchParams, ...pagination, types: activeTab.key}}))
  }

  const handleSelectedData = ({ selectedRows }) => {
    setSelectedData(selectedRows)
  }

  const handleMarkOrderArrivedAtHub = () => {
    const body = {
      is_reverse: activeTab.key === RETURN_ORDER_TABS.CUSTOMER_RETURN.key,
      shipment_updates: selectedData.map((order) => {
        return {
          order_id: order.orderID,
          awb_number: order.awb_number,
          shipping_partner_tag: order.shipping_partner_tag
        }
      }),
      status: RETURN_STATUS.REACHED_AT_HUB.key
    }
    dispatch(markOrdersAsReturnedTohHub({ body }))
  }

  const handleCancelOrderSuccess = () => {
    getReturnOrderWithFilter()
    handleCancelReturnOrderPopup();
  }

  useEffect(() => {
    if (success.cancelReturnOrder) {
      handleCancelReturnOrderPopup()
    }
    if (success.createReverseShipment || success.markOrdersAsReturnedTohHub || success.completeReturnOrderProcessing || success.completeRefundProcess || success.cancelReturnOrder) {
      getReturnOrderWithFilter()
      dispatch(resetSuccess())
      if (isReturnOrderDetailModalOpen) {
        handleReturnOrderDetailModal()
      }
    }
  }, [success])

  useEffect(() => {
    getReturnOrderWithFilter()
  }, [])

  useEffect(() => {
    if (returnOrders?.data) {
      const mappedData = returnOrders.data.map((data, index) => {
        return {
          ...data,
          serialNo: ((returnOrders.meta?.current_page - 1) * returnOrders.meta?.per_page) + index + 1,
          orderID: data.id,
          created_order_id: data.order_id,
          awb_number: data.shipment.awb_number,
          shipping_partner_name: data.shipment.shipping_partner_name,
          shipping_partner_tag: data.shipment.shipping_partner_tag,
          courier_partner: data.shipment.courier_partner,
          total_price: data.total,
          approved_by: data.approved_by.name,
          created_by: data.created_by.name,
          qc_processed_by: data.qc_processed_by?.name
        }
      })
      setTableData(mappedData)
    }
  }, [returnOrders])

 
  const isActionColumnVisible = checkActionColumnVisibility({ability, tableData});

  return (
    <div className="return-order">
      <div className="p-1 pb-0 d-flex gap-1">
        <DropdownWithTitle
          value={!filter.status ? {label: 'All', value: ''} : filter.status}
          isClearable={!!filter.status}
          title="Status"
          options={ALL_RETURN_STATUS_FILTER_OPTIONS(isLocationInventoryEnabled).filter((option) => {
            if (option.value === RETURN_STATUS.INITIATED.key) {
              return false
            }
            if (option.value === RETURN_STATUS.ITEMS_PICKED_UP.key) {
              return false
            }
            return true
          })}
          selectOptionHandler={(value) => filterHandler({ filterKey: 'status', filterValue: value })}
          externalStyles={STATUS_FILTER_DROPDOWN_STYLE}
        />
      </div>
      <div className="p-1 d-flex gap-1">
        <CustomFilter
          filterData={getReturnOrderCustomFilters()}
          defaultSelectedOption={filter}
          getAllAppliedFilters={getAllAppliedFilters}
        />
        {((ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource) && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE)) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.seller_id} 
            value={!filter.seller_id ? {label: 'All', value: ''} : filter.seller_id}
            title="Sellers"
            loadOptionsHandler={loadAllSellersOptions} 
            selectOptionHandler={(value) => filterHandler({filterKey: 'seller_id', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
          />
        </div>}
        {ability.can(abilityMap.sales_channel_app.view.action, abilityMap.sales_channel_app.view.resource) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.seller_sales_channel_id} 
            value={!filter.seller_sales_channel_id ? {label: 'All', value: ''} : filter.seller_sales_channel_id}
            title="Store Name"
            loadOptionsHandler={loadSellerSalesChannelOptions}
            additional={{
              seller: filter.seller_id?.value || selfTenantSellerId
            }}
            selectOptionHandler={(value) => filterHandler({filterKey: 'seller_sales_channel_id', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            isDisabled={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed}
            tooltipId={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && isSellerViewAllowed && 'seller_sales_channel_id'}
            tooltipText={!filter.seller_id && tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && 'Select a seller first'}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>}
        {ability.can(abilityMap.shipping_app.view.action, abilityMap.shipping_app.view.resource) && <div>
          <DropdownWithTitle 
            isAsync 
            isClearable={!!filter.courier_partner_tag} 
            value={!filter.courier_partner_tag ? {label: t('All'), value: ''} : filter.courier_partner_tag}
            title={t('Courier Partners')}
            loadOptionsHandler={loadCourierPartnersOptions} 
            selectOptionHandler={(value) => filterHandler({filterKey: 'courier_partner_tag', filterValue: value})} 
            externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            additionalComponents={{Option: CustomDropdownOption}}
          />
        </div>}
      </div>
      {selectedData.length > 0 && (
        <div className="w-100 d-flex align-items-stretch gap-1 selected-row-summary">
          <div className="selected-row-batch">
            {t('Order Selected')}: {selectedData.length}
          </div>
          <div className="vertcal-row"></div>
          <button
            onClick={handleMarkOrderArrivedAtHub}
            disabled={loading.markOrdersAsReturnedTohHub}
            className="border border-primary text-primary rounded"
          >
            {loading.markOrdersAsReturnedTohHub && (
              <Spinner size="sm" className="me-50" />
            )}
            {t('Mark Order(s) as Return To Origin')}
          </button>
        </div>
      )}

      <div className='mb-1 mx-1'>
        <TypeOfReturnsInformation returnInfo={COLLAPSED_CONTAINER_LIST.OFF_SYSTEM_RETURN}/>
      </div>
      <div className="return-order-table px-1">
        <ExpandableCustomTable
          loading={loading.getReturnOrders}
          success={success.getReturnOrders}
          error={error.getReturnOrders}
          isFilterOrSearchApplied={!!(Object.values(filter).length || Object.values(searchQuery).length > 1)}
          data={tableData}
          columns={getOffSystemReturnColumns({ handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, isActionColumnVisible, currentTabKey: activeTab.key, handleCancelReturnOrderPopup, status: filter.status?.value }).filter((column) => column.name !== RETURN_TABLE_COLUMN_HEADER.ORDER_ID.name)}
          columnName={ALL_COLUMN_NAME_MAPPING.OMS_ALL_RETURN_ORDER}
          searchcolumnsList={RETURN_ORDER_COLUMNS_LIST.filter((column) => column.id !== RETURN_ORDER_COLUMNS_LIST[0].id)}
          search_column_query={searchQuery}
          handleQueryParams={handleSearch}
          handlePagination={handlePagination}
          meta={returnOrders?.meta || {}}
          useReactPaginate={false}
          selectable={(filter.status?.value === RETURN_STATUS.REVERSE_SHIPMENT_CREATED.key || filter.status?.value === RETURN_STATUS.INITIATED.key) && ability.can(abilityMap.return.edit_order.action, abilityMap.return.edit_order.resource)}
          handleSelectedRowsChange={handleSelectedData}
          showSelected={ability.can(abilityMap.return.edit_order.action, abilityMap.return.edit_order.resource)}
          showColumnsTableHeader={true}
        />
      </div>
      {/* <ReverseShipment isCreateReverseShipmentModalOpen={isCreateReverseShipmentModalOpen} handleCreateReverseShipmentModal={handleCreateReverseShipmentModal}/> */}
      <ReturnOrderDetail isReturnOrderDetailModalOpen={isReturnOrderDetailModalOpen} handleReturnOrderDetailModal={handleReturnOrderDetailModal} handleCreateReverseShipmentModal={handleCreateReverseShipmentModal} handleOrderDetailSidebar={handleOrderDetailSidebar} returnOrderToShowDetail={returnOrderToShowDetail} fetchParentData={getReturnOrderWithFilter}/>
      <ReturnOrderCancelPopup
        isReturnOrderCancelPopupOpen={isCancelReturnOrderPopupOpen}
        setIsReturnOrderCancelPopupOpen={setIsCancelReturnOrderPopupOpen}
        orderDetail={returnOrderToShowDetail}
        setOrderDetail={setReturnOrderToShowDetail}
        handleCancelOrderSuccess={handleCancelOrderSuccess}
      />
      <OrderDetailsSideSlider
        sellerId={returnOrderToShowDetail?.created_order_id}
        isModalOpen={isOrderDetailSidebarOpen}
        setIsModalOpen={setOrderDetailSidebarOpen}
      />
      <CreateReturnOrderProcessing isCreateReturnOrderProcessingModalOpen={isCreateReturnOrderProcessingModalOpen} handleCreateReturnOrderProcessingModal={handleCreateReturnOrderProcessingModal}/>
    </div>
  )
}
export default memo(OffSystemReturn)