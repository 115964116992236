/**
 * @component ModalHeader
 * @description A reusable modal header component that supports different types of modals
 * with customizable icons, titles, and close buttons.
 *
 * @dependencies
 * - General, Cross, Warning icons from assets
 * - classNames for conditional styling
 * - React hooks (useState)
 * - i18n for translations
 * - modal.constant for types and utilities
 */

import { General } from '@src/assets/images/icons/Modal/GeneralIcons';
import { Cross, Warning } from '@src/assets/images/icons/Modal/Illustration';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getActionIcon, MODAL_TYPES, ModalHeaderProps } from './modal.constant';

/**
 * @interface CloseButtonProps
 * @description Props for the close button component
 * @property {Function} closeModalHandler - Callback function to handle modal close action
 */
interface CloseButtonProps {
  closeModalHandler: () => void
}

/**
 * @component CloseButton
 * @description A reusable close button component with hover state
 */
const CloseButton: FC<CloseButtonProps> = ({ closeModalHandler }) => {

  return (
    <span
      className="flex-center-center cursor-pointer close-icon"
      onClick={closeModalHandler}
    >
      <Cross
        size={18}
        color='var(--bs-dark-6)'
      />
    </span>
  );
};

/**
 * @component ModalHeader
 * @description Main modal header component that supports two types of layouts:
 * 1. Illustration type - Centered layout with larger icons
 * 2. Standard type - Horizontal layout with smaller icons
 */
const ModalHeader: FC<ModalHeaderProps> = ({
  type,
  isTranslationRequired,
  subTitle,
  icon,
  customIcon,
  closeButtonHidden,
  closeModalHandler,
  title,
  iconSize,
}) => {
  const { t } = useTranslation();

  // Determine if modal is illustration type for layout decisions
  const isIllustration = type === MODAL_TYPES.ILLUSTRATION;

  // Set default icon based on modal type
  const DefaultIcon = isIllustration ? Warning : General;

  // Use provided icon or fall back to default
  const Icon = icon
    ? (icon as FC<{ size?: string; color?: string }>)
    : DefaultIcon;

  return (
    <div
      className={classNames('modal-header-wrapper px-24px pt-24px pb-16px', {
        'border-bottom': !isIllustration
      })}
    >
      <div
        className={classNames('d-flex', {
          'flex-column align-items-center gap-24px': isIllustration,
          'gap-16px': !isIllustration,
        })}
      >
        <div
          className={classNames('d-flex', {
            'justify-content-between w-100': isIllustration,
          })}
        >
          {/* Close Button Container */}
          {!closeButtonHidden && (
            <div className="close-button-wrapper order-1 ms-auto position-absolute top-0 end-0 p-2">
              <CloseButton closeModalHandler={closeModalHandler} />
            </div>
          )}

          {/* Icon section with support for custom and default icons */}
          <>
            {(customIcon && (
              <div
                className={classNames({
                  'text-center flex-center w-100': isIllustration,
                  'modal-icon-wrapper flex-center-center max-width-60 max-height-60 rounded-8px order-2':
                    !isIllustration,
                })}
              >
                {isIllustration ? (
                  customIcon
                ) : (
                  <div className="icon-container flex-center-center rounded-6px">
                    {' '}
                    {customIcon}{' '}
                  </div>
                )}
              </div>
            )) ||
              (isIllustration ? (
                <div className="text-center flex-center w-100">
                  {typeof icon === 'string' ? (
                    getActionIcon({ icon })
                  ) : (
                    <Icon color="var(--bs-primary)" size={iconSize} />
                  )}
                </div>
              ) : (
                <div
                  className={classNames('modal-icon-wrapper', {
                    'flex-center-center max-width-60 max-height-60 rounded-8px order-2 p-8px rounded-8px':
                      !isIllustration,
                  })}
                >
                  <div className="icon-container flex-center-center rounded-6px">
                    {<Icon color="var(--bs-primary)" size={iconSize} />}
                  </div>
                </div>
              ))}
          </>
        </div>

        {/* Content section with title and optional subtitle */}
        <div
          className={classNames('content-wrapper', {
            'text-start w-100': isIllustration,
            'd-flex flex-column align-items-start gap-8px':
              !isIllustration && subTitle,
            'd-flex align-items-center order-2': !isIllustration && !subTitle,
            'd-flex flex-column gap-16px': isIllustration && subTitle,
          })}
        >
          <div className="txt-h1-sb text-dark">
            {isTranslationRequired ? t(title) : title}
          </div>
          {subTitle && (
            <div
              className={classNames(
                'd-flex align-items-start text-dark-6 txt-body-rg'
              )}
            >
              {isTranslationRequired ? t(subTitle) : subTitle}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
