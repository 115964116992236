// ** Reactstrap Imports
import Modal from '@src/@core/components/ui/modal/Modal'
import InfoFilledIcon from '@src/assets/images/icons/Infofilled'
import { useTranslation } from 'react-i18next'

const CityMappingConfirmationPopUp = (prop) => {
  const { isOpen, setIsOpen, addCityMapping, watch, isCityEditing, setIsCityEditing, setValue } = prop
  const { t } = useTranslation()

  const handleProceed = () => {
    addCityMapping({
      omniful_city_id: `${watch('city')?.value}`,
      city_mapping_id: `${watch('mapped_city')?.value}`,
      mapping_direction: 'system_to_courier',
      omniful_city_name: `${watch('city')?.label}`,
      omniful_country_name: `${watch('country')?.label}`
    })
    setIsOpen(false)
  }
  const handleTakeMeBack = () => {
    if (isCityEditing) {
      setIsCityEditing(false)
    }
    setValue('mapped_city', null)
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClosed={() => setIsOpen(false)}
        title={t('Confirm Delivery City Mapping')}
        primaryButtonText={t('Proceed')}
        secondaryButtonText={t('Take Me Back')}
        primaryButtonHandler={handleProceed}
        secondaryButtonHandler={handleTakeMeBack}
        closeModalHandler={() => setIsOpen(false)}
        isTranslationRequired={false}
      >
        <div className="content-text d-flex flex-column align-items-start">
          <div className="d-flex align-items-center mb-12px p-12px rounded-8px gap-10px bg-primary-lighter text-dark txt-body-rg w-100">
            <InfoFilledIcon fillColor='var(--bs-primary)' color="white" size={18} />
            <span className="txt-sub-rg">{t('New mapped city will be used for further orders')}.</span>
          </div>
          <div className="d-flex flex-column gap-2px txt-sub-rg">
            <p className="m-0">Are you sure you want to map <span className="txt-sub-md text-dark">{watch('city')?.label}</span> to <span className="txt-sub-md text-dark">{watch('mapped_city')?.label}</span> ?</p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CityMappingConfirmationPopUp
