export const tableData = ({showCountryOfOrigin, showOrderedQuantity, showPickedQuantity, showItemNote}) => {
  const columns = [
    {
      name: 'S. No.',
      key: 'serial_number',
      className: 'header-text-left',
      cellClass: 'serial-number table-row-td',
      getValue: (item, index) => index + 1
    },
    {
      name: 'SKU Code',
      key: 'sku_code',
      className: 'header-text-left',
      cellClass: 'sku-code table-row-td',
      getValue: (item) => item.sku_code || '-'
    },
    {
      name: 'SKU Name',
      key: 'sku_name',
      className: 'header-text-left',
      cellClass: 'sku-name-wrapper table-row-td',
      renderCustom: true,
      getValue: (item) => item.name ? (
        <>
          {item.child_items.length > 0 && <span className='kit-wrapper'>[Kit]</span>}
          <span className='sku-name-td'>{item.name}</span>
        </>
      ) : '-'
    },
    {
      name: 'Country of Origin',
      key: 'country_of_origin',
      className: 'header-text-left',
      cellClass: 'country-of-origin table-row-td',
      permission: showCountryOfOrigin,
      getValue: (item) => item.country_of_origin || '-'
    },
    {
      name: 'HS Code',
      key: 'hs_code',
      className: 'header-text-right',
      cellClass: 'hs-code table-row-td td-text-right',
      getValue: (item) => item.hs_code || '-'
    },
    {
      name: 'Ordered Qty',
      key: 'ordered_quantity',
      className: 'header-text-right',
      cellClass: 'qty table-row-td td-text-right',
      permission: showOrderedQuantity,
      getValue: (item) => item.ordered_quantity || '-'
    },
    {
      name: 'Picked Qty',
      key: 'picked_quantity',
      className: 'header-text-right',
      cellClass: 'qty table-row-td td-text-right',
      permission: showPickedQuantity,
      getValue: (item) => item.picked_quantity || '-'
    },
    {
      name: 'Qty',
      key: 'quantity',
      className: 'header-text-right',
      cellClass: 'qty table-row-td td-text-right',
      getValue: (item) => item.quantity || '-'
    },
    {
      name: 'Unit Price',
      key: 'unit_price',
      className: 'header-text-right',
      cellClass: 'unit-price table-row-td td-text-right',
      getValue: (item, _, data) => `${item.unit_price || '0.00'} ${data.currency || ''}`
    },
    {
      name: 'Tax Amount',
      key: 'tax_amount',
      className: 'header-text-right',
      cellClass: 'tax-amount table-row-td td-text-right',
      getValue: (item, _, data) => `${item.tax_amount || '0.00'} ${data.currency || ''}`
    },
    {
      name: 'Total Price',
      key: 'total_price',
      className: 'header-text-right',
      cellClass: 'total-price table-row-td td-text-right',
      getValue: (item, _, data) => `${item.total_price || '0.00'} ${data.currency || ''}`
    },
    {
      name: 'Customer Note',
      key: 'note',
      className: 'header-text-right',
      cellClass: 'note table-row-td td-text-right',
      permission: showItemNote,
      getValue: (item) => item.item_note || '-'
    }
  ]

  // Filter out columns that don't have permission
  return columns.filter(column => {
    return !('permission' in column) || column.permission === true
  })
} 

export const InfoRow = ({ label, value }) => {
  return (
    <div className='info-row'>
      <div className='info-col-1'>
        {label}
      </div>
      <div className='info-col-2'>
        <span className='me-1 info-seperator'>:</span>
        <span className='info-value'>&lrm;{value}</span>
      </div>
    </div>
  )
}