import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import RadioButton from '@src/@core/components/ui/radio-button'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import Edit from '@src/assets/images/icons/Edit'
import PrintManualAwb from '@src/components/PrintManualAwb'
import classNames from 'classnames'
import { ArrowRight, Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { Col, Form, Row, Spinner } from 'reactstrap'
import MultipleAwbSideSheet from '../components/MultipleAwbSideSheet/MultipleAwbSideSheet'
import OrderDetails from '../components/OrderDetails'
import ShippingPartner from '../components/ShippingPartner'
import ShippingDetails from '../components/shippingDetails'
import { CREATE_SHIPMENT_STATUS, PRINT_BUTTON_FOR_MANUAL_SHIPMENT, SHIPPING_ENTITY, SHIPPING_OPTION } from '../constant/orders.constants'
import useCreateShipmentComponents from '../create-shipment/hooks/useCreateShipmentComponents'
import PrintAwb from '../pending-actions/pages/orderDetails/orderDetailsTabPages/PrintAwb/PrintAwb'
import PrintDeliveryNote from '../pending-actions/pages/orderDetails/orderDetailsTabPages/PrintDeliveryNote'
import { EDIT_MODAL_TYPE, LIVE_ORDERS_TABS } from '../sales.constant'


const CustomDetailsOption = (props) => {
  const { data: { name, address } } = props
  const { Option } = components
  const {t} = useTranslation()
  return (
    <Option key={address} {...props}>
      <div className="pb-6px">
        <div className="fs-14px txt-body-md fw-bolder">{t(name)}</div>
        <div className='fs-11px txt-asst-rg mt-4px text-wrap'>{t(address)}</div>
      </div>
    </Option>
  )
}

const CreateShipmentComponents = ({
  entity_name = '',
  handleEditDetailsModal,
  omnifulGeneratedShipmentType,
  orderID,
  sellerSalesChannelId,
  setOmnifulGeneratedShipmentType,
  shippingDetailsData,
}) => {
  
  const createShipmentComponentsHook = useCreateShipmentComponents({ CustomDetailsOption, entity_name, omnifulGeneratedShipmentType, orderID, setOmnifulGeneratedShipmentType, shippingDetailsData })

  const {
    ability,
    clearErrors,
    configForParcelShops,
    control,
    createShipmentStatus,
    defaultCity,
    defaultCountry,
    errors,
    getPrioritisedShippingPartnersResonse,
    handleAwb,
    handleEditOrderDetails,
    handleGetOrderDataForManualPrint,
    handleGetShippingPartnerOrder,
    handleOrderDetailsData,
    handleOrderFulfillment,
    handleSubmit,
    isCityEditing,
    isCourierPartnerForShipping,
    isCourierPartnerRequired,
    isCreateShipmentDisabled,
    loading,
    loadingFetchDimensions,
    manualPrintLoadingState,
    multipleAwbList,
    multipleAwbModalOpen,
    newConfigForCourierPartner,
    omnifulCity,
    onSubmit,
    orderDetails,
    orderDetailsFieldConfigurations,
    packageData,
    printAwbLoading,
    printDeliveryNoteRef,
    printManualAwbRef,
    register,
    searchParams,
    setIsCityEditing,
    setPackageData,
    setValue,
    shipmentCreated,
    shipmentDetails,
    shippingPartnerOptions,
    t, 
    togglemultipleAwbModal,
    watch,
    RenderDeliveryStatus,
  } = createShipmentComponentsHook

  return (
    <>
      <div className="orderFulFillment mx-1 mb-3 mt-2 border rounded-4">
        <h5 className="py-1 px-1 fw-bolder text-secondary mb-0 flex-center-between rounded-top-4 txt-h3-md shipment-details-heading">
          {t('Shipment Details')}
        </h5>
        {!shipmentCreated ? (
          <div className="bg-white px-1">
            <div className="w-w-auto d-flex px-8px mt-16px txt-body-rg border border-primary-lighter rounded-4px width-fit-content">
              <span className='py-8px'>{t('Shipping From')}</span>
              <span className='border-end mx-8px'></span>
              <span className='py-8px text-dark'>
                {shippingDetailsData.entity_data.hub.name}
                {defaultCity.label && `, ${defaultCity.label}`}
                {defaultCountry.label && `, ${defaultCountry.label}`}
              </span>
            </div>
            <p className="txt-body-md mt-16px text-dark">{t('Order Fulfilment by')}</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex w-75 justify-content-between">
                {shippingDetailsData.shipping_options.map((option) => (
                  <div className="w-50 d-flex gap-1" key={option.key}>
                    <RadioButton
                      id={`formRadio-${option.key}`}
                      name={option.key}
                      label={option.display_name}
                      {...register('shipment_type')}
                      value={option.key}
                      checked={omnifulGeneratedShipmentType === option.key}
                      onClick={handleOrderFulfillment}
                      // disabled={createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED}
                    />
                  </div>
                ))}
              </div>
              <div className='mt-24px'>
                <h6 className="d-flex align-items-center gap-20px txt-body-md text-dark cursor-pointer">
                  <span className="">{t('Shipping Address')}</span>
                  {(ability.can(
                    abilityMap.order.edit_details.action,
                    abilityMap.order.edit_details.resource
                  ) || ability.can(
                    abilityMap.shipment_order.edit_details.action,
                    abilityMap.shipment_order.edit_details.resource
                  )) && handleEditDetailsModal && <div className='d-flex gap-8px align-items-center'
                    onClick={() => { handleEditDetailsModal(EDIT_MODAL_TYPE.SHIPPING_ADDRESS.key) }}>
                    <Edit width={18} height={18} color = 'var(--bs-primary)'/>
                    <span className='txt-body-rg' style = {{color:'var(--bs-primary)'}}>{t('Edit')}</span>
                  </div>}
                </h6>
                <div className="py-1">
                  <Row className='mb-1 mx-0'>
                    <div className='text-dark py-8px txt-sub-rg flex-center-start rounded-4px' style = {{backgroundColor: 'var(--bs-primary-lighter'}}>
                      <div className='flex-center-start'>
                        <div className='flex-center-start gap-50'>
                          <span className='flex-center-center'><Info size={14} /></span>
                          <span>{t('In the absence of recipient details in the shipping address, customer information (name, email, phone) will be utilized for delivery.')}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <ShippingDetails shippingDetailsData={shippingDetailsData} isFieldRequired={SHIPPING_OPTION.SHIPPING_PARTNER.name === omnifulGeneratedShipmentType && !shipmentCreated} />
                </div>
              </div>

              <div className='mt-30px d-flex gap-32px'>
                <div className='w-50'>
                  <OrderDetails
                    register={register}
                    errors={errors}
                    inputFieldConfigurations={orderDetailsFieldConfigurations}
                  />
                </div>
                <div className='w-50'>
                  {(omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) && <div>
                    <h6 className="txt-body-md mb-2">{t('Shipping Partner Details')}</h6>
                    <div className='d-grid gap-24px'>
                      <div className='w-100'>
                        <FloatingDropDown key={newConfigForCourierPartner.name}  {...newConfigForCourierPartner} />
                      </div>
                      <div className='w-100'>
                        <InputField
                          name='awb'
                          label='Enter AWB'
                          errors={errors}
                          isClearable={true}
                          {...register('awb')}
                        />
                      </div>
                      {
                        (omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) &&
                        <div className='w-100'>
                          <InputField
                            name='tracking_url'
                            label='Enter Tracking URL'
                            errors={errors}
                            isClearable={true}
                            {...register('tracking_url')}
                          />
                        </div>
                      }
                    </div>
                  </div>}

                  {omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED &&
                    <ShippingPartner
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      control={control}
                      sellerSalesChannelId={sellerSalesChannelId}
                      watch={watch}
                      createShipmentStatus={createShipmentStatus}
                      isCourierPartnerForShipping={isCourierPartnerForShipping}
                      isCourierPartnerRequired={isCourierPartnerRequired}
                      newConfigForCourierPartner={newConfigForCourierPartner}
                      shippingPartnerOptions={shippingPartnerOptions}
                      omnifulCity={omnifulCity}
                      isCityEditing={isCityEditing}
                      setIsCityEditing={setIsCityEditing}
                      configForParcelShops={configForParcelShops}
                      packageData={packageData} 
                      setPackageData={setPackageData}
                      shippingDetailsData={shippingDetailsData}
                    />
                  }
                </div>
              </div>
              {createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_UNSELECTED && (watch('shipment_type') !== SHIPPING_OPTION.MANUAL_DISPATCH.name && watch('shipment_type') !== SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) &&
                <div className="d-flex justify-content-end mt-36px mb-2">
                  <Button
                    type="button"
                    disabled={getPrioritisedShippingPartnersResonse.loading}
                    onClick={handleGetShippingPartnerOrder}
                  >
                    {getPrioritisedShippingPartnersResonse.loading && <Spinner size="sm" />}
                    <span className={classNames({ 'ms-50': getPrioritisedShippingPartnersResonse.loading })}>{t('Get Shipping Details')} <ArrowRight size={16} />
                    </span>
                  </Button>
                </div>
              }

              {(createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED || (watch('shipment_type') === SHIPPING_OPTION.MANUAL_DISPATCH.name || watch('shipment_type') === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name)) &&
                <div className="d-flex justify-content-end mt-36px mb-2 gap-12px">
                  {omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && <Button ofStyle='outlined' onClick={handleEditOrderDetails}>{t('Edit Details')}</Button>}
                  <Button type="submit" disabled={loading.createShipment || loading.createShipmentOrderShipment || loadingFetchDimensions || (createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED && !watch('shipping_partner')) || isCreateShipmentDisabled || Object.entries(errors).length}>
                    {(loading.createShipment || loading.createShipmentOrderShipment) && <Spinner size="sm" />}
                    <span className={classNames({ 'ms-50': loading.createShipment || loading.createShipmentOrderShipment })}>{t('Create Shipment')}</span>
                  </Button>
                </div>
              }
            </Form>
          </div>
        ) : (
          <>
            {omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && (
              <div>
                <div className="bg-white px-1 py-1">
                  <ShippingDetails shippingDetailsData={shippingDetailsData} />
                  <Row className="my-1">
                    <Col className="col-3">{t('Shipping Partner Order Id')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.shipping_partner_order_id) || '-'}
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col className="col-3">{t('AWB Number')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.awb_number) || '-'}
                      {shipmentDetails?.data.metadata?.tracking_info?.length > 1 &&
                        <span
                          onClick={() => togglemultipleAwbModal(shipmentDetails.data.metadata.tracking_info)}
                          className={'text-primary ms-12px cursor-pointer'}
                        >
                          {`+${shipmentDetails.data.metadata.tracking_info.length - 1}`}
                        </span>}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-3">{t('Shipping Partner Name')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.shipping_partner_name) || '-'}
                    </Col>
                  </Row>
                  {shipmentDetails && shipmentDetails.data.status && (
                    <Row className="my-1">
                      <Col className="col-3">{t('Delivery Status')}:</Col>
                      <Col className="text-secondary col-9">
                        <RenderDeliveryStatus />
                      </Col>
                    </Row>
                  )}
                </div>
                {(searchParams.get('currentTab') !== LIVE_ORDERS_TABS.PACKING.value) && <div className="d-flex justify-content-end gap-12px bg-white pb-2 px-2">
                  {shipmentDetails && shipmentDetails.data.print_awb_allowed &&
                    <Button disabled={printAwbLoading} onClick={handleAwb}>
                      {printAwbLoading && <Spinner size="sm" />}
                      <span className={classNames({ 'ms-50': printAwbLoading })}>{t('Print AWB')}</span>
                    </Button>}
                  {shipmentDetails && shipmentDetails.data.print_delivery_note_allowed && (!orderDetails ?
                    <Button disabled={loading.getSingleOrderDetails} onClick={handleOrderDetailsData}>{loading.getSingleOrderDetails && <Spinner size="sm" />}
                      <span className={classNames({ 'ms-50': loading.getSingleOrderDetails })}>{t('Print Delivery Note')}</span>
                    </Button> : <PrintDeliveryNote printDeliveryNoteRef={printDeliveryNoteRef} buttonClicked={true} orderDetails={orderDetails} />)}
                </div>}
              </div>
            )}

            {(omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) && (
              <div>
                <div className="bg-white px-1 py-1">
                  <ShippingDetails shippingDetailsData={shippingDetailsData}/>
                  <Row className="my-1">
                    <Col className="col-3">{t('AWB Number')}:</Col>
                    <Col className="text-secondary col-9">
                      {(shipmentDetails && shipmentDetails.data.awb_number) || '-'}
                      {shipmentDetails?.data.metadata?.tracking_info?.length > 1 &&
                        <span
                          onClick={() => togglemultipleAwbModal(shipmentDetails.data.metadata.tracking_info)}
                          className={'text-primary ms-12px cursor-pointer'}
                        >
                          {`+${shipmentDetails.data.metadata.tracking_info.length - 1}`}
                        </span>}
                    </Col>
                  </Row>
                  {shipmentDetails && shipmentDetails.data.status && (
                    <Row className="my-1">
                      <Col className="col-3">{t('Delivery Status')}:</Col>
                      <Col className="text-secondary col-9">
                        <RenderDeliveryStatus />
                      </Col>
                    </Row>
                  )}
                  {(searchParams.get('currentTab') !== LIVE_ORDERS_TABS.PACKING.value) && <div className="d-flex justify-content-end gap-2 bg-white pb-2 mt-3">
                    {shipmentDetails && shipmentDetails.data.print_manual_awb_allowed && entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value ? <PrintManualAwb entity_id={shipmentDetails.data.oms_order_id} entity_type={SHIPPING_ENTITY.SHIPMENT_ORDER.value} /> :
                      (!orderDetails ?
                        <Button disabled={manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB} onClick={() => { handleGetOrderDataForManualPrint(PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB) }}>{(manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB) && <Spinner size="sm" />}
                          <span className={classNames({ 'ms-50': (manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB) })}>{t('Print AWB')}</span>
                        </Button> : <PrintAwb buttonClicked={manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_AWB} orderDetails={orderDetails} printManualAwbRef={printManualAwbRef} />)
                    }

                    {shipmentDetails && shipmentDetails.data.print_delivery_note_allowed && (!orderDetails ?
                      <Button disabled={isCityEditing || manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE} onClick={() => { handleGetOrderDataForManualPrint(PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE) }}>{(manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE) && <Spinner size="sm" />}
                        <span className={classNames({ 'ms-50': (manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE) })}>{t('Print Delivery Note')}</span>
                      </Button> : <PrintDeliveryNote printDeliveryNoteRef={printDeliveryNoteRef}
                        buttonClicked={manualPrintLoadingState === PRINT_BUTTON_FOR_MANUAL_SHIPMENT.PRINT_DELIVERY_NOTE} orderDetails={orderDetails} />)}

                  </div>}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <MultipleAwbSideSheet modalOpen={multipleAwbModalOpen} data={multipleAwbList} toggleSidebar={togglemultipleAwbModal} />
    </>
  )
}

export default CreateShipmentComponents