import { IS_OPTION_VALUE_ALL } from './customTableFilterComponent.constant';

/**
 * Helper function to check if search is applied based on result's additional params
 * @param {Object} result - The result object from options fetch
 * @returns {boolean} - Whether search is applied
 */
const isSearchApplied = (result) => {
  const params = result.additional?.params || {}
  return !!(
    params.search_query ||
    params.search ||
    params.name_filter ||
    params.query
  )
}

/**
 * Function to sort options based on applied filters and multi-select state
 * @param {Array} options - Array of options to sort
 * @param {string} filterKey - Key identifying the filter
 * @param {Object} appliedOptions - Currently applied options
 * @param {boolean} isMultiSelect - Whether this is a multi-select dropdown
 * @returns {Array} - Sorted options array
 */
export const sortOptions = (
  options,
  filterKey,
  appliedOptions,
  isMultiSelect = false
) => {
  if (isMultiSelect) {
    // Multi-select sorting logic
    const appliedValues = (appliedOptions?.[filterKey] || [])?.map(opt => opt.value) || [];
    
    if (!appliedValues.length) return options;
    
    return [...options].sort((a, b) => {
      const aApplied = appliedValues.includes(a.value);
      const bApplied = appliedValues.includes(b.value);
      
      if (aApplied && !bApplied) return -1;
      if (!aApplied && bApplied) return 1;
      
      return 0;
    });
  } else {
    // Single-select sorting logic
    const appliedOption = appliedOptions?.[filterKey] || undefined;
    
    if (appliedOption) {
      return [...options].sort((a, b) => {
        if (a.value === appliedOption.value) return -1;
        if (b.value === appliedOption.value) return 1;
        
        if (IS_OPTION_VALUE_ALL(a.value)) return -1;
        if (IS_OPTION_VALUE_ALL(b.value)) return 1;
        
        return 0;
      });
    } else {
      return [...options].sort((a, b) => {
        if (IS_OPTION_VALUE_ALL(a.value)) return -1;
        if (IS_OPTION_VALUE_ALL(b.value)) return 1;
        return 0;
      });
    }
  }
};

/**
 * Common function to modify and sort loaded options for both single and multi-select dropdowns
 * @param {Function} options - Original options loading function
 * @param {string} filterKey - Key identifying the filter
 * @param {Object} selectedOptions - Currently selected options
 * @param {boolean} isMultiSelect - Whether this is a multi-select dropdown
 * @returns {Promise} - Promise resolving to modified options
 */
export const modifiedLoadOptions = (options, filterKey, selectedOptions, isMultiSelect = false) => {
  return (...args) => {
    return options(...args).then(result => {
      const allOptions = result.options?.[0]?.options || result.options || []
      const searchApplied = isSearchApplied(result)

      if (isMultiSelect) {
        // Multi-select handling
        const allSelected = selectedOptions?.[filterKey] || []
        const selected = searchApplied 
          ? allOptions.filter(option => allSelected.some(sel => sel.value === option.value))
          : allSelected
        const selectedValues = selected.map(opt => opt.value)
        const filteredOptions = allOptions.filter(option => !selectedValues.includes(option.value))

        // Handle both implementations (getOptionsFetcher and old)
        const fetcherOptions = result.additional?.params?.page === 1 
          ? [...selected, ...filteredOptions] 
          : [...filteredOptions]
        const nonFetcherOptions = result.additional?.page === 2 
          ? [...selected, ...filteredOptions] 
          : [...filteredOptions]

        return {
          ...result,
          options: result.additional?.params?.page ? fetcherOptions : nonFetcherOptions
        }
      } else {
        // Single-select handling
        const selected = searchApplied 
          ? allOptions.find(option => option.value === selectedOptions?.[filterKey]?.value)
          : selectedOptions?.[filterKey]
        const filteredOptions = allOptions.filter(option => option.value !== selected?.value)

        // Handle both implementations (getOptionsFetcher and old)
        const fetcherOptions = result.additional?.params?.page === 1 && selected 
          ? [selected, ...filteredOptions] 
          : [...filteredOptions]
        const nonFetcherOptions = result.additional?.page === 2 && selected 
          ? [selected, ...filteredOptions] 
          : [...filteredOptions]

        return {
          ...result,
          options: result.additional?.params?.page ? fetcherOptions : nonFetcherOptions
        }
      }
    })
  }
} 