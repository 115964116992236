import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Info, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import { KEYBOARD_KEYS, SKU_TYPES } from '@src/App.constants'
import { commaSeparateInternational } from '@src/utility/Utils'
import { CUSTOM_BATCH_SELECT } from '@src/views/sales/sales.constant'
import { isValidUptoTwoDecimal } from '@src/views/sales/sales.utils'
import { applyExpiryStatus, removeCreateOrderItemFromIndex, updateCreateOrderItemWiseNoteValue, updateCreateOrderItemWiseWeightValue, updateQtyOfCreateOrderItemAtIndex, updateTaxPercentOfCreateOrderItem, updateUnitPriceOfCreateOrderItem } from '@src/views/sales/store/store'

import BatchSelectWarningModal from './BatchSelectWarningModal'
import './ItemTable.scss'
import SelectBatchSideSheet from './SelectBatchSideSheet'

const expiryStatuses = [
  { value: 'fefo', label: 'Close To Expiry' },
  { value: 'lefo', label: 'Far From Expiry' },
  { value: 'custom', label: 'Custom Batch'}
]
const preventFormSubmitOnEnter = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault()
  }
}


const ItemsTableRow = (props) => {
  const { itemDetails, selectedSeller, selectedSkuBatches, setSelectedSkuBatches, isSelectBatchError, setIsSelectedBatchError, activeTab, hideBatch, hideWeight, uniqueItemId, createOrderItems, expiryStatusesfilterOption } = props
  const [isSelectBatchSideSheetOpen, setIsSelectBatchSideSheetOpen] = useState(false)
  const [isSelectBatchWarningModalOpen, setIsSelectBatchWarningModalOpen] = useState(false)
  const quantity = itemDetails?.quantity
  const unitPrice = +itemDetails?.cutoff_price
  const weight = itemDetails?.skuWeight?.value || 0
  const uom = itemDetails?.skuWeight?.uom || ''
  const taxPercentage = +itemDetails?.tax_percentage || 0
  const taxAmount = itemDetails?.tax_amount ? Number((itemDetails.tax_amount * quantity).toFixed(2)) : 0

  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  const totalSelectedQtyFromBatch = useMemo(() => {
    const selectedBatches = selectedSkuBatches?.[uniqueItemId];
    return selectedBatches 
      ? Object.values(selectedBatches).reduce((acc, { quantity = 0 }) => acc + quantity, 0)
      : 0;
  }, [selectedSkuBatches, uniqueItemId]);

  const checkAndSetBatchError = (value) => {
    if (itemDetails?.config?.is_batching_enable && createOrderItems[uniqueItemId]?.expiry_status === CUSTOM_BATCH_SELECT) {
      if (totalSelectedQtyFromBatch !== value) {
        setIsSelectedBatchError(pre => ({ ...pre, [uniqueItemId]: true }));
      } else {
        setIsSelectedBatchError(pre => ({ ...pre, [uniqueItemId]: false }));
      }
    }
  };

  const handlOnChange = (data) => {
    if (data.value === CUSTOM_BATCH_SELECT) {
      setIsSelectBatchSideSheetOpen(true)
      dispatch(applyExpiryStatus({uniqueItemId, value: data.value}))
    } else {
      setIsSelectedBatchError(pre => ({...pre, [uniqueItemId]: false}))
      dispatch(applyExpiryStatus({uniqueItemId, value: data?.value}))
    }
  }

  const handleToggleSelectBatchSideSheet = () => {
    setIsSelectBatchWarningModalOpen(pre => !pre)
  }

  const decreaseQuantity = () => {
    if (quantity > 0) {
      checkAndSetBatchError(quantity - 1);
      dispatch(updateQtyOfCreateOrderItemAtIndex({ uniqueItemId, value: quantity - 1 }))
    }
  }

  const increaseQuantity = () => {
    checkAndSetBatchError(quantity + 1);
    dispatch(updateQtyOfCreateOrderItemAtIndex({ uniqueItemId, value: quantity + 1 }))
  }

  const handleConfirmSelectedSideSheet = () => {
    setIsSelectedBatchError(pre => ({...pre, [uniqueItemId]: false}))
    setIsSelectBatchSideSheetOpen(false)
  }

  // select batch update functions
  const handleIncreaseQtyOfSelectedBatch = (uniqueItemId, batchNumber) => {
    setSelectedSkuBatches(pre => ({...pre, [uniqueItemId]: {...pre[uniqueItemId], [batchNumber] : {batch_number: batchNumber, quantity: pre[uniqueItemId]?.[batchNumber]?.quantity + 1 || 1}}}))
  }

  const handleDecreaseQtyOfSelectedBatch = (uniqueItemId, batchNumber) => {
    if (selectedSkuBatches[uniqueItemId][batchNumber]?.quantity - 1) {
      setSelectedSkuBatches(pre => ({...pre, [uniqueItemId]: {...pre[uniqueItemId], [batchNumber] : {batch_number: batchNumber, quantity: pre[uniqueItemId][batchNumber].quantity - 1}}}))
    } else {
      setSelectedSkuBatches(pre => {
        const removedSelectedBatch = {...pre}
        delete removedSelectedBatch[uniqueItemId][batchNumber]
        return { ...removedSelectedBatch }
      })
    }
  }

  const handleChangeQtyOfSelectedBatch = (uniqueItemId, batchNumber, avlQty, quantity) => {
    const changedQty = quantity !== '' ? (parseInt(quantity.trim()) <= avlQty ? parseInt(quantity.trim()) : avlQty) : 0
    setSelectedSkuBatches(pre => ({...pre, [uniqueItemId]: {...pre[uniqueItemId], [batchNumber] : {batch_number: batchNumber, quantity: changedQty }}}))
  }

  const handleDiscardProcess = () => {
    setSelectedSkuBatches(pre => {
      const removedSelectedBatch = {...pre}
      delete removedSelectedBatch[uniqueItemId]
      return { ...removedSelectedBatch }
    })
    setIsSelectedBatchError(pre => ({...pre, [uniqueItemId]: true}))
    setIsSelectBatchSideSheetOpen(false)
    setIsSelectBatchWarningModalOpen(false)
  }

  const onChange = (e) => {
    const number = e.target.value.trim()
    const value = parseInt(number || 1)
    if (/^[1-9]\d*$/.test(value) || e.target.value === '') {
      checkAndSetBatchError(value);
      dispatch(updateQtyOfCreateOrderItemAtIndex({ uniqueItemId, value }))
    }
  }

  const onKeyDown = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      e.preventDefault()
    }
  }

  const handleDeleteSku = () => {
    dispatch(removeCreateOrderItemFromIndex(uniqueItemId))
    setSelectedSkuBatches(pre => {
      const removedSelectedBatch = { ...pre }
      delete removedSelectedBatch[uniqueItemId]
      return { ...removedSelectedBatch }
    })
    setIsSelectedBatchError(pre => ({ ...pre, [uniqueItemId]: false }))
  }

  const getDefaultExpiryStatus = () => {
    let defaultExpiryStatus = expiryStatuses.find(status=>status.value===createOrderItems[uniqueItemId]?.expiry_status)
    return defaultExpiryStatus
  }
  
  useEffect(() => {
    if (itemDetails?.config?.is_batching_enable) {
      dispatch(applyExpiryStatus({ uniqueItemId, value: createOrderItems[uniqueItemId]?.expiry_status ? getDefaultExpiryStatus().value : expiryStatuses[0].value }))
    }
  }, [])

  return (
    <tr>
      <td>
        <div className="trash-icon cursor-pointer">
          <Trash2
            className='trash-icon-enabled'
            onClick={handleDeleteSku}
          />
        </div>
      </td>
      <td className='col-sku'>
        <div className="d-flex gap-8px w-100 text-dark">
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: itemDetails.id,
              image: {
                src: itemDetails.image,
                alt: itemDetails.name
              },
              details: {
                name: itemDetails.name,
                skuCode: itemDetails.sku_code,
                columnKey: 'items_sku_details'
              },
              customTag: {
                isCustomTagPresent: itemDetails.type === SKU_TYPES.BUNDLE.key,
                className:'text-info bg-info-light',
                title: 'Kit'
              }
            }}
          />
        </div>
      </td>
      <td className='col-qty'>
        <div className='d-flex align-items-center gap-8px w-100'>
          <QuantityButton
            decreaseQuantity={decreaseQuantity}
            increaseQuantity={increaseQuantity}
            quantity={quantity}
            minQuantityEnabled={quantity <= 1}
            maxQuantityEnabled={false}
            onChange={onChange}
            disabled={itemDetails?.isWeighted}
            onKeyDown={onKeyDown}
          />
                
          <div className={classNames({'hide-visibility':!(itemDetails?.config?.is_batching_enable && isSelectBatchError[uniqueItemId])})}>
            <Info id='select-batch-error' size={16} color='var(--bs-danger)'/>
            <UncontrolledTooltip  target='select-batch-error' placement='right' innerClassName='fit' popperClassName='ms-6px text-start text-nowrap'>
              {t('Please specify the batches of updated quantity before creating order')}
            </UncontrolledTooltip>
          </div>
                
        </div>
      </td>

      {activeTab.id !== 2 && !hideBatch && <td className='col-expiry'>
        <div id={`infoText_${uniqueItemId}`} className='w-100'>
          <div className={classNames('batch', {'batch-disabled': !itemDetails?.config?.is_batching_enable})}>
            <FloatingDropDown
              isClearable={false}
              value={itemDetails?.config?.is_batching_enable ? getDefaultExpiryStatus() : null}
              options={expiryStatuses}
              filterOption={expiryStatusesfilterOption}
              onChange={handlOnChange}
              modifiedStyles={{option: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                fontSize: 12,
                color: state.isSelected ? 'var(--bs-white)' : state.data.value === CUSTOM_BATCH_SELECT ? 'var(--bs-primary)' : 'var(--bs-dark)',
              }),
              menuList: (base) => ({ ...base, zIndex: 9999 })
              }}
              placeholder={itemDetails?.config?.is_batching_enable ? t('Select Duration') : t('Not Applicable')}
              width='160px'
              disabled={!itemDetails?.config?.available_batch_count || itemDetails?.config?.available_batch_count <= 1}
            />
            {itemDetails?.config?.is_batching_enable &&
              isSelectBatchSideSheetOpen && 
              <>
                <SelectBatchSideSheet 
                  isOpen={isSelectBatchSideSheetOpen}
                  toggle={handleToggleSelectBatchSideSheet}
                  itemDetails={itemDetails}
                  uniqueItemId={uniqueItemId}
                  selectedSeller={selectedSeller}
                  selectedSkuBatches={selectedSkuBatches[uniqueItemId]}
                  handleIncreaseQtyOfSelectedBatch={handleIncreaseQtyOfSelectedBatch}
                  handleDecreaseQtyOfSelectedBatch={handleDecreaseQtyOfSelectedBatch}
                  handleChangeQtyOfSelectedBatch={handleChangeQtyOfSelectedBatch}
                  handleConfirmSelectedSideSheet = {handleConfirmSelectedSideSheet}
                />
                <BatchSelectWarningModal
                  isOpen={isSelectBatchWarningModalOpen}
                  toggle={() => setIsSelectBatchWarningModalOpen(pre => !pre)}
                  handleDiscardProcess={handleDiscardProcess}
                />
              </>
            }
          </div>
          {!itemDetails?.config?.is_batching_enable && (
            <UncontrolledTooltip placement="top" target={`infoText_${uniqueItemId}`}>
              {t('Only the SKUs which are part of a batch have an expiry status.')}
            </UncontrolledTooltip>
          )}
        </div>
      </td>}

      {!hideWeight && <td className='col-weight text-dark'>
        {itemDetails?.isWeighted 
          && <div className="weight-wrapper w-100">
            <input
              className="custom-input-wrapper text-end"
              value={weight}
              type='number'
              onWheel={(e) => e.target.blur()}
              onKeyDown={preventFormSubmitOnEnter}
              onChange={e => {
                e.preventDefault()
                const value = parseFloat(e.target.value) || 0
                if (isValidUptoTwoDecimal(value)) {
                  dispatch(updateCreateOrderItemWiseWeightValue({ uniqueItemId, value }))
                }
              }}
              onFocus={(e) => e.target.select()}
            />
            <span>{uom}</span>
          </div>
        }
      </td>}

      <td className='col-unit-price text-dark'>
        <input
          className="custom-input-wrapper"
          value={unitPrice}
          type='number'
          onWheel={(e) => e.target.blur()}
          onKeyDown={preventFormSubmitOnEnter}
          onFocus={(e) => e.target.select()}
          onChange={e => {
            e.preventDefault()
            const value = parseFloat(e.target.value) || 0
            if (isValidUptoTwoDecimal(value)) {
              dispatch(updateUnitPriceOfCreateOrderItem({ uniqueItemId, taxPercentage, value }))
            }
          }}
        />
      </td>
      <td className='col-unit-price text-dark'>
        <input
          className="custom-input-wrapper"
          value={taxPercentage}
          type='number'
          onWheel={(e) => e.target.blur()}
          onKeyDown={preventFormSubmitOnEnter}
          onFocus={(e) => e.target.select()}
          onChange={e => {
            e.preventDefault()
            const value = parseFloat(e.target.value) || 0
            if (isValidUptoTwoDecimal(value)) {
              dispatch(updateTaxPercentOfCreateOrderItem({ uniqueItemId, unitPrice, value }))
            }
          }}
        />
      </td>
      <td className='col-sub-total text-dark'>
        <div className="mrp">
          {commaSeparateInternational(taxAmount)}
        </div>
      </td>
      <td className='col-sub-total text-dark'>
        <div className="mrp">
          {itemDetails?.isWeighted
            ? !isNaN(weight) && !isNaN(unitPrice) && unitPrice > 0 && weight > 0 ? commaSeparateInternational(((weight * unitPrice) + taxAmount).toFixed(2)) : 0
            : !isNaN(quantity) && !isNaN(unitPrice) && unitPrice > 0 ? commaSeparateInternational(((quantity * unitPrice) + taxAmount).toFixed(2)) : 0
          }
        </div>
      </td>
      <td className='col-customer-note text-dark'>
        <input
          className="custom-input-wrapper"
          value={itemDetails.customer_note || ''}
          type='text'
          onWheel={(e) => e.target.blur()}
          onKeyDown={preventFormSubmitOnEnter}
          onFocus={(e) => e.target.select()}
          onChange={e => {
            e.preventDefault()
            dispatch(updateCreateOrderItemWiseNoteValue({ uniqueItemId, value:e.target.value }))
          }}
        />
      </td>
    </tr>
  )
}

export default ItemsTableRow 