import Button from '@src/@core/components/ui/button';
import SkuItemSkeleton from '@src/@core/components/ui/skeleton/sku-item';
import TransactionsSideSheet from '@src/views/sales/components/transactions-sidesheet';
import { TRANSACTION_HANDLING_TYPES } from '@src/views/sales/components/transactions-sidesheet/constants';
import CustomDropdownItem from '@src/views/sales/shipmentOrders/createShipmentOrder/components/CustomDropdownItem';
import { forwardRef } from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ItemsTable from '../../pages/createOrder/ItemsTable';
import FreeItemsInfoModal from '../free-items-info-modal/FreeItemsInfoModal';
import SalesChannelSyncImpactWarningModal from '../sales-channel-sync-impact-warning-modal';
import './index.scss';
import useEditOrderItems from './useEditOrderItems';

const EditOrderItems = forwardRef((props, ref) => {
  const { orderDetails, editOrderItems, setEditOrderItems } = props

  const editOrderItemsHook = useEditOrderItems({ orderDetails, setEditOrderItems, ref })
  const { 
    control,
    currentOrderTotal,
    errors,
    filteredSellerSkusForCreateOrder,
    handleAddItemFromDropdown,
    handleItemEditingEnable,
    handleOrderItemProcessing,
    handleSaveChanges,
    handleScroll,
    handleShowAddItemsDropdown,
    handleSubmit,
    hasGiftItem,
    inputRef,
    isFreeItemsInfoModalOpen,
    isSalesChannelSyncImpactWarningModalOpen,
    isSelectBatchError,
    isTransactionsSideSheetOpen,
    isUneditedSalesChannelOrder,
    loading,
    openTransactionsSideSheet,
    register,
    selectedSkuBatches,
    sellerSkusForCreateOrder,
    setIsFreeItemsInfoModalOpen,
    setIsSalesChannelSyncImpactWarningModalOpen,
    setIsSelectedBatchError,
    setIsTransactionsSideSheetOpen,
    setSearchParams,
    setSelectedSkuBatches,
    setShowAddItemsDropdown,
    shouldDisableSaveChanges,
    showAddItemsDropdown,
    t,
    totalCharges,
    watch,
  } = editOrderItemsHook

  return (
    <>
      {
        editOrderItems.isOpen ? (
          <div id='edit-order-items-parent-wrapper'>
            <ItemsTable
              register={register}
              control={control}
              inputRef={inputRef}
              handleShowAddItemsDropdown={handleShowAddItemsDropdown}
              selectedSeller={{ value: orderDetails.seller_id }}
              handleSubmit={handleSubmit}
              setSearchParams={setSearchParams}
              watch={watch}
              errors={errors}
              selectedSkuBatches={selectedSkuBatches}
              setSelectedSkuBatches={setSelectedSkuBatches}
              isSelectBatchError={isSelectBatchError}
              setIsSelectedBatchError={setIsSelectedBatchError}
              setIsCreateOrderButtonDisabled={() => { }}
              hideHeading={true}
              autoFocusOnTable={false}
              hubID={orderDetails.hub_id}
              expiryStatusesfilterOption={(option) => option.value !== 'custom'}
              isEditOrder={true}
              totalCharges={totalCharges}
            />
            <div className='cancel-save-btn'>
              <Button onClick={() => handleItemEditingEnable(false)} ofStyle='outlined'>
                {t('Cancel')}
              </Button>
              <Button onClick={openTransactionsSideSheet} disabled={shouldDisableSaveChanges} loading={loading.editCustomerAndShippingDetails}>
                {t('Save Changes')}
              </Button>
            </div>
            {showAddItemsDropdown && <Dropdown isOpen={showAddItemsDropdown} toggle={() => setShowAddItemsDropdown(false)} className='create-order-dropdown-button'>
              <DropdownToggle className='invisible'></DropdownToggle>
              {loading.getSellerSkusForCreateOrder && sellerSkusForCreateOrder.length === 0 ? (
                <SkuItemSkeleton />
              ) : (
                <>
                  {filteredSellerSkusForCreateOrder.length ? (
                    <DropdownMenu>
                      <PerfectScrollBar onYReachEnd={handleScroll}>
                        {filteredSellerSkusForCreateOrder.map((skuDetails) => {
                          return (
                            <div
                              key={skuDetails.sku.id}
                              id={`sku-${skuDetails.sku.id}`}
                              onClick={() => handleAddItemFromDropdown({ skuDetails })}
                            >
                              <CustomDropdownItem itemDetails={skuDetails} />
                            </div>
                          )
                        })}
                      </PerfectScrollBar>
                    </DropdownMenu>
                  ) : (
                    <DropdownMenu className='width-300'>
                      <DropdownItem className='sku-cards w-100'>
                        <div className='text-center'>{t('No record found !')}</div>
                      </DropdownItem>
                    </DropdownMenu>
                  )}
                </>
              )}
            </Dropdown>}
          </div>
        ) : null
      }
      <FreeItemsInfoModal 
        isOpen={isFreeItemsInfoModalOpen} 
        handleClose={() => setIsFreeItemsInfoModalOpen(false)} 
        handleContinue={() => {
          setIsFreeItemsInfoModalOpen(false);
          handleOrderItemProcessing();
        }} 
      />

      <SalesChannelSyncImpactWarningModal
        isOpen={isSalesChannelSyncImpactWarningModalOpen}
        handleClose={() => setIsSalesChannelSyncImpactWarningModalOpen(false)}
        handleContinue={() => {
          setIsSalesChannelSyncImpactWarningModalOpen(false);
          handleOrderItemProcessing();
        }}
        hasGiftItem={hasGiftItem}
      />

      {isTransactionsSideSheetOpen && <TransactionsSideSheet
        isOpen={isTransactionsSideSheetOpen}
        handleClose={() => setIsTransactionsSideSheetOpen(false)}
        handleContinue={handleSaveChanges}
        previousInvoice={{...orderDetails.invoice, payment_method: orderDetails.payment_method}}
        newInvoice={{
          // COD Fee is not included in the total
          total: currentOrderTotal,
          currency: watch('currency').value
        }}
        isSalesChannelOrder={isUneditedSalesChannelOrder}
        transactionHandlingType={TRANSACTION_HANDLING_TYPES.EDIT_ORDER_TRANSACTION}
      />}
    </>
  )
})

export default EditOrderItems
