import { useTranslation } from 'react-i18next'
import Button from '../../button'
import DatePicker from '../../new-date-picker'
import TimeSlotSelector from '../../time-slot-selector'
import { DROPDOWN_TYPES } from '../customTableFilterComponent.constant'
import AppliedFilter from './AppliedFilter'
import FilterAsyncDropDown from './FilterAsyncDropDown'
import FilterDropDown from './FilterDropDown'
import MultiAsyncFilterDropDown from './MultiAsyncDropdown'
import MultiFilterDropDown from './MultiFilerDropdown'

const componentMapping = {
  [DROPDOWN_TYPES.APPLIED_DROPDOWN]: AppliedFilter,
  [DROPDOWN_TYPES.ASYNC_DROPDOWN]: FilterAsyncDropDown,
  [DROPDOWN_TYPES.DROPDOWN]: FilterDropDown,
  [DROPDOWN_TYPES.MULTI_DROPDOWN]: MultiFilterDropDown,
  [DROPDOWN_TYPES.MULTI_ASYNC_DROPDOWN]: MultiAsyncFilterDropDown,
  [DROPDOWN_TYPES.DATE_DROPDOWN]: DatePicker,
  [DROPDOWN_TYPES.TIME_DROPDOWN]: TimeSlotSelector
}
const ComponentRenderer = ({selectedFilter, handleSetSelectedOption,appliedOptions, selectedOptions, setSelectedOptions, handleRemoveAppliedFilter, allFilters, handleReset, handleApplyfilter, defaultSelectedFilter}) => {
  const {t} = useTranslation()
  const ActiveComponent = componentMapping[selectedFilter.type]
  const handleCheckFilterActionButtonEnableOrDisable = () => {
    if (((selectedFilter.type === DROPDOWN_TYPES.MULTI_DROPDOWN || selectedFilter.type === DROPDOWN_TYPES.MULTI_ASYNC_DROPDOWN) 
      ? selectedOptions[selectedFilter.filterKey]?.length > 0 
      : selectedOptions[selectedFilter.filterKey])) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <div className="body-container">
        <ActiveComponent selectedFilter={selectedFilter} handleSetSelectedOption={handleSetSelectedOption} appliedOptions={appliedOptions}  selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} handleRemoveAppliedFilter={handleRemoveAppliedFilter} allFilters={allFilters}/>
      </div>
      <div className="footer-container">
        {selectedFilter.filterKey === defaultSelectedFilter.filterKey
          ? <Button 
            disabled={!allFilters.length} 
            type="button" className="reset-filter-btn" onClick={() => handleReset(selectedFilter.filterKey)} ofType="compact" ofStyle="outlined" outline={true}>
            {t('Clear Filters')}
          </Button>
          : <>
            <Button 
              disabled={!handleCheckFilterActionButtonEnableOrDisable()}
              type="button" className="reset-filter-btn" onClick={() => handleReset(selectedFilter.filterKey)} ofType="compact" ofStyle="outlined" outline={true}>
              {t('Reset')}
            </Button>
            <Button 
              // disabled={!handleCheckFilterActionButtonEnableOrDisable()}
              type="button" 
              className="apply-filter-btn" onClick={handleApplyfilter} ofType="compact" ofStyle="filled"
            >
              {t('Apply')}
            </Button>
          </>
        }
      </div>
    </>
  )
}

export default ComponentRenderer
