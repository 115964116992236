import { iCalculateOrderTotalParams } from './types';

export const calculateOrderTotal = ({ items, totalCharges = 0 }: iCalculateOrderTotalParams): number => {
  const itemsTotal = Object.values(items).reduce((acc, item) => {
    const { cutoff_price = 0, tax_amount = 0, isWeighted, skuWeight = {}, quantity = 0 } = item;
    const price = cutoff_price + tax_amount;
    const qty = isWeighted ? (skuWeight.value || 0) : quantity;
    return acc + (price * qty);
  }, 0);

  return itemsTotal + totalCharges;
};

export const handleOpenUrlInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const getExternalAttachmentsBannerText = () => {
  return 'To view and download external invoice attachments, open them in a new tab.';
};