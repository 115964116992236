import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { formattedLongDate } from '@src/utility/Utils'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'

const Batches = ({ batchData }) => {
  const { data } = batchData
  const { t } = useTranslation()

  const columns = [
    {
      id: 1,
      name: t('S.No.'),
      key: 'batches_serial_number',
      width: '60px',
      cell: (_, index) => <div className='text-center w-100'>{index + 1}</div>
    },
    {
      id: 2,
      name: t('Batch Number'),
      key: 'batches_batch_number',
      minWidth: '230px',
      cell: (row) => <div className='txt-sub-rg'>
        <p className='m-0'>{row.batch?.external_batch_id || '-'}</p>
        <p className='m-0 text-dark-6 txt-asst-rg'>{t('Expiry')}:{formattedLongDate(row.batch?.expiry_date)}</p>
      </div>
    },
    {
      id: 4,
      name: <div className="w-100 text-end">{t('Picked Qty')}</div>,
      key: 'batches_picked_qty',
      minWidth: '230px',
      cell: (row) => <div className='txt-sub-rg w-100 text-end'>
        {row.quantity}
      </div>
    }
  ]

  return <section className='p-24px'>
    <ExpandableCustomTable
      columns={columns}
      data={data || []}
      showPagination={false}
      showColumnsTableHeader={false}
      isTableFixedHeader={false}
    />
  </section>
}

const BatchesSidesheet = (props) => {
  const { batchesSidesheet, setBatchesSidesheet, batchData, setBatchData } = props

  return (
    <SideSheet
      isOpen={batchesSidesheet}
      toggle={() => setBatchesSidesheet(false)}
      onClosed={() => setBatchData({})}
      unmountOnClose={true}
      modalClassName="modal-slide-in sidebar-todo-modal"
      contentClassName="p-0 bg-white"
      size="sm"
      title='Batches'
    >
      <PerfectScrollbar>
        <Batches batchData={batchData} />
      </PerfectScrollbar>
    </SideSheet>
  )
}

export default BatchesSidesheet