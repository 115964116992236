
import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import { ENVIRONMENT_VARIABLES } from '@src/@core/layouts/components/navbar/constant.navbar'
import { ENVIRONMENT } from '@src/App.constants'
import { Generate } from '@src/assets/images/icons/Modal/GeneralIcons'
import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'
import Modal from '../modal/Modal'
import { MODAL_TYPES } from '../modal/modal.constant'
import './ModalQRCode.scss'

const QrcodeModal = ({ modalOpen, toggle, qrData}) => {
  const { t } = useTranslation()

  const environment = import.meta.env.VITE_ENVIRONMENT
  let envVar = ''
  if (environment === ENVIRONMENT.PRODUCTION) {
    envVar = ENVIRONMENT_VARIABLES.PRODUCTION
  } else if (environment === ENVIRONMENT.STAGING) {
    envVar = ENVIRONMENT_VARIABLES.STAGING
  } else {
    envVar = ENVIRONMENT_VARIABLES.DEVELOPMENT
  }
  const currentUrl = `https://apps.omniful.com/${qrData.appURL}/${envVar}`

  return (
    <Modal
      isOpen={modalOpen}
      closeModalHandler={toggle}
      contentClassName="barcode-print-modal-hubs"
      type={MODAL_TYPES.GENERAL}
      icon={Generate}
      title={t(`Download ${qrData.appName}`)}
      isTranslationRequired={false}
    >
      <ModalBody className="flex-center-center flex-column p-0" >
        <div className="generated-qr-code-box">
          <QRCodeSVG value={currentUrl} className="generated-qr-code" size={300} level='H'
            imageSettings={{
              src: `${qrData.icon}`,
              height: `${qrData.iconHeight}`,
              width: `${qrData.iconWidth}`,
              opacity: 1,
              excavate: true,
            }}/>
        </div>
        <p className="txt-body-md body-text">{t(`To download the ${qrData.appName} scan the QR code or open the link below on your ${qrData.availability} device`)}</p>
        <div className="flex-start download-copy-box">
          <p className="txt-body-md download-url m-0">{t('App Download URL')}</p>
          <div className="qr-copy-area flex-start">
            <CopyOnClick isCopyIconVisibleAlways={true} additionalClasses="qr-code" title={currentUrl}>{currentUrl}</CopyOnClick>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default QrcodeModal