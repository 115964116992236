import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import { INSURANCE_STATUS } from '@src/views/sales/sales.constant'
import { OrderDetailsRow } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { AccordionBody, AccordionHeader, AccordionItem, Card, UncontrolledAccordion } from 'reactstrap'
import './shipmentDetailsCard.scss'
const ShipmentDetailsCard = ({ orderDetails, trackingImage }) => {
  const { t } = useTranslation()

  const shipment = orderDetails.shipment

  const [hasRemarksExpanded, setRemarksExpanded] = useState(false);
  
  const RenderAwbNumber = () => {
    return (
      <div className="d-flex align-items-center gap-50">
        {orderDetails.shipment?.tracking_url && (
          <a href={orderDetails.shipment.tracking_url} target="_blank" rel="noopener noreferrer">
            <img src={trackingImage} alt="tracking-image" />
          </a>
        )}
        {orderDetails.shipment?.awb_number}
      </div>
    )
  }

  const RenderDeliveryStatus = () => {
    return (
      <div id="shipment-details-delivery-status-accordian">
        {orderDetails.shipment?.shipping_partner_status ? (
          <UncontrolledAccordion defaultOpen="delivery-status">
            <AccordionItem className="pt-0">
              <AccordionHeader targetId="delivery-status" className="pt-0">
                <CustomLabel title={orderDetails.shipment.display_delivery_status} className="order-status-tag" />
              </AccordionHeader>
              <AccordionBody accordionId="delivery-status">
                <div className="text-secondary">{orderDetails.shipment.shipping_partner_status}</div>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        ) : (
          <CustomLabel title={orderDetails.shipment?.display_delivery_status} className="order-status-tag" />
        )}
      </div>
    )
  }

  const RenderRemark = () => {
    return (
      <>
        <div
          className={classNames('remarksContentExpanded', {
            remarksContentCollapsed: (shipment.remarks.length > 63 && !hasRemarksExpanded)
          }
          )}
        >
          {shipment.remarks}
        </div>
        {shipment.remarks.length > 63 && <span>
          {hasRemarksExpanded
            ? <span
              className="flex-center-start gap-25 text-primary cursor-pointer"
              onClick={() => setRemarksExpanded(prev => !prev)}>
              {t('View Less')} <ChevronUp size={16} />
            </span>
            :
            <span className="flex-center-start gap-25 text-primary cursor-pointer"
              onClick={() => setRemarksExpanded(prev => !prev)}>
              {t('View More')} <ChevronDown size={16} />
            </span>
          }
        </span>}
      </>
    )
  }
  const shipmentDetailsData = [
    {
      id: 'shipping_account',
      entityName: t('Shipping Account'),
      entityValue: orderDetails.shipment.shipping_account.display_name,
      omit: !orderDetails.shipment.shipping_account.display_name
    },
    {
      id: 'suggested_account',
      entityName: t('Suggested Account'),
      entityValue: orderDetails.shipment.suggested_shipping_account.display_name,
      omit: !orderDetails.shipment.suggested_shipping_account.display_name
    },
    {
      id: 'courier_partner',
      entityName: t('Courier Partner'),
      entityValue: orderDetails.shipment.courier_partner.name,
      omit: !orderDetails.shipment.courier_partner.name || orderDetails.shipment.shipping_partner_tag === orderDetails.shipment.courier_partner.tag
    },
    {
      id: 'awb_number',
      entityName: t('AWB Number'),
      entityValue: <RenderAwbNumber/>,
      omit: !orderDetails.shipment.tracking_url && !orderDetails.shipment.awb_number
    },
    {
      id: 'noOfPackages',
      entityName: 'Number of Packages',
      entityValue: orderDetails.shipment.number_of_boxes,
      omit: !orderDetails.shipment.number_of_boxes
    },
    {
      id: 'weight',
      entityName: 'Weight',
      entityValue: orderDetails.shipment.display_weight,
      omit: !orderDetails.shipment.display_weight
    },
    {
      id: 'delivery_status',
      entityName: t('Delivery Status'),
      entityValue: <RenderDeliveryStatus/>,
      omit: !orderDetails.shipment.display_delivery_status && !orderDetails.shipment.shipping_partner_status
    },
    {
      id: 'remarks',
      entityName: 'Remarks',
      entityValue: <RenderRemark/>,
      omit: !shipment?.remarks
    },
    {
      id: 'insurance_opted',
      entityName: 'Insurance Status',
      entityValue: orderDetails.shipment.metadata?.has_insurance_opted ? INSURANCE_STATUS.INSURED : INSURANCE_STATUS.NOT_INSURED,
      omit: orderDetails.shipment.metadata?.has_insurance_opted === undefined
    }
  ]

  return (
    orderDetails?.shipment?.awb_number ?
      (
        <Card className="details-card bg-white w-100 rounded-4">
          <div className="card-header d-flex justify-content-between py-12px px-16px rounded-top-4 text-secondary txt-h3-md">
            <span>{t('Shipment Details')}</span>
          </div>
          <div className='d-flex flex-column p-16px gap-16px'>
            {shipmentDetailsData.map((row) => {
              if (row.omit) return null
              return (
                <OrderDetailsRow
                  key={row.id}
                  entityNameColSpan={4}
                  entityValueColSpan={8}
                  entityName={row.entityName}
                  entityValue={row.entityValue || '-'}
                />
              )
            })}
          </div>
        </Card>
      )
      : null
  )
}

export default ShipmentDetailsCard
