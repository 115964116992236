import Modal from '@src/@core/components/ui/modal/Modal';

const FreeItemsInfoModal = ({ isOpen, handleClose, handleContinue }) => {

  return (
    <Modal isOpen={isOpen}
      closeModalHandler={handleClose}
      title='Gift Item Notice'
      subTitle='This order includes item(s) from Gifting Automation Rules, which cannot be edited or shown during edits. If the applied automation rule is no longer satisfied post-edit, the item(s) will be removed.'
      icon='info'
      primaryButtonText='Continue'
      secondaryButtonText='Close'
      primaryButtonHandler={handleContinue}
      secondaryButtonHandler={handleClose}
    />
  );
};

export default FreeItemsInfoModal;
