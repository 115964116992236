import { FILE_TYPES } from '@src/App.constants'
import EditIcon from '@src/assets/images/icons/EditIcon'
import Inventory from '@src/assets/images/icons/primary-navbar/Inventory'
import { ROUTES_KEYS } from '@src/router/route-keys'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'
import { CheckCircle, Plus, Slash } from 'react-feather'

export const CC_SKU_EXPORT_INCLUDE_KEYS = {
  ALL: 'all',
  DISCREPANCIES: 'discrepancies',
  NON_DISCREPANCIES: 'non_discrepancies'
}

export const INVENTORY_CONSTANTS = {
  GRN: {
    VIEW: {
      id: 1,
      name: 'view',
      ITEM_DETAILS: { id: 1, name: 'item-details' },
      QC_DETAILS: { id: 2, name: 'qc-details' },
      ADD_BATCH: { id: 3, name: 'add-batch' },
      SCAN_EXISTING: { id: 4, name: 'scan-existing-bins' }
    },
    SERIALISED_OPTION: {
      id: 2,
      name: 'serialised-option',
      SERIALISED: { id: 1, name: 'serialised' },
      NON_SERIALISED: { id: 2, name: 'non-serialised' }
    },
    SCAN_OPTION: {
      id: 3,
      name: 'scan-option'
    }
  }
}

// GATE ENTRY
export const GATE_ENTRY_CREATE_TYPES = {
  PO_ENABLED: 'Purchase Order',
  PO_DISABLED: 'Purchase Order Disabled'
}
export const GATE_ENTRY_CREATE_VIEW = {
  CARTON_DETAILS: 'Carton Details',
  PRINT_BARCODES: 'PRINT_BARCODES'
}
//GRN
export const GRN_VIEW = {
  ITEM_DETAILS: 'item-details',
  QC_DETAILS: 'qc-details',
  ADD_BATCH: 'add-batch',
  SCAN_EXISTING: 'scan-existing-bins'
}
export const GRN_QC_TYPES = {
  INDIVIDUAL: 'Individual',
  BULK: 'Bulk'
}
export const CREATE_QC_BIN_ID_MODE = {
  PRINT: 'Print',
  SCAN: 'Scan'
}
export const QC_STATUS = {
  PASS: 'pass',
  FAIL: 'fail'
}
// GRN Dated: 29th April
export const GRN_STEPS = {
  IDENTIFICATION: 'Identification',
  PRINT_BARCODES: 'Print Barcode',
  QUALITY_CHECK: 'Quality Check'
}
export const GRN_BIN = {
  PASS: 'pass',
  FAIL: 'fail'
}
export const HUB_SKU_SERIALIZATION_STATUS = {
  SERIALISED: 'serialized',
  NON_SERIALISED: 'non-serialized',
  SCAN: 'scan'
}

export const QC_FAIL_REASON = [
  { id: 1, value: 'Damaged item', label: 'Damaged item' },
  { id: 2, value: 'Expired Product', label: 'Expired Product' },
  { id: 3, value: 'Packaging Damaged', label: 'Packaging Damaged' },
  { id: 4, value: 'Unidentified object is inside packaging', label: 'Unidentified object is inside packaging' }
]
export const SERIALISATION_CONFIG = {
  SERIALISED: 'serialised',
  NON_SERIALISED: 'non_serialised',
  UNDEFINED: 'undefined'
}
export const BATCH_CONFIG = {
  IN_BATCH: 'batched',
  NOT_IN_BATCH: 'not_batched',
  UNDEFINED: 'undefined'
}
export const GRN_ITEMS_VIEW_TABS = isPutawayVisible => ({
  OVERVIEW: 'Overview',
  PUTAWAY_DETAILS: isPutawayVisible ? 'Putaway Details' : undefined
})
// PUTAWAY
export const PUTAWAY_TYPES = {
  ITEM_PUTAWAY: 'Item Putaway',
  BIN_PUTAWAY: 'Bin Putaway'
}
export const INVENTORY_ROUTES = {
  TOTAL_INVENTORY: ROUTES_MAPPING[ROUTES_KEYS.TOTAL_INVENTORY],
  LOCATION_INVENTORY: ROUTES_MAPPING[ROUTES_KEYS.LOCATION_INVENTORY],
  SKU_LOCATIONS: ROUTES_MAPPING[ROUTES_KEYS.SKU_LOCATIONS],
  BATCHES: ROUTES_MAPPING[ROUTES_KEYS.INVENTORY_BATCHES],
  GATE_ENTRY: ROUTES_MAPPING[ROUTES_KEYS.GATE_ENTRY],
  GRN: ROUTES_MAPPING[ROUTES_KEYS.GRN],
  PUTAWAY: ROUTES_MAPPING[ROUTES_KEYS.PUTAWAY],
  PENDING_PUTAWAY:  ROUTES_MAPPING[ROUTES_KEYS.PENDING_PUTAWAY],
  CYCLE_COUNT: ROUTES_MAPPING[ROUTES_KEYS.CYCLE_COUNT],
  CYCLE_COUNT_DETAILS: ROUTES_MAPPING[ROUTES_KEYS.CYCLE_COUNT_DETAILS],
  // ASSEMBLY: '/inventory/inventory-operations/assembly',
  // DEMAND_FORECASTING: '/inventory/inventory-reports/demand-forecasting',
  AD_HOC_USAGE: ROUTES_MAPPING[ROUTES_KEYS.AD_HOC_USAGE],
  STOCK_TRANSFER: ROUTES_MAPPING[ROUTES_KEYS.STOCK_TRANSFER],
  EXPIRED_INVENTORY_TASK:  ROUTES_MAPPING[ROUTES_KEYS.EXPIRED_INVENTORY_TASK]
}

export const INVENTORY_ENUMS = {
  BULK_UPLOAD_SKUS: 'BulkUploadSKUs',
  SKUS_MAPPING: 'SkusMapping'
}

export const BARCODE_PRINTING = {
  BIN: 'Bin Barcode',
  SKU: 'SKU'
}

export const PENDING_PUTAWAY_TABS = {
  CANCELLED_ITEM_PUTAWAY: { id: 1, name: 'Cancelled Item Putaway', title: 'Pending Putaway' },
  GRN_PENDING_PUTAWAY: { id: 2, name: 'GRN Pending Putaway', title: 'Pending Putaway' },
  ADJUST_INVENTORY: { id: 3, name: 'Adjust Inventory', title: 'Pending Putaway' }
}

export const BIN_TYPE_FILTER = {
  ALL: { id: 1, name: 'All' },
  PASS_BIN: { id: 2, name: 'Pass Bin' },
  FAIL_BIN: { id: 3, name: 'Fail Bin' }
}

export const INVENTORY_SAMPLE_SHEETS = {
  BULK_UPLOAD_SKUS_INVENTORY: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/hub_inventory/hub_inventory_sample.csv',
  SKUS_LOCATION_MAPPING: 'https://omniful-uploads-dev.s3.eu-central-1.amazonaws.com/tenant-onboarding/Location+Sku+Mapping+Sample+Sheet+-+Sheet1+(1).csv',
  BATCH_UPLOAD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/batch/Upload+Batch+-+Sheet1+(1).csv',
  BINS_BULK_UPLOAD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/bins/Sample+Bin+Upload+Sheet+-+Sheet1.csv',
  SAFETY_STOCK_BULK_UPLOAD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/hub_inventory/Safety+Stock+Update+-+Sheet1.csv',
  CYCLE_COUNT_BULK_UPLOAD: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/wms/cycle_count/cycle_count_locations.csv',
  PALLETS_BULK_UPLOAD: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/wms/bins/bulk/Pallet+Upload.csv',
  CUSTOMER_BULK_UPLOAD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/orders/customers/sampleBulkCustomer.csv',
  FOERCAST_BULK_UPLOAD: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/analytics/sample/demand_forecasting_sample.csv',
  REORDER_POINT: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/hub_inventory/reorder+point.csv',
  BULK_UPLOAD_EXPIRY_THRESHOLD: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/hub_inventory/near+expiry+threshold.csv',
  BULK_UPLOAD_PO: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/wms/purchase_order/bulk/Multiple+PO+Creation.csv',
  UPLOAD_MAX_SHELF_LIFE:'https://omniful-uploads-dev.s3.eu-central-1.amazonaws.com/tenant-onboarding/max_shelf_life+(1).csv'
}

export const SKU_MASTER_ENUMS = {
  ADD_VARIATION_DETAIL: 'AddVariationDetail',
  SEARCH_PRODUCT_ADD_DETAIL: 'SearchProductAddDetail',
  SHOW_UPLOAD_PRODUCT: 'ShowUploadProduct',
  SHOW_CREATE_BUNDLE: 'ShowCreateBundle',
  SHOW_BATCH_UPLOAD: 'ShowBatchUpload',
  SHOW_UPLOAD_EXPIRY_THRESHOLD: 'ShowUploadExpiryThreshold',
  SHOW_UPLOAD_MAX_SHELF_LIFE: 'ShowUploadMaxShelfLife',
}

export const GRN_TYPES = {
  STANDARD: 'standard',
  RETURN: 'return',
  BIN_DISABLED:'bin_disabled'
}

export const CYCLE_COUNT_DISCREPANCIES = {
  INVENTORY_MISMATCH: 'Inventory Mismatch',
  BIN_MISSING: 'Bin Missing'
}

export const ACTION_TYPES = {
  INDIVIDUAL: 'Individual',
  BULK: 'Bulk'
}

export const CYCLE_COUNT_LOCATION_STATUS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COUNTING: 'Counting',
  COUNTED: 'Counted',
  INVENTORY_UPDATE: 'Inventory Updated',
  RECOUNT_PENDING: 'Recount Pending',
  RECOUNT_IN_PROGRESS: 'Recount In Progress',
  RECOUNT_COUNTED: 'Recount Counted',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  COMPLETED: 'Completed',
  CANCELLED:  'Cancelled'
}

export const ENTITY_TYPES = {
  PO: 'po',
  PO_SHIPMENT: 'po_shipment',
  STO: 'sto'
}

export const SKU_LOCATION_TYPE = {
  IMPORT_AND_ADD_SKUS: 'Add SKUs',
  IMPORT_AND_REMOVE_SKUS: 'Remove SKUs'
}

export const BULK_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove'
}

export const CYCLE_COUNT_LOCATIONS_LIMIT = 1000

export const CYCLE_COUNT_LOCATION_OPERATIONS = {
  UPDATE: 'update',
  DELETE: 'delete',
  RECOUNT: 'recount',
  REJECTED: 'rejected'
}

export const GRN_PUTAWAY_DETAILS_TYPE = [
  { value: 'item_putaway', label: 'Item Putaway'},
  { value: 'bin_putaway', label: 'Bin Putaway'}
]

export const SKU_TYPE = {
  EACH: { label: 'Each', value: 'ea' },
  CASE_PACK: { label: 'Case Pack', value: 'case_pack' },
  PALLET: { label: 'Pallet', value: 'pallet' }
}

export const GRN_CONTAINER_TYPES = {
  BIN: 'bin',
  PALLET: 'pallet'
}
export const GRN_CODE_TYPE = {
  BARCODE: 'barcode',
  QR_CODE: 'qr-code'
}

export const LIVE_ORDER_TYPES = {
  STO: 'STO',
  B2C: 'B2C'
}

export const DATE_FILTER_KEY = {
  CREATED: 'created'
}

export const STOCK_TRANSFER_STATUS = {
  ORDERED: 'Ordered',
  ARRIVED: 'Arrived',
  PARTIAL_RECEIVED: 'Partially Recieved',
  RECEIVED: 'Recieved',
  CLOSED: 'Closed',
  ARRIVING: 'Arriving',
  CANCELLED: 'Cancelled'
}

export const STOCK_TRANSFER_LOGS_ACTIONS = {
  CREATE: {value: 'create', id: 1, icon: Plus},
  EDIT: {value: 'edit', id: 2, icon: EditIcon},
  ACCEPT: {value: 'recieved', id: 3, icon: CheckCircle},
  CLOSE: {value: 'closed', id: 4, icon: Slash}
}

export const GE_OPTIONS = {
  PO: 'po',
  STO: 'sto',
  SELLER: 'seller'
}

export const GET_SELECTED_SELLER = (setValue, sellerData, selectedSeller, firstTimeSelectedSeller) => {
  const getseller = sellerData ? { label: sellerData.label, value: sellerData.value } : selectedSeller || firstTimeSelectedSeller 
  setValue('seller', getseller)  
}

export const SELLER_INPUT_FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  TYPE: 'type',
  CODE: 'code',
  CONTACT_DETAILS: 'contactDetails',
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  POSTCODE: 'postcode',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  IS_ACTIVE: 'is_active'
}

export const REASON_TYPE = {
  GRN_ITEMS: 'grn_items'
}

export const SORT_TYPE = {
  ASC: 'name_asc'
}

export const PO_STO_STATUS = {
  ORDERED: 'ordered',
  PARTIAL_RECEIVED: 'partial_received',
  RECEIVED: 'received',
  CANCELLED: 'cancelled',
  //Adding this wrong spelling due to BE mapping, will remove after BE is updated
  RECIEVED: 'recieved'
}

export const INVENTORY_SEARCH_COLUMN_KEYS = {
  PURCHASE_ORDER_ID: 'purchase_order_id',
  GATE_ENTRY_ID: 'gate_entry_id',
  STOCK_TRANSFER_ORDER_ID : 'stock_transfer_order_id',
  EXTERNAL_BATCH_ID: 'external_batch_id'
}

export const LOCATION_INVENTORY_CONSTANTS = {
  LOCATION_ASC: 'locations_asc',
  LOCATION_DESC: 'locations_desc',
  SELLER_FILTER_KEY: 'seller_id',
  SELLER_TITLE: 'Seller Name',
  TABLE_COLUMN_NAME: 'location_inventory',
  SEARCH_FIELDS: [
    { id: 'seller_sku_code', name: 'SKU Code' },
    { id: 'sku_name', name: 'SKU Name' },
    { id: 'sku_barcode', name: 'SKU Barcode' },
    { id: 'location_barcode', name: 'Location Barcode' }
  ],
  PAGE_HEADER_PROPS: {
    breadcrumbIcon: Inventory,
    title: 'Location Inventory',
    breadcrumbsData: [{ title: 'Inventory' }, { title: 'Inventory Overview' }]
  },
  EXPIRED: 'Expired',
  NEAR_EXPIRY: 'Near Expiry',
  COLUMNS: {
    SERIAL_NUMBER: { id: 1, name: 'S. No.', key: 'serial_number', width: '60px', reorder: false },
    SKU_DETAILS: { id: 2, name: 'SKU Details', key: 'sku_details', width: '250px', reorder: false },
    SELLER_NAME: { id: 3, name: 'Seller Name', key: 'seller_name', width: '200px', reorder: false },
    SKU_BARCODE: { id: 4, name: 'SKU Barcode', key: 'sku_barcodes', width: '200px', reorder: false },
    LOCATION_BARCODE: { id: 5, name: 'Location Barcode', key: 'location_barcode', width: '200px', reorder: true },
    BIN_BARCODE: { id: 6, name: 'Bin/Pallet Barcode', key: 'bin_barcode', width: '200px', reorder: true },
    BATCH_DETAILS: { id: 7, name: 'Batch Details', key: 'batch_details', width: '150px', reorder: true },
    TOTAL_ITEMS: { id: 8, name: 'Total Items', key: 'total_items', width: '150px', reorder: true },
    PASS_ITEMS: { id: 9, name: 'Pass Qty', key: 'pass_items', width: '150px', reorder: true },
    EXPIRED_QTY: { id: 10, name: 'Expired Qty', key: 'expired_qty', width: '150px', reorder: true },
    FAIL_ITEMS: { id: 11, name: 'Fail Qty', key: 'fail_items', width: '150px', reorder: true }
  }
}

export const SKU_LOCATIONS_CONSTANTS = {
  PAGE_HEADER_PROPS: {
    breadcrumbIcon: Inventory,
    title: 'SKU Locations',
    breadcrumbsData: [{ title: 'Inventory' }, { title: 'Inventory Overview' }]
  },
  SEARCH_FIELDS: [
    { id: 1, name: 'SKU Name', value: 'name' },
    { id: 2, name: 'SKU Code', value: 'seller_sku_code' },
    { id: 3, name: 'Location Name', value: 'location_name' }
  ],
  BULK_ACTIONS: {
    ADD_SKUS: {label: 'Add SKUs', value: 'add'},
    REMOVE_SKUS: {label: 'Remove SKUs', value: 'remove'}
  },
  SELLER_FILTER_KEY: 'seller_id',
  SELLER_TITLE: 'Sellers',
  BANNER: { text: 'Some SKUs are not mapped to any location', actionText: 'See Details' },
  COLUMNS: {
    SERIAL_NUMBER: {id:1, name: 'S. No.', key: 'serial_number', width: '60px', reorder: false},
    SKU_DETAILS: {id:2, name: 'SKU Details', key: 'sku_details', width: '250px', reorder: false},
    SELLER_NAME: {id:3, name: 'Seller Name', key: 'seller_name', width: '200px', reorder: true},
    LOCATION: {id:4, name: 'Location', key: 'location', width: '200px', reorder: true}
  },
  UNMAPPED_SKUS: {
    TITLE: 'Unmapped SKUs',
    COLUMNS: {
      SERIAL_NUMBER: {id: 1, name: 'S. No.', key: 'serial_number', width: '60px', reorder: false},
      SKU_DETAILS: {id: 2, name: 'SKU Details', key: 'sku_details', width: '300px', reorder: false},
      SELLER_NAME: {id: 4, name: 'Seller Name', key: 'seller_name', width: '100px', reorder: false}
    },
    SEARCH_FIELDS: [
      { id: 'name', name: 'SKU Name' },
      { id: 'seller_sku_code', name: 'SKU Code' }
    ]
  },
  BULK_SKU_MAPPING: {
    sheetUrl: INVENTORY_SAMPLE_SHEETS.SKUS_LOCATION_MAPPING,
    title: '(Acceptable file format is .csv).',
    fileFormat: { [FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions },
    SELLER_TITLE: 'Select Seller',
    SELLER_KEY:'seller',
    PRIMARY_CTA: 'Save',
    SECONDARY_CTA: 'Cancel',
    SERVICE: 'wms',
    USECASE: 'bulk-sku-mapping'
  }
}
export const EXPIRED_INVENTORY_DETAILS_TABS = {
  SKU_DETAILS: { key:'SKU DETAILS',name:'SKU Details'},
  LOCATION_DETAILS: { key:'LOCATION DETAILS',name:'Location Details'}
}

export const BATCH_DETAILS_KEYS = {
  TASK_ID: 'Task ID',
  EXPIRED_QUANTITY: 'Expired Quantity',
  CREATED_ON: 'Created on',
  DISPOSED_QUANTITY: 'Disposed Quantity',
  TOTAL_BATCHES: 'Total Batches',
  FAILED_QUANTITY: 'Failed Quantity',
  TOTAL_SKUS: 'Total SKUs',
  USERS: 'Users'
}
export const SEARCH_COLUMN = {
  SKU_NAME: { id: 'name', name: 'SKU Name' },
  SKU_CODE: { id: 'code', name: 'SKU Code' },
};

export const SEARCH_COLUMN_LIST = [{ id: 'name', name: 'SKU Name' }, { id: 'code', name: 'SKU Code' }, { id: 'bin_barcode', name: 'Bin Barcode' }, { id: 'location_barcode', name: 'Location' }];

export const EXPIRED_INVENTORY_STATUS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed'
}
export const EXPIRED_INVENTORY_EXPIRED= {
  EXPIRED: 'Expired',
  NEAR_EXPIRY: 'Near Expiry'
}

export const PRINT_PAGE_STYLE = `
  @media print {
    body {
      height: fit-content !important;
    }
    .sku-details-container,.batch-details-container{
      display: flex;
      flex-direction: column;
      justify-content: left;
      gap: 2px;
      align-items: left;
      border-top: 1px solid #DEDEDE;
      padding: 6px 0;
      text-align: left;
      width: 100%;
    }
    .sku-name-container {
      width: 100%;
      line-height: 0;
    }
    .sku-name-text {
      max-width: 100%;
      word-break: break-all;
      white-space: normal;
      overflow-wrap: break-word;
      display: inline-block;
    }
  }
`

export const SERIALISED_SKU_BARCODE_PRINT_MAX_LIMIT = 500 

export const GLN_API_KEY = {
  CREATE: 'gln-create'
}
export const BIN_TYPE_OPTIONS = [
  { value: 'pass', label: 'Pass' },
  { value: 'fail', label: 'Fail' }
]
