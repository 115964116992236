import InputField from '@src/@core/components/ui/input-field'
import NewCheckbox from '@src/@core/components/ui/new-checkbox'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import Select from '@src/@core/components/ui/select'
import { STATUS_TYPES } from '@src/App.constants'
import { GET_CURRENCIES } from '@src/redux/authentication'
import { commaSeparateInternational, debounceAction } from '@src/utility/Utils'
import { disableEnterKeyEvent, isValidUptoTwoDecimal } from '@src/views/sales/sales.utils'
import { clearSellerSkusForCreateOrder, getSellerSkusForCreateOrder, updateCreatedOrderTotalWeight } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { AlertCircle, Plus } from 'react-feather'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'reactstrap'
import ItemsTableRow from './ItemsTableRow'
import './ItemTable.scss'
import { calculateOrderTotal } from './utils'


const ItemsTable = ({ register, handleShowAddItemsDropdown, inputRef, handleTotalCost, selectedSeller, hubID, setSearchParams, control, watch, errors, setIsCreateOrderButtonDisabled, selectedSkuBatches, setSelectedSkuBatches, isSelectBatchError, setIsSelectedBatchError, activeTab = 1, showTotal = true, hideBatch = false, hideWeight = false, hideHeading = false, autoFocusOnTable = true, expiryStatusesfilterOption, isEditOrder = false, totalCharges = 0, skuType }) => {
  const createOrderItems = useSelector(state => state.sales.createOrderItems)
  const createdOrderTotalWeight = useSelector(state => state.sales.createdOrderTotalWeight)
  const [ItemTableData, setItemTableData] = useState(createOrderItems)

  const [skuColumn, setSkuColumn] = useState(
    [
      {name: 'SKU Name', key: 'name', value: true},
      {name: 'SKU Barcode', key: 'barcodes', value: true},
      {name: 'Seller SKU Code', key: 'seller_sku_code', value: true}
    ]
  )

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (Object.values(ItemTableData) !== Object.values(createOrderItems)) setItemTableData(createOrderItems)
  }, [createOrderItems, inputRef.current])

  const orderTotal = useMemo(() => calculateOrderTotal({ items: ItemTableData, totalCharges }), [ItemTableData, totalCharges])

  const paramsArray = skuColumn.filter(val => val.value).map(item => item.key)
  // in case of RTS order, kits should not be included
  const params = { search_columns: paramsArray, type: skuType }

  const debouncedDispatch = debounceAction(payload => dispatch(getSellerSkusForCreateOrder({ status: STATUS_TYPES.ACTIVE.value, hubID, sku_name: payload, seller_id: selectedSeller?.value, ...params })), 500)
    
  const createOrderButtonState = useMemo(() => {
    return (Object.values(ItemTableData).some(
      (item) => (item.isWeighted && (!item.skuWeight?.value || !createdOrderTotalWeight))
    ))
  }, [ItemTableData, createdOrderTotalWeight])

  useEffect(() => {
    setIsCreateOrderButtonDisabled(createOrderButtonState)
  }, [createOrderButtonState])

  const customStyles = {
    control: provided => ({
      ...provided,
      borderColor: '#d8d6de',
      height: '32px',
      boxShadow: 'none',
      fontSize: '12px',
      '&:hover': {
        borderColor: '#5468FA',
        cursor: 'pointer'
      }
    }),
    valueContainer: provided => ({
      ...provided,
      overflow: 'visible',
      width: '100%'
    }),
    option: provided => ({
      ...provided,
      cursor: 'pointer',
      fontSize: '12px'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      top: state.hasValue || state.selectProps.inputValue ? '-50%' : '15%',
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
      backgroundColor: state.isDisabled ? 'transparent' : 'white'
    }),
    clearIndicator: provided => ({
      ...provided,
      paddingRight: 0
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: 'lightgray',
      paddingLeft: 0,
      width: '20px',
      '&:hover': {
        color: 'gray'
      }
    }),
    indicatorContainer: provided => ({
      ...provided,
      padding: '2px',
      border: '1px solid red !important',
      backgroundColor: 'red'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    })
  }

  const handleSkuFilter = (e) => {
    inputRef.current.value = ''
    inputRef.current.focus()
    dispatch(clearSellerSkusForCreateOrder())
    const updatedData = skuColumn.map(val => (val.key === e.target.name ? { ...val, value: !val.value } : val)
    )
    setSkuColumn(updatedData)
  }
  const skuSearchCount = skuColumn.filter((item) => item.value).length
  return (
    <div className="items-table-create-manual-order">
      <div className={classNames('mb-16px', {'ms-12px':!hideHeading})}>
        {!hideHeading && <p className="txt-h3-md text-dark mb-16px">{t('Add Order Items')}</p>}
        <div className="flex-center-start">
          <p className="m-0 me-12px txt-body-rg text-dark-5">{t('Search results shown by')}: </p>
          <div className="d-flex">
            {skuColumn.map((item) => (
              <NewCheckbox
                id={item.key}
                key={item.key}
                label={item.name}
                name={item.key}
                onChange={handleSkuFilter}
                register={register}
                defaultChecked={item.value}
                disabled={skuSearchCount === 1 && item.value}
                isRequired={false}
              />
            ))}
          </div>
        </div>
      </div>
      <Table borderless responsive>
        <thead>
          <tr>
            <th className="table-header trash-icon-column"></th>
            <th className='table-header col-sku txt-sub-md text-dark'>{t('SKU Details')}</th>
            <th className='table-header col-qty txt-sub-md text-dark'>
              <div className='d-flex align-items-center gap-8px'>
                <div className='w-100'>{t('Ordered Qty')}</div>
                <span className='hide-visibility'><AlertCircle size={16} color='var(--bs-danger)' /></span>
              </div>
            </th>
            {activeTab.id !== 2 && !hideBatch && <th className='table-header col-expiry txt-sub-md text-dark'>{t('Expiry Status')}</th>}
            {!hideWeight && <th className='table-header col-weight txt-sub-md text-dark'>
              <div className='d-flex align-items-center gap-8px'>
                <div className='w-100'>{t('Total Weight')}</div>
                <span className='hide-visibility'>kg</span>
              </div>
            </th>}
            <th className='table-header col-unit-price txt-sub-md text-dark'>{t('Unit Price (Tax excl.)')}</th>
            <th className='table-header col-unit-price txt-sub-md text-dark'>{t('Tax Percentage')}</th>
            <th className='table-header col-sub-total txt-sub-md text-dark'>{t('Tax Amount')}</th>
            <th className='table-header col-sub-total txt-sub-md text-dark'>{t('Item Total')}</th>
            <th className='table-header col-customer-note txt-sub-md text-dark'>{t('Customer Note')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(ItemTableData).map((item) => (
            <ItemsTableRow
              // seller_sku_id={item.seller_sku_id}
              key={item.uniqueItemId}
              itemDetails={item}
              handleTotalCost={handleTotalCost}
              selectedSeller={selectedSeller}
              orderTotal={orderTotal}
              customStyles={customStyles}
              selectedSkuBatches={selectedSkuBatches}
              setSelectedSkuBatches={setSelectedSkuBatches}
              isSelectBatchError = {isSelectBatchError}
              setIsSelectedBatchError={setIsSelectedBatchError}
              activeTab={activeTab}
              hideBatch={hideBatch}
              hideWeight={hideWeight}
              uniqueItemId={item.uniqueItemId}
              createOrderItems={createOrderItems}
              expiryStatusesfilterOption={expiryStatusesfilterOption}
            />
          ))}

          <tr>
            <td>
              <div className="trash-icon d-none"></div>
            </td>
            <td className='col-sku'>
              <div className="d-flex w-100">
                {/* <img className="" src={''} alt="" height="48px" width="48px" /> */}
                <div
                  style={{ height: '48px', width: '48px', border: '2px dotted var(--bs-primary)', borderRadius: '4px' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="h-100 w-100 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      inputRef.current.focus()
                    }}
                  >
                    <Plus color= 'var(--bs-primary)' />
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center input-container">
                  <input
                    ref={inputRef}
                    autoFocus={autoFocusOnTable ? activeTab.id !== 2 : autoFocusOnTable}
                    placeholder={t('Search for SKU')}
                    className="borderless-input" 
                    style={{border: '0px'}}
                    onKeyUp={e => {
                      const value = e.target.value.trim()
                      if (value) {
                        dispatch(clearSellerSkusForCreateOrder())
                        debouncedDispatch(value)
                        setSearchParams({sku_name : value})
                      }
                    }}
                    onChange={e => {
                      if (e.target.value) {
                        handleShowAddItemsDropdown(true)
                      } else {
                        dispatch(clearSellerSkusForCreateOrder())
                        handleShowAddItemsDropdown(false)
                      }
                    }}
                    onKeyDown={disableEnterKeyEvent}
                  />
                </div>
              </div>
            </td>
            <td className='col-qty'>
              <div className='d-flex align-items-center gap-8px w-100'>
                <QuantityButton quantity={' '} />
                <div className="hide-visibility">
                  <AlertCircle size={16} color='var(--bs-danger)' />
                </div>
              </div>
            </td>
            {activeTab.id !== 2 && !hideBatch && <td className='col-expiry'>
              <div className="w-100"></div>
            </td>}
            {!hideWeight && <td className='col-weight'>
              <div className="w-100"></div>
            </td>}
            <td className='col-unit-price'>
              <div className="w-100"></div>
            </td>
            <td className='col-unit-price'>
              <div className="w-100"></div>
            </td>
            <td className='col-sub-total'>
              <div className="w-100"></div>
            </td>
            <td className='col-sub-total'>
              <div className="w-100"></div>
            </td>
            <td className='col-sub-total'>
              <div className="w-100"></div>
            </td>
          </tr>
        </tbody>
      </Table>
      {!hideHeading && <hr/>}
      {showTotal && <><div className='order-total mt-1'>
        <div className='row-wrapper'>
          <div className='first-column'>
            <h3 className='m-0 txt-h3-md text-dark-6'>{t('Total Weight')}</h3>
          </div>
          <div className='second-column flex-stretch-center'>
            <InputField
              className="text-end fw-bold"
              value={createdOrderTotalWeight}
              type='number'
              onWheel={(e) => e.target.blur()}
              onFocus={(e) => e.target.select()}
              onChange={e => {
                e.preventDefault()
                const value = parseFloat(e.target.value) || 0
                if (isValidUptoTwoDecimal(value)) {
                  dispatch(updateCreatedOrderTotalWeight(value))
                }
              }}
              disabled={Object.values(createOrderItems).length === 0}
            />
            <div className='weight-input-wrapper'>
              <InputField
                width="100%"
                value="Kg"
                disabled
              />
            </div>
          </div>
        </div>
        <div className='row-wrapper'>
          <div className='first-column'>
            <h3 className='m-0 txt-h3-md text-dark-6'>{t('Order Total')}</h3>
          </div>
          <div className='second-column'>
            <div className='txt-h2-md text-dark'>{orderTotal > 0 ? commaSeparateInternational((orderTotal).toFixed(2)) : 0}</div>
            <div className='currency-input-wrapper'>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isAsync
                    errors={errors}
                    loadOptions={GET_CURRENCIES}
                    value={field.value}
                    defaultValue={field.value}
                    isLoading={false}
                    isDisabled={isEditOrder}
                    additional={{
                      page: 1
                    }}
                    styles={{
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: '180px',
                        overflowY: 'auto'
                      })
                    }}
                  />
                )}
              />
            </div>
                            
          </div>
        </div>
      </div>
      <hr className='my-24px'/>
      </>}
    </div>
  )
}

export default ItemsTable
