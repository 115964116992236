import { RETURN_STATUS } from '@src/views/returns/returns.constants'
import { markOrdersAsReturnedTohHub } from '@src/views/returns/store'
import { resetSuccess } from '@src/views/sales/store/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useReturnOrderDetails = ({ returnOrderDetail, handleReturnOrderDetailModal, handleCreateReverseShipmentModal, fetchParentData }) => {
  const dispatch = useDispatch()
  const [cancelShipmentModal, setCancelShipmentModal] = useState(false)
  const [isMarkAsRefundOpen, setIsMarkAsRefundOpen] = useState(false)

  const success = useSelector((state) => state.sales.success)
  const reverseShipmentDetail = useSelector((state) => state.returns.reverseShipmentDetail)

  const handleMarkOrderArrivedAtHub = () => {
    const body = {
      is_reverse: true,
      shipment_updates: [
        {
          order_id: returnOrderDetail.id,
          awb_number: returnOrderDetail.shipment.awb_number,
          shipping_partner_tag: returnOrderDetail.shipment.shipping_partner_tag,
        },
      ],
      status: RETURN_STATUS.REACHED_AT_HUB.key,
    }
    dispatch(markOrdersAsReturnedTohHub({ body }))
  }

  const openCancelShipmentModal = () => setCancelShipmentModal(true)
  const openMarksAsRefundSideSheet = () => setIsMarkAsRefundOpen(true)

  const openCreateReverseShipmentModal = () => {
    handleReturnOrderDetailModal()
    handleCreateReverseShipmentModal({ order: returnOrderDetail })
  }

  useEffect(() => {
    if (reverseShipmentDetail) {
      handleReturnOrderDetailModal()
      handleCreateReverseShipmentModal({ order: returnOrderDetail })
    }
  }, [reverseShipmentDetail])

  useEffect(() => {
    if (success.cancelShipment) {
      setCancelShipmentModal(false)
      handleReturnOrderDetailModal()
      fetchParentData()
      dispatch(resetSuccess())
    }
  }, [success.cancelShipment])

  return {
    cancelShipmentModal,
    setCancelShipmentModal,
    isMarkAsRefundOpen,
    setIsMarkAsRefundOpen,
    handleMarkOrderArrivedAtHub,
    openCancelShipmentModal,
    openMarksAsRefundSideSheet,
    openCreateReverseShipmentModal,
  }
} 