// import barcode from '@src/assets/images/svg/barcode.svg';
import Vector from '@src/assets/images/catalog/bundlesku/Vector.svg';
import Barcode from '@src/assets/images/svg/Barcode';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupText, Spinner } from 'reactstrap';
import './styles.scss';
import { X } from 'react-feather';

const ScanInputField = React.forwardRef(({ name, placeholder = 'Scan Barcode', loading = false, errors, iconColor = 'var(--bs-dark)', autoFocus = true, handleClear, hasValue, disabled, ...rest }, ref) => {
  const { t } = useTranslation()

  return (
    <div className='w-100 flex-column scanQrHeaderInputFieldWrapper'>
      <InputGroup className={classNames('w-100 flex-center-between shadow-none flex-nowrap scanQrHeaderInputGroup', {
        'has-error': !!errors?.[name]
      })}>
        <InputGroupText className={classNames('border-0 h-100', { 'bg-light-4': (loading || disabled) })}>
          <Barcode iconColor={iconColor}/>
        </InputGroupText>
        <input
          id={name}
          name={name}
          className={classNames('h-100 outline-none w-100 txt-sub-rg px-0 border-0', { 
            'bg-light-4': (loading || disabled),
            'cursor-not-allowed': (loading || disabled) 
          })}
          ref={ref}
          placeholder={t(placeholder)}
          autoFocus={autoFocus}
          disabled={loading || disabled}
          {...rest}
        />
        <InputGroupText className={classNames('h-100 border-0', { 'bg-light-4': (loading || disabled) })}>
          {loading && <Spinner size='sm' className='text-primary-global' />}
          {(handleClear && hasValue && !(disabled || loading)) &&
            <X onClick={handleClear} className="text-dark cursor-pointer" size={16} strokeWidth={1.5} />}
        </InputGroupText>
      </InputGroup>
      {!!errors?.[name] && <div className='d-flex gap-4px mt-6px'>
        <img src={Vector} />
        <div className="txt-asst-rg text-dark-5">{errors[name]?.message}</div>
      </div>}
      
    </div>
  );
});

export default ScanInputField;