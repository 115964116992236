export const useA4LayoutStyles = (printData) => {
  const textSize = printData.preferences.textSize || 'medium'
  const isRTL = printData.preferences.language === 'ar'

  // Define font size mapping
  const fontSizeMap = {
    small: '12px',
    medium: '14px',
    large: '16px'
  }

  const fontSize = fontSizeMap[textSize] || fontSizeMap['medium']

  const styles = {
    header: {
      borderBottom: '1px solid #DEDEDE',
      fontSize,
      textAlign: isRTL ? 'right' : 'left'
    },
    logo: {
      height: '46px',
      width: '158px',
      textAlign: 'center',
      paddingTop: '16px',
      margin: 'auto'
    },
    title: {
      padding: '16px 0',
      textAlign: 'center',
      fontWeight: '600',
      borderBottom: '1px solid #DEDEDE',
      color: '#222222',
      fontSize
    },
    headerGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: '16px',
      rowGap: '8px',
      padding: '24px'
    },
    headerItem: {
      display: 'flex',
      fontWeight: '400',
      gap: '16px'
    },
    headerLabel: {
      minWidth: '180px',
      maxWidth: '180px',
      color: '#666666',
      fontSize,
      wordBreak: 'break-all'
    },
    headerValue: {
      color: '#222222',
      wordBreak: 'break-all',
      fontSize
    },
    itemsSection: {
      marginTop: '24px',
      borderBottom: '1px solid #DEDEDE',
      padding: '0px 16px'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse'
    },
    tableCell: {
      padding: '8px',
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap',
      fontSize,
      verticalAlign: 'top'
    },
    rightAlign: {
      textAlign: 'right',
      fontSize
    },
    centerAlign: {
      textAlign: 'center',
      fontSize
    },
    leftAlign: {
      textAlign: 'left',
      fontSize
    },
    footerSection: {
      borderBottom: '1px solid #DEDEDE',
      fontSize
    },
    footerContent: {
      display: 'flex',
      flexDirection: isRTL ? 'column-reverse' : 'column',
      alignItems: isRTL ? 'flex-start' : 'flex-end'
    },
    footerItems: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '24px',
      width: '400px'
    },
    footerItem: {
      display: 'flex',
      gap: '16px'
    },
    footerLabel: {
      minWidth: '200px',
      maxWidth: '200px',
      color: '#666666',
      fontSize,
      wordBreak: 'break-all'
    },
    footerValue: {
      color: '#222222',
      textAlign: 'right',
      width: '100%',
      fontSize,
      wordBreak: 'break-all'
    },
    commentSection: {
      padding: '24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      alignItems: 'center',
      fontSize
    },
    commentImage: {
      objectFit: 'contain',
      height: '80px',
      width: '80px'
    },
    commentText: {
      margin: '0',
      fontSize,
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap'
    },
    columnWidths: {
      sixColumns: {
        '& th:nth-child(1), & td:nth-child(1)': {
          width: '5%'
        },
        '& th:nth-child(2), & td:nth-child(2)': {
          width: '25%'
        },
        '& th:nth-child(3), & td:nth-child(3)': {
          width: '25%'
        },
        '& th:nth-child(4), & td:nth-child(4)': {
          width: '15%'
        },
        '& th:nth-child(5), & td:nth-child(5)': {
          width: '15%'
        }
      },
      fiveColumns: {
        sixColumns: {
          '& th:nth-child(1), & td:nth-child(1)': {
            width: '5%'
          },
          '& th:nth-child(2), & td:nth-child(2)': {
            width: '35%'
          },
          '& th:nth-child(3), & td:nth-child(3)': {
            width: '20%'
          },
          '& th:nth-child(4), & td:nth-child(4)': {
            width: '20%'
          },
          '& th:nth-child(5), & td:nth-child(5)': {
            width: '20%'
          }
        }
      },
      fourColumns: {
        sixColumns: {
          '& th:nth-child(1), & td:nth-child(1)': {
            width: '5%'
          },
          '& th:nth-child(2), & td:nth-child(2)': {
            width: '45%'
          },
          '& th:nth-child(3), & td:nth-child(3)': {
            width: '25%'
          },
          '& th:nth-child(4), & td:nth-child(4)': {
            width: '25%'
          }
        }
      }
    },
    tableHeader: {
      padding: '8px',
      backgroundColor: '#F8F8F8',
      fontSize,
      textAlign: isRTL ? 'right' : 'left',
      wordBreak: 'break-all',
      verticalAlign: 'top'
    },
    salesNotes: {
      textAlign: 'center',
      padding: '24px',
      borderBottom: '1px solid #DEDEDE',
      fontSize
    }
  }

  return { styles }
}
