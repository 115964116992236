import Modal from '@src/@core/components/ui/modal/Modal'
import { createSplitOrder, updateSplitOrder } from '@src/views/sales/store/store'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const SplitOrderPopUp = (props) => {
  const { splitOrderPopUp, handleClosePopUp, order_id, isSplitOrderCreated } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleAction = () => {
    handleClosePopUp()
    if (isSplitOrderCreated) {
      dispatch(updateSplitOrder({order_id, body:splitOrderPopUp.body}))
    } else {
      dispatch(createSplitOrder({body:splitOrderPopUp.body}))
    }
  }
  return (
    <Modal
      isOpen={splitOrderPopUp.isOpen}
      closeModalHandler={handleClosePopUp}
      title={t(splitOrderPopUp.title)}
      subTitle={t(splitOrderPopUp.description)}
      primaryButtonText={t(splitOrderPopUp.buttonName)}
      primaryButtonHandler={handleAction}
      secondaryButtonText={t('Take me back')}
      secondaryButtonHandler={handleClosePopUp}
      isTranslationRequired={false}
    />
  )
}

export default SplitOrderPopUp