import Modal from '@src/@core/components/ui/modal/Modal'
import { MODAL_TYPES } from '@src/@core/components/ui/modal/modal.constant'
import { Notes } from '@src/assets/images/icons/Modal/GeneralIcons'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'
import './styles.scss'

/**
 * Renders a modal component for displaying remarks.
 *
 * @param {Object} prop - The props object containing the following properties:
 *   - isRemarksShow {boolean}: Indicates whether the remarks modal is open or closed.
 *   - setIsRemarksShow {function}: A function to toggle the visibility of the remarks modal.
 *   - remarksDiscription {string}: The description of the remarks.
 * @return {JSX.Element} The rendered remarks modal component.
 */

const RemarksModal = (prop) => {
  const { title, isRemarksShow, setIsRemarksShow, remarksDiscription, icon } = prop
  const { t } = useTranslation()

  /**
 * Toggles the value of `isRemarksShow` state.
 *
 * @return {void}
 */
  const handleToggle = () => {
    setIsRemarksShow(prev => !prev)
  }

  return (
    <Modal
      isOpen={isRemarksShow}
      modalClassNames="custom-customer-remarks-modal"
      title={t(title)}
      type={MODAL_TYPES.GENERAL}
      icon={icon || Notes}
      closeModalHandler={handleToggle}
      secondaryButtonText={t('Close')}
      secondaryButtonHandler={handleToggle}
      isTranslationRequired={false}
    >
      <ModalBody className="m-0 p-0">
        <div className="content-body pt-0">
          <pre dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(remarksDiscription) }} className="txt-body-rg text-dark m-0 customer-note-content">
          </pre>
        </div>
      </ModalBody>
    </Modal>
  )
}

RemarksModal.propTypes = {
  title: PropTypes.string,
  isRemarksShow: PropTypes.bool,
  setIsRemarksShow: PropTypes.func,
  remarksDiscription: PropTypes.string
}

export default RemarksModal
