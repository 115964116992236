import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SideSheetCloseButton from '@src/@core/components/ui/page-header/sideSheetCloseButton'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { getItemDetailsOfPicklist } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'

const ItemDetailsOfPicklist = ({
  // dropdownOptions (goes down here)
  toggleSidebar,
  isSidebarOpen,
  wave,
  time,
  columns,
  // title,
  status,
  picklist,
  tableData,
  handleSidebarClosed,
  hub_id
}) => {
  // states
  const [lastUpdatedTime, setLastUpdated] = useState(null)
  const [currentTime, setCurrentTime] = useState(new Date())
  const { t } = useTranslation()

  const itemDetailsOfPicklist = useSelector(
    (state) => state.sales.picklistDetails
  )
  const showHeading = useSelector((state) => state.sales.completedWaveState)

  const timer = useRef()

  const onSidebarClosed = () => {
    if (timer.current) {
      setLastUpdated(null)
      clearInterval(timer.current)
    }
    handleSidebarClosed()
  }

  useEffect(() => {
    if (lastUpdatedTime) {
      const interval = setInterval(() => {
        setCurrentTime(new Date())
      }, 5000) // Update every second
      timer.current = interval
      return () => clearInterval(interval)
    }
  }, [lastUpdatedTime])

  // Calculate the difference between the current time and last updated time
  const calculateTimeDifference = () => {
    if (lastUpdatedTime && currentTime) {
      const difference = Math.abs(currentTime - lastUpdatedTime)
      const minutes = Math.floor(difference / 1000 / 60)
      //   const seconds = Math.floor((difference / 1000) % 60)
      //   ${seconds} seconds
      return `${minutes} mins`
    }
  }
  const calculateSecondDifference = () => {
    const difference = Math.abs(currentTime - lastUpdatedTime)
    const seconds = Math.floor(difference / 1000)
    return seconds
  }

  const dispatch = useDispatch()
  const handleRefresh = () => {
    if (calculateSecondDifference() > 30) {
      setLastUpdated(new Date())
      setCurrentTime(new Date())
      dispatch(
        getItemDetailsOfPicklist({
          picklist_id: picklist?.picklist_id,
          type: status,
          waveId: wave?.waveId,
          hub_id
        })
      )
    }
  }

  return (
    <SideSheet
      isOpen={isSidebarOpen}
      onClosed={onSidebarClosed}
      toggle={toggleSidebar}
      contentClassName="p-0"
      modalClassName="modal-slide-in modal-slide-out picklist_wave_sidebar"
      size="md"
    >
      {/* TODO: Remove hardcoding Everywhere in this */}
      <div className="modal_header p-24px align-items-start">
        <div>

          <div className="d-flex align-items-center gap-12px">
            <h2 className="modal_header_title txt-h1-md">{t('Picklist Details')}</h2>
            {showHeading && true && (
              <CustomLabel title="Ongoing Wave" className="text-info bg-info-light" />
            )}
          </div>

          <small className="me-12px txt-body-md text-dark-6">
            <b>{wave?.name}</b> : {time}
          </small>
          <div className="d-flex align-items-center gap-1 ">
            {calculateTimeDifference() && (
              <span
                className="text-secondary "
                style={{ width: '180px', fontSize: 'small' }}
              >
                {t('Last updated')} : {calculateTimeDifference()} {t('ago')}
              </span>
            )}
            <Button
              id='sidebarRefresh'
              ofStyle='noBackground'
              className={classNames('p-2px bg-transparent', {'text-primary': calculateSecondDifference() <= 25})}
              onClick={handleRefresh}
            >
              {t('Refresh')}
            </Button>
            {calculateSecondDifference() <= 25 && (
              <UncontrolledTooltip target="sidebarRefresh">
                {t('Refresh After')}: {30 - calculateSecondDifference()} {t('seconds')}
              </UncontrolledTooltip>
            )}
          </div>
        </div>
        
        <SideSheetCloseButton clearButtonHandler={toggleSidebar}/>
      </div>
      <div className='overflow-auto'>
        <div className="p-24px ">
          <div className="d-flex flex-wrap">
            {status !== 'unassigned' && (
              <p className="text-muted w-50">
                {t('Picker Name')} :
                <span className="px-1 text-dark">{picklist?.picker_name}</span>
              </p>
            )}

            <p className="text-muted w-50">
              {t('Unique SKUs')} :
              <span className="px-1 text-dark">{picklist?.unique_skus}</span>
            </p>

            <p className="text-muted w-50">
              {t('Items Qty')} :
              <span className="px-1 text-dark">{picklist?.total_items}</span>
            </p>
          </div>
        </div>

        <div className="p-24px pt-0">
          <ExpandableCustomTable
            loading={itemDetailsOfPicklist?.loading}
            success={itemDetailsOfPicklist?.is_success}
            error={itemDetailsOfPicklist?.error}
            showPagination={false}
            data={tableData}
            columns={columns}
            columnName={ALL_COLUMN_NAME_MAPPING.ITEM_PICKLIST_DETAILS}
            showColumnsTableHeader={false}
            isTableFixedHeader={false}
          />
        </div>
      </div>
    </SideSheet>
  )
}

export default ItemDetailsOfPicklist
