import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import InputField from '@src/@core/components/ui/input-field'
import RadioButton from '@src/@core/components/ui/radio-button'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { PRINT_PAGE_STYLE } from '@src/App.constants'
import useBarcodeListModal from '@src/views/inventory/hooks/useBarcodeListModal'
import Barcode from 'react-barcode'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ModalBody } from 'reactstrap'
import icon from '../../../../../../assets/images/icons/info-filled-blue.svg'
import { BARCODE_SETTING, MAX_BARCODE_HEIGHT, SIDESHEET_VIEWS } from '../config'
import './styles.scss'

const BarcodeListModal = ({
  isBarCodeListModalOpen,
  barCodeListModalToggle,
  currentKit,
  currentTask,
  sellerId,
  hubId
}) => {

  const barcodeListModalLogic = useBarcodeListModal({
    currentKit,
    currentTask,
    sellerId,
    hubId
  })

  const {
    columns,
    pageTypeHandler,
    handleWidthAndHeight,
    handleClosed,
    handleOpened,
    handleSetSerialisationStatus,
    batches,
    pageConfig,
    pageConfigInPixel,
    error,
    selectedPageType,
    view,
    barcodeSetting,
    isSkuSerialised,
    t,
    componentRef,
    setBarcodeSetting,
    pageTypeOptions,
    barcodes
  } = barcodeListModalLogic

  return (
    <SideSheet
      isOpen={isBarCodeListModalOpen}
      modalClassName="modal-slide-in sidebar-todo-modal"
      title='Print Bulk Barcodes'
      toggle={barCodeListModalToggle}
      onClosed={handleClosed}
      onOpened={handleOpened}
    >
      <div className="modal-content p-24px">
        <PerfectScrollbar>
          <ModalBody className="barcode-list-modal">
            {view === SIDESHEET_VIEWS.SET_SERIALISATION ?
              <div className="barcode-setting mt-24px">
                <div className="txt-h3-md text-dark mb-8px">{t('Select Barcode Setting for this assembled Kit')}</div>
                <div className="txt-body-rg text-dark-6 mb-24px">{t('You wont be able to change this setting later')}</div>
                <div className="flex-center-start gap-40px mb-40px">
                  <div className="width-45-per">
                    <RadioButton
                      id='serialised-radio'
                      name='barcode-setting-radio'
                      label='Serialised'
                      value={BARCODE_SETTING.SERIALISED}
                      checked={barcodeSetting === BARCODE_SETTING.SERIALISED}
                      onChange={(e) => setBarcodeSetting(e.target.value)}
                    />
                  </div>
                  <div className="width-45-per">
                    <RadioButton
                      id='non-serialised-radio'
                      name='barcode-setting-radio'
                      label='Non Serialised'
                      value={BARCODE_SETTING.NON_SERIALISED}
                      checked={barcodeSetting === BARCODE_SETTING.NON_SERIALISED}
                      onChange={(e) => setBarcodeSetting(e.target.value)}
                    />
                  </div>
                </div>
                <Button onClick={handleSetSerialisationStatus}>{t('Continue')}</Button>
              </div> :
              <>
                <div className="flex-center-start gap-12px mt-28px mb-40px">
                  <div className="txt-body-md text-dark">{t('Barcode Setting:')}</div>
                  <div className="txt-body-rg text-dark-6">{isSkuSerialised ? t('Serialised Barcode') : t('Non Serialised Barcode')}</div>
                </div>
                <div className="page-config my-24px">
                  <div>
                    <FloatingDropDown
                      options={pageTypeOptions}
                      defaultValue={pageTypeOptions[0]}
                      title='Page Type'
                      width='100%'
                      onChangeFunc={value => pageTypeHandler(value)}
                    />
                  </div>
                  <div>
                    <InputField
                      width='100%'
                      onWheel={(e) => e.target.blur()}
                      name='width'
                      errors={error}
                      label={'Width (in cm)'}
                      type="number"
                      value={pageConfig.width}
                      onChange={(e) => handleWidthAndHeight(e)}
                      isRequired
                      disabled={selectedPageType?.label === 'Default'}
                    />
                  </div>
                  <div>
                    <InputField
                      width="100%"
                      onWheel={(e) => e.target.blur()}
                      name="height"
                      errors={error}
                      label="Height (in cm)"
                      type="number"
                      value={pageConfig.height}
                      onChange={(e) => handleWidthAndHeight(e)}
                      isRequired
                      disabled={selectedPageType?.label === 'Default'}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <span>{t('Total Printable barcodes : ')}</span>
                  <span className="ms-1 text-dark"> {currentKit?.quantity || 0} </span>
                </div>
                <div className="d-flex justify-content-between align-items-center my-1">
                  <div className="w-100 heading-top">
                    <div className="d-flex align-items-center heading-top-info" >
                      <div><img src={icon} /></div>
                      <div>
                        <span className="heading-top-content">
                          {t('Barcodes will be downloaded in batches of 100.')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-2 rounded-16px">
                  <ExpandableCustomTable
                    loading={false}
                    success={true}
                    error={false}
                    columns={columns}
                    data={batches}
                    useReactPaginate={false}
                    showColumnsTableHeader={false}
                    columnName='assembly_print_barcodes'
                    isTableFixedHeader={false}
                  />
                </div>
              </>
            }
          </ModalBody>
        </PerfectScrollbar>
      </div>
      <div className="hidden-div">
        <div className="hidden-div-child" ref={componentRef}>
          <style>{ PRINT_PAGE_STYLE }</style>
          {barcodes?.length > 0 && barcodes.map((el, index) => {
            return <div key={index} className='d-flex p-0' style={{ margin: ' 0 auto', padding: '0px', paddingTop: +pageConfigInPixel.height < 180 ? '0px' : +pageConfigInPixel.height < 500 ? `${(+pageConfigInPixel.height / 2) - (MAX_BARCODE_HEIGHT / 2) - 10}px` : `${+pageConfigInPixel.height / 2}px` }}>
              <Barcode fontSize={pageConfigInPixel.height > 180 ? 20 : 10} value={el} height={`${Math.min(MAX_BARCODE_HEIGHT, pageConfigInPixel.height > 50 ? pageConfigInPixel.height - 45 : pageConfigInPixel.height)}px`} />
            </div>
          })}
        </div>
      </div>
    </SideSheet>
  )
}

export default BarcodeListModal
