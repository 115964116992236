import { useTranslation } from 'react-i18next'
import { Card, CardTitle } from 'reactstrap'

const ReturnOrderDetailSummary = (props) => {
  const { details } = props
  const { t } = useTranslation()
  const itemDetails = details.filter((element) => element)
  return (
    <Card className='return-order-detail-summary m-0 border rounded-4'>
      <CardTitle className='card-title mb-0 txt-h3-md'>{t('Basic Details')}</CardTitle>
      <div className='card-body-container bg-white'>
        {itemDetails.map((element) => (
          <div className='d-flex' key={element.key}>
            <div className='label text-dark-5'>{element.key}</div>
            <div className='value d-flex gap-24px'>
              <span className='text-dark-5'>:</span>
              <span className='text-dark'>{element.value || '-'}</span>
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default ReturnOrderDetailSummary
