import omnifulFooterLogo from '@src/assets/images/omniful/Omniful-footer-logo.svg'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

const Footer = () => {
  const powered_by_omniful_enabled = useSelector(state => state.auth.userData?.tenant?.powered_by_omniful_enabled)
  return (
    <div className={classNames('mt-6px', { 'invisible cursor-default': !powered_by_omniful_enabled })}>
      <p className='bg-transparent px-16px py-8px mb-0'>
        <span className='flex-center-end d-block text-dark-5 gap-4px'>
          <span className="txt-asst-rg">Powered by </span>
          <a href='https://omniful.com' target='_blank' rel="nofollow noreferrer">
            <img src={omnifulFooterLogo} alt="footer-logo" height="12px" width="100%" />
          </a>
        </span>
      </p>
    </div>
  )
}

export default Footer
