import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import ImageActions from '@src/@core/components/ui/image-preview-modal'
import SideSheet from '@src/@core/components/ui/sideSheet'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { STATUS_TYPES } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import defaultImg from '@src/assets/images/catalog/bundlesku/default-img.svg'
import BarcodeListViewModal from '@src/components/barcode-view-modal'
import ability from '@src/configs/acl/ability'
import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalBody } from 'reactstrap'
import { BUNDLE, MAX_SINGLE_SKU_DESC_LENGTH } from '../../catalog.constants'
import { cleanupBundleChildSku, getBundleChildSku } from '../../store'
import SkuImageHandler from '../SkuImageHandler'
import SimpleSkuDetailModal from '../simple-sku-detail-modal/SimpleSkuDetailModal'
import SkuChildTable from './SkuChildTable'
import SkuDetail from './SkuDetail'
import './styles.scss'

const SkuChildDetailModal = ({
  skuForDetails,
  toggle,
  skuType,
  isArchive = false,
  ...rest
}) => {
  const [uploadedImages, setUploadedImages] = useState([])
  const [currentSelectedImage, setCurrentSelectedImage] = useState(0)
  const [singleChild, setSingleChild] = useState({})
  const [isSimpleSkuModalOpen, setIsSimpleSkuModalOpen] = useState(false)
  const [isImageActionModalOpen, setIsImageActionModalOpen] = useState(false)
  const [barcodeList, setBarcodeList] = useState([])
  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false)
  const { t } = useTranslation()
  const imageRef = useRef(null)
  const dispatch = useDispatch()
  const [showFullDesc, setShowFullDesc] = useState(false)
  const bundleChildSkuDetails = useSelector((state) => state.catalog.bundleChildSku)
  const loading = useSelector((state) => state.catalog.skuLoading)

  const onModalOpened = () => {
    dispatch(getBundleChildSku({ params: {sellerId: skuForDetails.seller_id, sku_id: skuForDetails.id}, query: {is_archived: isArchive ? `${isArchive}` : false } }))
  }

  const onModalClosed = () => {
    dispatch(cleanupBundleChildSku())
    setUploadedImages([])
    setCurrentSelectedImage(0)
  }

  useEffect(() => {
    const mappedImages = bundleChildSkuDetails.images?.map((ele) => {
      return { value: ele.default }
    }) || []
    setUploadedImages(mappedImages)

  }, [bundleChildSkuDetails.id])

  return (
    <div>
      <SideSheet
        modalClassName='modal-slide-in child-detail-sku-modal'
        size='md'
        onOpened={onModalOpened}
        onClosed={onModalClosed}
        backdrop={true}
        toggle={toggle}
        title={
          <div className='d-flex align-items-center gap-1'>
            <div className='txt-h2-md'>{`${skuType === BUNDLE ? 'Kit' : 'Assembled Kit'} Details `}</div>
            {((bundleChildSkuDetails?.status) && !loading) && <CustomTag className={classNames({
              'text-success bg-success-light': (bundleChildSkuDetails?.status) === STATUS_TYPES.ACTIVE.value,
              'text-dark bg-dark-1': (bundleChildSkuDetails?.status) === STATUS_TYPES.INACTIVE.value
            })} title={Object.values(STATUS_TYPES).find((type) => (bundleChildSkuDetails?.status) === type.value)?.label} />}
          </div>

        }
        {...rest}
      >
        {loading ? <ComponentSpinner /> : 
          <ModalBody>
            <div className='sku-child-detail-modal'>
              <div className='d-flex flex-column gap-30px p-24px'>
                <div className='d-flex gap-2'>
                  <div>
                    <SkuImageHandler
                      uploadedImages={uploadedImages}
                      currentSelectedImage={currentSelectedImage}
                      imageRef={imageRef}
                      setCurrentSelectedImage={setCurrentSelectedImage}
                      setUploadedImages={setUploadedImages}
                      isView={true}
                    />
                  </div>
                  <SkuDetail sku={bundleChildSkuDetails} t={t} skuType={skuType} setBarcodeList={setBarcodeList} setBarcodeModalOpen={setBarcodeModalOpen} barcodeModalOpen={barcodeModalOpen} />
                </div>
                {bundleChildSkuDetails.description && <div className='border border-dark-1 rounded-12px'>
                  <div className='child-sku-desc-title text-dark bg-primary-lighter-global border-primary-lighter-global simple-sku-desc-content  txt-h3-md  w-100 border-radius-tl-tr-10'>{t('Description')}</div>
                  <div className='txt-sub-rg text-dark text-break text-wrap child-sku-desc-content p-16px'>
                    {!showFullDesc && (bundleChildSkuDetails.description.length <= MAX_SINGLE_SKU_DESC_LENGTH ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bundleChildSkuDetails.description) }}></div> : <div>
                      <span className='simple-sku-show-less-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${bundleChildSkuDetails.description.slice(0, 395)}...`) }}></span>
                      <span className='txt-sub-md text-primary cursor-pointer' onClick={() => setShowFullDesc(true)}>{t(' Show full')}</span>
                    </div>)}
                    {showFullDesc && (
                      <div>
                        <span className='simple-sku-show-less-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bundleChildSkuDetails.description) }}></span><span className='txt-sub-md text-primary cursor-pointer' onClick={() => setShowFullDesc(false)}>{t(' Show less')}</span>
                      </div>
                    )}
                  </div>
                </div>}
                <div className='d-flex flex-column gap-2'>
                  <div className='txt-h3-sb text-dark'>{t('Added SKUs')}</div>
                  <SkuChildTable
                    sku={bundleChildSkuDetails || skuForDetails}
                    t={t}
                    defaultImg={defaultImg}
                    setIsSimpleSkuModalOpen={setIsSimpleSkuModalOpen}
                    setSingleChild={setSingleChild}
                    skuType={skuType}
                    loading={loading}
                    isArchive={isArchive}
                  />
                </div>
 
                {ability.can(abilityMap.product.view_price.action, abilityMap.product.view_price.resource) && <div>
                  <div className='d-flex gap-5 align-items-center'>
                    <div className='d-flex gap-1 flex-column w-100'>
                      <p className='txt-body-md text-dark-6 text-end w-100 m-0'>{skuType === BUNDLE ? t('Kit Retail Price') : t('Assembled Kit Retail Price')}</p>
                      <p className='txt-h3-sb text-dark text-end m-0'>{skuType === BUNDLE ? t('Kit Selling Price') : t('Assembled Kit Selling Price')}</p>
 
                    </div>
                    <div className='d-flex gap-1 flex-column'>
                      <div className='d-flex gap-8px align-items-center justify-content-end'>
                        <p className='txt-body-md text-dark-6 text-end m-0'>
                          {bundleChildSkuDetails.retail_price >= 0 ? `${bundleChildSkuDetails.retail_price.toFixed(2)}` : '-'}
                        </p>
                        <p className='txt-body-md text-dark-6 text-end m-0'>
                          {bundleChildSkuDetails.selling_price >= 0 ? bundleChildSkuDetails.currency : null}
                        </p>
                      </div>
                      <div className='d-flex gap-4px align-items-center justify-content-end'>
                        <p className='text-dark txt-h3-sb text-end m-0'>
                          {bundleChildSkuDetails.selling_price >= 0 ? `${bundleChildSkuDetails.selling_price.toFixed(2)}` : '-'}
                        </p>
                        <p className='text-dark txt-h3-sb text-end m-0'>
                          {bundleChildSkuDetails.selling_price >= 0 ? bundleChildSkuDetails.currency  : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </ModalBody>
        }
      </SideSheet>
      <BarcodeListViewModal
        modalOpen={barcodeModalOpen}
        toggleSidebar={() => setBarcodeModalOpen(false)}
        data={barcodeList}
      />
      <SimpleSkuDetailModal
        isOpen={isSimpleSkuModalOpen}
        skuForDetails={singleChild}
        toggleSimpleSkuModal={() => setIsSimpleSkuModalOpen(false)}
      />
      <ImageActions
        isOpen={isImageActionModalOpen}
        toggle={() => setIsImageActionModalOpen(false)}
        currentInView={0}
        imgArray={uploadedImages}
        // imageUploadHandler={imageUploadHandler}
        setCurrentSelectedImage={setCurrentSelectedImage}
        currentSelectedImage={currentSelectedImage}
        uploadedImages={uploadedImages}
        // handleImageRemover={handleImageRemover}
        imageRef={imageRef}
        isView={true}
      />
    </div>
  )
}

export default SkuChildDetailModal
