// ** React Imports
import { retry } from '@src/utility/retry'
import PublicTracking from '@src/views/public-tracking'
import ShipmentsTracking from '@src/views/public-tracking/tms/shipments-tracking'
import { lazy } from 'react'
import { ROUTES_KEYS } from '../route-keys'
import { ROUTES_MAPPING } from '../route-keys-mapping'

const Authentication = lazy(() => retry(() => import('../../pages/authentication')))

const AuthenticationRoutes = [
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.TRACKING],
    element:  <Authentication />,
    title: 'Public Tracking',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.TRACKING_DETAIL],
    element: <PublicTracking />,
    title: 'Public Tracking Detail',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.UPDATE_LOCATION],
    element:  <Authentication />,
    title: 'Update Location',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.SHIPMENTS_TRACKING_ID],
    element: <ShipmentsTracking/>,
    title: 'Public Tracking Detail',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.SHIPMENTS_UPDATE_LOCATION],
    element: <Authentication/>,
    title: 'Update Location',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.LOGIN],
    element: <Authentication />,
    title: 'Login',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.FORGOT_PASSWORD],
    element: <Authentication />,
    layout: 'BlankLayout',
    title: 'Forgot Password',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.SET_PASSWORD],
    element: <Authentication />,
    title: 'Set Password',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: ROUTES_MAPPING[ROUTES_KEYS.RESET_PASSWORD],
    element: <Authentication />,
    title: 'Reset Password',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
]

export default AuthenticationRoutes
