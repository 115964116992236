import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import BulkActions from '@src/@core/components/ui/bulk-actions/BulkActions'
import Button from '@src/@core/components/ui/button'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import Modal from '@src/@core/components/ui/modal/Modal'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { ORDER_TYPES } from '@src/App.constants'
import { PageNotFound } from '@src/components/pageNotFound/pageNotFound'
import STODetailSidebar from '@src/views/sales/StockTransferOrder/components/storeDetail/stoDetailSidebar'
import CancelOrderPopup from '@src/views/sales/components/CancelOrderPopup'
import EditCustomerShippingModal from '@src/views/sales/components/EditCustomerShippingModal'
import ReassignHubModal from '@src/views/sales/components/reassignHubModal'
import TransactionsSideSheet from '@src/views/sales/components/transactions-sidesheet'
import { TRANSACTION_HANDLING_TYPES } from '@src/views/sales/components/transactions-sidesheet/constants'
import { ORDER_STATUS } from '@src/views/sales/constant/orders.constants'
import ReorderModal from '@src/views/sales/create-order/components/reorder-modal'
import Attachments from '@src/views/sales/pending-actions/pages/createOrder/attachments'
import BasicDetailsCard from '@src/views/sales/pending-actions/pages/orderDetails/components/basicDetailsCard/basicDetailsCard'
import CustomerDetailsCard from '@src/views/sales/pending-actions/pages/orderDetails/components/customerDetailsCard/customerDetailsCard'
import DestinationHubDetailsCard from '@src/views/sales/pending-actions/pages/orderDetails/components/destinationHubDetailsCard/destinationHubDetailsCard'
import OrderInfobanner from '@src/views/sales/pending-actions/pages/orderDetails/components/orderInfoBanner/orderInfobanner'
import ShippingAddressCard from '@src/views/sales/pending-actions/pages/orderDetails/components/shippingAddressCard/shippingAddressCard'
import SplittedOrderDetailsSideSheet from '@src/views/sales/pending-actions/pages/splittedOrders/components/splittedOrderDetailsSideSheet'
import { EDIT_MODAL_TYPE } from '@src/views/sales/sales.constant'
import { updateOrderStatus } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap'
import AvailableWithoutReservationInfo from '../../AvailableWithoutReservationInfo'
import OnHoldItemsTable from '../OnHoldItemsTable'

// Import the hook
import { useOnHoldItemDetail } from '../hooks/useOnHoldItemDetail'

const OnHoldItemDetails = (props) => {
  const { orderId, onHoldOrderDetails, isFetching, isError, isSuccess, getSingleOnHoldOrderDetails, handleGetParentData, handleSideSheetClose } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    isForcefulHubRoutePopupOpen,
    isStoDetailsOpen,
    isReassignHubModalOpen,
    setReassignHubModalOpen,
    handleToggleForcefulHubRoutePopup,
    tableDataPending,
    tableDataError,
    tableDataSuccess,
    handleForcefulHubRouting,
    isForcefulHubRoutingPending,
    loading,
    isSplittedOrderDetailsSideSheetOpen,
    isEditDetailsModalOpen,
    setIsEditDetailsModalOpen,
    isApproveOrderPopUpOpen,
    isCancelOrderPopupOpen,
    setIsCancelOrderPopupOpen,
    attachmentSidebar,
    addedAttachments,
    setAddedAttachments,
    isOpenAddLocationAttributesModal,
    reorderModal,
    setReorderModal,
    hasEditAttachmentButton,
    hasForcefulRoutePermission,
    hasEditOrderDetailsPermission,
    isUneditedSalesChannelOrder,
    toggleAddLocationAttributesModal,
    handleToggleAttachmentSidebar,
    handleSyncPaymentStatus,
    handleOpenSplittedOrderDetailSideSheet,
    handleCloseSplittedOrderDetailSideSheet,
    handleEditDetailsModal,
    handleHubChange,
    toggleApprovalPopup,
    handleCancelOrderSuccess,
    handleReassignHubSuccess,
    transactionSideSheet,
    onSaveAttachments,
    handleShowSTODetail,
    handleReorder,
    hubOptions,
    show_inventory,
    closeTransactionSheet,
    isEditAttachmentPending,
    primaryButtonLoading,
    actionButtons,
    syncButtons,
    editButtons,
    primaryActionButton,
    uploadDetails
  } = useOnHoldItemDetail({
    orderId,
    onHoldOrderDetails,
    getSingleOnHoldOrderDetails,
    handleGetParentData,
    handleSideSheetClose
  })

  // store
  return (
    <>
      {isError ? (
        <PageNotFound />
      ) : isFetching ? (
        <ComponentSpinner />
      ) : onHoldOrderDetails ? (
        <div className='on-hold-order-item-detail'>
        
          <OrderInfobanner orderDetails={onHoldOrderDetails} />

          <div className='flex-center-between gap-16px'>
            <div className='status-wrapper bg-white rounded-8px d-flex align-items-center gap-12px flex-grow-1'>
              <div className='status-label rounded-start text-secondary txt-h3-md py-8px px-16px'>{t('Status')}</div>
              <div className='d-flex gap-8px pe-12px'>
                {onHoldOrderDetails.status && <CustomLabel title={onHoldOrderDetails.status} className='order-status-tag text-uppercase' />}
              </div>
            </div>
            {(primaryActionButton || actionButtons.length > 0) && (
              <div className='d-flex gap-12px'>
                {primaryActionButton && (
                  <Button onClick={primaryActionButton.onClick} disabled={primaryButtonLoading[primaryActionButton.name]}>
                    {primaryButtonLoading[primaryActionButton.name] && <Spinner size='sm' className='me-50' />}
                    {primaryActionButton.text}
                  </Button>
                )}

                {actionButtons.length > 0 && <BulkActions actions={actionButtons} selectedButton={{ id: 'moreActions', text: 'More Actions' }} />}
              </div>
            )}
          </div>

          <BasicDetailsCard
            orderDetails={onHoldOrderDetails}
            hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
            toggleAddLocationAttributesModal={toggleAddLocationAttributesModal}
            isOpenAddLocationAttributesModal={isOpenAddLocationAttributesModal}
            syncButtons={syncButtons}
            handleSyncPaymentStatus={handleSyncPaymentStatus}
            handleOpenSplittedOrderDetailSideSheet={handleOpenSplittedOrderDetailSideSheet}
            handleToggleAttachmentSidebar={handleToggleAttachmentSidebar}
            hasEditAttachmentButton={hasEditAttachmentButton}
            handleShowSTODetail={handleShowSTODetail}
          />

          <div className='customer-shipping-card-wrapper'>
            <CustomerDetailsCard
              customer={onHoldOrderDetails.customer}
              editButtons={editButtons}
              handleEditDetailsModal={handleEditDetailsModal}
              orderType={onHoldOrderDetails.type}
              hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
            />
            {onHoldOrderDetails.type === ORDER_TYPES.STO.value ? (
              <DestinationHubDetailsCard shipping_address={onHoldOrderDetails.shipping_address} destination_hub={onHoldOrderDetails.destination_hub} />
            ) : (
              <ShippingAddressCard
                shipping_address={onHoldOrderDetails.shipping_address}
                editButtons={editButtons}
                handleEditDetailsModal={handleEditDetailsModal}
                hasEditOrderDetailsPermission={hasEditOrderDetailsPermission}
              />
            )}
          </div>

          {show_inventory && (
            <div className='hub-details-wrapper d-flex align-items-center my-24px p-12px rounded-4 gap-12px'>
              <DropdownWithTitle
                title={t('Hub')}
                options={hubOptions}
                defaultValue={{ label: onHoldOrderDetails.selected_hub.name, value: onHoldOrderDetails.selected_hub.id }}
                value={onHoldOrderDetails.selected_hub ? { label: onHoldOrderDetails.selected_hub.name, value: onHoldOrderDetails.selected_hub.id } : null}
                selectOptionHandler={handleHubChange}
                getOptionValue={(option) => option.label}
                externalStyles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: '160px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    textAlign: 'start',
                    border: 'none',
                    boxShadow: 'none',
                  }),
                }}
              />
              <div className='hub-inventory-details-wrapper d-flex bg-white rounded-4 flex-grow-1 justify-content-around'>
                <div className='d-flex gap-8px'>
                  <span>{t('Ordered SKU(s)')}</span>
                  <span>:</span>
                  <span className='text-dark'>{onHoldOrderDetails.total_sku_count}</span>
                </div>
                <div className='vertical-divider'></div>
                <div className='d-flex gap-8px'>
                  <span className='text-success'>{t('Available SKU(s)')}</span>
                  <span>:</span>
                  <span className={classNames({ 'text-primary': tableDataPending, 'text-dark': !tableDataPending })}>
                    {tableDataPending ? <Spinner size='sm' /> : onHoldOrderDetails.available_sku}
                  </span>
                </div>
                <div className='vertical-divider'></div>
                <div className='d-flex gap-8px'>
                  <span className='text-danger'>{t('Unavailable SKU(s)')}</span>
                  <span>:</span>
                  <span className={classNames({ 'text-primary': tableDataPending, 'text-dark': !tableDataPending })}>
                    {tableDataPending ? <Spinner size='sm' /> : onHoldOrderDetails.unavailable_sku}
                  </span>
                </div>
              </div>
              {!onHoldOrderDetails?.assigned_hub?.name && hasForcefulRoutePermission && (
                <Button
                  ofType='compressed'
                  onClick={handleToggleForcefulHubRoutePopup}
                  disabled={isForcefulHubRoutingPending || onHoldOrderDetails.available_sku < onHoldOrderDetails.total_sku_count}
                >
                  {t('Assign Hub')}
                </Button>
              )}
            </div>
          )}

          {onHoldOrderDetails.show_available_without_reservation ? (
            <div className='mb-24px'>
              <AvailableWithoutReservationInfo />
            </div>
          ) : null}

          <OnHoldItemsTable
            orderDetails={onHoldOrderDetails}
            isPending={tableDataPending}
            isError={tableDataError}
            isSuccess={onHoldOrderDetails ? isSuccess : tableDataSuccess}
            show_inventory={show_inventory}
            editButtons={editButtons}
          />

          <SplittedOrderDetailsSideSheet
            isModalOpen={isSplittedOrderDetailsSideSheetOpen}
            handleCloseSideSheet={handleCloseSplittedOrderDetailSideSheet}
            order_id={onHoldOrderDetails.is_split_order ? onHoldOrderDetails.parent_order_details.id : onHoldOrderDetails.id}
            isSplitOrderCreated={onHoldOrderDetails.is_split_order}
          />

          {onHoldOrderDetails && (
            <EditCustomerShippingModal
              isModalOpen={isEditDetailsModalOpen}
              setIsModalOpen={setIsEditDetailsModalOpen}
              orderId={onHoldOrderDetails.id}
              editDetails={
                isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key || isEditDetailsModalOpen.key === EDIT_MODAL_TYPE.RECIPIENT_DETAILS.key
                  ? onHoldOrderDetails.customer
                  : onHoldOrderDetails.shipping_address
              }
            />
          )}

          <Modal
            key='approve_order_modal'
            isOpen={isApproveOrderPopUpOpen}
            isTranslationRequired={false}
            closeModalHandler={toggleApprovalPopup}
            primaryButtonHandler={() => {
              dispatch(updateOrderStatus({ orderID: onHoldOrderDetails.id, status:ORDER_STATUS.NEW_ORDER.key}))
            }}
            secondaryButtonHandler={toggleApprovalPopup} 
            title={
              <>
                <div>{t('Are you sure you want to approve the order?')}</div>
                <div>{onHoldOrderDetails?.seller_sales_channel_order_id && <span>{t('Order ID')}: {onHoldOrderDetails.seller_sales_channel_order_id}</span>}</div>
              </>
            }
            secondaryButtonText={t('Cancel')}
            primaryButtonText={t('Approve')}
            primaryButtonProps={{
              loading: loading.updateOrderStatus
            }}
          />

          <CancelOrderPopup
            isCancelOrderPopupOpen={isCancelOrderPopupOpen}
            setIsCancelOrderPopupOpen={setIsCancelOrderPopupOpen}
            orderDetail={{ ...onHoldOrderDetails, sellerOrderId: onHoldOrderDetails.seller_sales_channel_order_id }}
            setOrderDetail={() => {}}
            handleCancelOrderSuccess={handleCancelOrderSuccess}
          />
          <Attachments
            isOpen={attachmentSidebar.isOpen}
            toggle={handleToggleAttachmentSidebar}
            addedAttachments={addedAttachments}
            setAddedAttachments={setAddedAttachments}
            mode={attachmentSidebar.mode}
            onSaveAttachments={onSaveAttachments}
            isLoading={isEditAttachmentPending}
            uploadDetails={uploadDetails}
          />
          <Modal
            closeModalHandler={handleToggleForcefulHubRoutePopup}
            primaryButtonHandler={handleForcefulHubRouting}
            secondaryButtonHandler={handleToggleForcefulHubRoutePopup}
            primaryButtonText={t('Confirm')}
            primaryButtonProps={{ disabled: isForcefulHubRoutingPending }}
            secondaryButtonText={t('Cancel')}
            title={`Are you sure you want to assign this order to ${onHoldOrderDetails.selected_hub?.name}?`}
            subTitle={`This order will be assigned to the ${onHoldOrderDetails.selected_hub?.name} hub. This action cannot be reversed.`}
            isOpen={isForcefulHubRoutePopupOpen}
            isTranslationRequired={false}
          />

          <STODetailSidebar isShowSTODetail={isStoDetailsOpen} handleShowSTODetail={handleShowSTODetail} stoRequestId={onHoldOrderDetails.sto_request_id} />

          <ReassignHubModal
            isOpen={isReassignHubModalOpen}
            setIsOpen={setReassignHubModalOpen}
            orderDetail={onHoldOrderDetails}
            handleReassignHubSuccess={handleReassignHubSuccess}
          />
          
          <ReorderModal
            isOpen={reorderModal.isOpen}
            setIsOpen={() => setReorderModal({ isOpen: false, recreationType: '' })}
            handleContinue={handleReorder}
            preloadData={{
              orderId: onHoldOrderDetails?.seller_sales_channel_order_id,
              assignedHubId: onHoldOrderDetails?.hub_id,
              recreationType:reorderModal.recreationType
            }}
          />
          
          {transactionSideSheet.isOpen && <TransactionsSideSheet
            isOpen={transactionSideSheet.isOpen}
            handleClose={closeTransactionSheet}
            newInvoice={{
              total: onHoldOrderDetails.invoice.total,
              finalOrderTotal: transactionSideSheet.finalOrderTotal,
              currency: onHoldOrderDetails.invoice.currency,
              orderTotalType: transactionSideSheet.orderTotalType
            }}
            isSalesChannelOrder={isUneditedSalesChannelOrder}
            orderId={onHoldOrderDetails.id}
            transactionHandlingType={TRANSACTION_HANDLING_TYPES.PENDING_TRANSACTION}
          />}
        </div>
      ) : null}
    </>
  )
}

export default OnHoldItemDetails