import Button from '@src/@core/components/ui/button'
import { PRINT_MODES } from '@src/App.constants'
import { clearAwbDataForPrint, getAWBDetailForPrint, resetError, resetSuccess } from '@src/views/sales/store/store'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { Spinner } from 'reactstrap'
import ManualAwbLayout from './ManualAwbLayout'

const PrintManualAwb = ({ entity_id, entity_type, buttonRef = null, handleBeforePrint = () => {} }) => {
  const { t } = useTranslation()
  const componentRef = useRef()
  const dispatch = useDispatch()
  const [printingLoading, setPrintingLoading] = useState(false)
  const awbDataForPrint = useSelector(store => store.sales.awbDataForPrint)
  const success = useSelector(store => store.sales.success)
  const error = useSelector(store => store.sales.error)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print {
      @page {
        size: ${PRINT_MODES.FOUR_BY_SIX.size};
        margin: 10px;
        padding:0;
      },
    }`,
    onBeforeGetContent: async() => setPrintingLoading(true),
    onBeforePrint: () => setPrintingLoading(false),
    onAfterPrint: () => {
      handleBeforePrint()
    },
    onPrintError: () => {
      handleBeforePrint()
    }
  })

  const handleManualAwbPrint = async () => {
    if (!awbDataForPrint) {
      const params = {
        entity_id,
        entity_type
      }
      setPrintingLoading(true)
      dispatch(getAWBDetailForPrint({ params }))
    } else {
      handlePrint()
    }
  }

  useEffect(() => {
    if (success.getAWBDetailForPrint) {
      handlePrint()
      dispatch(resetSuccess())
    }
  }, [success.getAWBDetailForPrint])

  useEffect(() => {
    if (error.getAWBDetailForPrint) {
      setPrintingLoading(false)
      dispatch(resetError())
    }
  }, [error.getAWBDetailForPrint])

  useEffect(() => {
    return () => {
      if (awbDataForPrint) {
        dispatch(clearAwbDataForPrint())
      }
    }
  }, [])

  return (
    <div className='d-flex gap-1'>
      <div className='d-none'>
        <ManualAwbLayout ref={componentRef} awbData={awbDataForPrint} />
      </div>
      <Button
        ref={buttonRef}
        onClick={handleManualAwbPrint}
        disabled={printingLoading}
      >
        {printingLoading &&
          <Spinner size="sm"/>
        }
        {t('Print AWB')}
      </Button>
    </div>
  )
}

export default PrintManualAwb