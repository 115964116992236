import classNames from 'classnames'
import { forwardRef } from 'react'
import { Minus, Plus } from 'react-feather'
import './styles.scss'

const QuantityButton = forwardRef(({disabled = false, decreaseQuantity, increaseQuantity, quantity, onChange, minQuantityEnabled = true, maxQuantityEnabled = true, onKeyDown = () => {}, onBlur = () => {}, onInputClick = () => {}, customClassName = ''}, ref) => {
  return (
    <div className={`quantity-button ${customClassName}`}>
      <div className={`qty-input disabled-${disabled} ${customClassName}`}>
        <button
          disabled={quantity === 0 || disabled || minQuantityEnabled}
          onClick={decreaseQuantity}
          className={classNames(`minus-button qty-${minQuantityEnabled}`, { 'disabled-btn': quantity === 0 || disabled || minQuantityEnabled})}
          type="button"
        >
          <Minus />
        </button>

        <input
          className={classNames(`border-0 ${customClassName}`)}
          disabled={disabled || (minQuantityEnabled && maxQuantityEnabled)}
          value={quantity || 0}
          onChange={onChange}
          ref={ref}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={(e) => e.target.select()}
          onClick={onInputClick}
          type='number'
        />

        <button
          disabled={disabled || maxQuantityEnabled}
          onClick={increaseQuantity}
          className={classNames(`plus-button qty-${maxQuantityEnabled}`, { 'disabled-btn': disabled || maxQuantityEnabled})}
          type="button"
        >
          <Plus />
        </button>
      </div>
    </div>
  )
})

export default QuantityButton
