import { HUBS_CONFIGURATION, NOT_APPLICABLE, TENANT_TYPE } from '@src/App.constants';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import ability from '@src/configs/acl/ability';
import { HUB_ASSESTS } from '@src/views/hubs/constants.hubs';
import { getRootState, isObjEmpty } from './Utils';

/**
 * Determines if the user's plan only has POS enabled
 * 
 * Checks the user's plan configuration to determine if POS is the only
 * enabled feature in their subscription plan.
 * 
 * @returns {boolean} Whether POS is the only enabled feature in the user's plan
 */
export const isOnlyPosEnabledOnUserPlan = () => {
  const auth = getRootState().auth
  const userPlan = auth.userData?.user_plan || {}
  
  const filterPlans = (() => {
    const enabledPlans = Object.values(userPlan).filter(Boolean)
    return enabledPlans.length === 1 && userPlan.is_pos_enabled
  })()

  return filterPlans
}

/**
 * Determines if the user has permission to view bulk ship functionality
 * 
 * The logic checks three conditions:
 * - The hub is not an external hub
 * - User has bulk ship view permission
 * - WMS is enabled on the hub
 * 
 * All conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to view bulk ship functionality
 */
export const getBulkShipViewPermission = () => {
  const auth = getRootState().auth
  const selectedGlobalHub = auth.selectedGlobalHub || {}
  const isExternalHub = selectedGlobalHub.meta_data?.is_external_hub
  const isWmsEnabledOnHub = selectedGlobalHub.meta_data?.is_wms_enabled
  const hasBulkShipViewPermission = ability.can(abilityMap.bulk_ship.view.action, abilityMap.bulk_ship.view.resource)

  return !isExternalHub && hasBulkShipViewPermission && isWmsEnabledOnHub
}

/**
 * Determines if the user has permission to view tax invoices
 * 
 * The logic checks two conditions:
 * - User has invoice view permission
 * - User's plan is not POS-only
 * 
 * Both conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to view tax invoices
 */
export const getTaxInvoicesViewPermission = () => {
  const hasInvoiceViewPermission = ability.can(abilityMap.invoices.view.action, abilityMap.invoices.view.resource)
  return hasInvoiceViewPermission && !isOnlyPosEnabledOnUserPlan()
}

/**
 * Determines if the user has permission to view kits and sales channel listings
 * 
 * The logic checks two conditions:
 * - User has product view permission
 * - User's plan is not POS-only
 * 
 * Both conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to view kits and sales channel listings
 */
export const getKitsAndSalesChannelListingViewPermission = () => {
  const hasProductViewPermission = ability.can(abilityMap.product.view.action, abilityMap.product.view.resource)
  return hasProductViewPermission && !isOnlyPosEnabledOnUserPlan()
}

/**
 * Determines if the user has permission to manage invoices
 * 
 * The logic checks two conditions:
 * - User has invoice configure permission
 * - User's plan is not POS-only
 * 
 * Both conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to manage invoices
 */
export const getManageInvoicesViewPermission = () => {
  const hasInvoiceConfigurePermission = ability.can(abilityMap.invoices.configure.action, abilityMap.invoices.configure.resource)
  return hasInvoiceConfigurePermission && !isOnlyPosEnabledOnUserPlan()
}

/**
 * Determines if the user has permission to view POS functionality
 * 
 * The logic checks two conditions:
 * - POS is enabled on the selected hub
 * - User has POS view permission
 * 
 * Both conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to view POS functionality
 */
export const getPosViewPermission = () => {
  const selectedGlobalHub = getRootState().auth.selectedGlobalHub
  const isPosEnabledOnHub = selectedGlobalHub?.meta_data.is_pos_enabled
  const hasPosViewPermission = ability.can(abilityMap.custom.pos_view.action, abilityMap.custom.pos_view.resource)

  return hasPosViewPermission && isPosEnabledOnHub
}

/**
 * Determines if the user has permission to view delivery zones
 * 
 * The logic checks three conditions:
 * - Single hub data exists
 * - User has hub location view permission
 * - User's plan is not POS-only
 * 
 * Returns NOT_APPLICABLE if single hub data is empty
 * Otherwise, all conditions must be true for the permission to be granted
 * 
 * @returns {boolean|string} Whether the user should be able to view delivery zones, or NOT_APPLICABLE
 */
export const getDeliveryZoneViewPermission = () => {
  const revampedHubs = getRootState().revampedHubs
  const singleHub = revampedHubs.singleHub.data || {}
  if (isObjEmpty(singleHub)) return NOT_APPLICABLE
  const hasHubLocationViewPermission = ability.can(abilityMap.delivery_zone.view.action, abilityMap.delivery_zone.view.resource)

  return hasHubLocationViewPermission && !isOnlyPosEnabledOnUserPlan()
}

/**
 * Determines if the user has permission to view specific hub assets
 * 
 * The logic handles different types of hub assets (BIN, PALLET, CART, BOX)
 * and checks various conditions based on:
 * - WMS enablement (both on user plan and hub)
 * - External hub status
 * - Bin configuration
 * - User role (specifically for packer role)
 * - Specific permissions for each asset type
 * 
 * @param {string} entity - The type of hub asset to check permissions for
 * @returns {boolean|string} Whether the user should be able to view the specified hub asset, or NOT_APPLICABLE
 */
export const getViewHubAssetsPermission = (entity) => {
  const rootState = getRootState()
  const auth = rootState.auth
  const revampedHubs = rootState.revampedHubs
  const permissions = auth.permissions || []
  const isWmsEnabledOnUserPlan = auth.userData?.user_plan?.is_wms_enabled
  const hasBinViewPerm = permissions.find(perm => perm.action === 'view_bin' && perm.subject === 'hub_location')
  const singleHub = revampedHubs.singleHub.data || {}
  if (isObjEmpty(singleHub)) return NOT_APPLICABLE
  const isExternalHub = singleHub.is_external_hub
  const isBinEnabled = singleHub.configurations?.find(item => item.configuration_type === HUBS_CONFIGURATION.BIN_CONFIG.value)?.configuration_values?.enabled
  const user = auth.userData
  const isRolePacker = user?.roles?.some(role => role.name === 'packer')
  const isWmsEnabledOnHub = singleHub?.meta_data?.is_wms_enabled
  const isHubAssetsEnabled = isWmsEnabledOnUserPlan && isWmsEnabledOnHub && !isExternalHub

  switch (entity) {
  case HUB_ASSESTS.BIN:
  case HUB_ASSESTS.PALLET:
    return !!(isHubAssetsEnabled && hasBinViewPerm && isBinEnabled);
  case HUB_ASSESTS.CART:
    return !!(isHubAssetsEnabled && hasBinViewPerm);
  case HUB_ASSESTS.BOX:
    return !!(isHubAssetsEnabled && (hasBinViewPerm || isRolePacker));
  default:
    return false;
  }
}

/**
 * Determines if the user has permission to view hub locations
 * 
 * The logic checks multiple conditions:
 * - Single hub data exists
 * - WMS is enabled on user plan
 * - WMS is enabled on hub
 * - Hub is not external
 * - User has hub location view permission
 * 
 * Returns NOT_APPLICABLE if single hub data is empty
 * Otherwise, all conditions must be true for the permission to be granted
 * 
 * @returns {boolean|string} Whether the user should be able to view hub locations, or NOT_APPLICABLE
 */
export const getHubLocationViewPermission = () => {
  const rootState = getRootState()
  const permissions = rootState.auth.permissions || []
  const revampedHubs = rootState.revampedHubs
  const singleHub = revampedHubs.singleHub.data || {}
  if (isObjEmpty(singleHub)) return NOT_APPLICABLE
  const isWmsEnabledOnUserPlan = rootState.auth.userData?.user_plan?.is_wms_enabled
  const isWmsEnabledOnHub = singleHub?.meta_data?.is_wms_enabled
  const isExternalHub = singleHub.is_external_hub
  const hasHubLocationViewPermission = permissions.find(perm => perm.action === 'view' && perm.subject === 'hub_location')

  return !!(isWmsEnabledOnUserPlan && !isExternalHub && hasHubLocationViewPermission && isWmsEnabledOnHub)
}

/**
 * Determines if the user has permission to view stock ownership
 * 
 * The logic checks multiple conditions:
 * - Hub is not external
 * - Location inventory is enabled
 * - User has stock ownership view permission
 * 
 * All conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to view stock ownership
 */
export const getStockOwnerShipViewPermission = () => {
  const auth = getRootState().auth
  const permissions = auth.permissions || []
  const hubId = auth.selectedGlobalHubId
  const selectedHubConfiguration = auth.selectedHubConfiguration
  const isLocationInventoryEnabled = selectedHubConfiguration?.find(el => el.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled
  const isExternalHub = auth.userHubs?.find(hub => hub.id === hubId)?.is_external_hub
  const hasStockOwnerShipViewPermission = permissions.find(perm => perm.action === abilityMap.stock_ownership_transfer.view.action && perm.subject === abilityMap.stock_ownership_transfer.view.resource)

  return !!(!isExternalHub && isLocationInventoryEnabled && hasStockOwnerShipViewPermission)
}

/**
 * Determines if the user has permission to view sellers
 * 
 * The logic checks two conditions:
 * - Tenant type is FULFILLMENT_CENTRE
 * - User has sellers view permission
 * 
 * Both conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to view sellers
 */
export const getSellersViewPermission = () => {
  const auth = getRootState().auth
  const permissions = auth.permissions || []
  const hasSellersViewPermission = permissions.find(perm => perm.action === abilityMap.custom.seller_view.action && perm.subject === abilityMap.custom.seller_view.resource)
  const tenantType = auth.userData?.tenant.type

  return !!(tenantType === TENANT_TYPE.FULFILLMENT_CENTRE && hasSellersViewPermission)
}

/**
 * Determines if the user has permission to view expired inventory
 * 
 * The logic checks three conditions:
 * - Hub is not external
 * - User has expired inventory view permission
 * - Location inventory is enabled
 * 
 * All conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to view expired inventory
 */
export const getExpiredInventoryViewPermission = () => {
  const auth = getRootState().auth
  const selectedHubConfiguration = auth.selectedHubConfiguration
  const isLocationInventoryEnabled = selectedHubConfiguration?.find(el => el.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled
  const isExternalHub = auth.selectedGlobalHub?.is_external_hub;
  const hasExpiredInventoryViewPermission = ability.can(abilityMap.inventory.expired_inventory.action, abilityMap.inventory.expired_inventory.resource)
  
  return !!(!isExternalHub && hasExpiredInventoryViewPermission && isLocationInventoryEnabled)
}

/**
 * Determines if the user has permission to view shipment orders based on their permissions
 * 
 * The logic handles three types of permissions:
 * - Basic view permission for shipment orders
 * - Single mile shipment order view permission
 * - Multi mile shipment order view permission
 * 
 * The permissions are combined in a way that:
 * 1. Hides the view if user has both single mile AND multi mile permissions
 * 2. Hides the view if user has both basic view AND single mile permissions
 * 3. Shows the view if user has either:
 *    - Only basic view permission
 *    - Both basic view AND multi mile permissions
 * 
 * @returns {boolean} Whether the user should be able to view shipment orders
 */
export const getShipmentOrdersViewPermission = () => {
  const auth = getRootState().auth
  const permissions = auth.permissions || []
  
  const hasPermissions = {
    view: permissions.find(perm => 
      perm.action === abilityMap.view_shipment_order_v2.view.action && 
      perm.subject === abilityMap.view_shipment_order_v2.view.resource
    ),
    singleMile: permissions.find(perm =>
      perm.action === abilityMap.single_mile_shipment_order_v2.view.action &&
      perm.subject === abilityMap.single_mile_shipment_order_v2.view.resource  
    ),
    multiMile: permissions.find(perm =>
      perm.action === abilityMap.multi_mile_shipment_order_v2.view.action &&
      perm.subject === abilityMap.multi_mile_shipment_order_v2.view.resource
    )
  }

  const shouldHide = (!!hasPermissions.singleMile && !!hasPermissions.multiMile) || (!!hasPermissions.view && !!hasPermissions.singleMile)                 
  return !shouldHide && (!!hasPermissions.view || (!!hasPermissions.view && !!hasPermissions.multiMile))
}

/**
 * Determines if the user has permission to view both single mile and multi mile shipment orders
 * 
 * The logic handles three types of permissions:
 * - Basic view permission for shipment orders
 * - Single mile shipment order view permission
 * - Multi mile shipment order view permission
 * 
 * The permissions are combined in a way that shows the view if user has either:
 * 1. All three permissions (basic view AND single mile AND multi mile)
 * 2. Basic view AND single mile permissions only
 * 
 * This is typically used to determine if the user should see the combined single/multi mile view
 * 
 * @returns {boolean} Whether the user should be able to view both single and multi mile shipment orders
 */
export const getSingleAndMultiMileShipmentOrdersViewPermission = () => {
  const auth = getRootState().auth
  const permissions = auth.permissions || []

  const hasPermissions = {
    view: permissions.find(perm => 
      perm.action === abilityMap.view_shipment_order_v2.view.action && 
      perm.subject === abilityMap.view_shipment_order_v2.view.resource
    ),
    singleMile: permissions.find(perm =>
      perm.action === abilityMap.single_mile_shipment_order_v2.view.action &&
      perm.subject === abilityMap.single_mile_shipment_order_v2.view.resource  
    ),
    multiMile: permissions.find(perm =>
      perm.action === abilityMap.multi_mile_shipment_order_v2.view.action &&
      perm.subject === abilityMap.multi_mile_shipment_order_v2.view.resource
    )
  }
  return (!!hasPermissions.view && !!hasPermissions.singleMile && !!hasPermissions.multiMile) || (!!hasPermissions.view && !!hasPermissions.singleMile)
}

/**
 * Determines if the user has permission to view single mile shipment orders
 * 
 * The logic handles three types of permissions:
 * - Basic view permission for shipment orders
 * - Single mile shipment order view permission
 * - Multi mile shipment order view permission
 * 
 * The permissions are combined in a way that shows the view if user has:
 * - Both basic view AND single mile permissions
 * 
 * This is typically used to determine if the user should see the single mile shipment orders view
 * Note: Multi mile permission is checked but not used in the final determination
 * 
 * @returns {boolean} Whether the user should be able to view single mile shipment orders
 */
export const getSingleMileShipmentOrdersViewPermission = () => {
  const auth = getRootState().auth
  const permissions = auth.permissions || []

  const hasPermissions = {
    view: permissions.find(perm => 
      perm.action === abilityMap.view_shipment_order_v2.view.action && 
      perm.subject === abilityMap.view_shipment_order_v2.view.resource
    ),
    singleMile: permissions.find(perm =>
      perm.action === abilityMap.single_mile_shipment_order_v2.view.action &&
      perm.subject === abilityMap.single_mile_shipment_order_v2.view.resource  
    ),
  }

  return !!hasPermissions.view && !!hasPermissions.singleMile
}

/**
 * Determines if the user has permission to view multi mile shipment orders
 * 
 * The logic handles three types of permissions:
 * - Basic view permission for shipment orders
 * - Single mile shipment order view permission
 * - Multi mile shipment order view permission
 * 
 * The permissions are combined in a way that shows the view if user has:
 * - All three permissions (basic view AND multi mile AND single mile)
 * 
 * This is typically used to determine if the user should see the multi mile shipment orders view
 * Note: User must have single mile permission in addition to multi mile to view this section
 * 
 * @returns {boolean} Whether the user should be able to view multi mile shipment orders
 */
export const getMultiMileShipmentOrdersViewPermission = () => {
  const auth = getRootState().auth
  const permissions = auth.permissions || []

  const hasPermissions = {
    view: permissions.find(perm => 
      perm.action === abilityMap.view_shipment_order_v2.view.action && 
      perm.subject === abilityMap.view_shipment_order_v2.view.resource
    ),
    singleMile: permissions.find(perm =>
      perm.action === abilityMap.single_mile_shipment_order_v2.view.action &&
      perm.subject === abilityMap.single_mile_shipment_order_v2.view.resource  
    ),
    multiMile: permissions.find(perm =>
      perm.action === abilityMap.multi_mile_shipment_order_v2.view.action &&
      perm.subject === abilityMap.multi_mile_shipment_order_v2.view.resource
    )
  }
  return !!hasPermissions.view && !!hasPermissions.multiMile && !!hasPermissions.singleMile
}

/**
 * Determines if the user has permission to view picking waves
 * 
 * The logic checks two conditions:
 * - Hub is not external
 * - User has picking wave view permission
 * 
 * Both conditions must be true for the permission to be granted
 * 
 * @returns {boolean} Whether the user should be able to view picking waves
 */
export const getPickingWaveViewPermission = () => {
  const auth = getRootState().auth
  const hasPickingWaveViewPermission = ability.can(abilityMap.picking.view_wave.action, abilityMap.picking.view_wave.resource)
  const isExternalHub = auth.selectedGlobalHub?.is_external_hub;

  return !isExternalHub && hasPickingWaveViewPermission
}

/**
 * Determines if the user has permission to view SKU locations
 * 
 * The logic checks three conditions:
 * - Location inventory is NOT enabled
 * - User has SKU mapping view permission
 * - Hub is not external
 * 
 * All conditions must be true for the permission to be granted
 * Note: This permission is specifically for when location inventory is disabled
 * 
 * @returns {boolean} Whether the user should be able to view SKU locations
 */
export const getSkuLocationsViewPermission = () => {
  const auth = getRootState().auth
  const isExternalHub = auth.selectedGlobalHub?.is_external_hub;
  const hasSkuMappingViewPermission = ability.can(abilityMap.sku_mapping.view.action, abilityMap.sku_mapping.view.resource)
  const selectedHubConfiguration = auth.selectedHubConfiguration
  const isLocationInventoryEnabled = selectedHubConfiguration?.find(el => el.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled

  return !!(!isLocationInventoryEnabled && hasSkuMappingViewPermission && !isExternalHub)
}