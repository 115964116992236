import { API_ENDPOINTS, getApiUrl } from '@src/api.urls'
import { axiosInstance } from '@src/network/AxiosInstance'
import { paramsSerializer } from '@src/utility/Utils'
import { ANALYTICS_TABS_LIST } from './constants.analytics'

export const fetchMatrices = async ({ params }) => {
  const url = getApiUrl(API_ENDPOINTS.ANALYTICS.GET_MATRICES)
  const response = await axiosInstance.get(url, {params})
  return response.data
}

export const fetchMatrixDetail = async ({ params, body, scope }) => {
  const { tag, seller_id, hub_id, pagination} = params
  let url
  let serializedParams;
  if (scope === ANALYTICS_TABS_LIST.seller.id) {
    serializedParams = { hub_id: params.hub_id }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.GET_SELLER_MATRICES_DETAIL, { matrix: tag, sellerId: seller_id })
  } else if (scope === ANALYTICS_TABS_LIST.hub.id) {
    serializedParams = { seller_id: params.seller_id }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.GET_HUBS_MATRICES_DETAIL, { matrix: tag, hubId: hub_id })
  } else if (scope === ANALYTICS_TABS_LIST.shipping.id) {
    serializedParams = { hub_ids: params.hub_ids }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.GET_SHIPPING_MATRICES_DETAIL, { matrix: tag })
  } else if (scope === ANALYTICS_TABS_LIST.fulfilment.id) {
    delete body.shipping_accounts
    serializedParams = { hub_ids: params.hub_ids, seller_ids: params.seller_ids }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.GET_FULFILMENT_MATRICES_DETAIL, { matrix: tag })
  } else if (scope === ANALYTICS_TABS_LIST.lastmile.id) {
    delete body.shipping_accounts
    serializedParams = { hub_ids: params.hub_ids, seller_ids: params.seller_ids }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.GET_LASTMILE_MATRICES_DETAIL, { matrix: tag })
  } else if (scope === ANALYTICS_TABS_LIST.pos.id) {
    serializedParams = { hub_ids: params.hub_ids }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.GET_POS_MATRICES_DETAIL, { matrix: tag })
  } else {
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.GET_MATRICES_DETAIL, {matrix: tag})
  }
  const queryParams = { ...serializedParams, ...pagination }
  const queryString = paramsSerializer(queryParams)
  const query = queryString === '' ? '' : `?${queryString}`
  const response = await axiosInstance.post(`${url}${query}`, body)
  return response.data
}
  
export const downloadMatrixHandler = ({ params, body, scope }) => {
  const { tag, seller_id, hub_id, pagination } = params
  let url
  let serializedParams;
  if (scope === ANALYTICS_TABS_LIST.seller.id) {
    serializedParams = { hub_id: params.hub_id }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.EXPORT_SELLER_MATRIX, { matrix: tag, sellerId: seller_id })
  } else if (scope === ANALYTICS_TABS_LIST.hub.id) {
    serializedParams = { seller_id: params.seller_id }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.EXPORT_HUBS_MATRIX, { matrix: tag, hubId: hub_id })
  } else if (scope === ANALYTICS_TABS_LIST.shipping.id) {
    serializedParams = { hub_ids: params.hub_ids }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.EXPORT_SHIPPING_MATRIX, { matrix: tag })
  } else if (scope === ANALYTICS_TABS_LIST.fulfilment.id) {
    delete body.shipping_accounts
    serializedParams = { hub_ids: params.hub_ids, seller_ids: params.seller_ids }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.EXPORT_FULFILMENT_MATRICES, {matrix: tag})
  } else if (scope === ANALYTICS_TABS_LIST.lastmile.id) {
    delete body.shipping_accounts
    serializedParams = { hub_ids: params.hub_ids, seller_ids: params.seller_ids }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.EXPORT_LASTMILE_MATRICES, {matrix: tag})
  } else if (scope === ANALYTICS_TABS_LIST.pos.id) {
    serializedParams = { hub_ids: params.hub_ids }
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.EXPORT_POS_MATRICES, {matrix: tag})
  } else {
    url = getApiUrl(API_ENDPOINTS.ANALYTICS.EXPORT_HOME_MATRIX, {matrix: tag})
  }
  const queryParams = { ...serializedParams, ...pagination }
  const queryString = paramsSerializer(queryParams)
  const query = queryString === '' ? '' : `?${queryString}`
  return axiosInstance.post(`${url}${query}`, body)
}

export const getHubsAsyncDataForMultiSelectDropdown = async (search, loadedOptions, { page, is_external_hub }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/hubs', {
      params: { search_column: 'name', search_query: search, page, exclude_external_hub:is_external_hub },
    });

    const data = response?.data;
    const newOptions = data?.data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
    })) || [];
  
    return {
      options: page === 1
        ? [
          {
            label: '', // Add the group heading only on the first page
            options: newOptions,
          },
        ]
        : newOptions, // No group heading for subsequent pages
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        is_external_hub
      },
    };
  } catch (error) {
    console.error('Error fetching hubs:', error);
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        is_external_hub
      },
    };
  }
};

export const getAsyncShippingAccountsDataForMultiSelectDropdown = async (search, loadedOptions, { params, page = 1 }) => {
  const allParams = { ...params, name_filter: search, page }
  try {
    const response = await axiosInstance.get('/api/v1/shipping/partner_shipping_methods', {params: allParams})

    const data = response?.data?.data
    const newOptions = data?.map((ele) => ({
      value: ele.id,
      label: `${ele.account_name} (${ele.shipping_partner.name})`,
    })) || [];

    return {
      options: page === 1
        ? [
          {
            label: '', // Add the group heading only on the first page
            options: newOptions,
          },
        ]
        : newOptions, // No group heading for subsequent pages
      hasMore: response?.data?.meta?.current_page < response?.data?.meta?.last_page,
      additional: {
        page: response?.data?.meta?.current_page + 1,
        params
      }
    };
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        params
      }
    }
  }
}

export const getCountriesAsyncDataForMultiSelectDropdown = async (
  search,
  loadedOptions,
  { params, page = 1 }
) => {
  const allParams = { ...params, search_column: 'name', search_query: search, page }

  try {
    const response = await axiosInstance.get('/api/v1/tenant/countries', { params: allParams })
    const data = response?.data

    // Map the countries data into the required dropdown format
    const newOptions = data?.data?.map((ele) => ({
      value: params?.isValueString ? `${ele.id}` : ele.id,
      label: ele.name,
      code: ele.iso2,
      currencyCode: ele.currency?.code // Using optional chaining in case currency is undefined
    })) || [];

    return {
      options: page === 1
        ? [
          {
            label: '', // Add the group heading only on the first page
            options: newOptions,
          },
        ]
        : newOptions, // No group heading for subsequent pages
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1,
        params
      }
    };
  } catch (error) {
    console.warn('Error fetching countries:', error)
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        params
      }
    }
  }
}

export const getAllCitiesAsyncDataForMultiSelectDropdown = async (
  search,
  loadedOptions,
  { page = 1, country_id }
) => {
  const allParams = {
    search_column: 'name',
    search_query: search,
    page,
    country_id
  };

  try {
    const response = await axiosInstance.get('/api/v1/tenant/cities', {
      params: allParams
    });
    const data = response?.data;

    // Map the cities data into the format required for the dropdown options
    const newOptions = data?.data?.map((ele) => ({
      value: ele.id,
      label: ele.name,
      ...ele
    })) || [];

    return {
      options: page === 1
        ? [
          {
            label: '', // Add the group heading only on the first page
            options: newOptions,
          },
        ]
        : newOptions, // No group heading for subsequent pages
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: data?.meta?.current_page + 1,
        country_id,
      }
    };
  } catch (error) {
    console.warn('Error fetching all cities:', error);
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        country_id
      }
    };
  }
};
