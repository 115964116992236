import InputField from '@src/@core/components/ui/input-field'
import Modal from '@src/@core/components/ui/modal/Modal'
import { MODAL_TYPES } from '@src/@core/components/ui/modal/modal.constant'
import { Create, Edit } from '@src/assets/images/icons/Modal/GeneralIcons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
/**
 * Renders a modal component for adding a description.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - isOpen {boolean}: Indicates whether the modal is open or closed.
 *   - toggle {function}: A function to toggle the visibility of the modal.
 *   - index {number}: The index of the remark.
 *   - description {string}: The description of the remark.
 *   - handleDescription {function}: A function to handle the description change.
 * @return {JSX.Element} The rendered modal component.
 */
const AddDescription = (props) => {
  const { isOpen, toggle, index, description, handleDescription, isLoading} = props
  const { t } = useTranslation()
  const formDefaultState = {
    description: ''
  }
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: formDefaultState
  })

  const handleOnModalOpened = () => {
    reset({
      description
    })
  }

  const onSubmit = (data) => {
    handleDescription(index, data.description)
    toggle()
  }

  const handleClose = () => {
    reset(formDefaultState)
  }

  const getPrimaryButtonProps = () => {
    return {
      disabled: !isDirty || isLoading,
      loading: isLoading
    }
  }

  const getSecondaryButtonProps = () => {
    return {
      disabled: isLoading
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onOpened={handleOnModalOpened}
      onClosed={handleClose}
      type={MODAL_TYPES.GENERAL}
      icon={description ? Edit : Create}
      title={description ? t('Edit Description') : t('Add Description')}
      closeModalHandler={toggle}
      primaryButtonText={t('Save')}
      primaryButtonHandler={handleSubmit(onSubmit)}
      primaryButtonProps={getPrimaryButtonProps()}
      secondaryButtonText={t('Cancel')}
      secondaryButtonProps={getSecondaryButtonProps()}
      secondaryButtonHandler={toggle}
      isTranslationRequired={false}
    >
      <div className="pt-8px">
        <InputField 
          placeholder="Enter description"
          type="textarea"
          {...register('description')}
          className="max-height-300 min-height-100"
        />
      </div>
    </Modal>
  )
}

export default AddDescription
