import classNames from 'classnames'
import { useState } from 'react'
import { components } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import CheckBox from '../../new-checkbox'
import { customStyles } from '../customTableFilterComponent.constant'
import { modifiedLoadOptions } from '../utils'
import CustomSearchInput from './CustomSearchInput/CustomSearchInput'

const CheckBoxDropdownOption = (props) => {
  const {filterKey, isSelected, data: {label, color, logo, value}} = props
  const { Option } = components
  const customKey = `${filterKey}-${value}`
  const labelStyle = {color: color || 'text-dark', background: color ? `${color}1A` : 'transparent'}
  return (
    <Option key={customKey} {...props}>
      <CheckBox id={customKey} name={value} size='sm' defaultChecked={isSelected}/>
      <span className={classNames('txt-sub-rg rounded-10px cursor-pointer', {'custom-option-label-extra-padding' : color})} style={labelStyle} >{label}</span>
      {logo && <div className='custom-option-logo cursor-pointer'><img className='object-fit-contain' src={logo} width={32} height={12} alt={name}/></div>}
    </Option>
  )
}

const MultiAsyncFilterDropDown = ({selectedFilter, handleSetSelectedOption, selectedOptions}) => {
  const { filterKey, options, additional } = selectedFilter
  const [inputValue, setInputValue] = useState('')
  const handleInputChange = (value, { action }) => {
    if (action === 'input-change') setInputValue(value)
    else if (action === 'input-blur') setInputValue('')
  }

  return (
    <AsyncPaginate
      key={selectedFilter.filterKey }
      styles={customStyles()}
      captureMenuScroll={false}
      menuIsOpen={true}
      hideSelectedOptions={false}
      value={selectedOptions?.[filterKey]}
      closeMenuOnSelect={false}
      isSearchable={true}
      isMulti={true}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      components={{
        Option: (props) => <CheckBoxDropdownOption filterKey={filterKey} {...props}/>,
        Input: CustomSearchInput
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: 'var(--bs-primary)',
          primary25: 'var(--bs-white)',
          primary50: 'var(--bs-white)'
        }
      })}
      onChange={(option) => handleSetSelectedOption({filterKey, option})}
      additional={additional || { page: 1 }}
      loadOptions={modifiedLoadOptions(options, filterKey, selectedOptions, true)}
    />
  )
}
export default MultiAsyncFilterDropDown