export const RETURN_ORDER_DETAIL_CARDS = {
  BASIC_DETAILS: 'Basic Details',
  RETURN_DETAILS: 'Return details',
  ADDITIONAL_ORDER_DETAILS: 'Additional Order Details',
  PICKUP_DETAILS: 'Pickup Details',
  COURIER_PARTNER_RETURN: 'Courier Partner Return',
}

// These IDs are used to identify action buttons and are powered by the backend.
export const ACTION_BUTTONS_ID = {
  CANCEL_SHIPMENT: 'cancel_shipment',
  CREATE_SHIPMENT: 'create_shipment',
  PRINT_AWB: 'print_awb',
  PRINT_MANUAL_AWB: 'print_manual_awb',
  OMS_QC_PROCESSING: 'oms_qc_processing',
  RETURN_TO_ORIGIN: 'return_to_origin',
  REFUND_ORDER: 'refund_order',
  CANCEL_ORDER: 'cancel_order',
};