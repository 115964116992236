import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES, ModalContentProps } from './modal.constant';

const ModalContent: FC<ModalContentProps> = ({
  type,
  content,
  children,
  isTranslationRequired,
  isModalScrollable,
  hasModalFooterButtons
}) => {
  const { t } = useTranslation();
  const isIllustration = type === MODAL_TYPES.ILLUSTRATION;
  if (!content && !children) return null;

  const renderContent = (content: string | ReactNode) => {
    if (typeof content === 'string') {
      return isTranslationRequired ? t(content) : content;
    }
    return content;
  };

  return (
    <div className={classNames('modal-content-container px-24px mb-16px', {'pt-16px': !isIllustration, 'remove-modal-scroll': !isModalScrollable, 'mb-24px': !hasModalFooterButtons})}>
      {content ? (
        <div className="d-flex flex-column gap-16px txt-body-rg w-100">
          {renderContent(content)}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default ModalContent; 