import { MarkerClusterer } from '@googlemaps/markerclusterer'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { DEFAULT_MAP_CENTER, MAP_STYLES } from '@src/App.constants'
import Hub from '@src/assets/images/icons/primary-navbar/Hub'
import { axiosInstance } from '@src/network/AxiosInstance'
import { ROUTES_KEYS } from '@src/router/route-keys'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'
import { STRINGIFY } from '@src/utility/hooks/useFilters'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useSelector } from 'react-redux'
import { createPolygonWithHatch, fitPolygonsToBounds, handleGetHatchOverlayClass } from '../all-delivery-zones-utils'
import DeliveryZoneInfoTooltip from './DeliveryZoneInfoTooltip'

const useMapComponent = (componentProps) => {
  const {
    zoom = 11.5,
    filter
  } = componentProps
  const searchOptions = [
    {label: 'Location', value: 'location'},
    {label: 'Order ID', value: 'order'}
  ]
  const hubMarkers = []

  const hatchOverlayRef = useRef(null);

  const mapRef = useRef()
  const polygonRefs = useRef([])

  const selectedDeliveryZones = useSelector(state => state.revampedHubs.viewDeliveryZonesScreenData.selectedDeliveryZones)
  const selectedHubs = useSelector(state => state.revampedHubs.viewDeliveryZonesScreenData.selectedHubs)

  const zonesData = Object.values(selectedDeliveryZones).map((zone) => {
    const coordinates = zone.coordinates.map((latLng) => ({
      lng: +latLng[0],
      lat: +latLng[1],
    }));
    // Calculate the area of the polygon
    const area = window.google.maps.geometry.spherical.computeArea(
      coordinates
    );
    return { ...zone, coordinates, area };
  })
    .sort((a, b) => b.area - a.area);

  const [latLng, setLatLng] = useState({})
  const [orderId, setOrderId] = useState('')
  const [selectedSearchOption, setSelectedSearchOption] = useState(searchOptions[0])
  const [mapCenter, setMapCenter] = useState({latLng: {}})
  const [defaultMapCenter, setDefaultMapCenter] = useState({latLng: DEFAULT_MAP_CENTER.SAUDI_ARBIA}) 
  const handleClearSearch = () => {
    setLatLng({
      latitude: '',
      longitude: ''
    })
    setOrderId('')
    setMapCenter({latLng: {}})
  }

  const handleChangeSearchOption = (value) => {
    setSelectedSearchOption(value)
  }

  const handleSetUserLocation = () => {
    if (!zonesData?.length && !Object.values(selectedHubs)?.length) {
      const success = (pos) => {
        const crd = pos.coords
        setDefaultMapCenter({latLng:{ lat: crd.latitude, lng: crd.longitude }})
      }
      const error = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error, {enableHighAccuracy: true})
      }
    }
  }

  const createPolygon = ({coordinates, color, map}) => {
    const deliveredPath = new window.google.maps.Polygon({
      path: coordinates,
      strokeColor: color,
      fillColor: color,
      fillOpacity: 0.12,
      strokeOpacity: 1,
      strokeWeight: 2,
      map // Set the map instance
    })
    return deliveredPath
  }

  const createInfoWindowForPolygons = (polygons, map) => {
    polygons.forEach((polygon, index) => {
      const infoWindow = new window.google.maps.InfoWindow({headerDisabled: true})
      const contentString = `${ReactDOMServer.renderToStaticMarkup(<DeliveryZoneInfoTooltip zone={zonesData[index]} color={polygon.get('fillColor')}/>)}`
      window.google.maps.event.addListener(polygon, 'click', (event) => {
        infoWindow.setContent(contentString)
        infoWindow.setPosition(event.latLng)
        infoWindow.open(map)
        polygon.setOptions({ fillOpacity: 0.50 })
        // fitPolygonsToBounds([polygon], map, mapCenter)
      })
      window.google.maps.event.addListener(polygon, 'mouseover', () => {
        polygon.setOptions({ fillOpacity: 0.50 })
      })
      // Add event listener for mouseout
      window.google.maps.event.addListener(polygon, 'mouseout', () => {
        infoWindow.close()
        polygon.setOptions({ fillOpacity: 0.25 })
      })
    })
  }

  const createMarkersForSelectedHub = (map) => {
    // Add markers for the selected hub
    const bounds = new window.google.maps.LatLngBounds()
    const infoWindow = new window.google.maps.InfoWindow({headerDisabled: true})
    Object.values(selectedHubs)?.forEach((hub) => {
      const svgMarker = {
        path: 'M34 80.9999L10.5 57.4999C-2.5 44.5212-2.5 23.4786 10.5 10.4999C23.4783-2.4788 44.5209-2.4788 57.5 10.4999C70.4783 23.4786 70.4783 44.5212 57.5 57.4999L34 80.9999ZM33.9996 22.8999L21.2496 27.9999V45.2499H25.7496V35.4999C25.7496 34.8749 25.9684 34.3436 26.4059 33.9061C26.8434 33.4686 27.3746 33.2499 27.9996 33.2499H39.9996C40.6246 33.2499 41.1559 33.4686 41.5934 33.9061C42.0309 34.3436 42.2496 34.8749 42.2496 35.4999V45.2499H46.7496V27.9999L33.9996 22.8999ZM27.9996 47.4999H21.2496C20.6246 47.4999 20.0934 47.2811 19.6559 46.8436C19.2184 46.4061 18.9996 45.8749 18.9996 45.2499V28.0374C18.9996 27.5624 19.1309 27.1374 19.3934 26.7624C19.6559 26.3874 19.9996 26.1124 20.4246 25.9374L33.1746 20.8374C33.4496 20.7124 33.7246 20.6499 33.9996 20.6499C34.2746 20.6499 34.5496 20.7124 34.8246 20.8374L47.5746 25.9374C47.9996 26.1124 48.3434 26.3874 48.6059 26.7624C48.8684 27.1374 48.9996 27.5624 48.9996 28.0374V45.2499C48.9996 45.8749 48.7809 46.4061 48.3434 46.8436C47.9059 47.2811 47.3746 47.4999 46.7496 47.4999H39.9996V35.4999H27.9996V47.4999ZM29.8746 47.4999V45.2499H32.1246V47.4999H29.8746ZM32.8746 42.9999V40.7499H35.1246V42.9999H32.8746ZM35.8746 47.4999V45.2499H38.1246V47.4999H35.8746Z',
        fillColor: hub.color,
        fillOpacity: 1,
        strokeColor: 'white',
        strokeWeight: 1,
        scale: 0.5,
        anchor: new window.google.maps.Point(34, 81)
      }    
      const hubMarker = new window.google.maps.Marker({
        position: hub.center,
        icon: svgMarker,
        draggable: false,
        fillColor: 'blue',
        map
      })
      hubMarkers.push(hubMarker)
      bounds.extend(hub.center)
      // Add hover listener to show hub name
      const contentString = `${ReactDOMServer.renderToStaticMarkup(<div className="d-flex align-items-baseline gap-8px text-truncate" title={hub.label}>
        <div className="flex-shrink-0"><Hub width={16} height={16} color="var(--bs-dark-6)"/></div>
        <div className="txt-asst-rg text-dark-6 text-truncate">{hub.label}</div>
      </div>)}`
      hubMarker.addListener('mouseover', () => {
        infoWindow.setContent(contentString)
        infoWindow.open(map, hubMarker)
      })

      // Add listener to close InfoWindow on mouseout
      hubMarker.addListener('mouseout', () => {
        infoWindow.close()
      })
    })
    map.fitBounds(bounds)
    new MarkerClusterer({map, markers: hubMarkers})
  }

  const handleCreateMarkerForSearchPin = (map, latLng) => {
    const infoWindow = new window.google.maps.InfoWindow()
    const locationSearchMarker = new window.google.maps.Marker({
      position: {lat: latLng.latitude, lng: latLng.longitude},
      draggable: false,
      map
    })
    const searchQuery = STRINGIFY({
      column: { id: 'cs_query', name: 'Order'},
      query: orderId
    })
    const query = `${ROUTES_MAPPING[ROUTES_KEYS.LIVE_ORDERS]}?currentTab=all&q=${searchQuery}`
    const contentString = `${ReactDOMServer.renderToStaticMarkup(<div className="d-flex flex-column gap-8px text-truncate">
      {orderId && <div className="txt-asst-rg text-dark-6 text-truncate">Order ID: <a className="text-primary" href={query}>{orderId}</a></div>}
      <div className="txt-asst-rg text-dark-6 text-truncate">Latitude: <span className="text-dark">{latLng.latitude}</span></div>
      <div className="txt-asst-rg text-dark-6 text-truncate">Longitude: <span className="text-dark">{latLng.longitude}</span></div>
    </div>)}`
    infoWindow.setContent(contentString)
    infoWindow.open(map, locationSearchMarker)
    locationSearchMarker.addListener('click', () => {
      infoWindow.setContent(contentString)
      infoWindow.open(map, locationSearchMarker)
    })
  }

  const handleLatLongSearchChange = (event) => {
    const { value, name } = event.target
    const numberValue = +value
    setLatLng(prev => ({ ...prev, [name]: numberValue }))
  }

  const handleDrawPolyGons = (map, isFitToBounds = true) => {
    const polygons = zonesData.map((zone) => {
      const color = selectedHubs[zone.hub_id]?.color || '#142CDA'
      const isActive= zone.is_active

      const deliveredPath = isActive ? createPolygon({coordinates: zone.coordinates, color, map}) : createPolygonWithHatch({coordinates: zone.coordinates, color, map, hatchOverlayRef})
      return deliveredPath
    })
    polygonRefs.current = polygons
    if (isFitToBounds) {
      fitPolygonsToBounds(polygons, map, mapCenter)
    }
    createInfoWindowForPolygons(polygons, map)
  }

  const handleSetCenter = (latLng) => {
    setMapCenter({latLng})
  }

  const { mutate: getSingleOrderDetail, isPending: isOrderDetailPending } = useMutation({
    mutationKey: ['get-single-order-detail'],
    mutationFn: () => {
      return axiosInstance.get('/api/v1/oms/orders', {
        params: {
          cs_query: orderId
        }
      })
    },
    onSuccess: ({data}) => {
      const order = data.data?.orders?.[0]
      if (order) {
        const shippingAddress = order?.shipping_address
        if (shippingAddress?.latitude && shippingAddress?.longitude) {
          const latLng = {latitude: shippingAddress.latitude, longitude: shippingAddress.longitude}
          handleSetCenter(latLng)
        } else {
          CustomToast('Latitude and Longitude not present', {my_type: 'error', audioRequired: false})
          setLatLng({
            latitude: '',
            longitude: ''
          })
          setMapCenter({latLng: {}})
        }
      } else {
        CustomToast('Order ID not found', {my_type: 'error', audioRequired: false})
        setLatLng({
          latitude: '',
          longitude: ''
        })
        setMapCenter({latLng: {}})
      }
    },
    onError: () => {
      CustomToast('Something went wrong, Please try again later', {my_type: 'error', audioRequired: false})
      setLatLng({
        latitude: '',
        longitude: ''
      })
      setMapCenter({latLng: {}})
    }
  })

  const handleInitilaizeHatch = () => {
    const HatchOverlay = handleGetHatchOverlayClass()
    hatchOverlayRef.current = HatchOverlay;
  }

  useEffect(() => {
    handleSetUserLocation()
  }, [])

  useEffect(() => {
    if (!hatchOverlayRef.current) {
      handleInitilaizeHatch()
    }
  }, [filter.status])

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: defaultMapCenter.latLng,
      zoom,
      styles: MAP_STYLES
    })
    if (Object.values(selectedHubs)?.length) createMarkersForSelectedHub(map)
    if (mapCenter?.latLng?.latitude && mapCenter?.latLng?.longitude) {
      handleCreateMarkerForSearchPin(map, mapCenter.latLng)
    }
    //Draw polygons
    if (zonesData?.length || (mapCenter?.latLng?.latitude && mapCenter?.latLng?.longitude)) handleDrawPolyGons(map)
    // Clean up function
    return () => {
      polygonRefs.current.forEach((marker) => marker.setMap(null))
    }
  }, [selectedDeliveryZones, zoom, defaultMapCenter, mapCenter, selectedHubs])

  return {
    mapRef,
    selectedSearchOption,
    searchOptions,
    handleChangeSearchOption,
    handleLatLongSearchChange,
    handleSetCenter,
    handleClearSearch,
    getSingleOrderDetail,
    isOrderDetailPending,
    latLng,
    orderId,
    setOrderId
  }
}

export default useMapComponent