import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import Modal from '@src/@core/components/ui/modal/Modal'
import Select from '@src/@core/components/ui/select'
import { getApiUrl } from '@src/api.urls'
import { axiosInstance } from '@src/network/AxiosInstance'
import StripForReasonNotAvailable from '@src/views/sales/components/reasonsConfigrationRequired/StripForReasonNotAvailable'
import { CANCEL_ORDER_REASON } from '@src/views/sales/constant/orders.constants'
import { useFetchReasons } from '@src/views/sales/hooks/useFetchReasons'
import { cancelReturnOrderApi } from '@src/views/sales/sales.apis'
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants'
import { useMutation } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form, ModalBody } from 'reactstrap'

const ReturnOrderCancelPopup = (prop) => {
  const { isReturnOrderCancelPopupOpen, setIsReturnOrderCancelPopupOpen, orderDetail, setOrderDetail, handleCancelOrderSuccess } = prop
  const { t } = useTranslation()

  const { reasons } = useFetchReasons({
    isModalOpen: isReturnOrderCancelPopupOpen,
    entity_type: OMS_REASON_CONFIG.cancel_return_order.key,
  })
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange'
  })

  const getModalTitle = () => {
    return  <>
      <div>{t('Are you sure you want to cancel this return order?')}</div>
      <div>{orderDetail?.return_order_id && <span>{t('Return Order ID')}: {orderDetail.return_order_id}</span>}</div>
    </>
  }

  const { mutate: cancelReturnOrder, isPending: isCancelReturnOrderPending } = useMutation({
    mutationKey: cancelReturnOrderApi.apiKey,
    mutationFn: (body) => axiosInstance.put(getApiUrl(cancelReturnOrderApi.url, { orderId: orderDetail.id }), body),
    onSuccess: (response) => {

      handleCancelOrderSuccess()
      CustomToast(response.data.data.message, { my_type: 'success' })
      setOrderDetail(null)
      reset()
    }
  })


  const handleCancelOrder = (data) => {
    const reason = data.cancel_reasons.value === CANCEL_ORDER_REASON.OTHER.key ? data.reason_description : data.cancel_reasons.value
    cancelReturnOrder({ reason })
  }

  const handleToggle = () => {
    setIsReturnOrderCancelPopupOpen(prev => !prev)
    reset()
  }

  return (
    <Modal
      icon='warning'
      isOpen={isReturnOrderCancelPopupOpen}
      title={getModalTitle()}
      onClosed={reset}
      closeModalHandler={handleToggle}
      secondaryButtonText={t('Take Me Back')}
      secondaryButtonHandler={handleToggle}
      primaryButtonText={t('Yes, Cancel')}
      primaryButtonHandler={handleSubmit(handleCancelOrder)}
      primaryButtonProps={{ loading: isCancelReturnOrderPending, disabled: isCancelReturnOrderPending }}
      isTranslationRequired={false}
    >
      <ModalBody className="p-0">
        <div className="txt-body-rg text-dark-6 mb-24px">{t('This action cannot be reversed.')}</div>
        <Form>
          <Controller
            name='cancel_reasons'
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Select
                {...field}
                errors={errors}
                options={reasons}
                isRequired
                label={t('Reason for Cancellation')}
                isDisabled={isCancelReturnOrderPending || reasons?.length === 0}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 1100 })
                }}
              />
            )}
          />
          {reasons?.length === 0 && <StripForReasonNotAvailable entity_type={OMS_REASON_CONFIG.cancel_return_order.key} />}
        </Form>
      </ModalBody>
    </Modal>
  )
}

ReturnOrderCancelPopup.propTypes = {
  isReturnOrderCancelPopupOpen: PropTypes.bool,
  setIsReturnOrderCancelPopupOpen: PropTypes.func,
  orderDetail: PropTypes.object,
  setOrderDetail: PropTypes.func,
  handleCancelOrderSuccess: PropTypes.func
}

export default ReturnOrderCancelPopup
