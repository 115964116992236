import { HUBS_CONFIGURATION } from '@src/App.constants'
import { RETURN_ORDER_TABS } from '@src/views/returns/returns.constants'
import { t } from 'i18next'
import { RETURN_ORDER_DETAIL_CARDS } from './constant'

export const formatPickupDetails = (pickupAddress) => {
  if (!pickupAddress) return { pickup_name: '', pickup_address: '' }

  const pickup_name = `${pickupAddress.first_name} ${pickupAddress.last_name}`
  const addressComponents = [
    pickupAddress.address1,
    pickupAddress.address2,
    pickupAddress.city,
    pickupAddress.state,
    pickupAddress.country,
    pickupAddress.zip,
  ]
  const pickup_address = addressComponents.filter(Boolean).join(', ')

  return { pickup_name, pickup_address }
}

export const getLocationInventoryStatus = (activeReturnOrderPage, returnOrderHubDetail, selectedHubConfiguration) => {
  if (activeReturnOrderPage === RETURN_ORDER_TABS.ALL.id) {
    return returnOrderHubDetail?.configurations?.find(
      (config) => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value
    )?.configuration_values?.enabled
  }

  return selectedHubConfiguration?.find(
    (config) => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value
  )?.configuration_values?.enabled
}

export const getReturnOrderDetails = (returnOrderDetail) => {
  if (!returnOrderDetail) return [];

  const {
    source,
    created_at,
    order_created_at,
    order_delivered_at,
    delivered_quantity,
    type,
    shipment,
  } = returnOrderDetail;

  const isCourierPartnerReturn = type === RETURN_ORDER_DETAIL_CARDS.COURIER_PARTNER_RETURN;

  return [
    { 
      key: t('Return Source'), 
      value: source, 
      card: RETURN_ORDER_DETAIL_CARDS.RETURN_DETAILS 
    },
    { 
      key: t('Request Created At'), 
      value: created_at, 
      omit: isCourierPartnerReturn, 
      card: RETURN_ORDER_DETAIL_CARDS.RETURN_DETAILS 
    },
    { 
      key: t('Order Created At'), 
      value: order_created_at, 
      card: RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS 
    },
    { 
      key: t('Order Delivered At'), 
      value: order_delivered_at, 
      omit: isCourierPartnerReturn, 
      card: RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS 
    },
    { 
      key: t('Total Shipped Qty'), 
      value: delivered_quantity, 
      omit: !isCourierPartnerReturn, 
      card: RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS 
    },
    { 
      key: t('Total Delivered Qty'), 
      value: delivered_quantity, 
      omit: isCourierPartnerReturn, 
      card: RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS 
    },
    { 
      key: t('Shipping Partner'), 
      value: shipment?.shipping_partner_name, 
      card: RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS 
    },
    { 
      key: t('Awb Number'), 
      value: shipment?.awb_number, 
      url: shipment?.tracking_url, 
      card: RETURN_ORDER_DETAIL_CARDS.ADDITIONAL_ORDER_DETAILS 
    },
  ].filter(item => !item.omit); // Removes items with `omit: true`
};

