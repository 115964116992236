import { DROPDOWN_TYPES } from '@src/@core/components/ui/customTableFilterComponent/customTableFilterComponent.constant'
import { loadAllSellersConfig, loadShippingPartnerConfig } from '@src/app.apis'
import { TENANT_TYPE } from '@src/App.constants'
import { getOptionsFetcher } from '@src/utility/Utils'
import { format, startOfDay, subDays } from 'date-fns'
import { useSelector } from 'react-redux'
import { getHubsAsyncDataForMultiSelectDropdown } from '../analytics/store'
import { getHubsAsyncData } from '../settings/store'
import AppliedFilters from './pages/AppliedFilters'
import { loadReportsCategoriesConfig } from './reports.apis'
import { getHubLocations, getSellerSkus, getTenantSuppliers } from './store'

export const REPORTS_TABS = {
  categories: { id: 'categories', name: 'Generate New Reports'},
  myreports: { id: 'myreports', name: 'My Generated Reports'},
  archive: { id: 'archive', name: 'Archived Reports' }
}

export const REPORT_DETAILS_TABS = {
  applied_filters: { id: 'applied_filters', name: 'Applied Filters', component: AppliedFilters }
  // download_history: { id: 'download_history', name: 'Download History', component: DownloadHistory }
}

export const FILTERS_DATA_TYPES = {
  STRING: 'string',
  BOOL: 'bool'
}

export const FILTERS_TYPES = {
  ALL: 'all_filters',
  FREQUENT: 'frequent_filters'
}

export const DEFAULT_SELECTED_FILTERS = {
  HUB_ID: 'hub_id'
}
export const FILTER_KEYS = {
  DATE: 'date',
  DATE_RANGE: 'date_range',
  CREATED_DATE_RANGE: 'created_daterange',
  SELLER_ID : 'seller_id',
  SELLER_IDS : 'seller_ids',
  HUB_ID: 'hub_id',
  HUB_IDS: 'hub_ids',
  OUT_OF_STOCK: 'out_of_stock',
  PENDING_ORDER_REASON : 'pending_order_reasons',
  ORDER_STATUS: 'order_status',
  MANIFEST_STATUS: 'manifest_status',
  SUPPLIER_ID: 'supplier_id',
  GATE_ENTRY_STATUS: 'gate_entry_status',
  PUT_AWAY_TYPE: 'put_away_type',
  SHIPPING_PARTNER: 'shipping_partner',
  EXPIRY_DATE_RANGE:'expiry_date_range',
  ACTION_TYPE: 'action_type',
  ABSOLUTE_DATE_RANGE: 'absolute_date_range',
  RETURN_ORDER_STATUS: 'return_order_status',
  LOCATION_ID: 'location_id',
  SELLER_SKU_ID: 'seller_sku_id',
  STO_REQUEST_STATUS: 'sto_request_status',
  ORDER_TYPE: 'order_type',
  RETURN_REQUEST_STATUS: 'return_request_status'
}

export const INPUT_SOURCE = {
  CHECKBOX: 'checkbox',
  RADIO_BUTTON: 'radio_button',
  API: 'api',
  DATE_PICKER: 'datepicker'
}

export const useFilterFilterMapping = () => {
  const tenantType = useSelector((state) => state.auth.userData?.tenant.type)
  const tenantSellers = useSelector(state => state.auth.userSellers)
  const isSelfTenant = tenantType === TENANT_TYPE.SELF_USAGE
  const defaultSeller = { label: tenantSellers[0]?.name, value: tenantSellers[0]?.seller_id }
  const loadShippingPartnerOptions = getOptionsFetcher(loadShippingPartnerConfig())

  const loadAllSellerOptions = (isMultiSelect = false) => {
    return getOptionsFetcher(loadAllSellersConfig({ isMultiSelect: isMultiSelect }))
  }

  const getFilterMapping = (filter, appliedFilters) => {
    switch (filter.key_name) {
    case FILTER_KEYS.SELLER_ID: {
      
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
        isAsync: true,
        options: loadAllSellerOptions()
      }
    }
    case FILTER_KEYS.SELLER_IDS: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
        isAsync: true,
        options: loadAllSellerOptions(true)
      }
    }
    case FILTER_KEYS.HUB_ID: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
        isAsync: true,
        options: getHubsAsyncData
      }
    }
    case FILTER_KEYS.HUB_IDS: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
        isAsync: true,
        options: getHubsAsyncDataForMultiSelectDropdown
      }
    }
    case FILTER_KEYS.DATE_RANGE: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: filter.input_source,
        defaultValue: filter.is_required ? [format(new Date(), 'dd-MM-yyyy'), format(new Date(), 'dd-MM-yyyy')] : null
      }
    }
    case FILTER_KEYS.DATE: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: filter.input_source,
        defaultValue: filter.is_required ? {label: format(new Date(), 'dd-MM-yyyy'), value: format(new Date(), 'dd-MM-yyyy')} : null
      }
    }
    case FILTER_KEYS.OUT_OF_STOCK: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.PENDING_ORDER_REASON: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.ORDER_STATUS: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.MANIFEST_STATUS: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.SUPPLIER_ID: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
        isAsync: true,
        options: getTenantSuppliers
      }
    }
    case FILTER_KEYS.GATE_ENTRY_STATUS: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.PUT_AWAY_TYPE: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.SHIPPING_PARTNER: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
        isAsync: true,
        options: loadShippingPartnerOptions,
      }
    }
    case FILTER_KEYS.EXPIRY_DATE_RANGE: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: filter.input_source,
        defaultValue: filter.is_required ? [format(new Date(), 'dd-MM-yyyy'), format(new Date(), 'dd-MM-yyyy')] : null
      }
    }
    case FILTER_KEYS.ABSOLUTE_DATE_RANGE: {
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: filter.input_source,
        defaultValue: filter.is_required ? [format(startOfDay(subDays(new Date(), 1)), 'dd-MM-yyyy'), format(startOfDay(subDays(new Date(), 1)), 'dd-MM-yyyy')] : null
      }
    }
    case FILTER_KEYS.ACTION_TYPE: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.RETURN_ORDER_STATUS: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.LOCATION_ID: {
      const selectedHubId = appliedFilters?.[FILTER_KEYS.HUB_ID]?.value || appliedFilters?.[FILTER_KEYS.HUB_IDS]?.[0]?.value
      const isHubSelectedMulti = appliedFilters?.[FILTER_KEYS.HUB_IDS]?.length > 1
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
        isAsync: true,
        isDisabled: !selectedHubId || isHubSelectedMulti,
        options: getHubLocations,
        tooltipText: isHubSelectedMulti ? 'To enable location selection, choose only one hub' : 'First select the hub',
        additional: {
          page: 1,
          hub_id: selectedHubId
        },
        cacheUniqs: [selectedHubId]
      }
    }
    case FILTER_KEYS.SELLER_SKU_ID: {
      const selectedSellerId = appliedFilters?.[FILTER_KEYS.SELLER_ID]?.value || appliedFilters?.[FILTER_KEYS.SELLER_IDS]?.[0]?.value
      const isSellerSelectedMulti = appliedFilters?.[FILTER_KEYS.SELLER_IDS]?.length > 1
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
        isAsync: true,
        isDisabled: (!selectedSellerId && !isSelfTenant) || isSellerSelectedMulti,
        options: getSellerSkus,
        tooltipText: isSellerSelectedMulti ? 'To enable SKU selection, choose only one seller' : 'Select a seller first',
        additional: {
          page: 1,
          seller_id: isSelfTenant ? defaultSeller.value : selectedSellerId
        },
        cacheUniqs: [selectedSellerId]
      }
    }
    case FILTER_KEYS.STO_REQUEST_STATUS: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.ORDER_TYPE: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    case FILTER_KEYS.RETURN_REQUEST_STATUS: {
      const defaultValueElement = filter.is_required
        ? filter.values.find((element) => element.default)
        : null
      return {
        filterKey: filter.key_name,
        displayName: filter.display_name,
        type: DROPDOWN_TYPES.DROPDOWN,
        isAsync: false,
        options: filter.values.map((element) => ({ label: element.display_name, value: element.key_name })),
        defaultValue: defaultValueElement ? {label: defaultValueElement.display_name, value: defaultValueElement.key_name} : null
      }
    }
    default: {
      return null
    }
    }
  }
  return {getFilterMapping}
}
export const DEFAULT_FILTER_VALUE = {
  ORDER_STATUS: 'new_order',
  MANIFEST_STATUS: 'ready_for_handover'
}

export const DOWNLOADED_REPORT_STATUS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  FAILED: 'failed'
}

export const GET_DOWNLOAD_NOTIFICATION_FIRST_FOUR_QUERY_PARAMS = { page: 1, per_page: 4, sort: JSON.stringify([{ field: 'updated_at', order: 'desc' }]), preview: true }

export const PREPARED_REPORTS_FILTER_DATA = () =>{
  const loadReportsCategoriesOptions = getOptionsFetcher(loadReportsCategoriesConfig)
  return {
    // hub_id: {
    //     isAsync: true,
    //     filterKey: "hub_id",
    //     displayName: "Hubs",
    //     type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
    //     options: getHubsAsyncData
    // },
    category: {
      isAsync: true,
      filterKey: 'category',
      displayName: 'Reports',
      type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
      options: loadReportsCategoriesOptions
    },
    date_range: {
      filterKey: 'date_range',
      displayName: 'Date Range'
    }
  }
}

export  const PUSHER_REPORT_RESPONSE_KEY = {
  'order-item': {
    success: 'success',
    error: 'error'
  },
  'location-inventory': {
    success: 'success',
    error: 'error'
  },
  'hub-inventory': {
    success: 'success',
    error: 'error'
  },
  'inventory-log': {
    success: 'success',
    error: 'error'
  },
  'purchase-order': {
    success: 'success',
    error: 'error'
  }
}

export const starColor = '#ffd700'

export const API_METHOD = {
  POST: 'post',
  DELETE: 'delete'
}

export const REPORTS_PAGES = {
  PENDING_ORDER: 'pending-order',
  MANIFEST: 'manifest',
  ORDER_ITEM_LEVEL: 'order-item-level',
  HUB_INVENTORY: 'hub-inventory',
  INVENTORY_LOG: 'inventory-log',
  LOCATION_INVENTORY: 'location-inventory',
  GRN: 'grn',
  PURCHASE_ORDER: 'purchase-order',
  ORDERS: 'orders',
  BATCHES: 'batch'
}

export const REPORT_PREVIEW_TABLE_COLUMNS = {
  SERIAL_NO: {name: 'S. No.', key: 'serial_no'}
}

export const MAX_PAST_DAYS = 89

export const INITIAL_TIME_REPORT_PREVIEW_PER_PAGE = 5

export const ADDITIONAL_FILTER_DROPDOWN_STYLE_REPORTS = {
  control: (baseStyles) => ({
    ...baseStyles,
    minWidth: '100px !important',
    maxWidth:'200px !important',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}