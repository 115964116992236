import { isObjEmpty } from '@src/utility/Utils'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useA4LayoutStyles } from './useA4LayoutStyles'

const A4Layout = forwardRef(({ printData, hideFooterDiscountFiled }, ref) => {

  const { t } = useTranslation('translation', { lng: printData.preferences.language })
  const isRTL = printData.preferences.language === 'ar'
  if (!printData) return null

  const currency = printData.currency || ''
  const columnClass = printData.itemSection.include_price.show ? 'price-column' : 'no-price-column'

  const shouldIgnoreHeaderFieldInInvoice = (fieldId) => {
    const ignoredFields = ['company_logo']
    return ignoredFields.includes(fieldId)
  }

  const isHeaderVisible = Object.values(printData.headerSection)
    .filter(item => !shouldIgnoreHeaderFieldInInvoice(item.id))
    .some(item => item.show === true)
  
  const { styles } = useA4LayoutStyles(printData)

  const getColumnWidths = () => {
    const showPriceColumn = printData.itemSection.include_price.show
    const showTaxInclusiveColumn = printData.itemSection.total_tax_inclusive.show
    const showTaxExclusiveColumn = printData.itemSection.total_tax_exclusive.show

    if (showPriceColumn) {
      return styles.columnWidths.sixColumns
    } else if (showTaxInclusiveColumn || showTaxExclusiveColumn) {
      return styles.columnWidths.fiveColumns
    } else {
      return styles.columnWidths.fourColumns
    }
  }

  const columnWidthStyles = getColumnWidths()

  const renderCommentSection = () => {
    const comment = printData.footerSection.comment
    const commentImage = printData.footerSection.comment_image

    if ((comment.show && comment.value) || (commentImage.show && commentImage.value)) {
      return (
        <div style={styles.commentSection} ref={ref}>
          {commentImage.show && commentImage.value && (
            <img src={commentImage.url} style={styles.commentImage} alt='comment image' />
          )}
          {comment.show && comment.value && (
            <p style={styles.commentText}>{comment.value}</p>
          )}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className="a4-preview-layout" ref={ref} style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
      {/* header section start */}
      <div style={styles.header}>
        {printData.headerSection.company_logo.show && printData.headerSection.company_logo.value && (
          <div style={styles.logo}>
            <img src={printData.headerSection.company_logo.value} alt="company-logo" style={{ objectFit: 'contain' }} height="38px" width="150px" />
          </div>
        )}
        <div style={styles.title}>
          {t('Invoice')}
        </div>
        {isHeaderVisible && (
          <div style={styles.headerGrid}>
            {Object.values(printData.headerSection).map((item, index) => (
              !shouldIgnoreHeaderFieldInInvoice(item.id) && item.show && (
                <div style={styles.headerItem} key={index}>
                  <span style={styles.headerLabel}>{t(item.label)}</span>
                  <span style={styles.headerValue}>{item.value}</span>
                </div>
              )
            ))}
          </div>
        )}
      </div>
      {/* header section end */}

      {/* items section start */}
      <div style={styles.itemsSection}>
        <table className={`general-table ${columnClass}`} style={styles.table}>
          <thead>
            <tr>
              <th style={{ ...styles.tableHeader, width: columnWidthStyles.firstColumn }}></th>
              <th style={{ ...styles.tableHeader, width: columnWidthStyles.secondColumn }}>
                <span>{t('Product Name')}</span>
                {printData.itemSection.sku_barcode.show && <span> / {t('SKU')}</span>}
              </th>
              {(printData.itemSection.tax_amount.show || printData.itemSection.tax_percentage.show) && <th style={{ ...styles.tableHeader, ...styles.rightAlign, width: columnWidthStyles.thirdColumn }}>
                {t('Tax Breakdown')}
              </th>}
              {printData.itemSection.include_price.show && <th style={{ ...styles.tableHeader, ...styles.rightAlign, width: columnWidthStyles.fourthColumn }}>{t('Unit Price (Tax Inclusive)')}</th>}
              {printData.itemSection.total_tax_inclusive.show && <th style={{ ...styles.tableHeader, ...styles.rightAlign, width: columnWidthStyles.fifthColumn }}>{t('Total (Tax Inclusive)')}</th>}
              {printData.itemSection.total_tax_exclusive.show && <th style={{ ...styles.tableHeader, ...styles.rightAlign, width: columnWidthStyles.sixthColumn }}>{t('Total (Tax Exclusive)')}</th>}
            </tr>
          </thead>
          <tbody>
            {printData.items.map((item, index) => (
              <tr key={index}>
                <td style={styles.tableCell}>{item.qty}x</td>
                <td style={styles.tableCell}>
                  <p style={{ margin: '0' }}>{item.product_name}</p>
                  {printData.itemSection.sku_barcode.show && <p style={{ margin: '0' }}>{item.sku_barcode}</p>}
                  {(printData.itemSection.tax_amount.show || printData.itemSection.tax_percentage.show) && (
                    <p style={{ margin: '0' }}>
                      <span>{t('Tax')}: </span>
                      {printData.itemSection.tax_amount.show && <span>{item.tax_amount} {currency}</span>}
                      {printData.itemSection.tax_amount.show && printData.itemSection.tax_percentage.show && <span> / </span>}
                      {printData.itemSection.tax_percentage.show && <span>{item.tax_percentage}%</span>}
                    </p>
                  )}
                </td>
                {(printData.itemSection.tax_amount.show || printData.itemSection.tax_percentage.show) && <td style={{ ...styles.rightAlign, ...styles.tableCell }}>
                  {item.tax_lines?.map((tax) => (
                    <p style={{ margin: '0' }}>{tax.name}: {tax.amount} {currency}</p>
                  ))}
                  {!isObjEmpty(item.compound_tax) && <p style={{ margin: '0' }}>{item.compound_tax.name}: {item.compound_tax.amount} {currency}</p>}
                </td>}
                {printData.itemSection.include_price.show && (
                  <td style={{ ...styles.rightAlign, ...styles.tableCell }}>
                    <p style={{ margin: '0' }}>{item.price} {currency}</p>
                    {!(printData.footerSection.hide_discount_if_not_exist.show && item.discount_amount === '0.00') && printData.itemSection.discount_amount.show && <p style={{ margin: '0' }}>{t('Disc')}: {item.discount_amount} {currency}</p>}
                  </td>
                )}
                {printData.itemSection.total_tax_inclusive.show && <td style={{ ...styles.rightAlign, ...styles.tableCell }}>{item.total_tax_inclusive} {currency}</td>}
                {printData.itemSection.total_tax_exclusive.show && <td style={{ ...styles.rightAlign, ...styles.tableCell }}>{item.total_tax_exclusive} {currency}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* items section end */}

      {/* footer section start */}
      {printData.footerSection.display_footer_section.show && (
        <div style={styles.footerSection}>
          <div style={styles.footerContent}>
            <div style={styles.footerItems}>
              {printData.footerSection.display_total_items.show && (
                <div style={styles.footerItem}>
                  <span style={styles.footerLabel}>{t('Total Items')}</span>
                  <span style={styles.footerValue}>{printData.footerSection.totalItems}</span>
                </div>
              )}
              <div style={styles.footerItem}>
                <span style={styles.footerLabel}>{t('Sub Total (Tax Exclusive)')}</span>
                <span style={styles.footerValue}>{printData.footerSection.subTotal} {currency}</span>
              </div>
              {!hideFooterDiscountFiled && <div style={styles.footerItem}>
                <span style={styles.footerLabel}>{t('Total Discounts')}</span>
                <span style={styles.footerValue}>{printData.footerSection.totalDiscounts} {currency}</span>
              </div>}
              {/* <div style={styles.footerItem}>
                <span style={styles.footerLabel}>{t('Other Discounts')}</span>
                <span style={styles.footerValue}>{printData.footerSection.otherDiscounts} {currency}</span>
              </div> */}
              <div style={styles.footerItem}>
                <span style={styles.footerLabel}>{t('Total Tax')}</span>
                <span style={styles.footerValue}>{printData.footerSection.totalTax} {currency}</span>
              </div>
              <div style={styles.footerItem}>
                <span style={styles.footerLabel}>{t('Total')}</span>
                <span style={styles.footerValue}>{printData.footerSection.total} {currency}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* footer section end */}
      {printData.footerSection.sales_note.show && <div style={styles.salesNotes}>
        <p style={{ margin: '0', color: '#666666' }}>{t('Sales Note')}:</p>
        <p style={{ margin: '0' }}>{printData.footerSection.sales_note.value}</p>
      </div>}
      {/* comment section start */}
      {renderCommentSection()}
      {/* comment section end */}
    </div>
  )
})

export default A4Layout
