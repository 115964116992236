import Modal from '@src/@core/components/ui/modal/Modal'

const BatchSelectWarningModal = ({isOpen, toggle, handleDiscardProcess}) => {

  return (
    <Modal
      isOpen={isOpen}
      title='Quantity Mismatch!'
      subTitle='The selected quantity in Batch does not match the ordered quantity.'
      primaryButtonText='Edit Batches'
      secondaryButtonText='Discard'
      primaryButtonHandler={toggle}
      secondaryButtonHandler={handleDiscardProcess}
      closeButtonHandler={toggle}
    />
  )
}

export default BatchSelectWarningModal