import BundleSkuDetailsSideSheet from '@src/@core/components/ui/bundle-sku-details'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { SKU_TYPES } from '@src/App.constants'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import ReasonsModal from '@src/views/inventory/components/inventoryOperations/grn/GrnItemsView/ReasonsModal'
import ReturnReasonsPopup from '@src/views/returns/components/popup/returnReasonsPopup'
import ExpandableChildTable from '@src/views/sales/components/ExpandableChildTable/ExpandableChildTable'
import { useState } from 'react'
import useReturnItemsChildTableColumns from '../hooks/useReturnItemsChildTableColumns'
import useReturnItemsTableColumns from '../hooks/useReturnItemsTableColumns'

const ReturnItemsDetailTable = (props) => {
  const { order_items, returnOrderDetail } = props

  const [isMoreDetailPopupOpen, setMoreDetailPopupOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState([])
  const [returnReasonsPopUp, setReturnReasonsPopUp] = useState({ isOpen: false, reasonsList: [] })
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openBundleSkuDetailsSidebar, setOpenBundleSkuDetailsSidebar] = useState(false)
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)

  const handleMoreDetailPopup = () => {
    setMoreDetailPopupOpen((prev) => !prev)
  }

  const handleSelectItem = (item) => {
    setSelectedItem(item?.qc_fail_reasons)
    if (item) {
      handleMoreDetailPopup()
    }
  }

  const handleReturnReasonsPopUp = ({ reasonsList }) => {
    if (reasonsList) {
      setReturnReasonsPopUp({ isOpen: true, reasonsList })
    } else {
      setReturnReasonsPopUp({ isOpen: false, reasonsList: [] })
    }
  }

  const handleBundleSkuDetailsSidebar = (data) => {
    if (data) {
      const formattedData = {
        ...data,
        ...data.seller_sku,
        child_skus: data.seller_sku.child_skus,
      }
      setSkuForDetails(formattedData)
      setOpenBundleSkuDetailsSidebar(true)
    } else {
      setOpenBundleSkuDetailsSidebar(false)
    }
  }

  const handleSkuDetailsSidebar = (data) => {
    const filteredData = { ...data }; 
    delete filteredData.sales_channel_sku_id;
    
    setSkuForDetails(filteredData);
    setOpenSkuDetailsSidebar(!openSkuDetailsSidebar)
  }

  const tableColumns = useReturnItemsTableColumns({
    order_items,
    returnOrderDetail,
    handleSelectItem,
    handleReturnReasonsPopUp,
    handleBundleSkuDetailsSidebar,
    handleSkuDetailsSidebar
  })

  const childTableColumns = useReturnItemsChildTableColumns({
    order_items,
    returnOrderDetail,
    handleSelectItem,
    handleSkuDetailsSidebar
  })

  return (
    <>
      <ExpandableCustomTable
        loading={false}
        error={false}
        success={true}
        data={order_items || []}
        showPagination={false}
        columns={tableColumns}
        useReactPaginate={false}
        showColumnsTableHeader={false}
        expandableRows
        expandableRowsHideExpander
        expandableRowsComponent={ExpandableChildTable}
        expandableRowsComponentProps={{ childTableColumns }}
        expandableRowExpanded={(row) => row.seller_sku?.type === SKU_TYPES.BUNDLE.key}
      />
      {/* Failed reasons and images modal */}
      <ReasonsModal
        openReasonsModal={isMoreDetailPopupOpen}
        setOpenReasonsModal={setMoreDetailPopupOpen}
        reasons={selectedItem}
        setReasons={setSelectedItem}
        title='Failed Items'
      />
      <ReturnReasonsPopup returnReasonsPopUp={returnReasonsPopUp} handleReturnReasonsPopUp={handleReturnReasonsPopUp} />
      <BundleSkuDetailsSideSheet isOpen={openBundleSkuDetailsSidebar} bundleSkuForDetails={skuForDetails} toggleBundleSkuModal={() => handleBundleSkuDetailsSidebar(false)} />
      <SimpleSkuDetailModal skuForDetails={skuForDetails} toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)} isOpen={openSkuDetailsSidebar} />
    </>
  )
}

export default ReturnItemsDetailTable
