import { MANUAL_COURIER_PARTNER } from '@src/App.constants'
import { printMultipleAwb } from '@src/utility/Utils'
import { format, parse } from 'date-fns'
import { t } from 'i18next'
import { CREATE_SHIPMENT_STATUS, SHIPPING_ENTITY, SHIPPING_OPTION } from '../constant/orders.constants'
import { SHIPPING_PARTNERS_TAG_NAMES } from '../sales.constant'

export const getOrderDetailsFieldConfigurations = ({ createShipmentStatus, omnifulGeneratedShipmentType, isRemarkDisabled }) => [
  {
    name: 'number_of_boxes',
    label: 'No of Packages',
    validationSchema: {
      required: 'Packages Quantity is required',
      min: {
        value: 1,
        message: t('Please enter a valid number'),
      },
      validate: {
        'less-than-100': (fieldValue) => {
          return +fieldValue <= 1000 || 'Packages Quantity cannot be more than 1000'
        },
        'is-whole-number': (fieldValue) => {
          return /^[0-9]+$/.test(fieldValue) || 'Packages Quantity must be a whole number'
        },
      },
    },
    isClearable: true,
    isRequired: true,
    type: 'number',
    disabled: createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED,
  },
  {
    name: 'weight',
    label: 'Weight in KG',
    validationSchema: {
      required: 'Weight is required',
      validate: (fieldValue) => {
        if (+fieldValue <= 0) {
          return t('Please enter a valid weight')
        } else if (omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name) {
          return null
        } else {
          return +fieldValue <= 1000 || t('Weight cannot be more than 1000')
        }
      },
    },
    isClearable: true,
    isRequired: true,
    type: 'number',
    disabled: createShipmentStatus === CREATE_SHIPMENT_STATUS.SHIPPING_PARTNER_SELECTED,
  },
  {
    name: 'remarks',
    label: 'Enter Remarks',
    type: 'textarea',
    rows: '3',
    disabled: isRemarkDisabled,
  },
]

export const getCourierPartnerConfig = ({
  control,
  getCouriersPartners,
  isCourierPartnerRequired,
  watch,
  errors,
  omnifulGeneratedShipmentType,
  allFields,
  tenantId,
  shipping_partner_tag,
  shippingDetailsData,
  city_Id,
  shippingPartner,
  CustomCourierPartnerOption
}) => ({
  name: 'courier_partner',
  title: t('Courier Partner'),
  type: 'select',
  isAsync: true,
  control,
  loadOptions: getCouriersPartners,
  isRequired: isCourierPartnerRequired,
  isClearable: true,
  value: watch('courier_partner'),
  defaultValue: watch('courier_partner'),
  validationSchema: { courier_partner: { required: isCourierPartnerRequired ? t('Courier Partner is required') : undefined } },
  isDisabled: (omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name && !allFields.city?.value) || allFields.weight === '',
  errors,
  cacheUniqs: [watch('city')],
  additional: {
    tenant_id: tenantId,
    shipping_partner_tag,
    seller_id: shippingDetailsData.entity_data.seller_id,
    city_id: city_Id,
    weight: parseFloat(allFields.weight),
    number_of_boxes: parseInt(allFields.number_of_boxes),
    payment_type: shippingDetailsData.entity_data.payment_method,
    hub: shippingDetailsData.entity_data.hub,
    total_cost: shippingDetailsData.entity_data.invoice_total,
    account_id: shippingPartner?.id,
  },
  additionalComponents: shippingPartner?.shipping_partner?.tag === SHIPPING_PARTNERS_TAG_NAMES.TOROD ? { Option: CustomCourierPartnerOption } : {},
})

export const getParcelShopsConfig = ({
  control,
  getAsyncParcelShop,
  errors,
  watch,
  shipping_partner_tag,
  shippingDetailsData,
  CustomDetailsOption
}) => ({
  name: 'parcel_shop',
  title: t('Select Parcel Shop'),
  type: 'select',
  isAsync: true,
  control,
  loadOptions: getAsyncParcelShop,
  isClearable: true,
  errors,
  cacheUniqs: [watch('shipping_partner')?.id],
  additionalComponents: { Option: CustomDetailsOption },
  additional: {
    shipping_partner_tag,
    seller_id: shippingDetailsData.entity_data.seller_id,
    city: watch('city')?.label,
    country_code: shippingDetailsData.entity_data.shipping_address.country_code,
    pincode: shippingDetailsData.entity_data.shipping_address.postal_code,
    page: 1,
  },
})

export const handleAwb = async ({shipmentDetails, entity_name, fetchAWB, setPrintAwbLoading}) => {
  setPrintAwbLoading(true)
  try {
    if (shipmentDetails?.data?.metadata?.shipping_awb_label) {
      printMultipleAwb(shipmentDetails.data.metadata.shipping_awb_label)
    } else {
      const entityType = entity_name === SHIPPING_ENTITY.SHIPMENT_ORDER.value 
        ? SHIPPING_ENTITY.SHIPMENT_ORDER.value 
        : SHIPPING_ENTITY.FORWARD_ORDER.value
      
      const params = {
        entity_id: shipmentDetails?.data?.oms_order_id,
        entity_type: entityType,
      }
      
      const res = await fetchAWB({ params })
      if (res?.is_success && res?.data?.awb_label) {
        printMultipleAwb(res.data.awb_label)
      }
    }
  } finally {
    setPrintAwbLoading(false)
  }
}

export const prepareShipmentData = ({ data, omnifulGeneratedShipmentType, packageData, isFetch_multi_dimensions, watch, getPrioritisedShippingPartnersResonse }) => {

  let shipmentData
  // The exact same struct is sent
  const fromVillage = data.from_village || {}
  const toVillage = data.to_village || {}
  delete fromVillage.value
  delete fromVillage.label
  delete toVillage.value
  delete toVillage.label

  if (omnifulGeneratedShipmentType === SHIPPING_OPTION.SHIPPING_PARTNER.name) {
    shipmentData = {
      shipment_type: omnifulGeneratedShipmentType,
      shipping_partner_tag: data.shipping_partner?.shipping_partner?.tag,
      order_address: {
        to: {
          city_id: String(data.city.value),
          city: data.city.label,
          country_id: String(data.country.value),
          country: data.country.label,
        },
        village_details: {
          from: fromVillage,
          to: toVillage,
        },
      },
      order_details: {
        number_of_boxes: parseInt(data.number_of_boxes),
        weight: parseFloat(data.weight),
        remarks: data.remarks,
      },
      shipping_account: {
        id: data.shipping_partner?.id,
        name: data.shipping_partner?.account_name,
      },
      suggested_shipping_account: {
        id: data.suggested_shipping_account?.id,
        name: data.suggested_shipping_account?.account_name,
        shipping_partner_name: data.suggested_shipping_account?.shipping_partner?.name,
      },
      omniful_rule_id: getPrioritisedShippingPartnersResonse?.data?.data?.rule_id,
      shipping_reference: data.shipping_reference,
    }
    if (data.length && data.breadth && data.height) {
      shipmentData.order_details.shipment_packages = isFetch_multi_dimensions
        ? packageData
        : [
          {
            length: parseInt(data.length),
            breadth: parseInt(data.breadth),
            height: parseInt(data.height),
          },
        ]
    }
  }

  if (omnifulGeneratedShipmentType === SHIPPING_OPTION.MANUAL_DISPATCH.name || omnifulGeneratedShipmentType === SHIPPING_OPTION.SALES_CHANNEL_NOTIFIED.name) {
    shipmentData = {
      shipment_type: omnifulGeneratedShipmentType,
      awb_number: data.awb,
      tracking_url: data.tracking_url,
      order_details: {
        number_of_boxes: parseInt(data.number_of_boxes),
        weight: parseFloat(data.weight),
        remarks: data.remarks,
      },
      order_address: (data.country?.value || data.city?.value) && {
        to: {
          city_id: data.city?.value && String(data.city.value),
          city: data.city?.label,
          country_id: data.country?.value && String(data.country.value),
          country: data.country?.label,
        },
      },
    }
  }

  if (data.courier_partner && data.courier_partner.value) {
    shipmentData.courier_partner = {
      id: data.courier_partner.value.id,
      name: data.courier_partner.value.name,
      tag: data.courier_partner.value.tag,
      logo: data.courier_partner.value.logo,
    }
    if (data.courier_partner.value.id === MANUAL_COURIER_PARTNER.value) {
      delete shipmentData.courier_partner.id
    }
  }
  if (watch('shipping_partner')?.shipping_partner.details?.show_delivery_slot) {
    const parsedDate = parse(data.delivery_date.value, 'dd-MM-yyyy', new Date())
    shipmentData.order_details.slot = {
      start_time: data.delivery_start_time,
      end_time: data.delivery_end_time,
      delivery_date: format(parsedDate, 'ddMMyyyy'),
    }
  }
  if (watch('nearby_points_details')?.value || watch('opt_insurance')?.value !== undefined || watch('parcel_shop')?.value) {
    shipmentData.pre_shipment_details = {
      point_id: watch('nearby_points_details')?.value,
      opt_insurance: watch('opt_insurance')?.value,
      parcel_shop_id: watch('parcel_shop')?.value,
    }
  }

  if (data.service_type) {
    shipmentData.service_type = data.service_type.value
  }
  const productType = data?.shipping_partner?.shipping_partner?.product_type?.forward
  if (productType?.key_name && data[productType?.key_name]?.value) {
    shipmentData.pre_shipment_details = {
      ...shipmentData.pre_shipment_details,
      [productType.key_name]: data[productType.key_name].value,
    }
  }

  return shipmentData
}
