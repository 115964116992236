import Button from '@src/@core/components/ui/button'
import { PRINT_MODES } from '@src/App.constants'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { Spinner } from 'reactstrap'
import PrintAwbLayout from './PrintAwbLayout'

const PrintAwb = ({ printManualAwbRef, orderDetails, buttonClicked = false }) => {
  const { t } = useTranslation()
  const componentRef = useRef()
  
  const [printingLoading, setPrintingLoading] = useState(false)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print {
      @page {
        size: ${PRINT_MODES.FOUR_BY_SIX.size};
        margin: 10px;
        padding:0;
      }
    }`,
    onBeforeGetContent: () => setPrintingLoading(true),
    onBeforePrint: () => setPrintingLoading(false)
  })


  useEffect(() => {
    if (buttonClicked) {
      printManualAwbRef.current.click()
    }
  }, [buttonClicked])
  
  return (
    <div className='d-flex gap-1'>
      <div className='d-none'>
        <PrintAwbLayout ref={componentRef} orderDetails={orderDetails} />
      </div>
      <Button
        onClick={handlePrint}
        disabled={printingLoading}
        ref={printManualAwbRef}
      >
        {printingLoading &&
          <Spinner size="sm" className="text-white me-1" />
        }
        {t('Print AWB')}
      </Button>
    </div>
  )
}

export default PrintAwb