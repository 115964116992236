import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import warningImage from '@src/assets/images/catalog/bundlesku/warning-icon.svg'
import ability from '@src/configs/acl/ability'
import { ROUTES_KEYS } from '@src/router/route-keys'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'
import { setOmsReasonConfig } from '@src/views/settings/store'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const StripForReasonNotAvailable = ({ entity_type }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const hasConfigureReasonsPermission = ability.can(abilityMap.configuration.create.action, abilityMap.configuration.create.resource)

  const handleNavigation = () => {
    dispatch(setOmsReasonConfig({ isOpen: true, reasonConfig: { entity: entity_type, reasons: [] }}))
    navigate(ROUTES_MAPPING[ROUTES_KEYS.OMS_SETTINGS])
  }

  return (
    <>
      <div className='text-dark p-12px bg-warning-light flex-start-start rounded-8px mt-16px gap-12px'>
        <div>
          <img src={warningImage} width={18} height={18} alt='icon' />
        </div>
        <div>
          <p className='txt-body-md m-0'>{t('Reasons Configuration Required')}</p>
          {hasConfigureReasonsPermission ? 
            <p className='txt-sub-rg m-0'>
              {t('Please go to')}
              <span className='txt-sub-md'> {t('Settings > Order Settings > OMS Settings >')} </span>
              <span className='txt-sub-md text-primary cursor-pointer' onClick={() => handleNavigation()}>
                {t('Reasons Configuration')}
              </span>
            </p> :
            <p className='txt-sub-rg m-0'>
              {t('Only an admin can configure reasons. Please contact your admin.')}
            </p>
          }
        </div>
      </div>
    </>
  )
}

export default StripForReasonNotAvailable