// ** React & Third-party Libraries **
import { FC } from 'react';
import { Modal as ReactstrapModal } from 'reactstrap';

// ** Constants & Types **
import { MODAL_SIZES, MODAL_TYPES, ModalProps } from './modal.constant';

// ** Styles **
import './modal.scss';

// ** Components **
import classNames from 'classnames';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

// Modal size mapping logic
const getModalSizeClass = (size: typeof MODAL_SIZES[keyof typeof MODAL_SIZES]): string => {
  const sizeMap = {
    [MODAL_SIZES.SMALL]: 'max-width-600',
    [MODAL_SIZES.MEDIUM]: 'max-width-800'
  };
  return sizeMap[size] || 'max-width-600';
};

const Modal: FC<ModalProps> = ({
  // Header props
  title,
  subTitle,
  icon,
  customIcon = null,
  iconSize,
  closeButtonHidden,
  closeModalHandler,
  
  // Content props
  children,
  bodyContent,
  
  // Footer props
  loading = false,
  primaryButtonText,
  primaryButtonHandler,
  primaryButtonProps = {},
  secondaryButtonText,
  secondaryButtonHandler,
  secondaryButtonProps = {},
  modalFooterClass,
  
  // Modal props
  type = MODAL_TYPES.ILLUSTRATION,
  size = MODAL_SIZES.SMALL,
  modalClassNames = '',
  modalLoadingComponent = null,
  isTranslationRequired = true,
  isModalScrollable = true,
  ...rest
}) => {
  const modalSize = getModalSizeClass(size);
  const hasModalFooterButtons = !!(secondaryButtonText || primaryButtonText);

  const renderModalContent = () => {
    if (modalLoadingComponent) return modalLoadingComponent;

    return (
      <>
        <ModalHeader
          type={type}
          icon={icon as FC<{ size?: string; color?: string }> | string}
          iconSize={iconSize}
          customIcon={customIcon}
          title={title}
          subTitle={subTitle}
          closeButtonHidden={closeButtonHidden}
          closeModalHandler={closeModalHandler}
          isTranslationRequired={isTranslationRequired}
        />

        <ModalContent
          type={type}
          content={bodyContent}
          isTranslationRequired={isTranslationRequired}
          isModalScrollable={isModalScrollable}
          hasModalFooterButtons={hasModalFooterButtons}
        >
          {children}
        </ModalContent>

        <ModalFooter
          loading={loading}
          modalFooterClass={modalFooterClass}
          secondaryButtonHandler={secondaryButtonHandler}
          secondaryButtonProps={secondaryButtonProps}
          secondaryButtonText={secondaryButtonText}
          primaryButtonHandler={primaryButtonHandler}
          primaryButtonProps={primaryButtonProps}
          primaryButtonText={primaryButtonText}
          isTranslationRequired={isTranslationRequired}
        />
      </>
    );
  };

  return (
    <ReactstrapModal
      className={classNames(`omniful-modal ${modalSize} ${modalClassNames}`.trim(), {
        'remove-modal-scroll': !isModalScrollable
      })}
      modalClassName={`omniful-modal-${size}`}
      centered
      toggle={closeModalHandler}
      size={size}
      {...rest}
    >
      {renderModalContent()}
    </ReactstrapModal>
  );
};

export default Modal; 