import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import Button from '@src/@core/components/ui/button'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import Banner from '@src/@core/layouts/components/banner/Banner'
import { BANNER_TYPES, BANNER_VARIANTS, FILE_TYPES, MAX_PARALLEL_DOWNLOAD_LIMIT } from '@src/App.constants'
import image_fallback from '@src/assets/images/omniful/image_fallback.svg'
import { downloadFilesAsZip } from '@src/utility/Utils'
import { ATTACHMENT_ENTITY } from '@src/views/sales/constant/orders.constants'
import classNames from 'classnames'
import { saveAs } from 'file-saver'
import { useState } from 'react'
import { Download, ExternalLink } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { getExternalAttachmentsBannerText, handleOpenUrlInNewTab } from '../utils'
import FileIcon from './file-icon'
import Preview from './preview/preview'
const PreviewAttachments = (props) => {
  const {addedAttachments} = props

  const {t} = useTranslation()
  const [isDownloading, setDownloading] = useState(false)
  const [previewingAttachment, setPreviewingAttachment] = useState(null)
  const externalUrlAttachments = addedAttachments.filter(item => item.is_external_url)
  const allExternalAttachments = externalUrlAttachments.length === addedAttachments.length

  const handleSingleDownload = (item) => {
    fetch(item.file_url)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        saveAs(blob, item.name)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const handleDownloadAll = async () => {

    const internalUrlAttachments = addedAttachments.filter(item => !item.is_external_url)

    setDownloading(true);
  
    // If fewer than 6 attachments, download them individually
    if (internalUrlAttachments.length < MAX_PARALLEL_DOWNLOAD_LIMIT) {
      internalUrlAttachments.forEach(item => {
        handleSingleDownload(item); // Ensure this function works as intended
      });
      setDownloading(false); // Reset downloading state
      return;
    }
  
    await downloadFilesAsZip({ files: internalUrlAttachments });
    setDownloading(false);
  };
  
  const handleTogglePreview = (attachment) => {
    if (previewingAttachment) {
      setPreviewingAttachment(null)
    } else {
      setPreviewingAttachment(attachment)
    }
  }

  return (
    <div className="attachments-main-div w-100 d-flex flex-column gap-24px"> 
      {externalUrlAttachments.length > 0 && (
        <Banner
          type={BANNER_TYPES.NOTIFICATION}
          variant={BANNER_VARIANTS.INFO}
          text={getExternalAttachmentsBannerText()}
        />
      )}
      <div className='d-flex flex-column gap-16px'>
        {
          addedAttachments?.map((item, index) => {
            const fileType = item.file.type.split('/')[0] // Get the file type (image, video, text, etc.)
            const isPreviewAble = item.file.type.startsWith('image/') || item.file.type === FILE_TYPES.PDF.type
            const isExternalUrl = item.is_external_url
            const fileName = item.file.name
            const fileUrl = item.file_url
            
            return (
              <div key={index} className={classNames('rounded-8px border border-dark-2 bg-white p-10px flex-center-between upload_file_details', {'border-danger-light': item.error})}>
                <div className="flex-center-start gap-10px width-70-per">
                  {fileType === 'image' ?
                    <div className='file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2'>
                      <img
                        src={fileUrl || image_fallback}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = image_fallback
                        }}
                        alt={fileName}
                        className='rounded-4px object-fit-contain w-100 h-100'
                      />
                    </div>
                    :
                    <div className="rounded-4px bg-light-2 flex-center-center file-icon-wrapper flex-shrink-0 rounded-4px border border-dark-2">
                      <FileIcon fileType={fileType} size={24} />
                    </div>
                  }
                  <div className={classNames('d-flex flex-column width-90-per', {
                    'gap-4px': item.description,
                    'gap-12px': !item.description
                  })}>
                    <div className='d-flex flex-column gap-2px w-100'>
                      <div
                        className="txt-body-md text-dark w-100 text-truncate file_title"
                        onClick={() => {
                          const properties = { name: fileName, type: fileType, file_url: fileUrl }
                          if (isExternalUrl) {
                            handleOpenUrlInNewTab(fileUrl)
                          } else if (isPreviewAble) {
                            handleTogglePreview(properties)
                          } else {
                            handleSingleDownload(item)
                          }
                        }}
                      >
                        {fileName || 'Attachment'}
                      </div>
                      {item.description && <div className="txt-sub-rg text-dark-6 w-100 text-truncate">{item.description}</div>}
                    </div>
                  </div>
                </div>
                <div className='flex-center-end gap-16px flex-shrink-0'>
                  {item.attachment_entity === ATTACHMENT_ENTITY.INVOICE && (
                    <CustomLabel title={t('Invoice')} className="flex-center-center text-dark bg-dark-1"/>
                  )}
                  {
                    isExternalUrl ? (
                      <ExternalLink
                        size={16}
                        color="var(--bs-primary)"
                        className="cursor-pointer"
                        onClick={() => handleOpenUrlInNewTab(fileUrl)}
                      />
                    ) : (
                      <Download
                        size={16}
                        color="var(--bs-primary)"
                        className="cursor-pointer"
                        onClick={() => handleSingleDownload(item)}
                      />
                    )
                  }
                </div>
              </div>
            )
          })
        }
      </div>   
      {!allExternalAttachments && (
        <SidesheetFooter>
          <div className="flex-center-end gap-12px">
            <Button 
              ofStyle="outlined" 
              onClick={handleDownloadAll} 
              loading={isDownloading}
            >
              {t('Download All')}
            </Button>
          </div>
        </SidesheetFooter>
      )}
      <Preview 
        isOpen={!!previewingAttachment} 
        toggle={handleTogglePreview} 
        previewingAttachment={previewingAttachment}
      />
    </div>
  )
}

export default PreviewAttachments