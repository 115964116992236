import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import Hub from '@src/assets/images/icons/primary-navbar/Hub'
import ClockIcon from '@src/assets/images/svg/hubs/ClockIcon'
import MapIcon from '@src/assets/images/svg/hubs/MapIcon'
import { DELIVERY_AREA_TYPES } from '@src/views/hubs/constants.hubs'
import classNames from 'classnames'
import { Calendar, Circle } from 'react-feather'

const DeliveryZoneInfoTooltip = ({zone}) => {
  const {
    name, 
    hub_name, 
    type, 
    is_active, 
    is_serviceable_24_hours,
    meta_data,
    display_start_time,
    display_end_time
  } = zone
  const minutes = meta_data?.find((ele) => ele.Key === 'minutes')
  const displayType = Object.values(DELIVERY_AREA_TYPES).find((delArea) => delArea.key === type).type
  return (
    <div className="zone-info-container">
      <div className="flex-center-between">
        <div className="txt-body-md text-dark text-truncate">{name}</div>
        <CustomTag title={is_active ? 'Active' : 'Inactive'} icon={<Circle size={6} fill={is_active ? 'var(--bs-success)' : 'var(--bs-dark-6)'} stroke={is_active ? 'var(--bs-success)' : 'var(--bs-dark-6)'} strokeWidth={1} />}
          className={classNames('flex-center-center gap-6px',
            { 'bg-dark-2 text-dark-6': !is_active, },
            { 'custom-success-lighter text-success': is_active }
          )} />
      </div>
      <div className="d-flex flex-column gap-12px mt-12px">
        <div className="d-flex align-items-baseline gap-8px text-truncate" title={hub_name}>
          <div className="flex-shrink-0"><Hub width={16} height={16} color="var(--bs-dark-6)"/></div>
          <div className="txt-asst-rg text-dark-6 text-truncate">{hub_name}</div>
        </div>
        <div className="d-flex align-items-baseline gap-8px">
          <div className="flex-shrink-0"><Calendar width={16} height={16} color="var(--bs-dark-6)"/></div>
          <div className="txt-asst-rg text-dark-6">
            {is_serviceable_24_hours ? '24 hours' : `${display_start_time} - ${display_end_time}`}
          </div>
        </div>
        <div className="d-flex align-items-baseline gap-8px">
          <div className="flex-shrink-0"><MapIcon width={16} height={16} color="var(--bs-dark-6)"/></div>
          <div className="txt-asst-rg text-dark-6">{displayType}</div>
        </div>
        {type === DELIVERY_AREA_TYPES.SYSTEM_GENERATED.key && <div className="d-flex align-items-baseline gap-8px">
          <div className="flex-shrink-0"><ClockIcon width={16} height={16} color="var(--bs-dark-6)"/></div>
          <div className="txt-asst-rg text-dark-6">{minutes.Value} mins</div>
        </div>}
      </div>
    </div>
  )
}

export default DeliveryZoneInfoTooltip