import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { axiosInstance } from '@src/network/AxiosInstance'
import { paramsSerializer } from '@src/utility/Utils'
import { 
  getHubsAsyncData,
  getHubsAsyncDataWithAllOption,
  getCitiesAsyncData,
  getSalesChannel,
  getSellersAsyncData,
  getSellersAsyncDataUpdated,
  getSellersWithAllOption,
  getUnintegratedSellersAsyncData,
  getRolesAsyncData,
  getBillingProfiles,
  getContracts,
  getSkuOptionsForActions,
  getSkuOptionsForConditions,
  getUsersListAsyncData,
  getLocationListAsyncData,
  getAsyncAdHoc,
  getAsyncAdhocActivities,
  getAsyncSkuConfigHubs,
  getVirtualHubsInSettings,
  getOrderSkus
} from './settings.utils'

export {
  getHubsAsyncData,
  getHubsAsyncDataWithAllOption,
  getCitiesAsyncData,
  getSalesChannel,
  getSellersAsyncData,
  getSellersAsyncDataUpdated,
  getSellersWithAllOption,
  getUnintegratedSellersAsyncData,
  getRolesAsyncData,
  getBillingProfiles,
  getContracts,
  getSkuOptionsForActions,
  getSkuOptionsForConditions,
  getUsersListAsyncData,
  getLocationListAsyncData,
  getAsyncAdHoc,
  getAsyncAdhocActivities,
  getAsyncSkuConfigHubs,
  getVirtualHubsInSettings,
  getOrderSkus
}

/**********************Common *************************/

//roles and user setting
export const getRoles = createAsyncThunk('roleSettings/getRoles', async params => {
  const res = await axiosInstance.get('/api/v1/tenant/roles', { params })
  return res.data
})

//user setting and manage invoice
export const getSellersList = createAsyncThunk('sellers', async () => {
  const res = await axiosInstance.get('/api/v1/tenant/sellers')
  const data = await res.data
  const sellers = data.data.map(role => {
    return {
      value: role.id,
      label: role.name
    }
  })

  return sellers
})

/********************************* User Setting ****************************/
export const getUsersList = createAsyncThunk('settings/usersList', async ({ hub_id, ...params }) => {
  if (hub_id) {
    const response = await axiosInstance.get(`/api/v1/tenant/hubs/${hub_id}/users`, { params: { ...params } })
    return response?.data
  }
})

export const getHubs = createAsyncThunk('hubs', async () => {

  const res = await axiosInstance.get('/api/v1/wms/hubs')
  const data = await res?.data?.data
  const hubs = data?.map(role => {
    return {
      value: role.id,
      label: role.name
    }
  })
  return hubs
})

/************* Role setting *********************/
export const getPermissions = createAsyncThunk('roleSettings/getPermissions', async () => {
  const res = await axiosInstance.get('/api/v1/tenant/permissions')
  return res?.data?.data
})

export const getRole = createAsyncThunk('roleSettings/getRole', async params => {
  const res = await axiosInstance.get(`/api/v1/tenant/roles/${params.id}`)
  return res?.data
})

export const createRole = createAsyncThunk('roleSettings/createRole', async body => {
  const res = await axiosInstance.post('/api/v1/tenant/roles', body)
  return res?.data
})

export const updateRole = createAsyncThunk('roleSettings/updateRole', async body => {
  const res = await axiosInstance.put(`/api/v1/tenant/roles/${body.id}`, body)
  return res?.data
})

export const deleteRole = createAsyncThunk('roleSettings/deleteRole', async body => {
  const res = await axiosInstance.delete(`/api/v1/tenant/roles/${body.id}`, body)
  return res?.data
})

/************************************manage invoice******************************* */
export const getInvoiceSettings = createAsyncThunk('manageInvoice/getInvoiceSettings', async id => {
  if (id === undefined) {
    return undefined
  }
  const res = await axiosInstance.get(`/api/v1/oms/invoice/settings/sellers/${id}`)
  return res.data
})

export const createInvoiceSettings = createAsyncThunk('manageInvoice/createInvoiceSettings', async body => {
  const id = body.seller_id
  const res = await axiosInstance.post(`/api/v1/oms/invoice/settings/sellers/${id}`, body)
  return res.data
})
export const updateInvoiceSettings = createAsyncThunk('/updateInvoice', async body => {
  const id = body.seller_id
  const res = await axiosInstance.put(`/api/v1/oms/invoice/settings/sellers/${id}`, body)
  return res.data
})
/****************************User Profile ************************************/

export const updateUserInfo = createAsyncThunk('userProfileInformation/updateUserInfo', async body => {
  const res = await axiosInstance.put('/api/v1/tenant/users', body)
  return res.data
})

export const changePassword = createAsyncThunk('userProfileInformation/change_password', async body => {
  const res = await axiosInstance.post('/api/v1/users/change_password', body)
  return res
})

/****************************OMS Settings ************************************/

export const getOrderSettings = createAsyncThunk('settings/getorderSettings', async () => {
  const res = await axiosInstance.get('/api/v1/oms/configurations')
  return res.data
})

export const createOrderSettings = createAsyncThunk('settings/createOrderSettings', async body => {
  const res = await axiosInstance.post('/api/v1/oms/configurations', body)
  return res.data
})

export const getRulesConditions = createAsyncThunk('settings/orderTags/conditions', async () => {
  const res = await axiosInstance.get('/api/v1/oms/orders/tags/conditions')
  return res.data
})
export const getAllOrderTags = createAsyncThunk('settings/orderTags/getAllOrderTags', async ({params, searchParams}) => {
  delete params?.page
  delete params?.per_page
  const res = await axiosInstance.get('/api/v1/oms/orders/tags', {params})
  return { data: res?.data.data, searchParams}
})
export const getSingleOrderTags = createAsyncThunk('settings/orderTags/getSingleOrderTags', async ({id, params}, store) => {
  const isOrderTagsVersionTwoEnabled = store.getState()?.auth?.isOrderTagsVersionTwoEnabled
  const res = await axiosInstance.get(`/api/v1/oms/orders/tags/${id}`, {params})
  return {data: res?.data, isOrderTagsVersionTwoEnabled}
})
export const createOrderTags = createAsyncThunk('settings/orderTags/createOrderTags', async body => {
  const res = await axiosInstance.post('/api/v1/oms/orders/tags', body)
  return res.data
})
export const updateOrderTags = createAsyncThunk('settings/orderTags/updateOrderTags', async ({selectedTag, data}) => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/tags/${selectedTag}`, {...data, action: 'edit'})
  return res.data
})
export const deleteOrderTags = createAsyncThunk('settings/orderTags/deleteOrderTags', async id => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/tags/${id}`, {action: 'delete'})
  return res.data
})
export const changeOrderTagsStatus = createAsyncThunk('settings/orderTags/changeOrderTagsStatus', async ({id, status}) => {
  const res = await axiosInstance.put(`/api/v1/oms/orders/tags/${id}`, {action: 'update_status', is_active: status})
  return res.data
})

export const getEligibilityConditions = createAsyncThunk('settings/automation/eligibility', async () => {
  const res = await axiosInstance.get('/api/v1/shipping/rules/conditions')
  return res.data
})

export const createEligibilityRules = createAsyncThunk('settings/automation/create-eligibility-rules', async (body) => {
  const res = await axiosInstance.post('/api/v1/shipping/rules', body)
  return res.data
})

export const getEligibilityRules = createAsyncThunk('settings/automation/get-eligibility-rules', async (allParams) => {
  const res = await axiosInstance.get(`/api/v1/shipping/rules?${paramsSerializer({...allParams})}`)
  return res.data
})

export const getSingleEligibilityRules = createAsyncThunk('settings/automation/get-single-eligibility-rules', async ({id}) => {
  const res = await axiosInstance.get(`/api/v1/shipping/rules/${id}`)
  return res.data
})

export const changeEligibilityRules = createAsyncThunk('settings/automations/changeEligibilityStatus', async ({id, ...body}) => {
  const res = await axiosInstance.put(`/api/v1/shipping/rules/${id}`, {operation: 'edit', ...body})
  return res.data
})

export const getProductConfiguration = createAsyncThunk('settings/catalog-setting/get-bulk-upload', async ({seller_id}) => {
  // const res = await axiosInstance.get(`api/v1/products/configurations/${id}`)
  const res = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/skus/configuration`)
  return res.data
})

export const updateProductConfiguration = createAsyncThunk('settings/catalog-setting/update-bulk-upload', async ({seller_id, body}) => {
  // const res = await axiosInstance.get(`api/v1/products/configurations/${id}`)
  const res = await axiosInstance.post(`/api/v1/products/sellers/${seller_id}/skus/configuration`, body)
  return res.data
})

export const getTenantSetting = createAsyncThunk('settings/get/tenant-settings', async ({tenant_id}) => {
  const res = await axiosInstance.get(`/api/v1/tenant/${tenant_id}`)
  return res.data
})

export const updateTenantSetting = createAsyncThunk('settings/update/tenant-settings', async ({tenant_id, body}) => {
  const res = await axiosInstance.put(`/api/v1/tenant/${tenant_id}`, body)
  return res.data
})


const initialState = {
  loading: {},
  error: {},
  success: {},
  usersList: [],
  usersTableMeta: {},
  rolesList: null,
  hubsList: null,
  role: {},
  createRoleResponse: {},
  updateRoleResponse: {},
  deleteRoleResponse: {},
  sellersList: null,
  permissions: null,
  invoiceSetting: null,
  invoiceSettingUpdate: null,
  user: null,
  userCreated: false,
  userDeleted: false,
  userEdited: false,
  ordersConfiguration: null,
  conditions: null,
  OrderTagResponse:{},
  allOrderTags: null,
  singleOrderTag: null,
  orderTagMeta: null,
  createEligibilityRuleResponse: { loading: false, data: null, error: null },
  createPriorityRuleResponse: { loading: false, data: null, error: null },
  eligibilityConditions: {},
  createEligibilityRulesRes: { loading: false, data: null, error: null, is_success: false},
  getEligibilityRulesResponse: { loading: false, data: null, error: null },
  updateEligibilityRulesResponse: {loading: false, data: null, error: null, is_success: false},
  getSingleEligibilityRulesResponse: { loading: false, data: null, error: null },
  productConfiguration: { loading: false, data: null, error: null },
  updateProductConfigurationRes: {loading: false, data: null, error: null, is_success: false},
  tenantSettingRes: null,
  createEligibilityRuleResponseData: {},
  sellerDataList: {},
  configSetting: { isOpen: false, reasonConfig: { entity: '', reasons: [] }}
}
const allSettingsSlice = createSlice({
  name: 'allSettings',
  initialState,
  reducers: {
    cleanupRoleResponses: state => {
      state.updateRoleResponse = {}
      state.createRoleResponse = {}
      state.role = {}
      state.loading = {}
      state.error = {}
      state.success = {}  
    },
    cleanupInvoiceResponses: state => {
      state.invoiceSetting = null
    },
    clearOrderTagResponses : state => {
      state.OrderTagResponse = {}
    },
    setOrderTagsMeta: (state, action) => {
      state.orderTagMeta = {
        ...state.orderTagMeta,
        ...action.payload
      }
    },
    clearUpdateOrderTag : state => {
      state.singleOrderTag = null
      state.conditions = null
    },
    clearOrderConfiguration : state => {
      state.ordersConfiguration = null
    },
    clearCreateEligibilityRule: state => {
      state.createEligibilityRuleResponse = {data: null, loading: false, error: null}
    },
    clearCreateEligibilityRuleResponseData: state => {
      state.createEligibilityRuleResponseData = {}
    },
    clearCreateEligibilityRules: state => {
      state.createEligibilityRulesRes = {data: null, loading: false, error: null, is_success: false}
    },
    clearCreatePriorityRule: state => {
      state.createPriorityRuleResponse = {data: null, loading: false, error: null}
    },
    clearUpdateEligibilityRule: state => {
      state.updateEligibilityRulesResponse = {data: null, loading: false, error: null, is_success: false}
    },
    clearGetSingleEligibilityRule: state => {
      state.getSingleEligibilityRulesResponse = { loading: false, data: null, error: null }
    },
    clearUpdateProductConfiguration: state => {
      state.updateProductConfigurationRes = { loading: false, data: null, error: null, is_success: false }
    },
    resetSuccess: state => {
      state.success = {}
    },
    clearTenantSettingRes: state => {
      state.tenantSettingRes = null
    },
    sellerDataListRes: (state, action) => {
      state.sellerDataList = action.payload
    },
    setOmsReasonConfig: (state, action) => {
      state.configSetting = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUsersList.pending, state => {
        state.loading = { ...state.loading, getUsersList: true }
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getUsersList: false }
        state.error = { ...state.error, getUsersList: false }
        state.usersList = action.payload?.data
        state.usersTableMeta = action.payload?.meta
        state.userDeleted = false
        state.userCreated = false
        state.userEdited = false
      })
      .addCase(getUsersList.rejected, state => {
        state.loading = { ...state.loading, getUsersList: false }
        state.error = { ...state.error, getUsersList: true }
      })
      .addCase(getRoles.pending, state => {
        state.loading = { ...state.loading, getRolesData: true }
        state.error = { ...state.error, getRolesData: false }
        state.success = {...state.success, getRolesData: false}
        // state.rolesList = { loading: true }
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = {...state.loading, getRolesData: false}
        state.error = { ...state.error, getRolesData: false }
        state.success = {...state.success, getRolesData: true}
        state.rolesList = action.payload
      })
      .addCase(getRole.fulfilled, (state, action) => {
        state.loading = {...state.loading, getRolesData: false}
        state.error = { ...state.error, getRolesData: false }
        state.success = {...state.success, getRolesData: true}
        state.role = action.payload.data
      })
      .addCase(getRole.rejected, (state) => {
        state.loading = {...state.loading, getRolesData: false}
        state.error = { ...state.error, getRolesData: true }
        state.success = {...state.success, getRolesData: false}
      })
      .addCase(getHubs.fulfilled, (state, action) => {
        state.hubsList = action.payload
      })
      .addCase(getSellersList.fulfilled, (state, action) => {
        state.sellersList = action.payload
      })
      .addCase(getPermissions.pending, state => {
        state.loading = { ...state.loading, getPermissions: true }
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getPermissions: false }
        state.permissions = action.payload
      })
      .addCase(getPermissions.rejected, state => {
        state.loading = { ...state.loading, getPermissions: false }
      })
      .addCase(createRole.pending, state => {
        state.loading = { ...state.loading, createRole: true }
        state.error = { ...state.error, createRole: false }
        state.success = { ...state.success, createRole: false }
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createRole: false }
        state.success = { ...state.success, createRole: true }
        state.createRoleResponse = action.payload
        state.error = { ...state.error, createRole: false }
      })
      .addCase(createRole.rejected, state => {
        state.loading = { ...state.loading, createRole: false }
        state.success = { ...state.success, createRole: false }
        state.error = { ...state.error, createRole: true }
      })
      .addCase(updateRole.pending, state => {
        state.loading = { ...state.loading, updateRole: true }
        state.success = { ...state.success, updateRole: false }
        state.error = { ...state.error, updateRole: false }
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.loading = { ...state.loading, updateRole: true }
        state.success = { ...state.success, updateRole: false }
        state.updateRoleResponse = action.payload
        state.error = { ...state.error, updateRole: false }
      })
      .addCase(updateRole.rejected, state => {
        state.loading = { ...state.loading, updateRole: false }
        state.success = { ...state.success, updateRole: false }
        state.error = { ...state.error, updateRole: true }
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.deleteRoleResponse = action.payload
      })
      .addCase(getInvoiceSettings.fulfilled, (state, action) => {
        state.invoiceSetting = action.payload
        state.loading = { ...state.loading, getInvoiceSettings: false }
        state.invoiceSettingUpdate = false
      })
      .addCase(createInvoiceSettings.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          CustomToast('Invoice settings set successfully', { my_type: 'success' })
          state.invoiceSettingUpdate = action.payload.is_success
          state.loading = { ...state.loading, createInvoiceSettings: false }
        }
      })
      .addCase(updateInvoiceSettings.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          CustomToast('Invoice settings updated successfully', { my_type: 'success' })
          state.invoiceSettingUpdate = action.payload.is_success
          state.loading = { ...state.loading, updateInvoiceSettings: false }
        }
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = { ...state.loading, changePassword: true }
        state.error = { ...state.error, changePassword: false }
        state.success = { ...state.success, changePassword: false }
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = { ...state.loading, changePassword: false }
        state.error = { ...state.error, changePassword: false }
        state.success = { ...state.success, changePassword: true }
        if (action?.payload?.status === 200) {
          CustomToast('Password changed successfully', { my_type: 'success' })
        }
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading = { ...state.loading, changePassword: false }
        state.error = { ...state.error, changePassword: true }
        state.success = { ...state.success, changePassword: false }
      })
      .addCase(createOrderSettings.pending, state => {
        state.loading = { ...state.loading, createOrderSettings: true }
      })
      .addCase(createOrderSettings.fulfilled, (state) => {
        state.loading = { ...state.loading, createOrderSettings: false }
        state.ordersConfiguration = null
        CustomToast('Settings saved successfully', { my_type: 'success' })
      })
      .addCase(createOrderSettings.rejected, state => {
        state.loading = { ...state.loading, createOrderSettings: false }
      })
      .addCase(getOrderSettings.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          state.ordersConfiguration = action.payload?.data
        }
      })
      .addCase(getOrderSettings.rejected, (state, action) => {
        state.ordersConfiguration = null
      })
      .addCase(getRulesConditions.fulfilled, (state, action) => {
        if (action?.payload?.status_code === 200) {
          state.conditions = action.payload?.data
        }
      })
      .addCase(getAllOrderTags.pending, (state) => {
        state.loading = { ...state.loading, getAllOrderTags: true }
      })
      .addCase(getAllOrderTags.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getAllOrderTags: false }
        state.success = { ...state.success, getAllOrderTags: true }
        state.error = { ...state.error, getAllOrderTags: false }
        state.allOrderTags = action.payload?.data
        const per_page = action.payload.searchParams.get('per_page') || 20
        const total = action.payload?.data?.length
        const last_page = Math.abs(Math.ceil(total / per_page))
        const current_page =
          Number(action.payload.searchParams.get('page')) || 1
        const start = (current_page - 1) * per_page
        const end = start + per_page
        state.orderTagMeta = {
          current_page,
          last_page,
          per_page,
          start,
          end,
          total
        }
      })
      .addCase(getAllOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, getAllOrderTags: false }
        state.error = { ...state.error, getAllOrderTags: true }
        state.success = { ...state.success, getAllOrderTags: false }
      })
      .addCase(getSingleOrderTags.fulfilled, (state, action) => {
        console.log(action.payload, 'payload')
        if (action?.payload?.data?.status_code === 200) {
          state.loading = { ...state.loading, getSingleOrderTags: false }
          state.conditions = action.payload.isOrderTagsVersionTwoEnabled ? { condition_group:{conditions: action.payload.data?.data?.rule_entity?.condition_group?.conditions} } : { conditions: action.payload.data.data?.available_conditions?.conditions }
          const tempObj = { ...action.payload.data.data?.condition }
          delete tempObj.sub_conditions
          if (action.payload.data.data?.condition?.sub_conditions) {
            state.singleOrderTag = { ...action.payload?.data?.data, condition: [tempObj, ...action.payload.data.data?.condition?.sub_conditions] }
          } else {
            state.singleOrderTag = { ...action.payload?.data?.data, condition: [tempObj] }
          }
        }
      })
      .addCase(getSingleOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, getSingleOrderTags: false }
      })
      .addCase(getSingleOrderTags.pending, (state) => {
        state.loading = { ...state.loading, getSingleOrderTags: true }
      })
      .addCase(createOrderTags.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Order tag created successfully', { my_type: 'success' })
          state.OrderTagResponse = { ...state.OrderTagResponse, createOrderTags: true }
          state.loading = { ...state.loading, createOrderTags: false }
        }
      })
      .addCase(createOrderTags.pending, (state) => {
        state.loading = { ...state.loading, createOrderTags: true }
      })
      .addCase(updateOrderTags.pending, (state) => {
        state.loading = { ...state.loading, updateOrderTags: true }
      })
      .addCase(createOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, createOrderTags: false }
      })
      .addCase(updateOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, updateOrderTags: false }
      })
      .addCase(updateOrderTags.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Order tag updated successfully', { my_type: 'success' })
          state.OrderTagResponse = { ...state.OrderTagResponse, updateOrderTags: true }
          state.loading = { ...state.loading, updateOrderTags: false }
        }
      })
      .addCase(deleteOrderTags.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Order tag deleted successfully', { my_type: 'success' })
          state.OrderTagResponse = { ...state.OrderTagResponse, deleteOrderTags: true }
          state.loading = { ...state.loading, deleteOrderTags: false }
        }
      })
      .addCase(deleteOrderTags.rejected, (state) => {
        state.loading = { ...state.loading, deleteOrderTags: false }
      })
      .addCase(deleteOrderTags.pending, (state) => {
        state.loading = { ...state.loading, deleteOrderTags: true }
      })
      .addCase(changeOrderTagsStatus.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Order tag status updated successfully', { my_type: 'success' })
          state.OrderTagResponse = { ...state.OrderTagResponse, changeOrderTagsStatus: true }
        }
      })
      .addCase(getInvoiceSettings.pending, (state) => {
        state.loading = {...state.loading, getInvoiceSettings:true}
      })
      .addCase(getInvoiceSettings.rejected, (state) => {
        state.loading = {...state.loading, getInvoiceSettings:false}
      })
      .addCase(updateInvoiceSettings.pending, (state) => {
        state.loading = {...state.loading, updateInvoiceSettings:true}
      })
      .addCase(updateInvoiceSettings.rejected, (state) => {
        state.loading = {...state.loading, updateInvoiceSettings:false}
      })
      .addCase(createInvoiceSettings.pending, (state) => {
        state.loading = {...state.loading, createInvoiceSettings:true}
      })
      .addCase(createInvoiceSettings.rejected, (state) => {
        state.loading = {...state.loading, createInvoiceSettings:false}
      })
      .addCase(getEligibilityConditions.pending, (state) => {
        state.loading = { ...state.loading, eligibilityConditions: true }
        state.error = {...state.error, eligibilityConditions: false }
      })
      .addCase(getEligibilityConditions.fulfilled, (state, action) => {
        state.eligibilityConditions = action.payload?.data
        state.error = { ...state.error, eligibilityConditions: false }
        state.loading = { ...state.loading, eligibilityConditions: false }
      })
      .addCase(getEligibilityConditions.rejected, (state) => {
        state.error = { ...state.error, eligibilityConditions: true }
        state.loading = { ...state.loading, eligibilityConditions: false }
      })
      .addCase(createEligibilityRules.pending, (state) => {
        state.createEligibilityRulesRes = { data: null, loading: false, error: null, is_success: false }
      })
      .addCase(createEligibilityRules.fulfilled, (state, action) => {
        state.createEligibilityRulesRes = { ...action.payload, loading: false, is_success: true }
        state.createEligibilityRuleResponseData = action.payload.data
        CustomToast('Rule created successfully.', { my_type: 'success' })
      })
      .addCase(createEligibilityRules.rejected, (state) => {
        state.createEligibilityRulesRes = { error: { message: 'Could not create Eligibility Rule' }, loading: false, is_success: false}
      })
      .addCase(getEligibilityRules.pending, (state) => {
        state.getEligibilityRulesResponse = { data: null, loading: true, error: null }
      })
      .addCase(getEligibilityRules.fulfilled, (state, action) => {
        state.getEligibilityRulesResponse = {data: action.payload, loading: false, is_success: true}
      })
      .addCase(getEligibilityRules.rejected, (state) => {
        state.getEligibilityRulesResponse  = { data: null, loading: false, error: {message: 'Could not get Eligibility Rule'}, is_success: false }
      })
      .addCase(getSingleEligibilityRules.pending, (state) => {
        state.getSingleEligibilityRulesResponse = { data: null, loading: false, error: null }
      })
      .addCase(getSingleEligibilityRules.fulfilled, (state, action) => {
        state.getSingleEligibilityRulesResponse = { ...action.payload, loading: false}
      })
      .addCase(getSingleEligibilityRules.rejected, (state) => {
        state.getSingleEligibilityRulesResponse  = { data: null, loading: false, error: {message: 'Could not get Eligibility Rule'} }
      })
      .addCase(changeEligibilityRules.pending, (state) => {
        state.updateEligibilityRulesResponse = { ...state.updateEligibilityRulesResponse, loading: true }
      })
      .addCase(changeEligibilityRules.fulfilled, (state, action) => {
        if (action.payload?.status_code === 200) {
          CustomToast('Rule updated successfully.', { my_type: 'success' })
          state.updateEligibilityRulesResponse = { ...state.updateEligibilityRulesResponse, is_success: true, loading: false }
        }
      })
      .addCase(changeEligibilityRules.rejected, (state) => {
        state.updateEligibilityRulesResponse = { ...state.updateEligibilityRulesResponse, loading: false }
      })
      .addCase(getProductConfiguration.pending, (state) => {
        state.productConfiguration = { data: null, loading: true, error: null }
      })
      .addCase(getProductConfiguration.fulfilled, (state, action) => {
        state.productConfiguration = { ...action.payload, loading: false}
      })
      .addCase(getProductConfiguration.rejected, (state) => {
        state.productConfiguration  = { data: null, loading: false, error: {message: 'Could not get product configuration'} }
      })
      .addCase(updateProductConfiguration.pending, (state) => {
        state.updateProductConfigurationRes = { data: null, loading: true, error: null }
      })
      .addCase(updateProductConfiguration.fulfilled, (state, action) => {
        state.updateProductConfigurationRes = { ...action.payload, loading: false, is_success: true }
        CustomToast('Bulk SKU configuration edited successfully', { my_type: 'success' })
      })
      .addCase(updateProductConfiguration.rejected, (state) => {
        state.updateProductConfigurationRes  = { data: null, loading: false, is_success: false, error: {message: 'Could not update product configuration'} }
      })
      // getTenantSetting
      .addCase(getTenantSetting.pending, (state) => {
        state.loading = {...state.loading, getTenantSetting:true}
        state.error = {...state.error, getTenantSetting:false}
        state.success = { ...state.success, getTenantSetting: false }
        state.tenantSettingRes = null
      })
      .addCase(getTenantSetting.fulfilled, (state, action) => {
        state.loading = {...state.loading, getTenantSetting:false}
        state.success = { ...state.success, getTenantSetting: true }
        state.tenantSettingRes = action.payload.data
      })
      .addCase(getTenantSetting.rejected, (state) => {
        state.loading = {...state.loading, getTenantSetting:false}
        state.error = { ...state.error, getTenantSetting: true }
      })
      // updateTenantSetting
      .addCase(updateTenantSetting.pending, (state) => {
        state.loading = {...state.loading, updateTenantSetting:true}
        state.error = {...state.error, updateTenantSetting:false}
        state.success = { ...state.success, updateTenantSetting: false }
        state.tenantSettingRes = null
      })
      .addCase(updateTenantSetting.fulfilled, (state, action) => {
        state.loading = {...state.loading, updateTenantSetting:false}
        state.success = { ...state.success, updateTenantSetting: true }
        state.tenantSettingRes = action.payload
      })
      .addCase(updateTenantSetting.rejected, (state) => {
        state.loading = {...state.loading, updateTenantSetting:false}
        state.error = { ...state.error, updateTenantSetting: true }
      })
    
  }
})

export const { clearOrderConfiguration, cleanupRoleResponses, cleanupInvoiceResponses, clearOrderTagResponses, setOrderTagsMeta, clearUpdateOrderTag, clearCreateEligibilityRules, clearCreateEligibilityRuleResponseData, clearUpdateEligibilityRule, clearGetSingleEligibilityRule, clearUpdateProductConfiguration, resetSuccess, clearTenantSettingRes, sellerDataListRes, setOmsReasonConfig} = allSettingsSlice.actions

export default allSettingsSlice.reducer