export const invoiceStyles = `
.order-invoice {
  width:100%;
  padding: 16px;
  .page-break {
    display: block;
    page-break-before: auto !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }
  .invoice-header {
    display: flex;
    align-items: center;
    gap: 16px;
    .company-logo {
      position:relative;
      width: 74px;
      height: 74px;
      flex-shrink: 0; 
      img {
        width: 100%;
        height: 100%;
      }
    }

    .company-info {
      flex-grow: 1;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap:6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      .title {
        font-size: 24px;
        color: #222;
        font-weight: 600;
      }
      .vat_number{
        color: #222;
      }
      .description{
        color: #999 !important;
        width:500px;
        word-break: break-all;
      }
    }

    .qr-code-wrapper {
      width: 74px;
      display: flex;
      justify-content: end;
      align-items: start;
      flex-shrink: 0;
    }
  }

  .divider {
    margin: 16px 0px;
    border-bottom: 1px dashed #999999;
  }

  .invoice-barcode-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  .store-info {
    display: flex;
    .detail {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 12px !important;
      .detail-heading {
        color: #222;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      .info-row {
        display: flex;
        gap: 6px;
        font-size: 10px;
        line-height: normal;
        color: #222;
        .info-col-1 {
          width: 30%;
          color: #222;
          font-weight:300
        }
        .info-col-2 {
          width: 70%;
          display:flex;
          gap: 6px;
          .info-seperator{
            display:inline-block;
            font-weight:300
          }
          .info-value {
            font-weight: 400;
            word-break: break-all;
            display:inline-block;
            width:80%;
          }
        }
      }
    }
  }

  .info-table {
    width: 100%;
    position: relative;

    .invoice-footer {
      position: fixed;
      height: 100px;
      bottom: 0;
      width: 96%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      page-break-after: always;
    }

    .table>:not(caption)>*>* {
      padding: 0.5rem 0.5rem;
      background-color: var(--bs-table-bg);
      border-bottom-width: 1px;
      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }
    .table>:not(:last-child)>:last-child>* {
      border-bottom-color: currentColor;
    }

    .main-invoice-table-wrapper {
      width:100%;
      .empty-footer {
        height:100px;
      }
      .main-invoice-table-body {
        width:100%;
        .order-detail-table {
          width: 100%;
          .table-header {
            .table-header-row {
              background: #F2F2F2;
              height: 40px;
              .header-text-left{
                text-align: start;
              }
              .header-text-right{
                text-align: end;
              }
            }
            tr {
              th {
                color: #222222;
                padding: 8px;
                font-size: 8px;
                font-weight: 300;
              }
            }
          }
          .table-body{
            .table-row{
              border-top:1px solid #ccc;
              border-bottom:1px solid #ccc;
              .sku-code, .country-of-origin{
                max-width:100px;
                word-break: break-all;
              }
              .sku-name-wrapper{
                max-width:160px;
                .sku-name-td {
                  word-break: break-all;
                }
              }
              .qty{
                padding-right:10px !important;
              }
              .td-text-right{
                text-align: end;
              }
              .kit-wrapper{
                font-weight: 600;
                margin-right: 8px;
              }
            }
          }
          tbody {
            tr {
              padding-left:10px;
              td {
                padding: 8px;
                color: #222;
              }
            }
          }
          color: #222;
          font-size: 10px;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .order-total {
        display:flex;
        justify-content: end;
        margin-top:12px;
        .qr-code{
          transform: translateX(-50%);
        }
        .order-total-wrapper{
          display:flex;
          gap:16px;
          font-size: 10px;
          font-weight: 300;
          margin-right: 8px;
          .order-total-left-col{
            display:flex;
            flex-direction: column;
            gap:10px;
            color: #222;
            font-weight: 300;
          }
          .order-total-right-col{
            display:flex;
            flex-direction: column;
            gap:10px;
            text-align: right;
            color: #222;
            font-weight: 300;
          }
          .total{
            font-size:12px;
            font-weight:500;
            color: #222 !important;
          }
        }
      }
    }
  }

  .terms-condtions {
    .heading{
      color: #222;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .term{
      color:#222;
      font-size: 10px;
      font-weight:300
    }
  }
}` 