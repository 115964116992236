import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { clearAssembleTaskDetail, getAssembleTaskDetail } from '@src/views/inventory/store'
import { useState } from 'react'
import { Printer } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BarcodeListModal from '../BarcodeListModal'
import { getBadgeClassNamesForProgress } from '../config'
import ExpandedComponent from '../ExpandedComponent'
// import SkuCard from "@src/views/catalog/components/skuCard"
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import classNames from 'classnames'
import { UncontrolledTooltip } from 'reactstrap'
import AssmbledKitBarcodes from '../AssmbledKitBarcodes'

const AssembledKitsSidesheet = ({ showAssembledKitsSidesheet, setShowAssembledKitsSidesheet, currentTask, hub_id }) => {

  // constants
  const subTextData = [
    `Created At: ${currentTask?.created_at || ''}`,
    `Order ID: ${currentTask?.order_details.seller_sales_channel_order_id || ''}`
  ]

  // hooks and states
  const { t } = useTranslation()
  const [showBarcodeListModal, setShowBarcodeListModal] = useState(false)
  const [currentKit, setCurrentKit] = useState(null)
  const [assembleTasksDetail, showAssembleTaskDetail] = useState(null)
  const [skuData, setSkuDetails] = useState({})
  const dispatch = useDispatch()

  const assembleTaskDetail = useSelector(state => state.inventory.assembleTaskDetail.data?.data)
  const loading = useSelector(state => state.inventory.assembleTaskDetail.loading)
  const error = useSelector(state => state.inventory.assembleTaskDetail.error)
  const success = useSelector(state => state.inventory.assembleTasks.is_success)

  // handlers
  const barCodeListModalToggle = () => {
    setShowBarcodeListModal(!showBarcodeListModal)
  }

  const assembledBarcodesModalToggle = (sku) => {
    showAssembleTaskDetail(!assembleTasksDetail)
    setSkuDetails(sku)
  }

  const handleToggle = () => {
    setShowAssembledKitsSidesheet(prev => !prev)
  }

  const handleOpenPrintSidesheet = (row) => {
    setCurrentKit(row)
    barCodeListModalToggle()
  }

  const tableColumns = [
    {
      id: 1,
      name: t('Assembled Kit'),
      key: 'assembled_kit',
      cell: row => (
        // <SkuCard url={row.sku.images?.[0]?.url} skuName={row.sku.name} skuCode={row.sku.seller_sku_code} toolTipID={row.id} />
        <div className="sku-card-container flex-center-between gap-8px flex-grow-1">
          <div className="flex-center-start gap-4px">
            <img
              src={row.sku.images?.[0]?.url || no_sku_image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = no_sku_image
              }}
              width={34}
              height={34}
              alt="no img"
              className="align-self-center rounded-8px"
            />
            <div>
              <div
                id={`skuNameTooltip-${row.id}`}
                className={classNames('truncated-text txt-sub-rg width-fit-content', {'text-primary cursor-pointer': row.is_display_assembled_kit})}
                onClick={row.is_display_assembled_kit ? (() => assembledBarcodesModalToggle(row)) : null}
              >
                {row.sku.name}
              </div>
              <UncontrolledTooltip innerClassName="text-start" placement="bottom" target={`skuNameTooltip-${row.id}`}>
                {row.sku.name}
              </UncontrolledTooltip>
              <div className="txt-asst-rg text-dark-6">{row.sku.seller_sku_code}</div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 2,
      name: <div className="w-100 text-end">{t('Assembled Kit Qty')}</div>,
      key: 'assembled_kit_qty',
      minWidth: '150px',
      // maxWidth: '20%',
      cell: row => {
        return (
          <div className='w-100 text-end'>{row.display_assembled_quantity}</div>
        )
      }
    },
    {
      id: 3,
      name: t('Print Barcodes'),
      key: 'print_barcodes',
      maxWidth: '120px',
      cell: (row) => <div className='flex-center-center w-100'>
        <Button ofStyle='outlined' className='text-center cursor-pointer' onClick={() => handleOpenPrintSidesheet(row)} disabled={row.quantity === 0}>
          <Printer size={20} />
        </Button>
      </div>
    }
  ]

  const onModalOpened = () => {
    dispatch(getAssembleTaskDetail({ taskId: currentTask?.id, hub_id }))
  }

  const onModalClosed = () => {
    dispatch(clearAssembleTaskDetail())
  }

  return (
    <SideSheet
      isOpen={showAssembledKitsSidesheet}
      toggle={handleToggle}
      modalClassName='modal-slide-in'
      title={currentTask?.name}
      subTextData={subTextData}
      onOpened={onModalOpened}
      onClosed={onModalClosed}
      contentClassName='assembled-kits-sidesheet'
      tag={{ text: currentTask?.status, tagClassName: getBadgeClassNamesForProgress(currentTask?.status) }}
      size='md'
    >
      <div className='p-24px'>
        <div>
          <ExpandableCustomTable
            loading={loading}
            error={error}
            success={success}
            data={assembleTaskDetail?.assembled_task_items || {}}
            columns={tableColumns}
            showPagination={false}
            showColumnsTableHeader={false}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            columnName={ALL_COLUMN_NAME_MAPPING.ASSEMBLED_KITS}
            isTableFixedHeader={false}
            // expandableRowDisabled={row => row.type !== 'bundle'}
          />
        </div>

        {/* sidesheets */}
        <BarcodeListModal
          isBarCodeListModalOpen={showBarcodeListModal}
          barCodeListModalToggle={barCodeListModalToggle}
          currentKit={currentKit}
          setCurrentKit={setCurrentKit}
          currentTask={currentTask}
          sellerId={assembleTaskDetail?.seller.id}
          hubId={hub_id}
        />

        <AssmbledKitBarcodes
          isModalOpen={assembleTasksDetail}
          toggle={assembledBarcodesModalToggle}
          skuData={skuData}
          taskId = {currentTask?.id}
          hubId={hub_id}
        />
      </div>

    </SideSheet>
  )
}

export default AssembledKitsSidesheet