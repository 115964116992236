import Modal from '@src/@core/components/ui/modal/Modal'
import Select from '@src/@core/components/ui/select'
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form, ModalBody } from 'reactstrap'
import StripForReasonNotAvailable from '../components/reasonsConfigrationRequired/StripForReasonNotAvailable'
import { useFetchReasons } from '../hooks/useFetchReasons'
import { cancelShipment } from '../store/store'

const CancelShipmentPopUp = (prop) => {
  const { shipmentOrderID, deleteModalOpen, setDeleteModalOpen, shipping_partner_tag, awb_number } = prop
  const { reasons } = useFetchReasons({
    isModalOpen: deleteModalOpen,
    entity_type: OMS_REASON_CONFIG.cancel_shipment.key,
  })

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { control, formState: { errors }, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      shipping_cancel_reasons: null
    },
    mode: 'onChange'
  })

  const onSubmit = () => {
    dispatch(cancelShipment({ reason: watch('shipping_cancel_reasons').value, shipping_partner_tag, awb_number }))
    setDeleteModalOpen(false)
  }

  const handleToggle = () => {
    setDeleteModalOpen(prev => !prev)
    reset()
  }

  return (
    <Modal
      isOpen={deleteModalOpen}
      closeModalHandler={handleToggle}
      title={
        <>
          <div>{t('Are you sure you want to cancel this shipment for')}</div>
          <div>
            {shipmentOrderID && (
              <span>
                {t('Order ID')}: {shipmentOrderID}?
              </span>
            )}
          </div>
        </>
      }
      subTitle={t('This action cannot be reversed.')}
      primaryButtonText={t('Yes, Cancel')}
      primaryButtonHandler={handleSubmit(onSubmit)}
      secondaryButtonText={t('Take Me Back')}
      secondaryButtonHandler={handleToggle}
      isTranslationRequired={false}
    >
      <ModalBody className='m-0 p-0 pt-8px'>
        <Form>
          <Controller
            name='shipping_cancel_reasons'
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Select
                {...field}
                errors={errors}
                options={reasons}
                isRequired
                label={t('Reason for Cancellation')}
                isDisabled={reasons?.length === 0}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (baseStyles) => ({
                    ...baseStyles,
                    zIndex: '9999',
                  })
                }}
              />
            )}
          />
          {reasons?.length === 0 && <StripForReasonNotAvailable entity_type={OMS_REASON_CONFIG.cancel_shipment.key}/>}
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default CancelShipmentPopUp
