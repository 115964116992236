import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import ability from '@src/configs/acl/ability'
import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { useEffect, useState } from 'react'
import { Info } from 'react-feather'
import { useSelector } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'
import { MAX_SINGLE_SKU_DESC_LENGTH } from '../../catalog.constants'

const AttributeItem = ({ attribute, variations, selectedVariationId, showAllVariations }) => {
  return (
    <div className='d-flex px-1 align-items-center w-75'>
      <p className='w-25 m-0 p-0 txt-body-md text-dark-5'>{attribute?.name || '-'}</p>
      <div className='w-75 d-flex gap-1 align-items-center'>
        {showAllVariations
          ? variations?.map((variation, index) => {
            return <CustomLabel key={index} title={variation?.name || '-'} className={classNames('m-0 px-1 rounded-3', {
              'bg-primary-lighter': variation?.id === selectedVariationId,
              'bg-danger': variation?.id !== selectedVariationId,
              'bg-primary': variation?.id === selectedVariationId,
              'bg-dark-3': variation?.id !== selectedVariationId
            })}/>
          })
          : <CustomLabel title={attribute?.sku_attribute_variation?.name} className='ms-1 me-0 my-0 gap-8px text-dark bg-dark-1'/>
        }
      </div>
    </div>
  )
}

const SimpleSkuInfo = ({ t, sku }) => {
  const isPosEnabledOnUserPlan = useSelector(store => store.auth.userData?.user_plan?.is_pos_enabled)
  const productDetails = useSelector(state => state.inventory.product)
  const simpleSkuDetail = useSelector(state => state.catalog.sku)
  const [showFullDesc, setShowFullDesc] = useState(false)
  const [isDescriptionContainHtml, setIsDescriptionContainHtml] = useState(false)
  const [htmlContent, setHtmlContent] = useState(null)
  const getSourceDetails = (sku) => {
    if (sku.sku_upsert_source_details?.source === 'sheet_import') {
      return <a href={sku.sku_upsert_source_details.file_link}>{t('Sheet')}</a>
    } else {
      return 'Manual'
    }
  }

  const sourceDetails = sku.sku_upsert_source_details?.source ? getSourceDetails(sku) : '-'
  const parseHtml = (description) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    const textContent = doc.body.textContent.trim();
    setHtmlContent(textContent)
    return doc.body.children?.length > 0;
  };

  useEffect(() => {
    if (parseHtml(sku.description)) {
      setIsDescriptionContainHtml(true)
    }
  }, [])
  
  return (
    <div className='w-100 d-flex flex-column gap-2'>
      {sku.description && <div className='border border-dark-1 rounded-12px'>
        <div className='bg-primary-lighter-global border-primary-lighter-global simple-sku-desc-content  txt-h3-md text-dark w-100 border-radius-tl-tr-10'>{t('Description')}</div>
        <div className='txt-sub-rg text-dark p-16px text-break text-wrap'>
          {!showFullDesc && ((isDescriptionContainHtml ? htmlContent?.length : sku.description.length) <= MAX_SINGLE_SKU_DESC_LENGTH ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sku.description) }}></div> : <div>
            <span className='simple-sku-show-less-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${sku.description.slice(0, 395)}...`) }}></span>
            <span className='txt-sub-md text-primary cursor-pointer' onClick={() => setShowFullDesc(true)}>{t(' Show full')}</span>
          </div>)}
          {showFullDesc && (
            <div>
              <span className='simple-sku-show-less-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sku.description) }}></span><span className='txt-sub-md text-primary cursor-pointer' onClick={() => setShowFullDesc(false)}>{t(' Show less')}</span>
            </div>
          )}
        </div>
      </div>}
      {ability.can(abilityMap.product.view_price.action, abilityMap.product.view_price.resource) && <div className='border  border-dark-1 rounded-12px'>
        <div className='bg-primary-lighter-global border-primary-lighter-global simple-sku-desc-content  txt-h3-md text-dark w-100 border-radius-tl-tr-10'>
          {t('Price Details')}
        </div>
        <div className='d-flex gap-1 flex-column'>
          <div className='txt-sub-rg text-dark w-100 d-flex gap-5'>
            <div className='w-100 simple-sku-info-content'>
              <p className='txt-body-md text-dark-5'>
                {t('Retail Price')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.retail_price >= 0 ? `${sku.display_retail_price}` : '-'}
              </p>
              <p className='txt-body-md text-dark-5'>
                {isPosEnabledOnUserPlan ? t('Tax Category') : t('Tax Percentage')}
              </p>
              <p className='text-dark txt-body-md'>
                {isPosEnabledOnUserPlan ? sku.tax_category?.name : sku.tax_percentage?.selling_price}
              </p>
            </div>
            <div className='simple-sku-info-content w-100'>
              <p className='txt-body-md text-dark-5 d-flex gap-8px align-items-center'>
                {t('Selling Price')}
                <span id='selling-price-info' className='cursor-pointer'><Info size={14} color='var(--bs-dark-6)' /></span>
                <UncontrolledTooltip
                  placement="bottom"
                  target={'selling-price-info'}
                  autohide={false}
                  offset={[0, 5]}
                >
                  <span className='text-white'> { sku.tax_inclusive?.selling_price ? t('It includes Tax') : t('It excludes Tax')}</span>
                </UncontrolledTooltip>
              </p>
              <p className='text-dark txt-body-md'>
                {sku.selling_price >= 0 ? <div className='d-flex gap-8px align-items-center flex-wrap'>
                  <div>
                    {sku.display_selling_price} </div>
                </div> : '-'}
              </p>

              <p className='txt-body-md text-dark-5'>
                {t('Wholesale Price')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.wholesale_price >= 0 ? `${sku.display_whole_sale_price}` : '-'}
              </p>
              
            </div>
          </div>
        </div>
      </div>}
      <div className='border  border-dark-1 rounded-12px'>
        <div className='bg-primary-lighter-global border-primary-lighter-global simple-sku-desc-content txt-h3-md text-dark w-100 border-radius-tl-tr-10'>
          {t('Dimension Details')}
        </div>
        <div className='d-flex gap-1 flex-column'>
          <div className='txt-sub-rg text-dark w-100 d-flex gap-5'>
            <div className='w-100 simple-sku-info-content'>
              <p className='txt-body-md text-dark-5'>
                {t('SKU Length')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.dimensions?.length?.length >= 0 ? sku.dimensions.length.display_length : '-' }
              </p>
              <p className='txt-body-md text-dark-5'>
                {t('SKU Width')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.dimensions?.breadth?.breadth >= 0 ? sku.dimensions.breadth.display_breadth : '-' }
              </p>
            </div>
            <div className='simple-sku-info-content w-100'>
              <p className='txt-body-md text-dark-5'>
                {t('SKU Height')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.dimensions?.height?.height >= 0 ? sku.dimensions.height.display_height : '-' }
              </p>
            </div>
          </div>
        </div>
      </div>

      {sku?.attributes?.length && <div className='border  border-dark-1 rounded-12px '>
        <div className='bg-primary-lighter-global border-primary-lighter-global simple-sku-desc-content simple-sku-desc-content txt-h3-md text-dark w-100 border-radius-tl-tr-10'>
          {t('Attribute Details')}
        </div>
        <div className='py-1'>
          {
            sku?.attributes?.map((attribute) => {
              const allAttributes = productDetails?.attributes
              return <AttributeItem key={attribute?.id} selectedVariationId={sku?.attributes?.find(attr => attr?.id === attribute?.id)?.sku_attribute_variation?.id}
                attribute={attribute}
                variations={allAttributes?.find(attr => attr?.id === attribute?.id)?.sku_attribute_variation} />
            })
          }
        </div>
      </div>}

      <div className='border  border-dark-1 rounded-12px'>
        <div className='bg-primary-lighter-global border-primary-lighter-global simple-sku-desc-content txt-h3-md text-dark w-100 border-radius-tl-tr-10'>
          {t('Additional Details')}
        </div>
        <div className='d-flex gap-1 flex-column'>
          <div className='txt-sub-rg text-dark w-100 d-flex gap-5'>
            <div className='w-100 simple-sku-info-content'>
              <p className='txt-body-md text-dark-5'>
                {t('Brand')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.brand || '-'}
              </p>
              <p className='txt-body-md text-dark-5'>
                {t('Manufacturer')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.manufacturer || '-'}
              </p>
              <p className='txt-body-md text-dark-5'>
                {t('Reference')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.reference || '-'}
              </p>
            </div>
            <div className='simple-sku-info-content w-100'>
              <p className='txt-body-md text-dark-5'>
                {t('Country of origin')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.country_of_origin || '-'}
              </p>
              <p className='txt-body-md text-dark-5'>
                {t('HS Code')}
              </p>
              <p className='text-dark txt-body-md'>
                {sku.hs_code || '-'}
              </p>      
            </div>
          </div>
        </div>
      </div>

      {sku.id && <div className='border  border-dark-1 rounded-12px'>
        <div className='bg-primary-lighter-global border-primary-lighter-global simple-sku-desc-content txt-h3-md text-dark w-100 border-radius-tl-tr-10'>
          {t('Activity Logs')}
        </div>
        <div className='d-flex gap-1 flex-column'>
          <div className='txt-sub-rg text-dark w-100 d-flex gap-5'>
           
            <div className='w-100 simple-sku-info-content'>
              <p className='txt-body-md text-dark-5'>
                {t('Created At')}
              </p>
              <p className='txt-body-md text-dark'>
                {sku.created_at || '-'}
              </p>    
              {simpleSkuDetail.id && <>
                <p className='txt-body-md text-dark-5'>
                  {t('Updated By')}
                </p>
                <p className='txt-body-md text-dark'>
                  {sku.updated_by_user || '-'}
                </p>
              </>}
            </div>
            <div className='simple-sku-info-content w-100'>
              {simpleSkuDetail.id && <>
                <p className='txt-body-md text-dark-5'>
                  {t('Updated Via')}
                </p>
                <p className='text-dark txt-body-md'>
                  {sourceDetails}
                </p>
                <p className='txt-body-md text-dark-5'>
                  {t('Updated At')}
                </p>
                <p className='txt-body-md text-dark'>
                  {sku.updated_at || '-'}
                </p>              
              </>}
            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default SimpleSkuInfo
