import { Status } from '@googlemaps/react-wrapper';
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner';

export const createPolygonWithHatch = ({ coordinates, color, map, hatchOverlayRef }) => {
  // Create the polygon
  const polygon = new window.google.maps.Polygon({
    paths: coordinates,
    strokeColor: color,
    strokeOpacity: 1,
    strokeWeight: 2,
    fillOpacity: 0, // Set to 0 since we're adding a custom overlay for hatching
    map, // Set the map instance.
  });

  // Get the bounds of the polygon
  const bounds = new window.google.maps.LatLngBounds();
  coordinates.forEach(coord => bounds.extend(coord));

  // Create a custom overlay for the hatching

  // Add the custom overlay
  const hatchOverlay = new hatchOverlayRef.current({
    bounds,
    coordinates
  });
  hatchOverlay.setMap(map);

  return polygon;
};

export const mapRender = (status) => {
  switch (status) {
  case Status.LOADING:
    return (
      <div className="d-flex justify-content-center align-content-center">
        <ComponentSpinner />
      </div>
    )
  case Status.FAILURE:
    return <div>'Error'</div>
  default:
    return null
  }
}

export const handleGetHatchOverlayClass = () => {
  class HatchOverlay extends window.google.maps.OverlayView {
    constructor({bounds, coordinates}) {
      super();
      this.bounds = bounds;
      this.coordinates = coordinates;
    }
    onAdd() {
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.pointerEvents = 'none';

      // Append the div to the overlay layer
      this.div = div;
      const panes = this.getPanes();
      panes.overlayLayer.appendChild(div);
    }

    draw() {
      const overlayProjection = this.getProjection();
      // Retrieve the south-west and north-east coordinates of this overlay
      const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
      const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

      // Set the size and position of the div
      const div = this.div;
      div.style.left = `${sw.x}px`;
      div.style.top = `${ne.y}px`;
      div.style.width = `${ne.x - sw.x}px`;
      div.style.height = `${sw.y - ne.y}px`;

      // Draw diagonal hatch lines
      const canvas = document.createElement('canvas');
      canvas.width = div.offsetWidth;
      canvas.height = div.offsetHeight;
      const ctx = canvas.getContext('2d');

      // Convert polygon coordinates to canvas pixel positions
      const path = new Path2D();
      this.coordinates.forEach((coord, index) => {
        const point = overlayProjection.fromLatLngToDivPixel(
          new window.google.maps.LatLng(coord.lat, coord.lng)
        );
        if (index === 0) {
          path.moveTo(point.x - sw.x, point.y - ne.y);
        } else {
          path.lineTo(point.x - sw.x, point.y - ne.y);
        }
      });
      path.closePath();

      // Clip the canvas to the polygon path
      ctx.save();
      ctx.clip(path);

      // Hatch line settings
      ctx.strokeStyle = '#808080'; // Gray color
      ctx.lineWidth = 1;

      // Draw diagonal lines
      const step = 10; // Distance between lines
      for (let i = -canvas.height; i < canvas.width; i += step) {
        ctx.beginPath();
        ctx.moveTo(i, 0);
        ctx.lineTo(i + canvas.height, canvas.height);
        ctx.stroke();
      }

      ctx.restore();

      // Set the canvas as the background of the div
      div.style.backgroundImage = `url(${canvas.toDataURL()})`;
    }

    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }
  }
  return HatchOverlay
}

export const fitPolygonsToBounds = (polygons, map, mapCenter) => {
  // Create a new bounds object
  const bounds = new window.google.maps.LatLngBounds()

  if (mapCenter.latLng?.latitude && mapCenter.latLng?.longitude) {
    bounds.extend({lat: mapCenter.latLng.latitude, lng: mapCenter.latLng.longitude})
  }

  // Iterate through each polygon
  polygons.forEach((polygon) => {
    const path = polygon.getPath()

    // Extend bounds to include each point in the polygon path
    path.getArray().forEach((point) => {
      bounds.extend(point)
    })
  })

  // Fit the map to the computed bounds
  map.fitBounds(bounds)
  const center = bounds.getCenter()
  map.setCenter(center)
}