import { ROUTES_KEYS } from '@src/router/route-keys'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'

export const ANALYTICS_TABS_LIST = {
  hub: { id: 'hub', name: 'Hub', path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_HUB] },
  seller: { id: 'seller', name: 'Seller', path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_SELLER] },
  shipping: { id: 'shipping', name: 'Shipping', path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_SHIPPING] },
  fulfilment: { id: 'fulfillment', name: 'Fulfilment', path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_FULFILLMENT] },
  lastmile: { id: 'last_mile', name: 'Last Mile', path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_LAST_MILE] },
  pos: { id: 'pos', name: 'POS', path: ROUTES_MAPPING[ROUTES_KEYS.ANALYTICS_POS] }
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '180px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}