import { isObjEmpty } from '@src/utility/Utils'
import { useOutsideClick } from '@src/utility/hooks/useOutsideClick'
import { useRTL } from '@src/utility/hooks/useRTL'
import classNames from 'classnames'
import { addDays, differenceInDays, endOfDay, endOfMonth, format, isBefore, isSameDay, parse, startOfDay, startOfMonth, startOfWeek, startOfYear, sub, subDays } from 'date-fns'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { ChevronDown } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownMenu, DropdownToggle, InputGroup, InputGroupText, UncontrolledTooltip } from 'reactstrap'
import Button from '../button'
import CustomToast from '../custom-toast/CustomToast'
import TimePicker from '../time-picker/TimePicker'
import close from './Close.svg'
import calender from './calender.svg'
import { RANGE_SELECTION_TYPE } from './dateFilter.constant'
import './styles.scss'

const DateFilter = ({
  value = null,
  placeholder,
  onChange,
  title = null,
  hasMaxDate = true,
  isClearable = true,
  isTimePicker = false,
  showCustomDisplayValue = false,
  startTimeLabel = 'Start Time',
  endTimeLabel = 'End Time',
  startTimePlaceholder = 'Select Start Time',
  endTimePlaceholder = 'Select End Time',
  container = '',
  customStaticRanges,
  defaultValue = {},
  currentValue = {},
  id = '',
  tooltipText = '',
  minDate = null,
  rangeRestrictionInDays,
  canSelectTime = false,
  isDisabled = false
}) => {

  const { t } = useTranslation()
  const [isRtl] = useRTL()
  const dateRangePickerContainerRef = useRef()
  const dateRangePickerbodyRef = useRef()
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false)
  const [isCustom, setIsCustom] = useState(currentValue.interval === RANGE_SELECTION_TYPE.CUSTOM.value)
  const [rangeSelectionType, setRangeSelectionType] = useState()
  const [preview, setPreview] = useState()

  const parseDate = (dateString) => parse(isTimePicker ? dateString.split(' ')[0] : dateString, 'dd-MM-yyyy', new Date())
  const extractTime = (dateTimeString) => dateTimeString?.split(' ')[1] || ''
  const defaultStartEndDate = {
    startDate: value?.length ? parseDate(value[0]) : (!isObjEmpty(defaultValue) && new Date(defaultValue.startDate)) || new Date(),
    endDate: value?.length ? parseDate(value[value.length - 1]) : (!isObjEmpty(defaultValue) && new Date(defaultValue.endDate)) || new Date()
  }
  const [date, setDate] = useState([
    {
      ...defaultStartEndDate,
      key: 'selection',
      color: 'var(--bs-primary)',
      isApplied: !!currentValue.isApplied,
      displayDateLabel:currentValue?.displayDateLabel || defaultValue.displayDateLabel,
      interval:currentValue?.interval || defaultValue.interval
    }
  ])
  const [time, setTime] = useState((currentValue.interval === RANGE_SELECTION_TYPE.CUSTOM.value || canSelectTime) ? {startTime: currentValue.startTime, endTime: currentValue.endTime} : '')
  const selectedStartDate = date[0].startDate
  const selectedEndDate = date[0].endDate
  const maxDate = rangeRestrictionInDays && !isSameDay(selectedStartDate, defaultStartEndDate.startDate) ? new Date(Math.min(addDays(selectedStartDate, rangeRestrictionInDays), new Date())) : new Date()
  const minDateValue = rangeRestrictionInDays && !isSameDay(selectedStartDate, defaultStartEndDate.startDate) ? subDays(selectedEndDate, rangeRestrictionInDays) : minDate
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range()
      return isCustom && definedRange.rangeSelectionType === RANGE_SELECTION_TYPE.CUSTOM.value
        ? true
        : isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
    }
  }
  
  const isApplyDisabled = isTimePicker && !!((time.startTime && !time.endTime) || (!time.startTime && time.endTime))
  
  const startEndOfDate = {startOfToday: startOfDay(new Date()), endOfToday: endOfDay(new Date())}
  const getTodayRange = () => ({ startDate: startEndOfDate.startOfToday, endDate: startEndOfDate.endOfToday })
  const getYesterdayRange = () => ({ startDate: startOfDay(subDays(startEndOfDate.startOfToday, 1)), endDate: startOfDay(subDays(startEndOfDate.startOfToday, 1)) })
  const getThisWeekRange = () => ({ startDate: startOfWeek(startEndOfDate.startOfToday), endDate: startEndOfDate.endOfToday })
  const getThisMonthRange = () => ({ startDate: startOfMonth(startEndOfDate.startOfToday), endDate: startEndOfDate.endOfToday })
  const getPrevMonthRange = () => ({ startDate: startOfMonth(sub(startEndOfDate.startOfToday, { months: 1 })), endDate: endOfMonth(sub(startEndOfDate.startOfToday, { months: 1 })) })
  const getLastNinetyDaysRange = () => ({ startDate: startOfDay(subDays(startEndOfDate.startOfToday, 89)), endDate: startEndOfDate.endOfToday })
  const getThisYearRange = () => ({ startDate: startOfYear(startEndOfDate.startOfToday), endDate: startEndOfDate.endOfToday })

  const defaultStaticRanges = [
    { label: t('Today'), range: getTodayRange },
    { label: t('Yesterday'), range: getYesterdayRange },
    { label: t('This Week'), range: getThisWeekRange },
    { label: t('This Month'), range: getThisMonthRange },
    { label: t('Prev. Month'), range: getPrevMonthRange },
    { label: t('Last 90 Days'), range: getLastNinetyDaysRange },
    { label: t('This Year'), range: getThisYearRange }
  ].filter((range) => {
    if (minDate) {
      const rangeStartDate = range.range().startDate
      if (isBefore(rangeStartDate, minDate)) {
        return false
      }
    }
    if (rangeRestrictionInDays) {
      const dateDifference = differenceInDays(range.range().endDate, range.range().startDate)
      if (dateDifference > rangeRestrictionInDays) {
        return false
      }
    }
    return true
  })
  const createStaticRanges = (ranges) => ranges.map((range) => ({ ...staticRangeHandler, ...range }))
  const staticRanges = createStaticRanges(customStaticRanges || defaultStaticRanges)

  const doDateRangesSame = (range1, range2) => (
    isSameDay(range1.startDate, range2.startDate) && isSameDay(range1.endDate, range2.endDate)
  )

  const handleDateLabelFormat = useCallback((range) => {
    if ((isTimePicker && !canSelectTime) || showCustomDisplayValue) {
      return date[0].displayDateLabel 
    }
    const rangeStartDate = parseDate(range[0])
    const rangeEndDate = parseDate(range[range.length - 1])
    const rangeToCompare = { startDate: rangeStartDate, endDate: rangeEndDate }
    const dateDifference = differenceInDays(rangeEndDate, rangeStartDate)


    const ranges = {
      todayRange: getTodayRange(),
      yesterdayRange: getYesterdayRange(),
      weekRange: getThisWeekRange(),
      monthRange: getThisMonthRange(),
      quarterRange: getLastNinetyDaysRange(),
      yearRange: getThisYearRange(),
      prevMonthRange: getPrevMonthRange()
    }

    const formattedRangeStartDate = format(rangeStartDate, 'dd/MM/yyyy')
    const formattedRangeEndDate = format(rangeEndDate, 'dd/MM/yyyy')
    if (doDateRangesSame(rangeToCompare, ranges.todayRange)) {
      return `Today (${range[0].split(' ')[0]})`
    } else if (doDateRangesSame(rangeToCompare, ranges.yesterdayRange)) {
      return `Yesterday (${range[0].split(' ')[0]})`
    } else if (doDateRangesSame(rangeToCompare, ranges.weekRange)) {
      return `This Week (${formattedRangeStartDate} - ${formattedRangeEndDate})`
    } else if (doDateRangesSame(rangeToCompare, ranges.monthRange)) {
      return `This Month (${formattedRangeStartDate} - ${formattedRangeEndDate})`
    } else if (doDateRangesSame(rangeToCompare, ranges.prevMonthRange)) {
      return `Prev. Month (${formattedRangeStartDate} - ${formattedRangeEndDate})`
    } else if (doDateRangesSame(rangeToCompare, ranges.quarterRange)) {
      return `Last 90 Days (${formattedRangeStartDate} - ${formattedRangeEndDate})`
    } else if (doDateRangesSame(rangeToCompare, ranges.yearRange)) {
      return `This Year (${formattedRangeStartDate} - ${formattedRangeEndDate})`
    } else {
      return `${formattedRangeStartDate} - ${formattedRangeEndDate} (${dateDifference + 1}) days`
    }
  }, [date])

  const handleToggleDateRangePicker = () => setDateRangePickerOpen(prev => !prev)

  const handleApplyDateFilter = () => {
    const start = format(date[0].startDate, 'dd-MM-yyyy')
    const end = format(date[0].endDate, 'dd-MM-yyyy')
    let body = { start, end }
    if (isTimePicker || showCustomDisplayValue) {
      body = { ...body, ...date[0] }
      if ((date[0].interval === RANGE_SELECTION_TYPE.CUSTOM.value) || (canSelectTime && time.startTime && time.endTime)) {
        body = { ...body, ...time }
      }
    }
    if (canSelectTime) {
      onChange({...body, isApplied: true})
    } else {
      onChange(body)
    }
    setDate([{ ...date[0], isApplied: true }])
    handleToggleDateRangePicker()
    if (date[0].interval !== RANGE_SELECTION_TYPE.CUSTOM.value && !canSelectTime) setTime({ startTime: '', endTime: '' })
  }

  const resetTimeAndDate = () => {
    if (date?.length) setDate([{ ...date[0], ...defaultStartEndDate }])
    if (!isTimePicker) return
    
    if (date[0].isApplied) {
      setDate([
        {
          startDate:(!isObjEmpty(currentValue) && new Date(currentValue.startDate)) || new Date(),
          endDate: (!isObjEmpty(currentValue)  && new Date(currentValue.endDate)) || new Date(),
          key: 'selection',
          color: 'var(--bs-primary)',
          isApplied:true,
          displayDateLabel: currentValue?.displayDateLabel,
          interval: currentValue.interval
        }
      ])
      if (currentValue.interval === RANGE_SELECTION_TYPE.CUSTOM.value) {
        setTime({startTime: extractTime(value?.[0]), endTime: extractTime(value?.[value.length - 1])})
        setIsCustom(true)
      } else {
        setIsCustom(false)
        if (canSelectTime && currentValue.startTime && currentValue.endTime) return
        setTime({startTime:'', endTime:''})
      }
    } else {
      setDate([
        {
          ...defaultValue,
          startDate:(!isObjEmpty(defaultValue) && new Date(defaultValue.startDate)) || new Date(),
          endDate: (!isObjEmpty(defaultValue) && new Date(defaultValue.endDate)) || new Date(),
          key: 'selection',
          color: 'var(--bs-primary)',
          isApplied:false
        }
      ])
      setIsCustom(false)
      if (canSelectTime && currentValue.startTime && currentValue.endTime) return
      setTime({startTime:'', endTime:''})
    }
  }

  const handleClearDateFilter = () => {
    if (!isObjEmpty(defaultValue)) {
      const start = format(defaultValue.startDate, 'dd-MM-yyyy')
      const end = format(defaultValue.endDate, 'dd-MM-yyyy')
      onChange({ start, end, ...defaultValue})
    } else {
      onChange(null)
    }
    setDate([
      {
        ...defaultValue,
        startDate:(!isObjEmpty(defaultValue) && new Date(defaultValue.startDate)) || new Date(),
        endDate: (!isObjEmpty(defaultValue) && new Date(defaultValue.endDate)) || new Date(),
        key: 'selection',
        color: 'var(--bs-primary)',
        isApplied: false
      }
    ])
    setTime({ startTime: '', endTime: '' })
    setIsCustom(false)
    handleToggleDateRangePicker()
  }

  const handleCloseDateFilter = () => {
    setDateRangePickerOpen(false)
    resetTimeAndDate()
  }

  const handleStartTimePicker = (time) => setTime(prev => ({ ...prev, startTime: time }))

  const handleEndTimePicker = (time) => setTime(prev => ({ ...prev, endTime: time }))

  const handelOnPreviewChange = (preview) => {
    if (preview?.startDate) {
      setPreview(preview)
    } else {
      setPreview({startDate:preview, endDate:preview})
    }
    setRangeSelectionType(preview?.rangeSelectionType)
  }

  const handleChange = (item) => {
    const selectedRange = { ...item.selection }
    if (rangeRestrictionInDays) {
      const dateDifference = differenceInDays(selectedRange.endDate, selectedRange.startDate)
      if (dateDifference > rangeRestrictionInDays) {
        const defaultDate = new Date()
        selectedRange.startDate = defaultDate
        selectedRange.endDate = defaultDate
        setDate([selectedRange])
        CustomToast('Maximum date range allowed is 90 days. Please shorten your selection', {my_type: 'error', audioReqired: true})
        return
      }
    }
    if (isTimePicker || showCustomDisplayValue) {
      if (!rangeSelectionType || rangeSelectionType === RANGE_SELECTION_TYPE.CUSTOM.value) {
        if (!selectedRange.startDate) {
          selectedRange.startDate = Date.now()
          selectedRange.endDate = Date.now()
        }
        selectedRange.interval = RANGE_SELECTION_TYPE.CUSTOM.value
        selectedRange.displayDateLabel = RANGE_SELECTION_TYPE.CUSTOM.label
        setIsCustom(true)
        if (canSelectTime) {
          setTime({startTime:currentValue.startTime || time.startTime || '', endTime:currentValue.endTime || time.endTime || ''})
        } else {
          setTime({startTime:currentValue.startTime || '', endTime:currentValue.endTime || ''})
        }
      } else if (rangeSelectionType === RANGE_SELECTION_TYPE.STATIC.value) {
        selectedRange.interval = item.selection.interval
        setIsCustom(false)
        setTime({startTime:'', endTime:''})
      }
    }
    setDate([selectedRange])
  }

  useOutsideClick([dateRangePickerContainerRef, dateRangePickerbodyRef], handleCloseDateFilter)

  useEffect(() => {
    if (canSelectTime) {
      setTime(typeof currentValue === 'object' && !isObjEmpty(currentValue) ? {startTime: currentValue.startTime, endTime: currentValue.endTime} : '')
    }
  }, [currentValue.startTime, currentValue.endTime])

  return (
    <div className="datefilterDropdownWithTitle" ref={dateRangePickerContainerRef}>
      <Dropdown className="date-filter" isOpen={isDateRangePickerOpen} toggle={() => { }}>
        <DropdownToggle
          id={id}
          onClick={() => isDisabled ? null : handleToggleDateRangePicker()}
          className="p-0 w-100 d-flex gap-50 align-items-center justify-content-between fw-normal date-filter-dropdown-toggle"
          color="white"
        >
          <InputGroup className="dropdown">
            <InputGroupText className={classNames('dropdown-container', { 'disabled-dropdown-container': isDisabled })}>
              <div className="flex-center-center gap-50">
                <img src={calender} alt="calender" />
                {title && <span className="txt-sub-rg text-dark-6">{title}</span>}
              </div>
              <div className={classNames('d-flex align-items-center', {
                'cursor-default': isDisabled,
                'cursor-pointer': !isDisabled
              })}>
                <div
                  className="border-0 date-value-container text-nowrap text-start"
                >
                  {value || !isObjEmpty(defaultValue)
                    ? <span className="text-dark">
                      {handleDateLabelFormat(value || [format(defaultValue.startDate, 'dd-MM-yyyy'), format(defaultValue.endDate, 'dd-MM-yyyy')])}
                    </span>
                    : <span className="text-dark-6">{t(placeholder)}</span>
                  }
                </div>
                {(value || !isObjEmpty(defaultValue)) && isClearable && !isDisabled && <div className="cursor-pointer" onClick={handleClearDateFilter}>
                  <img src={close} alt="clear" className="me-8px" />
                </div>}
                <ChevronDown size={16} color="black" className={classNames({ 'rotated-date-filter-dropdown-icon': isDateRangePickerOpen })} />
              </div>
            </InputGroupText>
          </InputGroup>
        </DropdownToggle>
        {isDateRangePickerOpen &&
          <DropdownMenu className="p-0" container={container}>
            <div className="start-0 box-shadow-6 date-range-picker-container" ref={dateRangePickerbodyRef}>
              <div>
                {hasMaxDate
                  ? <DateRangePicker
                    onChange={(item) => handleChange(item)}
                    showSelectionPreview={false}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={date}
                    direction="vertical"
                    maxDate={maxDate}
                    inputRanges={[]}
                    staticRanges={staticRanges}
                    editableDateInputs={true}
                    locale={locales[isRtl ? 'arSA' : 'enUS']}
                    preview={{...preview, color:'var(--bs-primary)'}}
                    showPreview={!!(preview?.startDate && rangeSelectionType !== RANGE_SELECTION_TYPE.CUSTOM.value)}
                    onPreviewChange={(preview) => handelOnPreviewChange(preview)}
                    {...(minDateValue ? { minDate: minDateValue } : {})} 
                  />
                  : <DateRangePicker
                    onChange={(item) => handleChange(item)}
                    showSelectionPreview={false}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={date}
                    direction="vertical"
                    inputRanges={[]}
                    staticRanges={staticRanges}
                    editableDateInputs={true}
                    locale={locales[isRtl ? 'arSA' : 'enUS']}
                    preview={{...preview, color:'var(--bs-primary)'}}
                    showPreview={!!(preview?.startDate && rangeSelectionType !== RANGE_SELECTION_TYPE.CUSTOM.value)}
                    onPreviewChange={(preview) => handelOnPreviewChange(preview)}
                    {...(minDateValue ? { minDate: minDateValue } : {})}
                  />
                }
              </div>
              {isTimePicker && (isCustom || canSelectTime) &&
                <div className="d-flex gap-16px px-16px py-10px bg-white">
                  <div className="w-100">
                    <TimePicker
                      key='start_time'
                      label={startTimeLabel}
                      placeHolder={startTimePlaceholder}
                      onChange={(time) => handleStartTimePicker(time)}
                      value={time.startTime}
                    />
                  </div>
                  <div className="w-100">
                    <TimePicker
                      key='end_time'
                      label={endTimeLabel}
                      placeHolder={endTimePlaceholder}
                      onChange={(time) => handleEndTimePicker(time)}
                      value={time.endTime}
                    />
                  </div>
                </div>}
              <div className="w-100 d-flex gap-6px justify-content-end bg-white p-16px pt-6px date-range-picker-footer">
                <Button className="txt-asst-md" ofType="compressed" ofStyle="outlined" onClick={handleCloseDateFilter}>{t('Cancel')}</Button>
                <Button
                  className="txt-asst-md"
                  ofType="compressed"
                  ofStyle="filled"
                  disabled={isApplyDisabled}
                  onClick={handleApplyDateFilter}>
                  {t('Apply')}
                </Button>
              </div>
            </div>
          </DropdownMenu>
        }
      </Dropdown>
      {!isDateRangePickerOpen && tooltipText && <UncontrolledTooltip autohide={false}  trigger="hover" offset={[0, 5]} placement='top' target={id} popperClassName={currentValue.startTime ? 'width-350' : 'width-200'} innerClassName='mw-100'>
        {tooltipText}
      </UncontrolledTooltip>}
    </div>
  )
}

export default DateFilter

DateFilter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]), /*This prop will represent the selected date value. If selected then it would be a array length of 2, else it can be set to null.*/
  title: PropTypes.string,  /*This prop will specify the title text to display */
  placeholder: PropTypes.string, /*This prop will specify the placeholder text to display when no date range is selected. It can be a string representing the placeholder text.*/
  onChange: PropTypes.func, /*This is the handler function that manages date selection and updates the value prop when the user selects a date range. It should be a callback function that takes the selected date range as an argument.*/
  hasMaxDate: PropTypes.bool, /*This props will show max date if true */
  isClearable: PropTypes.bool,
  isTimePicker: PropTypes.bool,
  showCustomDisplayValue: PropTypes.bool,
  startTimeLabel: PropTypes.string,
  endTimeLabel: PropTypes.string,
  startTimePlaceholder: PropTypes.string,
  endTimePlaceholder: PropTypes.string,
  customStaticRanges: PropTypes.array,
  defaultValue: PropTypes.object,
  currentValue: PropTypes.object,
  id: PropTypes.string,
  tooltipText: PropTypes.string
}
