import required_field from '@src/assets/images/svg/required_field.svg'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

const ShippingDetails = ({ shippingDetailsData, isFieldRequired = false }) => {
  const { t } = useTranslation()

  return (
    <>
      <Row className="mb-1">
        <Col className="col-3">{t('Recipient\'s Name')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.first_name ? (
            <span>{shippingDetailsData.entity_data.shipping_address.first_name} {shippingDetailsData.entity_data.shipping_address.last_name}</span>
          ) : '-'}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3">{t('Email ID')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.email || '-'}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3">{t('Address')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_address}
        </Col>
      </Row>
      {shippingDetailsData.entity_data.shipping_address.area && <Row className="mb-1">
        <Col className="col-3">{t('Area')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.area}
        </Col>
      </Row>
      }
      <Row className="mb-1">
        <Col className="col-3"><span>{t('City')}</span>{isFieldRequired && <img className='position-absolute' src={required_field} alt="required_field" height="4px" width="4px"/>}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_city}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="col-3"><span>{t('State/Country')}</span>{isFieldRequired && <img className='position-absolute' src={required_field} alt="required_field" height="4px" width="4px"/>}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_state_country}
        </Col>
      </Row>
      {/* <Row className="mb-1">
        <Col className="col-3">{t("Country")}{isFieldRequired && <img className='position-absolute' src={required_field} alt="required_field" height="4px" width="4px"/>}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.omniful_country ? (
            <span>
              {shippingDetailsData.entity_data.shipping_address.omniful_country} {shippingDetailsData.entity_data.shipping_address.country_code && `(${shippingDetailsData.entity_data.shipping_address.country_code})`}
            </span>
          ) : "-"}
        </Col>
      </Row> */}
      {shippingDetailsData.entity_data.shipping_address.display_sales_channel_city && <Row className="mb-1">
        <Col className="col-3">{t('Sales Channel City')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_sales_channel_city}
        </Col>
      </Row>}
      {shippingDetailsData.entity_data.shipping_address.display_manual_city && <Row className="mb-1">
        <Col className="col-3">{t('Shipping City')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.display_manual_city}
        </Col>
      </Row>}
      <Row>
        <Col className="col-3">{t('Phone number')}:</Col>
        <Col className="text-secondary col-9">
          {shippingDetailsData.entity_data.shipping_address.phone || '-'}
        </Col>
      </Row>
    </>
  )
}

export default ShippingDetails