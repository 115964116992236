import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import Button from '@src/@core/components/ui/button'
import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import { ORDER_SOURCE, ORDER_TYPES, TENANT_TYPE } from '@src/App.constants'
import RemarksModal from '@src/views/Purchases/components/PuchaseOrder/pages/RemarksModal'
import { DELIVERY_TYPE, ORDER_STATUS } from '@src/views/sales/constant/orders.constants'
import AddFreeTags from '@src/views/sales/pending-actions/components/AddFreeTags/AddFreeTags'
import { REASONS_FOR_ON_HOLD } from '@src/views/sales/sales.constant'
import { OrderDetailsRow } from '@src/views/sales/sales.utils'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card } from 'reactstrap'
import { RenderAttachment, RenderOrderTags, RenderPaymentStatus, RenderRefreshSalesChannel, RenderSalesChannel, RenderSeller, RenderStoRequestId, RenderTags } from './RenderBasicDetailComponents'


const BasicDetailsCard = ({ orderDetails, hasEditOrderDetailsPermission, toggleAddLocationAttributesModal, isOpenAddLocationAttributesModal, syncButtons, handleSyncPaymentStatus, handleNavigateToReturnOrders = () => { }, handleOpenSplittedOrderDetailSideSheet, handleToggleAttachmentSidebar, hasEditAttachmentButton, handleShowSTODetail }) => {
  
  // Hooks
  const noteDescriptionContainer = useRef()
  const { t } = useTranslation()

  // Selectors
  const tenantType = useSelector(store => store.auth.userData.tenant.type)

  // States
  const [isNotesShown, setIsNotesShown] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  
  // Variables
  const hasReferenceSalesChannel = orderDetails.source === ORDER_SOURCE.MANUAL.label && orderDetails.sales_channel.name

  // Functions
  const handleNotesDescriptions = useCallback(() => {
    setIsNotesShown(prev => !prev)
  }, [])

  const hideParentReason = useCallback((reason) => {
    return (
      reason === REASONS_FOR_ON_HOLD.INVENTORY_NOT_PRESENT.label ||
      reason === REASONS_FOR_ON_HOLD.SKU_NOT_VALID.label
    );
  }, []); 

  useEffect(() => {
    if (noteDescriptionContainer.current) {
      const container = noteDescriptionContainer.current;
      setIsTruncated(container.scrollHeight > container.clientHeight);
    }
  }, [orderDetails.order_note])

  const basicDetailsData = useMemo(() => [
    {
      id: 'sto_request_id',
      entityName: 'STO Request ID',
      entityValue: <RenderStoRequestId orderDetails={orderDetails} handleShowSTODetail={handleShowSTODetail} />,
      omit: orderDetails.type !== ORDER_TYPES.STO.value || !orderDetails.stock_transfer_request_details.display_id
    },
    {
      id: 'order_tags',
      entityName: 'Order Tags',
      entityValue: <RenderOrderTags orderDetails={orderDetails}/>,
      omit: orderDetails.tags.length <= 0
    },
    {
      id: 'created_at',
      entityName: 'Created At',
      entityValue: orderDetails.order_created_at,
    },
    {
      id: 'tags',
      entityName: 'Tags',
      entityValue: <RenderTags orderDetails={orderDetails} hasEditOrderDetailsPermission={hasEditOrderDetailsPermission} toggleAddLocationAttributesModal={toggleAddLocationAttributesModal}/>,
      omit: orderDetails.type === ORDER_TYPES.RTS.value
    },
    {
      id: 'ordered_quantity',
      entityName: 'Ordered Quantity',
      entityValue: orderDetails.ordered_quantity,
    },
    {
      id: 'number_of_items',
      entityName: 'Number of Items',
      entityValue: orderDetails.total_item_count
    },
    {
      id: 'delivery_date',
      entityName: 'Delivery Date',
      entityValue: orderDetails.slot.display_date,
      omit: !orderDetails.slot?.display_date
    },
    {
      id: 'delivery_slot',
      entityName: 'Delivery Slot',
      entityValue: `${orderDetails.slot.display_start_time} - ${orderDetails.slot.display_end_time}`,
      omit: !orderDetails.slot?.display_date
    },
    {
      id: 'payment_status',
      entityName: 'Payment Status',
      entityValue: <RenderPaymentStatus orderDetails={orderDetails} syncButtons={syncButtons} handleSyncPaymentStatus={handleSyncPaymentStatus}/>,
      omit: !orderDetails.payment_method
    },
    {
      id: 'payment_mode',
      entityName: 'Payment Mode',
      entityValue: orderDetails.invoice.payment_mode,
      omit: !orderDetails.invoice.payment_mode
    },
    {
      id: 'hub_name',
      entityName: 'Hub Name',
      entityValue: orderDetails.status === ORDER_STATUS.ON_HOLD.name? orderDetails.assigned_hub?.name : orderDetails.hub?.name,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name && !orderDetails.assigned_hub?.name
    },
    {
      id: 'seller_name',
      entityName: 'Seller',
      entityValue: <RenderSeller orderDetails={orderDetails} />,
      omit: tenantType === TENANT_TYPE.SELF_USAGE
    },
    {
      id: 'source',
      entityName: 'Source',
      entityValue: orderDetails.display_source,
    },
  
    {
      id: 'sales_channel',
      entityName: 'Sales Channel',
      entityValue: <RenderSalesChannel orderDetails={orderDetails}/>,
      omit: orderDetails.source === ORDER_SOURCE.MANUAL.label
    },
    {
      id: 'return_status',
      entityName: 'Return Status',
      entityValue: <CustomLabel title={orderDetails.return_status} className="order-status-tag" />,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name || !orderDetails.return_status
    },
    {
      id: 'return_order_count',
      entityName: 'Return Order Count',
      entityValue: (
        <span className='text-primary cursor-pointer' onClick={handleNavigateToReturnOrders}>
          {orderDetails.return_order_count} {t('Return(s)')}
        </span>
      ),
      omit: (orderDetails.status === ORDER_STATUS.ON_HOLD.name || !orderDetails.return_order_count)
    },
    {
      id: 'delivery_reattempts',
      entityName: 'Delivery Reattempts',
      entityValue: orderDetails.shipment.number_of_retries,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name || orderDetails.shipment.number_of_retries <= 0
    },
    {
      id: 'parent_reason',
      entityName: 'Reason',
      entityValue: orderDetails.reason,
      omit: !orderDetails.reason || hideParentReason(orderDetails.reason) || orderDetails.status !== ORDER_STATUS.ON_HOLD.name
    },
    {
      id: 'reason_for_on_hold',
      entityName: 'Reason for On Hold',
      entityValue: orderDetails.forceful_onhold_reason,
      omit: !orderDetails.forceful_onhold_reason || orderDetails.status !== ORDER_STATUS.ON_HOLD.name
    },
    {
      id: 'parent_order_id',
      entityName: 'Parent Order ID',
      entityValue: (
        <span className='text-primary cursor-pointer' onClick={handleOpenSplittedOrderDetailSideSheet}>
          {orderDetails.parent_order_details.display_id}
        </span>
      ),
      omit: !orderDetails.is_split_order
    },
    {
      id: 'shipping_method',
      entityName: 'Shipping Method',
      entityValue: `${orderDetails.ssc_order_details.shipping_company} (${t('Sales Channel')})`,
      omit: !orderDetails.ssc_order_details.shipping_company
    },
    {
      id: 'delivery_type',
      entityName: 'Delivery Type',
      entityValue: DELIVERY_TYPE[orderDetails.delivery_type.toUpperCase()]?.label,
      omit: !(orderDetails.delivery_type && orderDetails.delivery_type !== DELIVERY_TYPE.NORMAL_DELIVERY.name)
    },
    {
      id: 'partial_fulfillment',
      entityName: 'Partial Fulfillment',
      entityValue: t('Allowed'),
      omit: !orderDetails.is_partial_fulfillment_allowed
    },
    {
      id: 'attachment',
      entityName: 'Attachments',
      entityValue: <RenderAttachment orderDetails={orderDetails} handleToggleAttachmentSidebar={handleToggleAttachmentSidebar} hasEditOrderDetailsPermission={hasEditOrderDetailsPermission} hasEditAttachmentButton={hasEditAttachmentButton}/>,
      omit: !(hasEditOrderDetailsPermission && hasEditAttachmentButton)
    },
    {
      id: 'reorder_reason',
      entityName: 'Reorder Reason',
      entityValue: orderDetails.reorder_details?.reason,
      omit: !orderDetails.is_reorder || !orderDetails.reorder_details?.reason,
    },
    {
      id: 'original_order',
      entityName: 'Original Order',
      entityValue: <CopyOnClick
        id={orderDetails.reorder_details?.previous_order_display_id}
        handleDetails={null}
        columnKey='original_order'
      >
        {orderDetails.reorder_details?.previous_order_display_id}
      </CopyOnClick>,
      omit: !orderDetails.is_reorder
    },
    {
      id: 'reference_sales_channel',
      entityName: 'Reference Sales Channel',
      entityValue: <RenderRefreshSalesChannel orderDetails={orderDetails}/>,
      omit: !(hasReferenceSalesChannel)
    },
    {
      id: 'order_note',
      entityName: 'Note',
      entityValue: <>
        <pre ref={noteDescriptionContainer} className='order-note-wrapper bg-transparent txt-body-rg two-line-truncate mb-0'>{orderDetails.order_note}</pre>   {isTruncated && <div>
          <Button
            ofStyle="textOnly"
            ofType="compressed"
            className="txt-body-rg py-2px px-0 box-shadow-0" onClick={handleNotesDescriptions}>{t('Read More')}</Button>
        </div>}
      </>,
      omit: !orderDetails.order_note
    }
  ], [
    orderDetails,
    handleShowSTODetail,
    hasEditOrderDetailsPermission,
    toggleAddLocationAttributesModal,
    syncButtons,
    handleSyncPaymentStatus,
    handleNavigateToReturnOrders,
    handleOpenSplittedOrderDetailSideSheet,
    handleToggleAttachmentSidebar,
    hasEditAttachmentButton,
    tenantType,
    hasReferenceSalesChannel,
    noteDescriptionContainer,
    isTruncated,
    handleNotesDescriptions,
    t
  ]);

  const filteredDetailsData = useMemo(() => 
    basicDetailsData.filter(item => !item.omit),
  [basicDetailsData])

  return (
    <>
      <Card className='bg-white w-100 rounded-3  details-card mt-24px'>
        <div className='card-header py-12px px-16px rounded-top-2  text-secondary txt-h3-md'>
          <span>{t('Basic Details')}</span>
        </div>
        <div className='basic-details-row-wrapper'>
          {filteredDetailsData.map((item) => (
            <OrderDetailsRow 
              key={item.id}
              entityNameColSpan={4}
              entityValueColSpan={8}
              entityName={item.entityName}
              entityValue={item.entityValue || '-'}
            />
          ))}
        </div>
        {orderDetails.type !== ORDER_TYPES.RTS.value ? (
          <AddFreeTags isOpen={isOpenAddLocationAttributesModal} toggle={toggleAddLocationAttributesModal} orderDetails={orderDetails} />
        ) : null}
      </Card>
      <RemarksModal
        title='Note'
        isRemarksShow={isNotesShown}
        setIsRemarksShow={setIsNotesShown}
        remarksDiscription={orderDetails.order_note}
      />
    </>
  )
}

export default memo(BasicDetailsCard)