import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { CREATE_RETURN_REQUES_PATH } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { ROUTES_KEYS } from '@src/router/route-keys'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'
import { AbilityContext } from '@src/utility/context/Can'
import { STRINGIFY } from '@src/utility/hooks/useFilters'
import { ATTACHMENTS_SIDEBAR_MODE, EDIT_ORDER_ENTITY_TYPES, ORDER_OPERATIONAL_BUTTONS } from '@src/views/sales/constant/orders.constants'
import { ORDER_RECREATION_TYPE } from '@src/views/sales/create-order/create-order.constant'
import { DEFAULT_TRANSACTION_SIDE_SHEET, EDIT_MODAL_TYPE, LIVE_ORDERS_TABS } from '@src/views/sales/sales.constant'
import { checkUneditedSalesChannelOrder } from '@src/views/sales/sales.utils'
import { clearCreateOrderItems, clearInvoiceData, editOrder, getSingleOrderDetails, resetSuccess, syncOrderPaymentStatus } from '@src/views/sales/store/store'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import useOrderRecreation from '../../components/useOrderRecreation'

export const useItemDetail = ({setIsModalOpen, handleGetParentData }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const { handleOrderRecreation } = useOrderRecreation();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const currentTab = queryParams.get('currentTab');
  // Store
  const { singleOrderDetailsData } = useSelector((state) => state.sales)
  const salesSuccess = useSelector((state) => state.sales.success)
  const returnSuccess = useSelector((state) => state.returns.success)
  const sales_loading = useSelector((state) => state.sales.loading)
  const orderDetails = singleOrderDetailsData?.data
  const selectedGlobalHubId = useSelector((store) => store.auth.selectedGlobalHubId);

  // States
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState({ open: false, key: EDIT_MODAL_TYPE.CUSTOMER_DETAILS.key })
  const [isSplittedOrderDetailsSideSheetOpen, setIsSplittedOrderDetailsSideSheetOpen] = useState(false)
  const [multipleAwbModalOpen, setMultipleAwbModalOpen] = useState(false)
  const [multipleAwbList, setMultipleAwbList] = useState([])
  const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false)
  const [isOnHoldOrderCofirmationPopUpOpen, setIsOnHoldOrderCofirmationPopUpOpen] = useState(false)
  const [Buttons, setButton] = useState({ actionButtons: {}, syncButtons: {}, editButtons: {} })
  const [isStoDetailsOpen, setIsStoDetailsOpen] = useState(false)
  const [isReassignHubModalOpen, setReassignHubModalOpen] = useState(false)
  const [reorderModal, setReorderModal] = useState({ isOpen: false, recreationType: '' })
  const [transactionSideSheet, setTransactionSideSheet] = useState(DEFAULT_TRANSACTION_SIDE_SHEET);

  const { syncButtons, editButtons } = Buttons

  const hasEditAttachmentButton = useMemo(() => {
    return orderDetails?.buttons?.find(btn => btn.key === ORDER_OPERATIONAL_BUTTONS.EDIT_ATTACHMENTS.id)
  }, [orderDetails])
  const [attachmentSidebar, setAttachmentSidebar] = useState({
    isOpen: false,
    mode: ATTACHMENTS_SIDEBAR_MODE.ADD.id
  })
  const [addedAttachments, setAddedAttachments] = useState([])
  const [isOpenAddLocationAttributesModal, setIsOpenAddLocationAttributesModal] = useState(false)

  const hasEditOrderDetailsPermission = ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource)
  const isUneditedSalesChannelOrder = checkUneditedSalesChannelOrder(orderDetails)

  const toggleAddLocationAttributesModal = () => {
    setIsOpenAddLocationAttributesModal(p => !p)
  }

  const handleToggleAttachmentSidebar = () => {
    const mode = hasEditOrderDetailsPermission && hasEditAttachmentButton
      ? orderDetails.attachments?.length ? ATTACHMENTS_SIDEBAR_MODE.EDIT.id : ATTACHMENTS_SIDEBAR_MODE.ADD.id
      : ATTACHMENTS_SIDEBAR_MODE.VIEW.id
    setAttachmentSidebar(prev => ({ ...prev, isOpen: !prev.isOpen, mode }))
  }

  const handleWhenAttachmentEdited = (req) => {
    const message = req.toastMessage || 'Files saved successfully';
    CustomToast(message, { my_type: 'success' })
    handleToggleAttachmentSidebar()
    dispatch(getSingleOrderDetails(orderDetails.id))
    handleGetParentData()
  }

  const { mutate: editAttachment, isPending: isEditAttachmentPending } = editOrder({
    onSuccess: handleWhenAttachmentEdited
  })

  const handleNavigateToReturnOrders = () => {
    const searchQuery = {
      column: { id: 'seller_sales_channel_order_ids', name: 'Order ID' },
      query: orderDetails.seller_sales_channel_order_id
    }
    const stringifiedSearchQuery = STRINGIFY(searchQuery)
    navigate(`${ROUTES_MAPPING[ROUTES_KEYS.RETURN_ORDERS]}?currentTab=all&q=${stringifiedSearchQuery}`)
  }

  const handleSyncPaymentStatus = () => {
    dispatch(syncOrderPaymentStatus({ seller_sales_channel_order_id: orderDetails.seller_sales_channel_order_id, seller_id: orderDetails.seller_id, seller_sales_channel_id: orderDetails.seller_sales_channel_id }))
  }

  const handleReorder = ({ recreationType, hubId }) => {
    const isReorder = recreationType === ORDER_RECREATION_TYPE.RE_ORDER;
    const isAllTabActive = currentTab === LIVE_ORDERS_TABS.ALL.value;
    const isCrossHub = orderDetails.hub_id !== selectedGlobalHubId
  
    if (isReorder && isAllTabActive && !reorderModal.isOpen && isCrossHub) {
      setReorderModal({ isOpen: true, recreationType });
      return;
    }
  
    handleOrderRecreation({
      recreationType,
      hubId: hubId || orderDetails.hub_id || selectedGlobalHubId,
      orderId: orderDetails.id,
      previousOrderDisplayId: orderDetails.seller_sales_channel_order_id
    });
  };
  
  const closeTransactionSheet = () => {
    setTransactionSideSheet(DEFAULT_TRANSACTION_SIDE_SHEET);
  };

  useEffect(() => {
    if (salesSuccess.editCustomerAndShippingDetails) {
      setIsEditDetailsModalOpen(prev => ({ ...prev, open: false }))
      dispatch(clearInvoiceData())
      dispatch(resetSuccess())
      dispatch(clearCreateOrderItems())
      if(transactionSideSheet.isOpen) closeTransactionSheet()
      if (orderDetails) {
        dispatch(getSingleOrderDetails(orderDetails.id))
      }
      handleGetParentData()
    }

    if (salesSuccess.syncOrderPaymentStatus) {
      dispatch(getSingleOrderDetails(orderDetails.id))
      dispatch(resetSuccess())
      dispatch(clearInvoiceData())
    }

    if (returnSuccess.getReturnOrderToCreateReturnRequest) {
      navigate(CREATE_RETURN_REQUES_PATH)
    }
  }, [salesSuccess.editCustomerAndShippingDetails, salesSuccess.syncOrderPaymentStatus, returnSuccess.getReturnOrderToCreateReturnRequest])

  useEffect(() => {
    if (orderDetails?.attachments?.length) {
      const attachments = orderDetails.attachments.map(attachment => (
        {
          file: {
            name: attachment.name,
            type: attachment.mime_type
          },
          description: attachment.description,
          file_url: attachment.file_url,
          attachment_entity: attachment.attachment_entity,
          is_external_url: attachment.is_external_url
        }
      ))
      setAddedAttachments(attachments)
    } else {
      setAddedAttachments([])
    }
  }, [orderDetails])

  const handleEditDetailsModal = (key) => {
    setIsEditDetailsModalOpen({ open: true, key })
  }

  const togglemultipleAwbModal = (awbData) => {
    setMultipleAwbList(
      awbData ? awbData : []
    )
    setMultipleAwbModalOpen((p) => !p)
  }

  const handleOpenSplittedOrderDetailSideSheet = () => {
    setIsSplittedOrderDetailsSideSheetOpen(true)
  }

  const handleCloseSplittedOrderDetailSideSheet = () => {
    setIsSplittedOrderDetailsSideSheetOpen(false)
  }

  const handleCancelOrderSuccess = () => {
    handleGetParentData()
    setIsCancelOrderPopupOpen(false)
    setIsModalOpen(false)
  }

  const handleReassignHubSuccess = () => {
    handleGetParentData()
    setReassignHubModalOpen(false)
    setIsModalOpen(false)
  }

  const onSaveAttachments = (files, toastMessage) => {
    const attachments = files.map((fileObj) => {
      return {
        file_url: fileObj.file_url,
        name: fileObj.file.name.split('.')[0],
        description: fileObj.description,
        mime_type: fileObj.file.type,
        should_upload: Boolean(fileObj.should_upload),
        attachment_entity: fileObj.attachment_entity,
        is_external_url: fileObj.is_external_url
      }
    })
    const body = {
      attachments,
      edit_entity_type: EDIT_ORDER_ENTITY_TYPES.ORDER_ATTACHMENTS,
    }
    editAttachment({ body, order_id: orderDetails.id, toastMessage })
  }

  const handleGetButtonArray = (buttonsData) => {
    setButton(buttonsData)
  }

  const handleShowSTODetail = () => {
    setIsStoDetailsOpen(p=>!p)
  }

  const openTransactionSheet = (data) => {
    setTransactionSideSheet({
      isOpen: true,
      orderTotalType: data.orderTotalType,
      finalOrderTotal: data.finalOrderTotal,
    });
  };

  const uploadDetails = {
    service: 'oms',
    usecase: 'order-attachments'
  }

  return {
    deleteModalOpen,
    setDeleteModalOpen,
    isEditDetailsModalOpen,
    setIsEditDetailsModalOpen,
    isSplittedOrderDetailsSideSheetOpen,
    multipleAwbModalOpen,
    multipleAwbList,
    isCancelOrderPopupOpen,
    setIsCancelOrderPopupOpen,
    isOnHoldOrderCofirmationPopUpOpen,
    setIsOnHoldOrderCofirmationPopUpOpen,
    isStoDetailsOpen,
    isReassignHubModalOpen,
    setReassignHubModalOpen,
    reorderModal,
    setReorderModal,
    transactionSideSheet,
    attachmentSidebar,
    addedAttachments,
    isOpenAddLocationAttributesModal,
    syncButtons,
    editButtons,
    hasEditAttachmentButton,
    hasEditOrderDetailsPermission,
    isUneditedSalesChannelOrder,
    orderDetails,
    sales_loading,
    handleToggleAttachmentSidebar,
    handleNavigateToReturnOrders,
    handleSyncPaymentStatus,
    handleReorder,
    closeTransactionSheet,
    handleEditDetailsModal,
    togglemultipleAwbModal,
    handleOpenSplittedOrderDetailSideSheet,
    handleCloseSplittedOrderDetailSideSheet,
    handleCancelOrderSuccess,
    handleReassignHubSuccess,
    onSaveAttachments,
    handleGetButtonArray,
    handleShowSTODetail,
    openTransactionSheet,
    toggleAddLocationAttributesModal,
    uploadDetails,
    setAddedAttachments,
    isEditAttachmentPending
  }
}