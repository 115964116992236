import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import circularWarningIcon from '@src/assets/images/icons/warning_circular.svg'
import { AbilityContext } from '@src/utility/context/Can'
import { SPLIT_ORDER_ITEMS_ACTIONS } from '@src/views/sales/sales.constant'
import { getStatusComponent } from '@src/views/sales/sales.utils'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import SplitOrderPopUp from './splitOrderPopUp'

const SplittedOrderItemsTab = ({ isSplitOrderCreated, order_id }) => {

  const TableColumnsHeader = [{ id: 'query', name: 'SKU' }]
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const singleSplitOrderDetails = useSelector(store => store.sales.singleSplitOrderDetails)
  const loading = useSelector(store => store.sales.loading)

  const [splitOrderPopUp, setSplitOrderPopUp] = useState({ isOpen: false, title: '', buttonName: '' })
  const [tableData, setTableData] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [buttonAction, setButtonAction] = useState('')

  const handleClosePopUp = () => {
    setSplitOrderPopUp({ isOpen: false, title: '', buttonName: '', body: {} })
  }

  const handleSplitOrder = () => {
    setButtonAction(SPLIT_ORDER_ITEMS_ACTIONS.SPLIT.action)
    const hasZeroQuantity = selectedData.some(data => data.remaining_quantity === 0)
    if (hasZeroQuantity) {
      CustomToast(t('Please choose at least 1 quantity for selected items to perform action.'), { my_type: 'error', audioRequired: false })
      return
    }
    let hasInsufficientInventory = false
    const body = {
      items: selectedData.map(item => {
        if (item.remaining_quantity > item.available_quantity) {
          hasInsufficientInventory = true
        }
        return { seller_sku_code: item.seller_sku_code, quantity: item.remaining_quantity, item_id:item.itemId }
      })
    }
  
    if (isSplitOrderCreated) {
      body.action = SPLIT_ORDER_ITEMS_ACTIONS.SPLIT.action
    } else {
      body.oms_order_id = order_id
    }
  
    if (hasInsufficientInventory) {
      CustomToast(t('Some SKU’s doesn’t have sufficient inventory to fulfill this order'), { my_type: 'error', audioRequired: false })
      return
    }
  
    setSplitOrderPopUp({
      isOpen: true,
      title: SPLIT_ORDER_ITEMS_ACTIONS.SPLIT.title,
      buttonName: SPLIT_ORDER_ITEMS_ACTIONS.SPLIT.buttonName,
      description: SPLIT_ORDER_ITEMS_ACTIONS.SPLIT.description,
      body
    })
  }
  
  const handleCancelItem = () => {
    setButtonAction(SPLIT_ORDER_ITEMS_ACTIONS.CANCEL.action)

    const hasZeroQuantity = selectedData.some(data => data.remaining_quantity === 0)
    if (hasZeroQuantity) {
      CustomToast(t('Please choose at least 1 quantity for selected items to perform action.'), { my_type: 'error', audioRequired: false })
      return
    }

    const body = {
      action: SPLIT_ORDER_ITEMS_ACTIONS.CANCEL.action,
      items: selectedData.map(item => ({ seller_sku_code: item.seller_sku_code, quantity: item.remaining_quantity, item_id:item.itemId }))
    }

    setSplitOrderPopUp({
      isOpen: true,
      title: SPLIT_ORDER_ITEMS_ACTIONS.CANCEL.title,
      buttonName: SPLIT_ORDER_ITEMS_ACTIONS.CANCEL.buttonName,
      description: SPLIT_ORDER_ITEMS_ACTIONS.CANCEL.description,
      body
    })
  }

  const handleSelectedData = ({ selectedRows }) => {
    const rowsData = []
    selectedRows.forEach(row => {
      const found = selectedData.find(data => data.itemId === row.itemId)
      if (found) {
        rowsData.push(found)
      } else {
        rowsData.push(row)
      }
    })
    setSelectedData(rowsData)
  }

  const handleIncreaseQuantity = (itemId) => {
    const updatedSelectedData = selectedData.map((el) => {
      if (el.itemId === itemId) {
        return {
          ...el,
          remaining_quantity: el.remaining_quantity + 1
        }
      }
      return el
    })
    const updatedCreateSplitOrderData = tableData.map((el) => {
      if (el.itemId === itemId) {
        return {
          ...el,
          remaining_quantity: el.remaining_quantity + 1
        }
      }
      return el
    })
    setSelectedData(updatedSelectedData)
    setTableData(updatedCreateSplitOrderData)
  }

  const handleDecreaseQuantity = (itemId) => {
    const updatedSelectedData = selectedData.map((el) => {
      if (el.itemId === itemId) {
        return {
          ...el,
          remaining_quantity: el.remaining_quantity - 1
        }
      }
      return el
    })
    const updatedCreateSplitOrderData = tableData.map((el) => {
      if (el.itemId === itemId) {
        return {
          ...el,
          remaining_quantity: el.remaining_quantity - 1
        }
      }
      return el
    })
    setSelectedData(updatedSelectedData)
    setTableData(updatedCreateSplitOrderData)
  }

  const handleQtyOnChange = ({ e, itemId, initially_remaining_quantity }) => {
    const number = e.target.value.trim()
    let value = parseInt(number) || 0
    if (value > initially_remaining_quantity) {
      value = initially_remaining_quantity
    }
    const updatedSelectedData = selectedData.map((el) => {
      if (el.itemId === itemId) {
        return {
          ...el,
          remaining_quantity: value
        }
      }
      return el
    })
    const updatedCreateSplitOrderData = tableData.map((el) => {
      if (el.itemId === itemId) {
        return {
          ...el,
          remaining_quantity: value
        }
      }
      return el
    })
    setSelectedData(updatedSelectedData)
    setTableData(updatedCreateSplitOrderData)
  }

  const rowDisabledCriteria = (row) => { 
    return row.status !== 'Unfulfilled' || (!isSplitOrderCreated && row.available_quantity === 0)
  }

  const TableColumns = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'serial_number',
      width: '60px',
      omit:ability.can(abilityMap.split_order.edit.action, abilityMap.split_order.edit.resource),
      cell: (row) => (
        <div className="w-100 text-center">{row.serialNumber}</div>
      )
    },
    {
      id: 2,
      name: t('SKU Details'),
      minWidth: '380px',
      key: 'sku_details',
      cell: (row) => {
        return (
          <div className="flex-center-start gap-50 w-100">
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: true,
                id: row.id,
                image: {
                  src: row.seller_sku.images?.[0]?.default,
                  alt: row.seller_sku.name
                },
                details: {
                  name: row.seller_sku.name,
                  skuCode: row.seller_sku_code, 
                  columnKey: 'sku_details'
                },
                warnings: {
                  isWarningShown: row.remaining_quantity > row.available_quantity, 
                  name: row.seller_sku.name,
                  src: circularWarningIcon,
                  alt: 'warning icon',
                  message: 'You do not have sufficient inventory available to fulfil this SKU.'
                }
              }}
            />
          </div>
        )
      }
    },
    {
      id: 3,
      name: t('Status'),
      key: 'status',
      minWidth: '100px',
      cell: row => getStatusComponent({status:row.status, t})
    },
    {
      id: 4,
      name: <div className='w-100 text-end text-truncate'>{t('Ordered Qty')}</div>,
      key: 'ordered_quantity',
      minWidth: '100px',
      cell: (row) => <div className='w-100 text-end'>{row.ordered_quantity}</div>
    },
    {
      id: 5,
      name: <div className='w-100 text-end text-truncate'>{t('Fulfilled Qty')}</div>,
      key: 'fulfilled_qty',
      minWidth: '100px',
      cell: (row) => <div className='w-100 text-end'>{row.fulfilled_quantity}</div>
    },
    {
      id: 6,
      name: <div className='w-100 text-truncate text-center'>{t('Remaining Qty')}</div>,
      key: 'remaining_quantity',
      minWidth: '140px',
      cell: (row) => <div className='w-100 text-center'>
        <QuantityButton
          quantity={row.remaining_quantity}
          minQuantityEnabled={row.remaining_quantity === 1}
          maxQuantityEnabled={isSplitOrderCreated ? row.remaining_quantity >= row.initially_remaining_quantity : row.remaining_quantity >= Math.min(row.ordered_quantity - row.fulfilled_quantity, row.available_quantity)}
          increaseQuantity={() => handleIncreaseQuantity(row.itemId)}
          decreaseQuantity={() => handleDecreaseQuantity(row.itemId)}
          disabled={!selectedData.some(data => data.itemId === row.itemId)}
          onChange={(e) => handleQtyOnChange({ e, itemId: row.itemId, initially_remaining_quantity:row.initially_remaining_quantity })}
        />
      </div>
    },
    {
      id: 7,
      name: <div className='w-100 text-end text-truncate'>{t('Available Qty')}</div>,
      key: 'available_quantity',
      minWidth: '120px',
      cell: (row) => <div className='w-100 text-end'>{row.available_quantity}</div>
    },
    {
      id: 8,
      name: <div className='w-100 text-end text-truncate'>{t('Unit Price')}</div>,
      minWidth: '100px',
      key: 'unit_price',
      cell: (row) => <div className='w-100 text-end'>{row.unit_price || '-'}</div>
    }
  ]

  useEffect(() => {
    if (singleSplitOrderDetails) {
      const updatedOrderItems = singleSplitOrderDetails.order_items.map((item, index) => {
        return {
          ...item,
          id: index + 1,
          serialNumber: index + 1,
          remaining_quantity: item.ordered_quantity - item.fulfilled_quantity,
          initially_remaining_quantity:item.remaining_quantity,
          itemId:item.id
        }
      })

      setTableData(updatedOrderItems)
    }
  }, [singleSplitOrderDetails])

  return (
    <div className="mb-5">
      <ExpandableCustomTable
        key={ALL_COLUMN_NAME_MAPPING.SPLITTED_ORDER_ITEMS}
        loading={false}
        error={false}
        success={true}
        data={tableData}
        columns={TableColumns}
        columnName={ALL_COLUMN_NAME_MAPPING.SPLITTED_ORDER_ITEMS}
        searchcolumnsList={TableColumnsHeader}
        meta={{}}
        useReactPaginate={false}
        showPagination={false}
        showColumnsTableHeader={false}
        selectable={ability.can(abilityMap.split_order.edit.action, abilityMap.split_order.edit.resource)}
        handleSelectedRowsChange={handleSelectedData}
        selectableRowDisabled={rowDisabledCriteria}
        isTableFixedHeader={false}
      />
      {ability.can(abilityMap.split_order.edit.action, abilityMap.split_order.edit.resource) && <div className='d-flex justify-content-end mt-36px'>
        <SidesheetFooter className='gap-12px'>
          {isSplitOrderCreated && <>
            <Button disabled={!selectedData.length || loading.createSplitOrder || loading.updateSplitOrder} ofStyle='danger' onClick={handleCancelItem}>
              {(loading.createSplitOrder || loading.updateSplitOrder) && buttonAction === SPLIT_ORDER_ITEMS_ACTIONS.CANCEL.action && <Spinner size='sm'/>}
              {t('Cancel Items')}
            </Button>
          </>}
          <Button disabled={!selectedData.length || loading.createSplitOrder || loading.updateSplitOrder} onClick={handleSplitOrder}>
            {(loading.createSplitOrder || loading.updateSplitOrder) && buttonAction === SPLIT_ORDER_ITEMS_ACTIONS.SPLIT.action && <Spinner size='sm'/>}
            {t('Split Order')}
          </Button>
        </SidesheetFooter>
      </div>}
      <SplitOrderPopUp splitOrderPopUp={splitOrderPopUp} handleClosePopUp={handleClosePopUp} order_id={singleSplitOrderDetails.id} isSplitOrderCreated={isSplitOrderCreated} />
    </div>
  )
}

export default SplittedOrderItemsTab