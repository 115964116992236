import { PRINT_PAGE_STYLE } from '@src/App.constants'
import logo from '@src/assets/images/manageInvoice/Image/Logo.svg'
import { forwardRef } from 'react'
import Barcode from 'react-barcode'

const ManualAwbLayout = forwardRef(({ awbData }, ref) => {
  return (
    <div ref={ref}>
      <style>{ PRINT_PAGE_STYLE }</style>

      {awbData ? (
        <div
          style={{ border: '1px solid #999999', color: '#222222' }}
        >
          {/* header start*/}
          <div
            style={{
              borderBottom: '1px solid #999999',
              display: 'flex'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50%',
                padding: '10px'
              }}
            >
              <div style={{ height: '50px', width: '50px' }}>
                <img
                  src={awbData.shipment_details.courier_partner.logo || logo}
                  alt="logo"
                  style={{ objectFit: 'contain' }}
                  height='100%'
                  width='100%'

                />
              </div>
            </div>
            <div
              style={{
                width: '1px',
                backgroundColor: '#999999'
              }}
            ></div>
            <div
              style={{
                padding: '8px',
                fontSize: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                width: '50%'
              }}
            >
              <div style={{ display: 'flex', gap: '6px' }}>
                <div style={{ fontWeight: '600', width: '26%' }}>
                  Courier:
                </div>
                <div
                  style={{
                    fontWeight: '400',
                    wordBreak: 'break-word',
                    width: '74%'
                  }}
                >
                  {awbData.shipment_details.courier_partner.name}
                </div>
              </div>
              <div style={{ display: 'flex', gap: '6px' }}>
                <div style={{ fontWeight: '600', width: '26%' }}>
                  Date:
                </div>
                <div
                  style={{
                    fontWeight: '400',
                    wordBreak: 'break-word',
                    width: '74%'
                  }}
                >
                  {awbData.shipment_details.shipment_created_at}
                </div>
              </div>
              <div style={{ display: 'flex', gap: '6px' }}>
                <div style={{ fontWeight: '600', width: '26%' }}>Order No:</div>
                <div
                  style={{
                    fontWeight: '400',
                    wordBreak: 'break-word',
                    width: '74%'
                  }}
                >
                  {awbData.entity_details.order_number}
                </div>
              </div>
            </div>
          </div>
          {/* header end*/}

          <div
            style={{
              textAlign: 'center',
              padding: '4px'
            }}
          >
            <Barcode
              value={awbData.shipment_details.awb_number}
              height={25}
              fontSize={8}
              textMargin={4}
              width={1}
              textPosition="top"
            />
          </div>

          {/* address details wrapper start */}
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #999999',
              borderTop: '1px solid #999999'
            }}
          >
            {/* sender */}
            <div style={{ width: '50%' }}>
              <div
                style={{
                  fontSize: '8px',
                  fontWeight: '600',
                  padding: '8px'
                }}
              >
                Sender Details
              </div>
              <div
                style={{
                  padding: '8px',
                  paddingTop: '0px',
                  fontSize: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px'
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div>
                    <span style={{ fontWeight: '600' }}>Name:</span>
                    <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.sender_details.name}</span>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span style={{ fontWeight: '600' }}>Address:</span>
                    <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.sender_details.address}</span>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span style={{ fontWeight: '600' }}>Contact No:</span>
                    <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.sender_details.contact_number}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                backgroundColor: '#999999'
              }}
            ></div>
            {/* Recipient */}
            <div style={{ width: '50%' }}>
              <div
                style={{ fontSize: '8px', fontWeight: '600', padding: '8px' }}
              >
                Recipient Details
              </div>
              <div
                style={{
                  padding: '8px',
                  paddingTop: '0px',
                  fontSize: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px'
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div>
                    <span style={{ fontWeight: '600' }}>Name:</span>
                    <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.receiver_details.name}</span>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span style={{ fontWeight: '600' }}>Address:</span>
                    <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.receiver_details.address}</span>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span style={{ fontWeight: '600' }}>Contact No:</span>
                    <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.receiver_details.contact_number}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* address details wrapper start end*/}

          {/* order info wrapper start */}
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #999999',
              fontSize: '8px'
            }}
          >
            <div style={{
              width: '50%',
              display: 'flex',
              padding: '8px',
              gap: '6px'
            }}>
              <span style={{ fontWeight: '600' }}>Payment Type:</span>
              <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.entity_details.payment_type}</span>
            </div>
            <div style={{ width: '1px', backgroundColor: '#999999' }}></div>
            <div style={{
              width: '50%',
              display: 'flex',
              padding: '8px',
              gap: '6px'
            }}>
              <span style={{ fontWeight: '600' }}>Total Value:</span>
              <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.entity_details.total_value}</span>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #999999',
              fontSize: '8px'
            }}
          >
            <div style={{
              width: '50%',
              display: 'flex',
              padding: '8px',
              gap: '6px'
            }}>
              <span style={{ fontWeight: '600' }}>Weight:</span>
              <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.shipment_details.weight}</span>
            </div>
            <div style={{ width: '1px', backgroundColor: '#999999' }}></div>
            <div style={{
              width: '50%',
              display: 'flex',
              padding: '8px',
              gap: '6px'
            }}>
              <span style={{ fontWeight: '600' }}>No of Packages:</span>
              <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.shipment_details.number_of_packages}</span>
            </div>
          </div>

          {/* order info wrapper end */}

          <div
            style={{
              textAlign: 'center',
              padding: '4px'
            }}
          >
            <Barcode
              value={awbData.shipment_details.awb_number}
              height={25}
              fontSize={8}
              textMargin={4}
              width={1}
              textPosition="top"
            />
          </div>

          <div style={{ borderTop: '1px solid #999999' }}>
            <div
              style={{
                display: 'flex',
                padding: '8px',
                gap: '6px',
                fontSize: '8px'
              }}
            >
              <span style={{ fontWeight: '600' }}>Description:</span>
              <span style={{ fontWeight: '300', paddingLeft: '4px' }}>{awbData.shipment_details.description}</span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
})
export default ManualAwbLayout
