import { Wrapper } from '@googlemaps/react-wrapper'
import { mapRender } from '../all-delivery-zones-utils'
import MapComponent from './MapComponent'


const DeliveryZonesMapView = (props) => {
  return (
    <Wrapper
      apiKey={import.meta.env.VITE_GOOGLE_MAP_KEY}
      render={mapRender}
      libraries={['places', 'geocoding', 'geometry']}
    >
      <MapComponent
        {...props}
      >
      </MapComponent>
    </Wrapper>
  )
}

export default DeliveryZonesMapView