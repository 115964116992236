import { setPusherLoading } from '@src/redux/authentication';
import { store } from '@src/redux/store';
import { pusherCallback } from '@src/utility/Utils';
import { v7 as uuidv7 } from 'uuid';
const INACTIVITY_TIMEOUT = 15 * 60 * 1000 // 15 minutes in milliseconds
const channelNamePrefix = import.meta.env.VITE_PUSHER_CHANNEL_NAME_PREFIX

class PusherConnection {
  constructor() {
    this.pusher = null
    this.channel = null
    this.timeoutId = null
  }

  startInactivityCheck() {
    this.timeoutId = window.setTimeout(() => {
      if (this.pusher) {
        this.pusher.disconnect()
      }
    }, INACTIVITY_TIMEOUT)
  }

  handleUserActivity() {
    if (this.timeoutId !== null) {
      window.clearTimeout(this.timeoutId)
    }
  }

  bindEvent(eventName, customCallback) {
    const callback = typeof customCallback === 'function' ? customCallback : pusherCallback
    if (this.channel) {
      this.channel.bind(eventName, callback)
    }
  }

  unbindEvent(eventName) {
    if (this.channel) {
      this.channel.unbind(eventName)
    }
  }

  unsubscribe(userId) {
    if (this.pusher) {
      this.pusher.unsubscribe(`${channelNamePrefix}-${userId}`)
    }
  }

  disconnect() {
    if (this.pusher) {
      this.pusher.disconnect()
      this.pusher = null
      this.channel = null
    }
    this.handleUserActivity()
  }

  async initialize(dependentFunc = () => {}) {
    const userId = store.getState().auth.userData.id
    store.dispatch(setPusherLoading(true))

    if (!this.pusher) {
      const Pusher = await import('pusher-js')
      this.pusher = new Pusher.default(import.meta.env.VITE_PUSHER_APP_KEY, {
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        encrypted: true,
        authEndpoint: '/api/v1/pusher/authenticate',
        channelAuthorization: {
          endpoint: '/api/v1/pusher/authenticate',
          headers: {
            'x-omniful-platform': 'web',
            'x-omniful-correlation-id': uuidv7(),
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth')).access_token}`
          }
        }
      })

      this.channel = this.pusher.subscribe(`${channelNamePrefix}-${userId}`)
      this.startInactivityCheck()

      this.channel.bind('pusher:subscription_succeeded', () => {
        store.dispatch(setPusherLoading(false))
        dependentFunc()
      })

      this.pusher.connection.bind('message', (msg) => {
        if (!msg.channel && msg.event !== 'pusher:pong') {
          store.dispatch(setPusherLoading(false))
          dependentFunc()
          this.disconnect()
        }
      })
    } else {
      store.dispatch(setPusherLoading(false))
      dependentFunc()
    }
  }
}

const pusherInstance = new PusherConnection()

// Export instance methods with the same interface as before
export const startInactivityCheck = () => pusherInstance.startInactivityCheck()
export const userActivityDetected = () => pusherInstance.handleUserActivity()
export const bindToChannelEvent = (eventName, callback) => pusherInstance.bindEvent(eventName, callback)
export const unbindFromChannelEvent = (eventName) => pusherInstance.unbindEvent(eventName)
export const unsubscribeChannel = (userId) => pusherInstance.unsubscribe(userId)
export const disconnectFromPusher = () => pusherInstance.disconnect()
export const initRealTime = (dependentFunc) => pusherInstance.initialize(dependentFunc)

export class BeamManager {
  constructor() {
    this.beamsClient = null
    this.isBeamStarted = false
    this.registration = null
  }

  async registerServiceWorker({ tenant_id, isEnabled }) {
    if (this.registration) {
      console.log('Service worker already registered')
      return
    }

    if (!('serviceWorker' in navigator)) {
      console.log('Service Worker not supported')
      return
    }

    try {
      this.registration = await navigator.serviceWorker.register('/serviceWorker.js')
      if (isEnabled) {
        await this.initBeam({ tenant_id })
      } else {
        console.log('Notification not enabled')
      }
    } catch (error) {
      console.error('Service Worker registration failed:', error)
    }
  }

  async initBeam({ tenant_id }) {
    if (this.beamsClient) {
      console.log('Beam is already initialized')
      return
    }

    try {
      const PusherPushNotifications = await import('@pusher/push-notifications-web')
      this.beamsClient = new PusherPushNotifications.Client({
        instanceId: import.meta.env.VITE_BEAM_APP_KEY,
        serviceWorkerRegistration: this.registration
      })
      await this.startBeam({ tenant_id })
    } catch (error) {
      console.error('Error initializing Beam:', error)
    }
  }

  async startBeam({ tenant_id }) {
    if (this.isBeamStarted) {
      console.log('Beam is already started')
      return
    }

    try {
      await this.beamsClient.start()
      this.isBeamStarted = true

      const interests = await this.beamsClient.getDeviceInterests()
      const tenantInterest = `new-order-notification-${tenant_id}`
      
      if (!interests.includes(tenantInterest)) {
        await this.beamsClient.addDeviceInterest(tenantInterest)
        const updatedInterests = await this.beamsClient.getDeviceInterests()
        console.log('New Interests Subscribed', updatedInterests)
      } else {
        console.log('No new interests to subscribe', interests)
      }
    } catch (error) {
      console.warn('Could not start Beam:', error)
    }
  }

  async stopBeam() {
    if (!this.beamsClient) {
      console.log('Beams client is not initialized')
      return
    }

    try {
      await this.beamsClient.stop()
      this.isBeamStarted = false
      console.log('Beam stopped')
    } catch (error) {
      console.error('Could not stop Beams:', error)
    }
  }

  async unRegisterServiceWorker() {
    if (!('serviceWorker' in navigator)) return

    try {
      const registrations = await navigator.serviceWorker.getRegistrations()
      await Promise.all(registrations.map(registration => registration.unregister()))
    } catch (error) {
      console.error('Error while unregistering service worker:', error)
    }
  }

  async stopAndLogoutBeam() {
    if (!this.beamsClient) {
      console.log('Beams client is not initialized')
      return
    }

    try {
      await this.stopBeam()
      await this.unRegisterServiceWorker()
      this.beamsClient = null
      this.isBeamStarted = false
      this.registration = null
      console.log('Beam is stopped and service worker unregistered')
    } catch (error) {
      console.error('Error stopping beam or unregistering service worker:', error)
    }
  }
}

