import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import SideSheet from '@src/@core/components/ui/sideSheet'
import trackingImage from '@src/assets/images/orders/trackingOrder.svg'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'

const MultipleAwbSideSheet = ({ data, toggleSidebar, modalOpen }) => {

  const { t } = useTranslation()

  const barcodeColumns = [
    {
      id: 1,
      name: <div className="w-100 text-center">{t('S. No.')}</div>,
      key: 'serialNumber',
      width: '100px',
      cell: (_, index) => <div className="w-100 text-center">{index + 1}</div>
    },
    {
      id: 2,
      name: t('AWB'),
      width: '300px',
      key: 'awb',
      cell: (row) => <div>
        {row.tracking_url && <a href={row.tracking_url} target="_blank" rel="noreferrer"><img src={trackingImage} alt="tracking-image" /></a>} {row.awb_number}
      </div>
    }
  ]

  return (
    <SideSheet
      title="AWB"
      isOpen={modalOpen}
      toggle={() => toggleSidebar()}
      contentClassName="p-0"
      modalClassName="modal-slide-in sidebar-todo-modal"
    >
      <PerfectScrollbar>
        <div className="w-100 mt-2 px-2">
          <ExpandableCustomTable
            showColumnsTableHeader={false}
            data={data}
            columns={barcodeColumns}
            columnName={ALL_COLUMN_NAME_MAPPING.MULTIPLE_AWB_DETAILS_TABLE}
            showPagination={false}
            isTableFixedHeader={false}
          />
        </div>
      </PerfectScrollbar>
    </SideSheet>
  )
}

export default MultipleAwbSideSheet