import Modal from '@src/@core/components/ui/modal/Modal';
import { MODAL_TYPES } from '@src/@core/components/ui/modal/modal.constant';
import Select from '@src/@core/components/ui/select';
import { Sync } from '@src/assets/images/icons/Modal/GeneralIcons';
import { getAllHubListAsyncData } from '@src/views/sales-channel/store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody } from 'reactstrap';

interface iReorderModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  // eslint-disable-next-line no-unused-vars
  handleContinue: ({ recreationType, hubId }: { recreationType: string; hubId: string }) => void;
  preloadData: {
    orderId: string
    assignedHubId: string
    recreationType: string
  }
}

interface iHubOptions {
  label: string
  value: string
}

const ReorderModal: React.FC<iReorderModalProps> = ({ isOpen, setIsOpen, handleContinue, preloadData  }) => {
  
  const { t } = useTranslation();
  const { recreationType } = preloadData;

  const [selectedHub, setSelectedHub] = useState<iHubOptions | null>(null);

  const handleCancel = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModalHandler={handleCancel}
      title={t('Reordering')}
      type={MODAL_TYPES.GENERAL}
      icon={Sync}
      primaryButtonText={t('Continue')}
      primaryButtonHandler={() => handleContinue({ recreationType, hubId: selectedHub?.value })}
      secondaryButtonText={t('Close')}
      secondaryButtonHandler={handleCancel}
      primaryButtonProps={{
        disabled: !selectedHub
      }}
      isTranslationRequired={false}
    >
      <ModalBody className='p-0 d-flex flex-column gap-16px'>
        <div className='d-flex flex-column gap-16px'>
          <div className='text-dark'>
            {t('The order you are trying to reorder was fulfilled from a different hub than your current operating hub. Please select the hub you wish to use for fulfilling the reorder.')}
          </div>
          <Select
            name='hub'
            label='Hub'
            // @ts-expect-error FIX ME
            loadOptions={getAllHubListAsyncData}
            isAsync
            isRequired
            isClearable
            validationSchema={{ hub: { required: 'This field is required' } }}
            onChange={(option) => {
              setSelectedHub(option);
            }}
            // @ts-expect-error FIX ME
            value={selectedHub}
            // @ts-expect-error FIX ME
            defaultValue={selectedHub}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 1100 })
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReorderModal;
