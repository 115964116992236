import { nanoid } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { FILE_TYPES } from '@src/App.constants'
import { uploadFileOnS3 } from '@src/redux/authentication'
import { ATTACHMENT_ENTITY } from '@src/views/sales/constant/orders.constants'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFieldArray } from 'react-hook-form'

const useUploadAttachments = (props) => {
  const {
    isUploading,
    minFileSizeInBytes,
    maxFileSizeInBytes,
    formObject,
    uploadDetails
  } = props
  const [isDescriptionPopupOpen, setDescriptionPopupOpen] = useState(false)
  const [previewingAttachment, setPreviewingAttachment] = useState(null)
  const [selectedAttachment, setSelectedAttachment] = useState(null)
  const { append: appendFile, remove: removeFile, replace: replaceFile } = useFieldArray({ control: formObject.control, name: 'uploadedFiles' })

  const acceptedFiles = [FILE_TYPES.CSV, FILE_TYPES.XLS, FILE_TYPES.XLSX, FILE_TYPES.PDF, FILE_TYPES.IMG, FILE_TYPES.DOC, FILE_TYPES.DOCX, FILE_TYPES.TXT, FILE_TYPES.ODS]
  const fileFormat = acceptedFiles.map(file => file.extensions).join(',')
  const acceptedFileTypes = acceptedFiles.map(file => file.type).join(',')

  const handleValidateFiles = (files) => {
    return files.every(file => {
      const type = file.type
      const subType = type ? type.split('/')[1] : ''
      const isAcceptable = type ? (acceptedFileTypes.includes(type) || fileFormat.includes(subType)) : false
      return isAcceptable
    })
  } 

  const handleUpload = async (selectedFiles) => {
    if (selectedFiles.length && handleValidateFiles(selectedFiles)) {

      // appending files before upload 
      const filesWithId = selectedFiles.map(file => ({
        file: Object.assign(file),
        uploading: true,
        progress: 0,
        error: false,
        id: nanoid()
      }))
      appendFile(filesWithId)

      // uploading files
      const filePromises = filesWithId.map(fileObj => {
        return new Promise((res, rej) => {
          
          uploadFileOnS3({
            service: uploadDetails?.service || 'general',
            usecase: uploadDetails?.usecase || 'attachments',
            file: fileObj.file
          })
            .then((response) => res({ id: fileObj.id, uploadId: response }))
            .catch(uploadError => {
              rej({id: fileObj.id, error: uploadError})
            })

        })
      })

      const uploadedFiles = await Promise.allSettled(filePromises)

      // replace files after upload
      const newUploadedFiles = formObject.watch('uploadedFiles').map((file) => {
        const matchingFile = uploadedFiles.find(fileObj => fileObj.value?.id === file.id)
        if (matchingFile) {
          return {
            ...file,
            progress: 100,
            error: matchingFile.status === 'rejected',
            file_url: matchingFile.value?.uploadId,
            preview_url: URL.createObjectURL(file.file),
            description: '',
            should_upload: true,
            attachment_entity: ATTACHMENT_ENTITY.ORDER
          }
        }
        return file
      })
      replaceFile(newUploadedFiles)

      // updating the files uploading state after a delay
      setTimeout(() => {
        const filesWithUploadingFalse = formObject.watch('uploadedFiles').map((file) => {
          return {
            ...file,
            uploading: false
          }
        })
        replaceFile(filesWithUploadingFalse)
      }, 1000)

    } else {
      CustomToast(
        ('Please upload files in a supported format.'), { my_type: 'error', audioRequired: false }
      )
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileFormat,
    multiple: true,
    disabled: isUploading,
    minSize:minFileSizeInBytes,
    maxSize:maxFileSizeInBytes,
    onDrop: files => {
      handleUpload(files)
    }
  })

  const handleRemoveFiles = (removeItemIndex) => {
    if (isUploading) return
    removeFile(removeItemIndex)
  }

  const handleToggleDescription = (item, index) => {
    if (isDescriptionPopupOpen) {
      setDescriptionPopupOpen(prev => !prev)
    } else {
      item.index = index
      setSelectedAttachment(item)
      setDescriptionPopupOpen(prev => !prev)
    }
  }

  const handleAddDescription = (index, description) => {
    formObject.setValue(`uploadedFiles.${index}.description`, description, { shouldDirty: true })
  }

  const handleRemoveDescription = (index) => {
    formObject.setValue(`uploadedFiles.${index}.description`, '', { shouldDirty: true })
  }

  const handleTogglePreview = (attachment) => {
    if (previewingAttachment) {
      setPreviewingAttachment(null)
    } else {
      setPreviewingAttachment(attachment)
    }
  }

  const handleRetryUpload = async (item) => {
    const updatedFiles = formObject.watch('uploadedFiles')?.map(el => {
      if (el.id === item.id) {
        return {
          ...el,
          uploading: true,
          error: false
        }
      }
      return el
    })
    replaceFile(updatedFiles)

    let uploadId = await uploadFileOnS3({
      service: uploadDetails?.service || 'general',
      usecase: uploadDetails?.usecase || 'attachments',
      file: item.file
    })

    // updating the result in files list
    const newFiles = formObject.watch('uploadedFiles')?.map(el => {
      if (el.id === item.id) {
        return {
          ...el,
          uploading: false,
          error: !uploadId,
          file_url: uploadId,
          preview_url: URL.createObjectURL(el.file),
          description: '',
          should_upload: true,
          attachment_entity: ATTACHMENT_ENTITY.ORDER
        }
      }
      return el
    })

    replaceFile(newFiles)

  }

  const filesAreBeingUploadedOnS3 = formObject.watch('uploadedFiles').some(file => file.uploading)

  return {
    filesAreBeingUploadedOnS3,
    getInputProps,
    getRootProps,
    handleAddDescription,
    handleRemoveDescription,
    handleRemoveFiles,
    handleRetryUpload,
    handleToggleDescription,
    handleTogglePreview,
    isDescriptionPopupOpen,
    previewingAttachment,
    selectedAttachment
  }
}

export default useUploadAttachments