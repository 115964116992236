// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { getSingleSeller } from '../sellers/store'
import { SALES_CHANNEL_DETAILS_DEFAULT_TAB, SELLER_SALES_CHANNEL_TYPES } from './salesChannel.constants'
import { getAllHubListAsyncData, getSellers, getShippingCodesAsyncData } from './salesChannel.utils'

export { getAllHubListAsyncData, getSellers, getShippingCodesAsyncData }

export const getSalesChannelSellerList = createAsyncThunk(
  '/sales-channels/seller',
  async (params = {}) => {
    params.is_active = true
    const response = await axiosInstance.get('/api/v1/tenant/sellers', {params})
    return {data: response.data.data}
  } 
)
export const getSalesChannel = createAsyncThunk(
  '/sales-channels',
  async ({params}) => {
    const response = await axiosInstance.get(`/api/v1/sales_channels/${params.seller.value.id}`, { params: { sales_channel_name: params.sales_channel_name || undefined}})
    return {data: response.data.data}
  } 
)

export const getSellerSalesChannel = createAsyncThunk(
  '/sales-channels/get-sellerSalesChannel',
  async ({ sellerId, salesChannelId, status }) => {
    let params 
    if (status !== '') {
      params = {...params, status}
    }
    params = {...params, sales_channel_id: salesChannelId }
    const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels`, {params})
    return response.data.data
  } 
)

export const getSellerSalesChannelDetails = createAsyncThunk(
  '/sales-channels/sellerSalesChannel/detail',
  async ({ params, storeId }, store) => {
    const activeStore = store.getState().salesChannel.activeStore
    let response
    if (activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
      response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${params?.seller?.value?.id}/shipping_channels/${storeId}`)
    } else {
      response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${params?.seller?.value?.id}/sales_channels/${storeId}`)
    }
    return response.data.data
  } 
)
// put request
export const updateSyncingStatus = createAsyncThunk(
  '/sales-channels/updateSyncingStatus',
  async ({ params, storeId, body }, store) => {
    const activeStore = store.getState().salesChannel.activeStore

    let response
    if (activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
      // here for Shipping we need to pass seller_shipping_channel_id in the place of seller_sales_channel_id
      body = body.map(obj => {
        delete obj.seller_sales_channel_id
        obj.seller_shipping_channel_id = activeStore.id
        return obj
      })
      response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${params?.seller?.value?.id}/shipping_channels/${storeId}/configurations`, body)
    } else {
      response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${params?.seller?.value?.id}/sales_channels/${storeId}/configurations`, body)
    }
    return response.data.data
  } 
) 

export const syncProducts = createAsyncThunk('/sales-channels/syncProducts', async ({ params, storeId }, store) => {
  const activeStore = store.getState().salesChannel.activeStore
  let response
  if (activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
    response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${params?.seller?.value?.id}/shipping_channels/${storeId}/sync`, {params: {sync_entity: 'products'}})
  } else {
    response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${params?.seller?.value?.id}/sales_channels/${storeId}/sync_products`, {params: {sync_entity: 'products'}})
  }

  return response
})

export const syncOrders = createAsyncThunk('/sales-channels/syncOrders', async ({ params, storeId, selected_sync_option, sync_entity }, store) => {
  const activeStore = store.getState().salesChannel.activeStore
  let response
  if (activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
    response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${params.seller.value.id}/shipping_channels/${storeId}/sync`, {params: {sync_entity, sync_from_time:selected_sync_option.sync_from_time, sync_from_type:selected_sync_option.sync_from_type}})
  } else {
    response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${params.seller.value.id}/sales_channels/${storeId}/sync_products`, {params: {sync_entity, sync_from_time:selected_sync_option.sync_from_time, sync_from_type:selected_sync_option.sync_from_type}})
  }
  return response
})

export const updateHubsMapping = createAsyncThunk(
  '/sales-channel/store/detail/hubmapping',
  async ({body, sellerSalesChannelId, sellerId}) => {
    const response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels/${sellerSalesChannelId}/hubs`, body)
    return response
  }
)

export const updateOrderStatusMapping = createAsyncThunk(
  '/sales-channel/store/detail/orderStatusMapping',
  async ({body, sellerSalesChannelId, sellerId}) => {
    const response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels/${sellerSalesChannelId}/order_status`, body)
    return response
  }
)

export const updateScToOmnifulOrderStatusMapping = createAsyncThunk(
  '/sales-channel/store/detail/updateScToOmnifulOrderStatusMapping',
  async ({body, sellerSalesChannelId, sellerId},store) => {
    const activeStore = store.getState().salesChannel.activeStore
    let response
    if (activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
      response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${sellerId}/shipping_channels/${sellerSalesChannelId}/sc_to_omniful_shipping_order_status`, body)
    } else {
      response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels/${sellerSalesChannelId}/sc_to_omniful_order_status`, body)
    }
    return response
  }
)

export const updateOmnifulToScOrderStatusMapping = createAsyncThunk(
  '/sales-channel/store/detail/OmnifulToScorderStatusMapping',
  async ({body, sellerSalesChannelId, sellerId},store) => {
    const activeStore = store.getState().salesChannel.activeStore
    let response
    if (activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
      response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${sellerId}/shipping_channels/${sellerSalesChannelId}/omniful_to_sc_shipping_order_status`, body)
    } else {
      response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels/${sellerSalesChannelId}/omniful_to_sc_order_status`, body)
    }
    return response
  }
)

export const updateOrderTags = createAsyncThunk(
  '/sales-channel/store/detail/updateOrderTags',
  async({body, sellerSalesChannelId, sellerId}) => {
    const bodydata = {...body, seller_sales_channel_id: sellerSalesChannelId}
    const response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels/${sellerSalesChannelId}/order_tags`, [bodydata])
    return response
    
  }
)
// shipping partner 
export const getShippingPartners = createAsyncThunk(
  '/sales-channels/store/get-shipping-partners',
  async ({carrierTypeFilterValue, params: paramsFromProps, omitSellerId}, state) => {
    const allState = state.getState()
    const params = {
      is_integrated: 1,
      seller_id: omitSellerId ? undefined : allState.salesChannel?.params?.seller?.value?.id,
      ...paramsFromProps
    }
   
    const response = await axiosInstance.get('/api/v1/shipping/shipping_partners', {params})
    const responseData = response.data.data
    if (carrierTypeFilterValue) {
      const shippingPartnersData = responseData.filter((data) => {
        return carrierTypeFilterValue === data.category
      }).map((data) => {
        return {
          value: {...data},
          label: data.name
        }
      })
      return shippingPartnersData
    } else {
      const shippingPartnersData = responseData.map((data) => {
        return {
          value: {...data},
          label: data.name
        }
      })
      return shippingPartnersData
    }
  } 
)

export const addSellerSalesChannelShippingPartner = createAsyncThunk(
  '/sales-channels/store/add-shipping-partner',
  async ({body}, state) => {
    const allState = state.getState()
    const afterAddingSellerBody = {...body, seller_id: allState.salesChannel?.params?.seller?.value?.id}
    const response = await axiosInstance.post('/api/v1/shipping/sales_channel_mappings', afterAddingSellerBody)
    return response
  } 
)

export const removeSellerSalesChannelShippingPartner = createAsyncThunk(
  '/sales-channels/store/remove-shipping-partner',
  async (sales_channel_mapping_id) => {
    // const allState = state.getState()
    const params = {
      status: 'inactive'
      // seller_id: allState.salesChannel?.params?.seller?.value?.id
    }
    const response = await axiosInstance.put(`/api/v1/shipping/sales_channel_mappings/${sales_channel_mapping_id}`, params)
    return response
  } 
)

export const multiGetApi = createAsyncThunk(
  '/sales-channels/success',
  async ({ sellerId, salesChannelId, sellerSalesChannelId }) => {
    const singleSellerResponse = await axiosInstance.get(`/api/v1/tenant/sellers/${sellerId}`)
    let params = {}
    params = {sales_channel_id:salesChannelId}
    const sellerSalesChannelResponse = await axiosInstance.get(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels`, {params})
 
    return {
      sellerSalesChannelId,
      seller: singleSellerResponse.data.data,
      sellerSalesChannels: sellerSalesChannelResponse.data.data
    }
  }
)

export const zidActivationCodeIntegration = createAsyncThunk('/zid/activetion-code', async({data, seller_id, integrationType}) => {
  const zidData = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/zid_aggregator/activate?${integrationType}`, data)
  return zidData.data
})

export const zidResendCodeIntegration = createAsyncThunk('/zid/resendcode', async({data, seller_id, integrationType}) => {
  const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/zid_aggregator/email?${integrationType}`, data)
  return response
})

export const sallaIntegration = createAsyncThunk('/salla/integration', async({data, seller_id}) => {
  const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/salla/activate`, data)
  return response.data
})

export const getSallaIntegrationCode = createAsyncThunk('/salla/resendcode', async({data, seller_id}) => {
  const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/salla/email`, data)
  return response
})

export const getSalesChannelHubLocations = createAsyncThunk(
  '/sales-channels/get-salesChannel-hub-locations',
  async ({params, storeId, salesChannelId, fetch_directly}) => {
    const salesChannelHubLocationsResponse = await axiosInstance.get(`/api/v1/sales_channels/sellers/${params?.seller?.value?.id}/sales_channels/${storeId}/locations?sales_channel_id=${salesChannelId}&fetch_directly=${fetch_directly}`)
    const salesChannelHubLocationsData = salesChannelHubLocationsResponse.data.data
    const mappedSalesChannelHubLocationsData = salesChannelHubLocationsData.map((item) => {
      return {
        label: `${item.name}-${item.id}`,
        value: item.id,
        customLabel: item
      }
    })
    return mappedSalesChannelHubLocationsData
  }
)

export const fetchAllStoresOfMagento = createAsyncThunk('/sales-channels/magento/fetch_stores', async({seller_id, magento_domain}) => {
  const res = await axiosInstance.get(`/api/v1/sales_channels/sellers/magento/stores/${seller_id}`, {params:{magento_domain}})
  return res.data
})

export const changeStoreName = createAsyncThunk('/sales-channels/stores/change-store-name', async ({ seller_id, storeId, body }, store) => {
  const activeStore = store.getState().salesChannel.activeStore
  let res
  if (activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
    res = await axiosInstance.put(`/api/v1/sales_channels/sellers/${seller_id}/shipping_channels/${storeId}`, body)
  } else {
    res = await axiosInstance.put(`/api/v1/sales_channels/sellers/${seller_id}/sales_channels/${storeId}`, body)
  }
  return res.data
})

export const zidFulfillmentActivationCodeIntegration = createAsyncThunk('/zid_fulfillment/activetion-code', async({data, seller_id}) => {
  const zidFulfilmentData = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/zid_fulfillment/activate`, data)
  return zidFulfilmentData.data
})

export const zidFulfillmentResendCodeIntegration = createAsyncThunk('/zid_fulfillment/resendcode', async({data, seller_id}) => {
  const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/zid_fulfillment/email`, data)
  return response
})

export const salesChannelIntegration = createAsyncThunk('sales-channel/integration', async({integrationRequestConfig}) => {
  console.log(integrationRequestConfig)
  const response = await axiosInstance({
    ...integrationRequestConfig
  })
  return response.data
})

export const fetchExternalOrderStatuses = createAsyncThunk('sales-channel/fetch-external-order-statuses', async ({ sellerId, salesChannelId, sellerSalesChannelId }, store) => {
  const activeStore = store.getState().salesChannel.activeStore
  let response
  if (activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
    response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${sellerId}/shipping_channels/${sellerSalesChannelId}/orders/statuses`, { params: { shipping_channel_id: salesChannelId } })
  } else {
    response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels/${sellerSalesChannelId}/orders/statuses`, { params: { sales_channel_id: salesChannelId } })
  }
  return response.data
})

export const getConfigLogData = createAsyncThunk('sales-channel/get-config-log-data', async ({ sellerId, salesChannelId, pagination }) => {
  const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${sellerId}/sales_channels/${salesChannelId}/logs`, {params:pagination})
  return response.data
})

export const createSalesChannelBulkOrder = createAsyncThunk(
  '/sales-channels/create/bulk-order',
  async ({params, storeId, body}) => {
    const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${params.seller.value.id}/seller_sales_channels/${storeId}/orders/sheet`, body)
    return response.data
  } 
)

export const uploadIgnoreInventoryUpdateSheet = createAsyncThunk(
  '/sales-channels/ignore-inventory-update',
  async ({params, seller_sales_channel_id, body}) => {
    const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${params.seller.value.id}/sales_channels/${seller_sales_channel_id}/inventory_update_allowed/sheet`, body)
    return response.data
  } 
)

export const syncInventory = createAsyncThunk('/sales-channels/syncInventory', async({params, storeId}) => {
  const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${params?.seller?.value?.id}/sales_channels/${storeId}/sync_products`, {params: {sync_entity: 'inventory'}})
  return response
})

export const uploadSalesChannelSkuPriceUpdateSheet = createAsyncThunk(
  '/sales-channels/sales-channel-sku-price-update',
  async ({params, seller_sales_channel_id, body}) => {
    const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${params.seller.value.id}/sales_channels/${seller_sales_channel_id}/price_update/sheet`, body)
    return response.data
  } 
)

export const getShippingMethodCode = createAsyncThunk(
  '/sales-channels/get-shipping-method-code',
  async ({seller_id, sellerSalesChannelId, params}) => {
    const response = await axiosInstance.get(`/api/v1/sales_channels/sellers/${seller_id}/sales_channels/${sellerSalesChannelId}/shipping_options`, {params})
    return response.data
  } 
)

export const updateShippingMethodCode = createAsyncThunk(
  '/sales-channels/update-shipping-method-code',
  async ({seller_id, storeId, body}) => {
    const response = await axiosInstance.put(`/api/v1/sales_channels/sellers/${seller_id}/seller_sales_channels/${storeId}/shipping_method_codes`, body)
    return response.data
  } 
)

export const uploadScCatalogSheet = createAsyncThunk(
  '/sales-channels/catalog-sheet-upload',
  async ({ params, seller_sales_channel_id, body }) => {
    const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${params.seller.value.id}/seller_sales_channels/${seller_sales_channel_id}/sc_catalogue/sheet`, body)
    return response.data
  } 
)

export const sallaVersionTwoIntegration = createAsyncThunk('/version-two/salla/integration', async({data, seller_id, integrationType}) => {
  const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/salla_v2/activate?${integrationType}`, data)
  return response.data
})

export const getSallaVersionTwoIntegrationCode = createAsyncThunk('/version-two/salla/resend-code', async({data, seller_id, integrationType}) => {
  const response = await axiosInstance.post(`/api/v1/sales_channels/sellers/${seller_id}/salla_v2/email?${integrationType}`, data)
  return response
})

// shipping partner accounts
export const getShippingAccountsData = createAsyncThunk(
  '/sales-channels/store/get-shipping-partners-accounts-data',
  async ({carrierTypeFilterValue, params: paramsFromProps, omitSellerId}, state) => {
    const allState = state.getState()
    const params = {
      seller_id: omitSellerId ? undefined : allState.salesChannel?.params?.seller?.value?.id,
      ...paramsFromProps
    }
   
    const response = await axiosInstance.get('/api/v1/shipping/partner_shipping_methods', {params})
    const responseData = response.data.data

    if (carrierTypeFilterValue) {
      const shippingPartnersAccountsData = responseData.filter((data) => {
        return carrierTypeFilterValue === data.category
      }).map((data) => {
        return {
          value: {...data},
          label: data.name
        }
      })
      return shippingPartnersAccountsData
    } else {
      const shippingPartnersAccountsData = responseData.map((data) => {
        return {
          value: {...data},
          label: `${data.account_name} (${data.shipping_partner.name})`
        }
      })
      return shippingPartnersAccountsData
    }
  } 
)

export const salesChannelSlice = createSlice({
  name: 'salesChannel',
  initialState: {
    loading: {},
    error: {},
    success: {},
    allChannels :[],
    sellerSalesChannel : [],
    activeStore : {},
    activeChannel : {},
    sellerSalesChannelDetails : {},
    shippingPartner: [],
    salesChannelSellers: [],
    activeSeller: null,
    params: {},
    active: SALES_CHANNEL_DETAILS_DEFAULT_TAB,
    sync_config: {},
    syncProductCatalog : null,
    syncOrder: null,
    picking_config: null,
    salesChannelHubLocations: [],
    isStoreFetch:false,
    magentoStores:{},
    webhook_config: null,
    zidActivationCode: null,
    zidResendCode: null,
    zidFulfillmentActivationCode: null,
    zidFulfillmentResendCode: null,
    externalOrderStatuses: [],
    address_preferences_config: null,
    cancel_order_config: null,
    syncDropDownOption: [],
    configLogData:[],
    configLogMetaData:{},
    sheet_order_config: {},
    ignore_inventory_update_config: {},
    syncInventory: null,
    price_update_config: {},
    shipping_method_codes_config: {},
    shippingMethodCodes: [],
    no_inventory_cancel_order_config: {},
    force_update_all_sku_config: {},
    order_sync_allowed_config: {},
    shippingPartnersAccounts: [],
    auto_reject_orders_on_hold: {},
    sc_order_tags_config: {},
    tabs_config: {},
    on_hold_orders_inventory_reservation_config: {},
    include_backstock_in_available_inventory_config: {},
    auto_shipment_creation_config: {},
    shipping_method_to_delivery_type_mapping_config: {},
    order_hub_routing_config: {},
    order_cancel_reason_mapping_config: {}
  },
  reducers: {
    setActiveChannel : (state, action) => {
      state.activeChannel = action.payload
    },
    setActiveStore : (state, action) => {
      state.activeStore = action.payload
    },
    setActiveSeller: (state, action) => {
      state.activeSeller = action.payload
    },
    setActive: (state, action) => {
      state.active = action.payload
    },
    sellerSalesChannelDetailsCleanup: (state) => {
      state.error = {}
      state.loading = {}
      state.success = {}
    },
    setParams : (state, action) => {
      state.params = {...state.params, ...action.payload}
    },
    setIntegrationLoading: (state, action) => {
      state.loading = {...state.loading, integrationLoading: action.payload}
    },
    clearFetchStoreStatus : state => { 
      state.isStoreFetch = false
    },
    clearSalesChannelData : state => {
      state.sellerSalesChannelDetails = {}
    },
    resetSuccess : state => {
      state.success = {}
    },
    removeConfigLogResponse : state => {
      state.configLogData = []
      state.configLogMetaData = {}
    },
    externalOrderStatusesCleanUp: state => {
      state.externalOrderStatuses = []
    },
    clearShippingMethodCodes: state => {
      state.shippingMethodCodes = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(multiGetApi.pending, (state) => {
        state.loading = {...state.loading, getSalesChannel:true}
      })
      .addCase(multiGetApi.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSalesChannel:false}
        const {seller, sellerSalesChannels, sellerSalesChannelId} = action.payload
        state.params = {...state.params, seller:{label: seller.name, value: seller}}
        state.sellerSalesChannel = sellerSalesChannels
        state.activeStore = sellerSalesChannels.find((item) => item.id === sellerSalesChannelId)
      })
      .addCase(multiGetApi.rejected, (state) => {
        state.loading = {...state.loading, getSalesChannel:false}
        state.error = {...state.error, getSalesChannel:true}
      })
      .addCase(getSalesChannel.pending, (state) => {
        state.loading = {...state.loading, getSalesChannel:true}
        state.allChannels = []
      })
      .addCase(getSalesChannel.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSalesChannel:false}
        state.error = {...state.error, getSalesChannel:false}
        state.allChannels = action.payload.data
      })
      .addCase(getSalesChannel.rejected, (state) => {
        state.error = {...state.error, getSalesChannel:true}
        state.loading = {...state.loading, getSalesChannel:false}
      })
      .addCase(getSellerSalesChannel.pending, (state) => {
        state.loading = {...state.loading, getSellerSalesChannel:true}
        state.sellerSalesChannel = []
      })
      .addCase(getSellerSalesChannel.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSellerSalesChannel:false}
        state.error = {...state.error, getSellerSalesChannel:false}
        state.sellerSalesChannel = action.payload
      })
      .addCase(getSellerSalesChannel.rejected, (state) => {
        state.error = {...state.error, getSellerSalesChannel:true}
        state.loading = {...state.loading, getSellerSalesChannel:false}
      })
      .addCase(getSellerSalesChannelDetails.pending, (state) => {
        state.loading = {...state.loading, getSellerSalesChannelDetails:true}
        state.picking_config = null
        state.sync_config = {}
        state.sellerSalesChannelDetails = {}
        state.syncProductCatalog = null
        state.syncOrder = null
        state.syncInventory = null
        state.syncDropDownOption = []
        state.webhook_config = null
        state.address_preferences_config = {}
        state.cancel_order_config = {}
        state.force_update_all_sku_config = {}
        state.sheet_order_config = {}
        state.ignore_inventory_update_config = {}
        state.price_update_config = {}
        state.shipping_method_codes_config = {}
        state.shippingMethodCodes = []
        state.no_inventory_cancel_order_config = {}
        state.order_sync_allowed_config = {}
        state.shippingPartnersAccounts = []
        state.auto_reject_orders_on_hold = {}
        state.sc_order_tags_config = {}
        state.tabs_config = {}
        state.on_hold_orders_inventory_reservation_config = {}
        state.include_backstock_in_available_inventory_config = {}
        state.auto_shipment_creation_config = {}
        state.shipping_method_to_delivery_type_mapping_config = {}
        state.order_cancel_reason_mapping_config = {}
        state.order_hub_routing_config = {}
      })
      .addCase(getSellerSalesChannelDetails.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSellerSalesChannelDetails:false}
        state.error = {...state.error, getSellerSalesChannelDetails:false}
        state.sellerSalesChannelDetails = action.payload
        state.syncProductCatalog = action.payload.product_sync
        state.syncOrder = action.payload.order_sync
        state.syncInventory = action.payload.inventory_sync
        state.syncDropDownOption  = action.payload.order_sync_options.map(option => ({
          label: option.display_name,
          value: option
        }))
        let sellerSalesChannelConfigurations
        if (state.activeStore.type === SELLER_SALES_CHANNEL_TYPES.SHIPPING_ONLY_INTEGRATION.value) {
          sellerSalesChannelConfigurations = action.payload.seller_shipping_channel_configurations
        } else {
          sellerSalesChannelConfigurations = action.payload.seller_sales_channel_configurations
        }
        sellerSalesChannelConfigurations.forEach(config => {
          state[config.config_name] = config 
        })
      })
      .addCase(getSellerSalesChannelDetails.rejected, (state) => {
        state.error = { ...state.error, getSellerSalesChannelDetails: true }
        state.loading = { ...state.loading, getSellerSalesChannelDetails: false }
      })
      .addCase(updateSyncingStatus.pending, (state) => {
        state.loading = { ...state.loading, updateSyncingStatus: true }
        state.success = { ...state.success, updateSyncingStatus: false }
      })
      .addCase(updateSyncingStatus.fulfilled, (state, action) => {
        state.loading = { ...state.loading, updateSyncingStatus: false }
        state.error = { ...state.error, updateSyncingStatus: false }
        state.success = { ...state.success, updateSyncingStatus: true }
        CustomToast(action?.payload?.status, { my_type: 'success' })
      })
      .addCase(updateSyncingStatus.rejected, (state) => {
        state.error = { ...state.error, updateSyncingStatus: true }
        state.success = { ...state.success, updateSyncingStatus: false }
        state.loading = { ...state.loading, updateSyncingStatus: false }
      })
      .addCase(syncOrders.pending, (state) => {
        state.loading = { ...state.loading, syncOrders: true }
        state.success = { ...state.success, syncProducts: false }
      })
      .addCase(syncOrders.fulfilled, (state, action) => {
        state.loading = { ...state.loading, syncOrders: false }
        state.error = { ...state.error, syncOrders: false }
        state.success = { ...state.success, syncOrders: true }
        state.syncOrder = action.payload.data.data
      })
      .addCase(syncOrders.rejected, (state) => {
        state.error = { ...state.error, syncOrders: true }
        state.success = { ...state.success, syncOrders: false }
        state.loading = { ...state.loading, syncOrders: false }
      })
      .addCase(syncProducts.pending, (state) => {
        state.loading = { ...state.loading, syncProducts: true }
        state.success = { ...state.success, syncProducts: false }
      })
      .addCase(syncProducts.fulfilled, (state, action) => {
        state.loading = { ...state.loading, syncProducts: false }
        state.error = { ...state.error, syncProducts: false }
        state.success = { ...state.success, syncProducts: true }
        state.syncProductCatalog = action.payload.data.data
      })
      .addCase(syncProducts.rejected, (state) => {
        state.error = { ...state.error, syncProducts: true }
        state.success = { ...state.success, syncProducts: false }
        state.loading = { ...state.loading, syncProducts: false }
      })
      .addCase(updateOrderTags.pending, (state) => {
        state.loading = { ...state.loading, updateOrderTags: true }
        state.success = { ...state.success, updateOrderTags: false }
      })
      .addCase(updateOrderTags.fulfilled, (state) => {
        state.loading = { ...state.loading, updateOrderTags: false }
        state.error = { ...state.error, updateOrderTags: false }
        state.success = { ...state.success, updateOrderTags: true }
        CustomToast('Order tags updated successfully', { my_type: 'success' })
      })
      .addCase(updateOrderTags.rejected, (state) => {
        state.error = { ...state.error, updateOrderTags: true }
        state.success = { ...state.success, updateOrderTags: false }
        state.loading = { ...state.loading, updateOrderTags: false }
      })
      .addCase(updateHubsMapping.pending, (state) => {
        state.loading = { ...state.loading, updateHubsMapping: true }
        state.success = { ...state.success, updateHubsMapping: false }
      })
      .addCase(updateHubsMapping.fulfilled, (state) => {
        state.loading = { ...state.loading, updateHubsMapping: false }
        state.error = { ...state.error, updateHubsMapping: false }
        state.success = { ...state.success, updateHubsMapping: true }
        CustomToast('Hubs mapping updated successfully', { my_type: 'success' })
      })
      .addCase(updateHubsMapping.rejected, (state) => {
        state.error = { ...state.error, updateHubsMapping: true }
        state.loading = { ...state.loading, updateHubsMapping: false }
        state.success = { ...state.success, updateHubsMapping: false }
      })
      .addCase(updateOrderStatusMapping.pending, (state) => {
        state.loading = { ...state.loading, updateOrderStatusMapping: true }
        state.success = { ...state.success, updateOrderStatusMapping: false }
      })
      .addCase(updateOrderStatusMapping.fulfilled, (state) => {
        state.loading = { ...state.loading, updateOrderStatusMapping: false }
        state.error = { ...state.error, updateOrderStatusMapping: false }
        state.success = { ...state.success, updateOrderStatusMapping: true }
        CustomToast('Order status mapping updated successfully', { my_type: 'success' })
      })
      .addCase(updateOrderStatusMapping.rejected, (state) => {
        state.error = { ...state.error, updateOrderStatusMapping: true }
        state.loading = { ...state.loading, updateOrderStatusMapping: false }
        state.success = { ...state.success, updateOrderStatusMapping: false }
      })
      .addCase(updateScToOmnifulOrderStatusMapping.pending, (state) => {
        state.loading = { ...state.loading, updateOrderStatusMapping: true }
        state.success = { ...state.success, updateOrderStatusMapping: false }
      })
      .addCase(updateScToOmnifulOrderStatusMapping.fulfilled, (state) => {
        state.loading = { ...state.loading, updateOrderStatusMapping: false }
        state.error = { ...state.error, updateOrderStatusMapping: false }
        state.success = { ...state.success, updateOrderStatusMapping: true }
        CustomToast('Order status mapping updated successfully', { my_type: 'success' })
      })
      .addCase(updateScToOmnifulOrderStatusMapping.rejected, (state) => {
        state.error = { ...state.error, updateOrderStatusMapping: true }
        state.loading = { ...state.loading, updateOrderStatusMapping: false }
        state.success = { ...state.success, updateOrderStatusMapping: false }
      })
      .addCase(updateOmnifulToScOrderStatusMapping.pending, (state) => {
        state.loading = { ...state.loading, updateOmnifulToScOrderStatusMapping: true }
        state.success = { ...state.success, updateOmnifulToScOrderStatusMapping: false }
      })
      .addCase(updateOmnifulToScOrderStatusMapping.fulfilled, (state) => {
        state.loading = { ...state.loading, updateOmnifulToScOrderStatusMapping: false }
        state.error = { ...state.error, updateOmnifulToScOrderStatusMapping: false }
        state.success = { ...state.success, updateOmnifulToScOrderStatusMapping: true }
        CustomToast('Order status mapping updated successfully', { my_type: 'success' })
      })
      .addCase(updateOmnifulToScOrderStatusMapping.rejected, (state) => {
        state.error = { ...state.error, updateOmnifulToScOrderStatusMapping: true }
        state.loading = { ...state.loading, updateOmnifulToScOrderStatusMapping: false }
        state.success = { ...state.success, updateOmnifulToScOrderStatusMapping: false }
      })
      .addCase(getShippingPartners.pending, (state) => {
        state.loading = { ...state.loading, shippingPartner: true }
        state.success = { ...state.success, shippingPartner: false }
      })
      .addCase(getShippingPartners.fulfilled, (state, action) => {
        state.loading = { ...state.loading, shippingPartner: false }
        state.error = { ...state.error, shippingPartner: false }
        state.shippingPartner = action.payload
      })
      .addCase(addSellerSalesChannelShippingPartner.pending, (state) => {
        state.loading = { ...state.loading, addSellerSalesChannelShippingPartner: true }
        state.success = { ...state.success, addSellerSalesChannelShippingPartner: false }
      })
      .addCase(addSellerSalesChannelShippingPartner.fulfilled, (state) => {
        state.loading = { ...state.loading, addSellerSalesChannelShippingPartner: false }
        state.success = { ...state.success, addSellerSalesChannelShippingPartner: true }
        state.error = { ...state.error, addSellerSalesChannelShippingPartner: false }
        CustomToast('Shipping partner added successfully', { my_type: 'success' })
      })
      .addCase(addSellerSalesChannelShippingPartner.rejected, (state) => {
        state.error = { ...state.error, addSellerSalesChannelShippingPartner: true }
        state.loading = { ...state.loading, addSellerSalesChannelShippingPartner: false }
      })
      .addCase(zidActivationCodeIntegration.pending, (state) => {
        state.loading = { ...state.loading, zidActivationCode: true }
        state.success = { ...state.success, zidActivationCode: false }
      })
      .addCase(zidActivationCodeIntegration.fulfilled, (state, action) => {
        state.loading = { ...state.loading, zidActivationCode: false }
        state.error = { ...state.error, zidActivationCode: false }
        state.success = { ...state.success, zidActivationCode: action.payload.data }
      
      })
      .addCase(zidActivationCodeIntegration.rejected, (state) => {
        state.error = { ...state.error, zidActivationCode: true }
        state.loading = { ...state.loading, zidActivationCode: false }
      })
      .addCase(zidResendCodeIntegration.pending, (state) => {
        state.loading = { ...state.loading, zidResendCode: true }
        state.success = { ...state.success, zidResendCode: false }
      })
      .addCase(zidResendCodeIntegration.fulfilled, (state, action) => {
        state.loading = { ...state.loading, zidResendCode: false }
        state.error = { ...state.error, zidResendCode: false }
        state.success = { ...state.success, zidResendCode: action.payload.data }
        // CustomToast(`Zid app activated successfully`, {my_type:"success"})
      })
      .addCase(zidResendCodeIntegration.rejected, (state) => {
        state.error = { ...state.error, zidResendCode: true }
        state.loading = { ...state.loading, zidResendCode: false }
      })
      // sallaIntegration response
      .addCase(sallaIntegration.pending, (state) => {
        state.loading = { ...state.loading, sallaIntegration: true }
        state.success = { ...state.success, sallaIntegration: false }
      })
      .addCase(sallaIntegration.fulfilled, (state, action) => {
        state.loading = { ...state.loading, sallaIntegration: false }
        state.error = { ...state.error, sallaIntegration: false }
        state.success = { ...state.success, sallaIntegration: action.payload.data }
      
      })
      .addCase(sallaIntegration.rejected, (state) => {
        state.error = { ...state.error, sallaIntegration: true }
        state.loading = { ...state.loading, sallaIntegration: false }
      })
      // getSallaIntegrationCode response
      .addCase(getSallaIntegrationCode.pending, (state) => {
        state.loading = { ...state.loading, getSallaIntegrationCode: true }
        state.success = { ...state.success, getSallaIntegrationCode: false }
      })
      .addCase(getSallaIntegrationCode.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getSallaIntegrationCode: false }
        state.error = { ...state.error, getSallaIntegrationCode: false }
        state.success = { ...state.success, getSallaIntegrationCode: action.payload.data }
      })
      .addCase(getSallaIntegrationCode.rejected, (state) => {
        state.error = { ...state.error, getSallaIntegrationCode: true }
        state.loading = { ...state.loading, getSallaIntegrationCode: false }
      })
      // 
      .addCase(removeSellerSalesChannelShippingPartner.pending, (state) => {
        state.loading = { ...state.loading, removeSellerSalesChannelShippingPartner: true }
        state.success = { ...state.success, removeSellerSalesChannelShippingPartner: false }
      })
      .addCase(removeSellerSalesChannelShippingPartner.fulfilled, (state) => {
        state.loading = { ...state.loading, removeSellerSalesChannelShippingPartner: false }
        state.success = { ...state.success, removeSellerSalesChannelShippingPartner: true }
        state.error = { ...state.error, removeSellerSalesChannelShippingPartner: false }
        CustomToast('Shipping partner removed successfully', { my_type: 'success' })
      })
      .addCase(removeSellerSalesChannelShippingPartner.rejected, (state) => {
        state.error = { ...state.error, removeSellerSalesChannelShippingPartner: true }
        state.loading = { ...state.loading, removeSellerSalesChannelShippingPartner: false }
      })
      .addCase(getSalesChannelSellerList.pending, (state) => {
        state.loading = { ...state.loading, getSalesChannel: true }
      })
      .addCase(getSalesChannelSellerList.fulfilled, (state, action) => {
        state.error = { ...state.error, getSalesChannel: false }
        if (action.payload.data?.length > 0) {
          state.params = { ...state.params, seller: { label: action.payload.data[0].name, value: action.payload.data[0] } }
        }
        state.loading = { ...state.loading, getSalesChannel: false }
      })
      .addCase(getSalesChannelSellerList.rejected, (state) => {
        state.error = { ...state.error, getSalesChannel: true }
        state.loading = { ...state.loading, getSalesChannel: false }
      })
      .addCase(getSingleSeller.pending, (state) => {
        state.loading = { ...state.loading, getSalesChannel: true }
      })
      .addCase(getSingleSeller.fulfilled, (state, action) => {
        state.error = { ...state.error, getSalesChannel: false }
        state.params = { ...state.params, seller: { label: action.payload.name, value: action.payload } }
        state.loading = { ...state.loading, getSalesChannel: false }
      })
      .addCase(getSingleSeller.rejected, (state) => {
        state.error = { ...state.error, getSalesChannel: true }
        state.error = { ...state.error, getSalesChannel: false }
      })
      .addCase(getSalesChannelHubLocations.pending, (state) => {
        state.loading = { ...state.loading, getSalesChannelHubLocations: true }
        state.salesChannelHubLocations = []
      })
      .addCase(getSalesChannelHubLocations.fulfilled, (state, action) => {
        state.salesChannelHubLocations = action.payload
        state.loading = { ...state.loading, getSalesChannelHubLocations: false }
      })
      .addCase(getSalesChannelHubLocations.rejected, (state) => {
        state.loading = { ...state.loading, getSalesChannelHubLocations: false }
      })
      .addCase(fetchAllStoresOfMagento.pending, (state) => {
        state.loading = { ...state.loading, fetchAllStoresOfMagento: true }
        state.magentoStores = {}
        state.isStoreFetch = false
      })
      .addCase(fetchAllStoresOfMagento.fulfilled, (state, action) => {
        state.magentoStores = action.payload.data
        state.loading = { ...state.loading, fetchAllStoresOfMagento: false }
        state.isStoreFetch = true
      })
      .addCase(fetchAllStoresOfMagento.rejected, (state) => {
        // for testing only
        state.loading = { ...state.loading, fetchAllStoresOfMagento: false }
      })
      // changeStoreName
      .addCase(changeStoreName.pending, (state) => {
        state.loading = { ...state.loading, changeStoreName: true }
        state.success = { ...state.success, changeStoreName: false }
      })
      .addCase(changeStoreName.fulfilled, (state) => {
        state.loading = { ...state.loading, changeStoreName: false }
        state.success = { ...state.success, changeStoreName: true }
        CustomToast('Store name changed successfully', { my_type: 'success' })
      })
      .addCase(changeStoreName.rejected, (state) => {
        state.loading = { ...state.loading, changeStoreName: false }
        state.success = { ...state.success, changeStoreName: false }
      })
      .addCase(zidFulfillmentActivationCodeIntegration.pending, (state) => {
        state.loading = { ...state.loading, zidFulfillmentActivationCode: true }
        state.success = { ...state.success, zidFulfillmentActivationCode: false }
      })
      .addCase(zidFulfillmentActivationCodeIntegration.fulfilled, (state, action) => {
        state.loading = { ...state.loading, zidFulfillmentActivationCode: false }
        state.error = { ...state.error, zidFulfillmentActivationCode: false }
        state.success = { ...state.success, zidFulfillmentActivationCode: action.payload.data }
      
      })
      .addCase(zidFulfillmentActivationCodeIntegration.rejected, (state) => {
        state.error = { ...state.error, zidFulfillmentActivationCode: true }
        state.loading = { ...state.loading, zidFulfillmentActivationCode: false }
      })
      .addCase(zidFulfillmentResendCodeIntegration.pending, (state) => {
        state.loading = { ...state.loading, zidFulfillmentResendCode: true }
        state.success = { ...state.success, zidFulfillmentResendCode: false }
      })
      .addCase(zidFulfillmentResendCodeIntegration.fulfilled, (state, action) => {
        state.loading = { ...state.loading, zidFulfillmentResendCode: false }
        state.error = { ...state.error, zidFulfillmentResendCode: false }
        state.success = { ...state.success, zidFulfillmentResendCode: action.payload.data }
      })
      .addCase(zidFulfillmentResendCodeIntegration.rejected, (state) => {
        state.error = { ...state.error, zidFulfillmentResendCode: true }
        state.loading = { ...state.loading, zidFulfillmentResendCode: false }
      })
      // sales channel integration response
      .addCase(salesChannelIntegration.pending, (state) => {
        state.loading = { ...state.loading, salesChannelIntegration: true }
        state.success = { ...state.success, salesChannelIntegration: false }
      })
      .addCase(salesChannelIntegration.fulfilled, (state, action) => {
        state.loading = { ...state.loading, salesChannelIntegration: false }
        state.error = { ...state.error, salesChannelIntegration: false }
        state.success = { ...state.success, salesChannelIntegration: action.payload.data }

      })
      .addCase(salesChannelIntegration.rejected, (state) => {
        state.error = { ...state.error, salesChannelIntegration: true }
        state.loading = { ...state.loading, salesChannelIntegration: false }
      })
      // fetchExternalOrderStatuses response
      .addCase(fetchExternalOrderStatuses.pending, (state) => {
        state.loading = { ...state.loading, fetchExternalOrderStatuses: true }
      })
      .addCase(fetchExternalOrderStatuses.fulfilled, (state, action) => {
        state.loading = { ...state.loading, fetchExternalOrderStatuses: false }
        state.error = { ...state.error, fetchExternalOrderStatuses: false }
        state.externalOrderStatuses = action.payload.data?.order_statuses
      })
      .addCase(fetchExternalOrderStatuses.rejected, (state) => {
        state.error = { ...state.error, fetchExternalOrderStatuses: true }
        state.loading = { ...state.loading, fetchExternalOrderStatuses: false }
      })
      // getConfigLogData
      .addCase(getConfigLogData.pending, (state) => {
        state.loading = { ...state.loading, getConfigLogData: true }
        state.error = { ...state.error, getConfigLogData: false }
        state.success = { ...state.success, getConfigLogData: false }
      })
      .addCase(getConfigLogData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getConfigLogData: false }
        state.success = { ...state.success, getConfigLogData: true }
        state.configLogData = action.payload.data
        state.configLogMetaData = action.payload.meta
      })
      .addCase(getConfigLogData.rejected, (state) => {
        state.error = { ...state.error, getConfigLogData: true }
        state.loading = { ...state.loading, getConfigLogData: false }
      })
    
      // createSalesChannelBulkOrder
      .addCase(createSalesChannelBulkOrder.pending, (state) => {
        state.loading = { ...state.loading, createSalesChannelBulkOrder: true }
        state.success = { ...state.success, createSalesChannelBulkOrder: false }
        state.error = { ...state.error, createSalesChannelBulkOrder: false }
      })
      .addCase(createSalesChannelBulkOrder.fulfilled, (state, action) => {
        state.loading = { ...state.loading, createSalesChannelBulkOrder: false }
        state.success = { ...state.success, createSalesChannelBulkOrder: true }
        if (action.payload.status_code === 200) {
          CustomToast('Order Created Successfully', { my_type: 'success' })
        }
      })
      .addCase(createSalesChannelBulkOrder.rejected, (state) => {
        state.error = { ...state.error, createSalesChannelBulkOrder: true }
        state.loading = { ...state.loading, createSalesChannelBulkOrder: false }
      })
      
      // uploadIgnoreInventorySheet
      .addCase(uploadIgnoreInventoryUpdateSheet.pending, (state) => {
        state.loading = { ...state.loading, uploadIgnoreInventoryUpdateSheet: true }
        state.success = { ...state.success, uploadIgnoreInventoryUpdateSheet: false }
        state.error = { ...state.error, uploadIgnoreInventoryUpdateSheet: false }
      })
      .addCase(uploadIgnoreInventoryUpdateSheet.fulfilled, (state, action) => {
        state.loading = { ...state.loading, uploadIgnoreInventoryUpdateSheet: false }
        state.success = { ...state.success, uploadIgnoreInventoryUpdateSheet: true }
        if (action.payload?.status_code === 200) {
          CustomToast(action.payload.data.status, { my_type: 'success' })
        }
      })
      .addCase(uploadIgnoreInventoryUpdateSheet.rejected, (state) => {
        state.error = { ...state.error, uploadIgnoreInventoryUpdateSheet: true }
        state.loading = { ...state.loading, uploadIgnoreInventoryUpdateSheet: false }
      })
      .addCase(syncInventory.pending, (state) => {
        state.loading = { ...state.loading, syncInventory: true }
        state.success = { ...state.success, syncInventory: false }
        state.error = { ...state.error, syncInventory: false }
      })
      .addCase(syncInventory.fulfilled, (state, action) => {
        state.loading = { ...state.loading, syncInventory: false }
        state.success = { ...state.success, syncInventory: true }
        state.syncInventory = action.payload.data.data
      })
      .addCase(syncInventory.rejected, (state) => {
        state.loading = { ...state.loading, syncInventory: false }
        state.error = { ...state.error, syncInventory: true }
      })
    // uploadIgnoreInventorySheet
      .addCase(uploadSalesChannelSkuPriceUpdateSheet.pending, (state) => {
        state.loading = {...state.loading, uploadSalesChannelSkuPriceUpdateSheet: true}
        state.success = {...state.success, uploadSalesChannelSkuPriceUpdateSheet: false}
        state.error = {...state.error, uploadSalesChannelSkuPriceUpdateSheet: false}
      })
      .addCase(uploadSalesChannelSkuPriceUpdateSheet.fulfilled, (state, { payload }) => {
        state.loading = {...state.loading, uploadSalesChannelSkuPriceUpdateSheet: false}
        state.success = { ...state.success, uploadSalesChannelSkuPriceUpdateSheet: true }
        const eventName = payload.data?.pusher_response?.event
        if (eventName) {
          bindToChannelEvent(eventName)
        }
      })
      .addCase(uploadSalesChannelSkuPriceUpdateSheet.rejected, (state) => {
        state.error = { ...state.error, uploadSalesChannelSkuPriceUpdateSheet: true }
        state.loading = { ...state.loading, uploadSalesChannelSkuPriceUpdateSheet: false }
      })
      
    // getShippingMethodCode
      .addCase(getShippingMethodCode.pending, (state) => {
        state.loading = {...state.loading, getShippingMethodCode: true}
        state.success = {...state.success, getShippingMethodCode: false}
        state.error = {...state.error, getShippingMethodCode: false}
      })
      .addCase(getShippingMethodCode.fulfilled, (state, action) => {
        state.loading = { ...state.loading, getShippingMethodCode: false }
        state.success = { ...state.success, getShippingMethodCode: true }
        const modifiedData = action.payload.data.shipping_companies.map((item) => {
          return {
            ...item,
            shipping_method_code: item.shipping_method_code.replace(/\./g, '$@$'),
            display_code: item.shipping_method_code
          }
        })
        state.shippingMethodCodes = modifiedData
      })
      .addCase(getShippingMethodCode.rejected, (state) => {
        state.error = { ...state.error, getShippingMethodCode: true }
        state.loading = { ...state.loading, getShippingMethodCode: false }
      })
      
    // updateShippingMethodCode
      .addCase(updateShippingMethodCode.pending, (state) => {
        state.loading = {...state.loading, updateShippingMethodCode: true}
        state.success = {...state.success, updateShippingMethodCode: false}
        state.error = {...state.error, updateShippingMethodCode: false}
      })
      .addCase(updateShippingMethodCode.fulfilled, (state, action) => {
        state.loading = {...state.loading, updateShippingMethodCode: false}
        state.success = { ...state.success, updateShippingMethodCode: true }
        CustomToast(action.payload.data?.status, {my_type:'success'})
      })
      .addCase(updateShippingMethodCode.rejected, (state) => {
        state.error = { ...state.error, updateShippingMethodCode: true }
        state.loading = { ...state.loading, updateShippingMethodCode: false }
      })

    // uploadScCatalogSheetSheet
      .addCase(uploadScCatalogSheet.pending, (state) => {
        state.loading = {...state.loading, uploadScCatalogSheet: true}
        state.success = {...state.success, uploadScCatalogSheet: false}
        state.error = {...state.error, uploadScCatalogSheet: false}
      })
      .addCase(uploadScCatalogSheet.fulfilled, (state, action) => {
        state.loading = {...state.loading, uploadScCatalogSheet: false}
        state.success = { ...state.success, uploadScCatalogSheet: true }
        CustomToast('Your request has been accepted. You will receive a confirmation shortly.', { my_type: 'info' })
        const eventName = action.payload.data.pusher_data.event
        bindToChannelEvent(eventName)
      })
      .addCase(uploadScCatalogSheet.rejected, (state) => {
        state.error = { ...state.error, uploadScCatalogSheet: true }
        state.loading = { ...state.loading, uploadScCatalogSheet: false }
      })

    // sallaVersionTwoIntegration response
      .addCase(sallaVersionTwoIntegration.pending, (state) => {
        state.loading = {...state.loading, sallaVersionTwoIntegration: true}
        state.success = {...state.success, sallaVersionTwoIntegration: false}
      })
      .addCase(sallaVersionTwoIntegration.fulfilled, (state, action) => {
        state.loading = {...state.loading, sallaVersionTwoIntegration: false}
        state.error = {...state.error, sallaVersionTwoIntegration: false}
        state.success = {...state.success, sallaVersionTwoIntegration: action.payload.data}
      
      })
      .addCase(sallaVersionTwoIntegration.rejected, (state) => {
        state.error = {...state.error, sallaVersionTwoIntegration: true}
        state.loading = {...state.loading, sallaVersionTwoIntegration: false}
      })
    // getSallaVersionTwoIntegrationCode response
      .addCase(getSallaVersionTwoIntegrationCode.pending, (state) => {
        state.loading = {...state.loading, getSallaVersionTwoIntegrationCode: true}
        state.success = {...state.success, getSallaVersionTwoIntegrationCode: false}
      })
      .addCase(getSallaVersionTwoIntegrationCode.fulfilled, (state, action) => {
        state.loading = {...state.loading, getSallaVersionTwoIntegrationCode: false}
        state.error = {...state.error, getSallaVersionTwoIntegrationCode: false}
        state.success = {...state.success, getSallaVersionTwoIntegrationCode: action.payload.data}
      })
      .addCase(getSallaVersionTwoIntegrationCode.rejected, (state) => {
        state.error = {...state.error, getSallaVersionTwoIntegrationCode: true}
        state.loading = {...state.loading, getSallaVersionTwoIntegrationCode: false}
      })
      .addCase(getShippingAccountsData.pending, (state) => {
        state.loading = { ...state.loading, shippingPartnersAccounts: true }
        state.success = { ...state.success, shippingPartnersAccounts: false }
      })
      .addCase(getShippingAccountsData.fulfilled, (state, action) => {
        state.loading = { ...state.loading, shippingPartnersAccounts: false }
        state.error = { ...state.error, shippingPartnersAccounts: false }
        state.shippingPartnersAccounts = action.payload
      })
      .addCase(getShippingAccountsData.rejected, (state) => {
        state.loading = { ...state.loading, shippingPartnersAccounts: false }
        state.error = { ...state.error, shippingPartnersAccounts: true }
      })
  }
})

export const { setActiveChannel, setActiveStore, setActiveSeller, setActive, sellerSalesChannelDetailsCleanup, setParams, setIntegrationLoading, clearFetchStoreStatus, clearSalesChannelData, resetSuccess, removeConfigLogResponse, externalOrderStatusesCleanUp, clearShippingMethodCodes } = salesChannelSlice.actions

export default salesChannelSlice.reducer