import Attributes from '@src/@core/components/ui/attributes'
import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { PRICE_BREAK_DOWN_SECTION, SKU_TYPES } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { AbilityContext } from '@src/utility/context/Can'
import { BUNDLE } from '@src/views/catalog/catalog.constants'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import SkuChildDetailModal from '@src/views/catalog/components/sku-child-detail-modal/SkuChildDetailModal'
import { LIVE_ORDER_TYPES } from '@src/views/inventory/inventory.constants'
import SKUBarcodeModal from '@src/views/sales/components/SKUBarcodeModal'
import CustomerNoteModal from '@src/views/sales/live-orders/newPacking/components/CustomerNoteModal'
import PropTypes from 'prop-types'
import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OrderItemsChildTable from '../../../pages/ordersForApproval/OrderItemsChildTable/OrderItemsChildTable'
import { PriceBreakDownSection } from '../../../pages/ordersForApproval/PriceBreakDownSection'
import BatchDeatilsSideSheet from '../../BatchDeatilsSideSheet/batchDeatilsSideSheet'
import EditOrderItems from '../../editOrderItems'
import { useOnHoldItemsTableColumns } from '../hooks/useOnHoldItemsTableColumns'

const OnHoldItemsTable = ({orderDetails, isPending, isError, isSuccess, show_inventory, editButtons = {}}) => {

  const { t } = useTranslation()
  const editOrderItemsRef = useRef(null)
  const ability = useContext(AbilityContext)

  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false)
  const [barcodeList, setBarcodeList] = useState([])
  const [batchModalOpen, setBatchModalOpen] = useState(false)
  const [batchModalData, setBatchModalData] = useState({})
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [customerNoteCard, setCustomerNoteCard] = useState({ isOpen: false, customerNote: null })
  const [openSkuKitDetailModal, setOpenSkuKitDetailModal] = useState(false)
  const [editOrderItems, setEditOrderItems] = useState({ isOpen: false, isLoading: false })
  const [attributes, setAttributes] = useState({ isOpen: false, attributes: [] })
  
  const hasBatches = orderDetails.order_items.some(item => item.batches.length > 0)
  const hasEditOrderDetailsPermission = ability.can(abilityMap.order.edit_details.action, abilityMap.order.edit_details.resource)
  const showEditItemsButton = !!editButtons.edit_order_items && hasEditOrderDetailsPermission
  const showAvailableWithoutReservation = orderDetails.show_available_without_reservation

  const toggleBarcodeModal = (barcodes) => {
    setBarcodeList(
      barcodes ? barcodes?.map((barcode) => ({ barcodeValue: barcode })) : []
    )
    setBarcodeModalOpen((p) => !p)
  }

  const toggleBatchModal = (row) => {
    setBatchModalData(row)
    setBatchModalOpen((p) => !p)
  }

  const handleSkuDetailsSidebar = (data) => {
    setSkuForDetails({...data, seller_id: orderDetails.seller_id})
    setOpenSkuDetailsSidebar(!openSkuDetailsSidebar)
  }

  const handleCustomerNote = (customerNote = null) => {
    setCustomerNoteCard({  isOpen: true, customerNote })
  }

  const handleBundleSkuDetailsSidebar = (data) => {
    if (data) {
      const formattedData = {
        ...data,
        ...data.seller_sku,
        child_skus: data.seller_sku.child_skus
      }
      setSkuForDetails({...formattedData, seller_id: orderDetails.seller_id})
      setOpenSkuKitDetailModal(true)
    } else {
      setOpenSkuKitDetailModal(false)
    }
  }

  const toggleSkuKitDetailModal = () => {
    setOpenSkuKitDetailModal(!openSkuKitDetailModal)
  }

  const handleEditItemClick = (mode) => {
    if (editOrderItemsRef.current) {
      editOrderItemsRef.current.handleItemEditingEnable(mode);
    }
  };

  const toggleAttributes = () => {
    setAttributes({ isOpen: false, attributes: [] })
    setSkuForDetails({})
  }

  const handleOpenAttributesSidesheet = (row) => {
    setAttributes({ isOpen: true, attributes: row.item_attributes })
    setSkuForDetails({...row.seller_sku, seller_sku_code:row.seller_sku_code, image: row.seller_sku.images?.[0]?.default})
  }

  const { tableColumns, childTableColumns } = useOnHoldItemsTableColumns({
    orderDetails,
    show_inventory,
    hasBatches,
    showAvailableWithoutReservation,
    handleOpenAttributesSidesheet,
    handleBundleSkuDetailsSidebar,
    handleSkuDetailsSidebar,
    toggleBarcodeModal,
    toggleBatchModal,
    handleCustomerNote
  })

  const showPriceSection = {
    show_sub_total:false,
    show_total_discount:false,
    show_total_tax:false,
    show_additional_charges:false,
    show_shipping_cost:false,
    show_shipping_tax:false,
    show_horizontal_divider:false, 
    show_grand_total:true,
    show_total_amount_paid:false,
    show_paid_amounts:false,
    show_total_refund:false,
    show_refunded_amounts: false,
    show_total_due: false
  }

  return (
    <>
      {!editOrderItems.isOpen && (
        <ExpandableCustomTable
          loading={isPending}
          error={isError}
          success={isSuccess}
          data={orderDetails.order_items || []}
          showPagination={false}
          columns={tableColumns}
          useReactPaginate={false}
          showColumnsDropdown={false}
          showColumnsTableHeader={showEditItemsButton}
          TableHeaderComponent={
            showEditItemsButton ? (
              <Button ofStyle='outlined' onClick={() => handleEditItemClick(true)} loading={editOrderItems.isLoading}>
                {t('Edit Items')}
              </Button>
            ) : null
          }
          expandableRows
          expandableRowsComponent={OrderItemsChildTable}
          expandableRowsComponentProps={{ childTableColumns, childDataKey: 'seller_sku.child_skus' }}
          expandableRowExpanded={(row) => row.seller_sku?.type === SKU_TYPES.BUNDLE.key}
          expandableRowDisabled={(row) => row.seller_sku?.type !== SKU_TYPES.BUNDLE.key}
          isTableFixedHeader={false}
        />
      )}

      <EditOrderItems orderDetails={orderDetails} editOrderItems={editOrderItems} setEditOrderItems={setEditOrderItems} ref={editOrderItemsRef} />

      {orderDetails && !editOrderItems.isOpen && (
        <div className='mt-24px'>
          <PriceBreakDownSection
            orderDetails={orderDetails}
            orderType={PRICE_BREAK_DOWN_SECTION.FORWARD_ORDER.value}
            show_price_section_keys={orderDetails.type === LIVE_ORDER_TYPES.STO && showPriceSection}
          />
        </div>
      )}

      <SKUBarcodeModal modalOpen={barcodeModalOpen} toggleSidebar={toggleBarcodeModal} data={barcodeList} />
      <BatchDeatilsSideSheet modalOpen={batchModalOpen} toggleSidebar={toggleBatchModal} data={batchModalData} />
      <SimpleSkuDetailModal skuForDetails={skuForDetails} toggleSimpleSkuModal={() => handleSkuDetailsSidebar({})} isOpen={openSkuDetailsSidebar} />
      <CustomerNoteModal isCustomerNoteShow={customerNoteCard.isOpen} setIsCustomerNoteShow={setCustomerNoteCard} customerNote={customerNoteCard.customerNote} />
      <SkuChildDetailModal isOpen={openSkuKitDetailModal} skuForDetails={skuForDetails} toggle={toggleSkuKitDetailModal} skuType={BUNDLE} />
      <Attributes isOpen={attributes.isOpen} toggle={toggleAttributes} itemAttributes={attributes.attributes} skuDetails={skuForDetails}/>
    </>
  )
}

OnHoldItemsTable.propTypes = {
  orderDetails:PropTypes.object,
  isFetching:PropTypes.bool,
  isError:PropTypes.bool,
  isSuccess:PropTypes.bool,
  show_inventory:PropTypes.bool
}

export default OnHoldItemsTable