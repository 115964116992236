import Button from '@src/@core/components/ui/button'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import InputField from '@src/@core/components/ui/input-field'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE } from '@src/App.constants'
import { Check, X } from 'react-feather'
import useMapComponent from './useMapComponent'

const MapComponent = ({
  zoom = 11.5,
  filter
}) => {
  const {
    mapRef,
    selectedSearchOption,
    searchOptions,
    handleChangeSearchOption,
    handleLatLongSearchChange,
    handleSetCenter,
    handleClearSearch,
    getSingleOrderDetail,
    isOrderDetailPending,
    latLng,
    orderId,
    setOrderId
  } = useMapComponent({
    zoom,
    filter
  })

  return (
    <>
      <div ref={mapRef} className="map" style={{ width: '100%', height: '100%', overflow: 'hidden'}} />
      <div className="bg-white w-100 flex-center-between py-8px ps-22px pe-8px z-1">
        <div className="flex-center-start gap-8px">
          <div className="flex-center-center gap-8px">
            {/* <div className="txt-body-md text-dark-6">{t("Search")}</div> */}
            {/* < */}
            <DropdownWithTitle 
              isSearchable={false}
              value={selectedSearchOption}
              title={'Search'}
              options={searchOptions}
              selectOptionHandler={(value) => handleChangeSearchOption(value)} 
              externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
              menuPlacement='top'
              disabled={isOrderDetailPending}
            />
          </div>
        </div>
        <div className="flex-center-end gap-6px">
          {selectedSearchOption.value === searchOptions[0].value ? <>
            <div className="width-200">
              <InputField 
                name="latitude"
                label="Latitude" 
                isRequired 
                onChange={handleLatLongSearchChange}
                value={latLng.latitude}
                type="number"
              />
            </div>
            <div className="width-200">
              <InputField 
                name="longitude"
                label="Longitude" 
                isRequired 
                onChange={handleLatLongSearchChange}
                value={latLng.longitude}
                type="number"
              />
            </div>
          </> : <div className="width-300">
            <InputField 
              name="order_id"
              label="Order ID" 
              isRequired 
              onChange={(event) => setOrderId(event.target.value)}
              value={orderId}
              disabled={isOrderDetailPending}
            />
          </div>}
          {selectedSearchOption.value === searchOptions[0].value 
            ? <>
              <Button icon={Check} disabled={!(latLng.latitude && latLng.longitude)} onClick={() => handleSetCenter(latLng)}></Button>
              <Button icon={X} ofStyle="outlined" disabled={!(latLng.latitude && latLng.longitude)} onClick={handleClearSearch}></Button>
            </>
            : <>
              <Button icon={Check} disabled={isOrderDetailPending || !orderId} onClick={getSingleOrderDetail}></Button>
              <Button icon={X} ofStyle="outlined" disabled={isOrderDetailPending || !orderId} onClick={handleClearSearch}></Button>
            </>
          }
        </div>
      </div>
    </>
  )
} 

export default MapComponent