import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Plus } from 'react-feather'
import { DESCRIPTION_MAX_LIMIT, SKU_TYPES } from '@src/App.constants'
import { LIVE_ORDER_TYPES } from '@src/views/inventory/inventory.constants'
import { ORDER_ITEM_SOURCES, REASONS_FOR_ON_HOLD } from '@src/views/sales/sales.constant'
import { showSkuTypeTag } from '@src/views/sales/sales.utils'
import { BUNDLE } from '@src/views/catalog/catalog.constants'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import { pluralizeUnit } from '@src/utility/Utils'

export const useOnHoldItemsTableColumns = ({
  orderDetails,
  show_inventory,
  hasBatches,
  showAvailableWithoutReservation,
  handleOpenAttributesSidesheet,
  handleBundleSkuDetailsSidebar,
  handleSkuDetailsSidebar,
  toggleBarcodeModal,
  toggleBatchModal,
  handleCustomerNote
}) => {
  const { t } = useTranslation()

  const tableColumns = useMemo(() => {
    return [
      {
        id: 1,
        name: t('SKU Details'),
        minWidth: '340px',
        cell: (row) => {
          const hasFreeItem = row.source === ORDER_ITEM_SOURCES.AUTOMATION_RULE.value && row.unit_price === 0
          const hasBundle = !!showSkuTypeTag(row.seller_sku.type)
          const hasAttributes = row.item_attributes.length
          const totalAttributes = hasAttributes && row.item_attributes.reduce((acc, curr) => {
            return acc += curr.attributes.length 
          }, 0)
          const customTagsArray = [
            hasFreeItem && ({
              className:'text-purple bg-purple-light',
              title: showSkuTypeTag(SKU_TYPES.GIFT.key)
            }),
            hasBundle && ({
              className:'text-info bg-info-light',
              title: showSkuTypeTag(row.seller_sku.type)
            }),
            hasAttributes && ({
              className:'text-primary bg-white border border-primary cursor-pointer',
              title: pluralizeUnit(totalAttributes, 'Attribute'),
              onClick: () => handleOpenAttributesSidesheet(row)
            })
          ].filter(Boolean)
          return (
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: !((row.reason === REASONS_FOR_ON_HOLD.SKU_NOT_VALID.label && row.seller_sku?.name === '' && row.seller_sku_code === '')),
                id: row.seller_sku.id,
                image: {
                  src: row.seller_sku.images?.[0]?.default,
                  alt: row.seller_sku.name
                },
                details: {
                  name: row.seller_sku.name,
                  ...(row.reason !== REASONS_FOR_ON_HOLD.SKU_NOT_VALID.label && { handleSkuDetails: () => row.seller_sku.type === BUNDLE ? handleBundleSkuDetailsSidebar(row) : handleSkuDetailsSidebar(row) }),
                  skuCode: row.seller_sku_code,
                  columnKey: 'sku_details'
                },
                customTags: customTagsArray
              }}
            />
          )
        }
      },
      {
        id: 2,
        name: t('Barcode'),
        minWidth: '200px',
        cell: (row) => {
          return (
            <div className="box-cell flex-center-between gap-8px w-100 px-2px">
              <span className="text-truncate txt-sub-rg text-dark">
                {row.seller_sku?.barcodes?.[0]}
              </span>
              {row.seller_sku?.barcodes?.length > 1 ? (
                <p className="cursor-pointer flex-center-center gap-2px m-0 ps-6px pe-8px order-details-barcode"
                  onClick={() => toggleBarcodeModal(row.seller_sku?.barcodes)}>
                  <Plus size={12}/>
                  <span className="txt-asst-rg pt-2px">{row.seller_sku?.barcodes?.length - 1}</span>
                </p>
              ) : null}
            </div>
          )
        }
      },
      {
        id: 3,
        name: t('On Hold Reason'),
        minWidth: '180px',
        omit: !show_inventory,
        selector: (row) => row.reason || '-'
      },
      {
        id: 4,
        name: <div className="w-100 text-end">{t('Ordered Qty')}</div>,
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end">{row.quantity || 0}</div>
      },
      {
        id: 5,
        name: <div className="w-100 text-end">{t('Ordered Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_ordered_weight || 0}</div>
      },
      {
        id: 6,
        name: <div className="w-100 text-end">{t('Available Qty')}</div>,
        minWidth: '120px',
        omit: !show_inventory,
        cell: (row) => <div className="w-100 text-end">{row.display_available}</div>
      },
      {
        id: 7,
        name: <div className="w-100 text-end text-truncate" title={t('Available without Reservation')}>{t('Available without Reservation')}</div>,
        minWidth: '180px',
        omit: !showAvailableWithoutReservation,
        cell: (row) => <div className="w-100 text-end">{row.display_available_without_reservation}</div>
      },
      {
        id: 8,
        name: <div className="w-100 text-end">{t('Picked Qty')}</div>,
        minWidth: '150px',
        cell: (row) => <div className="w-100 text-end">{row.picked_quantity || 0}</div>
      },
      {
        id: 9,
        name: <div className="w-100 text-end">{t('Picked Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_picked_weight || 0}</div>
      },
      {
        id: 10,
        name: <div className="w-100 text-end">{t('Packed Qty')}</div>,
        minWidth: '150px',
        cell: (row) => <div className="w-100 text-end">{row.packed_quantity || 0}</div>
      },
      {
        id: 11,
        name: <div className="w-100 text-end">{t('Packed Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_packed_weight || 0}</div>
      },
      {
        id: 12,
        name: t('Batch Number'),
        minWidth: '240px',
        omit: !hasBatches,
        cell: (row) => <div className="w-100 d-flex justify-content-between align-items-center ps-2px pe-6px">
          <div className="d-flex flex-column gap-4px">
            <span className="d-inline">{row.batches[0]?.number || '-'}</span>
            <span className="d-inline text-dark-6">{row.batches[0]?.expiry_date}</span>
          </div>
          {row.batches.length > 1 && <div className="d-flex justify-content-between gap-2px align-items-center cursor-pointer ps-2px pe-6px order-details-batch-number" onClick={() => toggleBatchModal(row)}>
            <Plus size={14}/>{row.batches.length}</div>}
        </div>
      },
      {
        id: 13,
        name: <div className="w-100 text-end">{t('Unit Price')}</div>,
        minWidth: '190px',
        cell: (row) => {
          return (
            <div className="w-100 text-end">
              <span>
                {row.unit_price ? row.unit_price.toFixed(2) : '0'}&nbsp;
                {orderDetails.invoice.currency}&nbsp;
                {row.tax_inclusive === true && '(Incl. of tax)'}
              </span>
            </div>  
          )
        }
      },
      {
        id: 14,
        name: <div className="w-100 text-end">{t('Discount')}</div>,
        minWidth: '150px',
        omit: orderDetails.type === LIVE_ORDER_TYPES.STO,
        cell: (row) => <div className="w-100 text-end">{(row.discount ? row.discount.toFixed(2) : 0)}&nbsp;{orderDetails.invoice.currency}</div>
      },
      {
        id: 15,
        name: <div className="w-100 text-end">{t('Tax(%)')}</div>,
        minWidth: '80px',
        omit: orderDetails.type === LIVE_ORDER_TYPES.STO,
        cell: (row) => <div className="w-100 text-end">{row.tax_percent || 0}</div>
      },
      {
        id: 16,
        name: <div className="w-100 text-end">{t('Tax Amount')}</div>,
        minWidth: '140px',
        omit: orderDetails.type === LIVE_ORDER_TYPES.STO,
        cell: (row) => {
          return (
            <div className="w-100 text-end">
              <span>
                {row.tax ? row.tax.toFixed(2) : '0'}&nbsp;
                {orderDetails.invoice.currency}
              </span>
            </div>
          )
        }
      },
      {
        id: 17,
        name: <div className="w-100 text-end">{t('Total')}</div>,
        minWidth: '140px',
        cell: (row) => {
          return (
            <div className="w-100 text-end">
              <span>
                {row.total ? row.total.toFixed(2) : 0}&nbsp;
                {orderDetails.invoice.currency}
              </span>
            </div>
          )
        }
      },
      {
        id: 18,
        name: t('Customer Note'),
        minWidth: '180px',
        omit: !orderDetails.show_customer_note,
        cell: (row) => {
          const customerNote = row.custom_attributes?.customer_note
          if (!customerNote) return '-'
          const isTruncated = customerNote.length > DESCRIPTION_MAX_LIMIT
          return (
            <div>
              <div className='two-line-truncate'>{customerNote}</div>
              {isTruncated && (
                <span
                  className="text-primary txt-asst-md cursor-pointer"
                  onClick={() => handleCustomerNote(customerNote)}
                >
                  {t('Read More')}
                </span>
              )}
            </div>
          )
        }
      }
    ]
  }, [orderDetails, t, show_inventory, hasBatches, showAvailableWithoutReservation])

  const childTableColumns = useMemo(() => {
    return [
      {
        id: 1,
        name: t('SKU Details'),
        minWidth: '350px',
        cell: (row) => {
          return (
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: true,
                id: row.id,
                image: {
                  src: row.images?.[0]?.default,
                  alt: row.name
                },
                details: {
                  name: row.name,
                  handleSkuDetails: row.id ? () => (handleSkuDetailsSidebar(row)) : null,
                  skuCode: row.seller_sku_code,
                  columnKey: 'order_sku_details'
                }
              }}
            />
          )
        }
      },
      {
        id: 2,
        name: t('Barcode'),
        minWidth: '240px',
        cell: (row) => {
          return (
            <div className="box-cell">
              <span className="text-truncate w-75">
                {row.barcodes?.[0]}
              </span>
              {row.barcodes?.length > 1 ? (
                <span
                  onClick={() => toggleBarcodeModal(row.barcodes)}
                  className="ms-1 cursor-pointer w-25"
                  style={{
                    color: 'var(--bs-primary)'
                  }}
                >
                  {`+${row.barcodes?.length - 1}`}
                </span>
              ) : null}
            </div>
          )
        }
      },
      {
        id: 3,
        name: <div className="w-100 text-end">{t('Qty Per Kit')}</div>,
        minWidth: '150px',
        cell: (row) => <div className="w-100 text-end">{row.quantity || 0}</div>
      },
      {
        id: 5,
        name: <div className="w-100 text-end">{t('Ordered Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_ordered_weight || 0}</div>
      },
      {
        id: 6,
        name: <div className="w-100 text-end">{t('Available Qty')}</div>,
        minWidth: '150px',
        omit: !show_inventory,
        cell: (row) => <div className="w-100 text-end">{row.display_available}</div>
      },
      {
        id: 7,
        name: <div className="w-100 text-end text-truncate" title={t('Available without Reservation')}>{t('Available without Reservation')}</div>,
        minWidth: '180px',
        omit: !showAvailableWithoutReservation,
        cell: (row) => <div className="w-100 text-end">{row.display_available_without_reservation}</div>
      },
      {
        id: 8,
        name: <div className="w-100 text-end">{t('Picked Qty')}</div>,
        minWidth: '150px',
        cell: (row) => <div className="w-100 text-end">{row.picked_quantity || 0}</div>
      },
      {
        id: 9,
        name: <div className="w-100 text-end">{t('Picked Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_picked_weight || 0}</div>
      },
      {
        id: 10,
        name: <div className="w-100 text-end">{t('Packed Qty')}</div>,
        minWidth: '150px',
        cell: (row) => <div className="w-100 text-end">{row.packed_quantity || 0}</div>
      },
      {
        id: 11,
        name: <div className="w-100 text-end">{t('Packed Weight')}</div>,
        minWidth: '150px',
        omit: !orderDetails.is_order_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_packed_weight || 0}</div>
      },
      {
        id: 12,
        name: <div className="w-100 text-end">{t('Unit Price')}</div>,
        minWidth: '200px',
        cell: (row) => {
          return (
            <div className="w-100 text-end">
              {row.unit_price ? row.unit_price.toFixed(2) : 0}
            </div>
          )
        }
      }
    ]
  }, [orderDetails, t, show_inventory, showAvailableWithoutReservation])

  return {
    tableColumns,
    childTableColumns
  }
} 