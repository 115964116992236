import { LANGUAGES } from '@src/App.constants'
import classNames from 'classnames'
import { QRCodeCanvas } from 'qrcode.react'
import { forwardRef } from 'react'
import Barcode from 'react-barcode'
import { useTranslation } from 'react-i18next'
import { InfoRow, tableData } from './invoice.constants.jsx'
import { invoiceStyles } from './invoice.styles.js'

export const InvoiceLayout = forwardRef(({ data, }, ref) => {

  const printLanguage = data?.invoice_setting.print_language || LANGUAGES.ENGLISH.value
  const { t } = useTranslation('translation', { lng: printLanguage })
  // Render Billing Details
  const showBillingCustomerName = data?.invoice_setting.show_customer_name && data.billing_details.name
  const showBillingCustomerNumber = data?.invoice_setting.show_customer_number && data.billing_details.phone_number
  const showBillingCustomerEmail = data?.billing_details.email
  const showBillingCustomerAddress = data?.invoice_setting.show_customer_address && data.billing_details.address

  const shouldRenderBillingDetail = showBillingCustomerName || showBillingCustomerNumber || showBillingCustomerEmail || showBillingCustomerAddress

  // Render Shipping Details
  const showShippingCustomerName = data?.invoice_setting.show_customer_name && data.shipping_details.name
  const showShippingCustomerNumber = data?.invoice_setting.show_customer_number && data.shipping_details.phone_number
  const showShippingCustomerEmail = data?.shipping_details.email
  const showShippingCustomerAddress = data?.invoice_setting.show_customer_address && data.shipping_details.address

  const shouldRenderShippingDetail = showShippingCustomerName || showShippingCustomerNumber || showShippingCustomerEmail || showShippingCustomerAddress
  const showOrderedQuantity = data?.invoice_setting.show_ordered_quantity
  const showPickedQuantity = data?.invoice_setting.show_picked_quantity
  const showCountryOfOrigin = data?.invoice_setting.show_country_of_origin
  const showOrderIdBarcode = data?.invoice_setting.show_order_id_barcode
  const showItemNote = data?.invoice_setting.show_item_notes

  const columns = tableData({ showCountryOfOrigin, showOrderedQuantity, showPickedQuantity, showItemNote })

  return (
    <div className='w-100' ref={ref}>
      <style>{invoiceStyles}</style>
      {data && <div className='order-invoice' style={{ direction: printLanguage === LANGUAGES.ARABIC.value ? 'rtl' : 'ltr' }}>
        <div className='invoice-header'>
          <div className='company-logo'>
            {data.invoice_setting.show_logo && data.invoice_setting.logo && <img width='74' height='74' src={data.invoice_setting.logo} alt='company-logo' />}
          </div>
          <div className='company-info'>
            <span className='title'>{t(data.invoice_setting.company_name) || t('Invoice')}</span>
            {data.invoice_setting.vat_number && <span className='vat_number'>{`VAT: ${data.invoice_setting.vat_number}`}</span>}
            {data.invoice_setting.description && <span className='description'>{data.invoice_setting.description}</span>}
          </div>

          <div className='qr-code-wrapper'>
            {(data.qr_hash && data.invoice_setting.show_qr_code) && <QRCodeCanvas size={64} value={data.qr_hash} />}
          </div>
        </div>
        <div className='divider'></div>
        <div className='store-info'>
          <div className='detail'>
            <div className='detail-heading'>
              {t('Store Details')}:
            </div>
            <InfoRow label={t('Store Name')} value={data.store_information.name} />
            <InfoRow label={t('Contact No')} value={data.store_information.phone_number} />
            <InfoRow label={t('Address')} value={data.store_information.address} />
          </div>
          <div className='detail'>
            <div className='detail-heading'>
              {t('Invoice Details')}:
            </div>
            {data.sc_invoice_number && <InfoRow label={t('Invoice No')} value={data.sc_invoice_number} />}
            {(data.invoice_setting.show_order_id && data.order_id) && <InfoRow label={t('Order ID')} value={data.order_id} />}
            {(data.invoice_setting.show_reference_order_id && data.reference_order_id) && <InfoRow label={t('Reference Order ID')} value={data.reference_order_id} />}
            {(data.invoice_setting.show_awb_number && data.awb_number) && <InfoRow label={t('AWB No')} value={data.awb_number} />}
            <InfoRow label={t('Invoice Date and Time')} value={`${data.invoice_date} at ${data.invoice_time}`} />
            {/* <InfoRow label={t('Invoice Time')} value={} /> */}
            <InfoRow label={t('Total Quantity')} value={data.total_item_count} />
            <InfoRow label={t('Payment Status')} value={data.payment_status} />
          </div>
        </div>
        <div className='divider'></div>
        <div className='store-info'>
          {shouldRenderBillingDetail && (
            <div className='detail'>
              <div className='detail-heading'>
                {t('Billing To')}:
              </div>
              {showBillingCustomerName && <InfoRow label={t('User Name')} value={data.billing_details.name} />}
              {showBillingCustomerNumber && <InfoRow label={t('Contact No')} value={data.billing_details.phone_number} />}
              {showBillingCustomerEmail && <InfoRow label={t('Email')} value={data.billing_details.email} />}
              {showBillingCustomerAddress && <InfoRow label={t('Address')} value={data.billing_details.address} />}
            </div>
          )}
          {shouldRenderShippingDetail &&
            <div className='detail'>
              <div className='detail-heading'>
                {t('Shipping To')}:
              </div>
              {showShippingCustomerName && <InfoRow label={t('User Name')} value={data.shipping_details.name} />}
              {showShippingCustomerNumber && <InfoRow label={t('Contact No')} value={data.shipping_details.phone_number} />}
              {showShippingCustomerEmail && <InfoRow label={t('Email')} value={data.shipping_details.email} />}
              {showShippingCustomerAddress && <InfoRow label={t('Address')} value={data.shipping_details.address} />}
            </div>}
        </div>
        {(shouldRenderBillingDetail || shouldRenderShippingDetail) && <div className='divider'></div>}
        <div className='info-table'>
          <table className='main-invoice-table-wrapper'>
            <thead></thead>
            <tbody className='main-invoice-table-body'>
              <table className='order-detail-table'>
                <thead className='table-header'>
                  <tr className='table-header-row'>
                    {columns.map((column) => <th key={column.key} className={classNames(`table-header-th ${column.className}`)}>{column.name}</th>)}
                  </tr>
                </thead>

                <tbody className='table-body'>
                  {data.order_items.map((item, index) => (
                    <tr className='table-row' key={index}>
                      {columns.map((column) => (
                        <td key={column.key} className={column.cellClass}>
                          {column.renderCustom 
                            ? column.getValue(item, index, data)
                            : column.getValue(item, index, data)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </tbody>
            <div className='order-total'>
              <div className='order-total-wrapper'>
                <div className='order-total-left-col'>
                  <span>{t('Sub Total')}</span>
                  <span>{t('Discount')}</span>
                  {data.invoice_setting.show_refund_amount && <span>{t('Refund')}</span>}
                  {data.invoice_setting.show_tax_percentage && <span>{t('Tax Percentage')}</span>}
                  {data.invoice_setting.show_price_includes_tax && <span>{t('Tax')}</span>}
                  {data.invoice_setting.show_shipping_cost && <span>{t('Shipping Cost')}</span>}
                  <span className='total'>{t('Total')}</span>
                </div>
                <div className='order-total-right-col'>
                  <span>{`${data.sub_total || '0.00'} ${data.currency || ''}`}</span>
                  <span>{`${data.discount || '0.00'} ${data.currency || ''}`}</span>
                  {data.invoice_setting.show_refund_amount && <span>{`${data.refund_amount || '0.00'} ${data.currency || ''}`}</span>}
                  {data.invoice_setting.show_tax_percentage && <span>{data.tax_percentage || '0.00'}%</span>}
                  {data.invoice_setting.show_price_includes_tax && <span>{`${data.tax_amount || '0.00'} ${data.currency || ''}`}</span>}
                  {data.invoice_setting.show_shipping_cost && <span>{`${data.shipping_cost || '0.00'} ${data.currency || ''}`}</span>}
                  <span className='total'>{`${data.total_with_tax || '0.00'} ${data.currency || ''}`}</span>
                </div>
              </div>
            </div>
            {data.invoice_setting.terms &&
            <>
              <div className='divider'></div>
              <div className='terms-condtions'>
                <p className='heading'>{t('Terms and Conditions')}:</p>
                {data.invoice_setting.terms.map((ele, idx) => <li key={idx} className='term'>{ele}</li>)}
              </div>
            </>}
            <div className='divider'></div>
            <tfoot className='empty-footer'>
              <div className='d-flex justify-content-center align-items-center w-100'>
                <Barcode  
                  renderer='canvas' 
                  background='transparent' 
                  width={1} 
                  height={44} 
                  fontSize={14} 
                  value={data.order_id}
                  text={`${t('Order ID')}: ${data.order_id}`}
                />
              </div>
            </tfoot>
          </table>
          {showOrderIdBarcode  && <footer className="invoice-footer">
          </footer>}
          <div className='page-break' />
        </div>
      </div>}
    </div>
  )
})