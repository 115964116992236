import Button from '@src/@core/components/ui/button'
import { CENTIMETERS_PER_INCH, RESOLUTION } from '@src/views/hubs/constants.hubs'
import { clearSkuConfigResponse, generateBarcode, getAssembleTaskDetail, setGeneratedBarcode, setSkuConfig } from '@src/views/inventory/store'
import { useEffect, useRef, useState } from 'react'
import { Printer } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { Badge, Spinner } from 'reactstrap'
import '../components/inventoryOperations/assembly/BarcodeListModal/styles.scss'
import { ASSEMBLY_BARCODES_BATCH_SIZE, BARCODE_SETTING, initial_config_state, SIDESHEET_VIEWS } from '../components/inventoryOperations/assembly/config'

const useBarcodeListModal = ({
  currentKit,
  currentTask,
  sellerId,
  hubId
}) => {
  const { t } = useTranslation()
  const pageTypeOptions = [
    { label: t('Default'), value: 'Default' },
    { label: t('Custom'), value: 'Custom' }
  ]
  // hooks and states
  const dispatch = useDispatch()
  const [currParam, setCurrParam] = useState(null)
  const hub_id = hubId || useSelector(store => store.auth.selectedGlobalHubId)
  const { barcodes } = useSelector(state => state.inventory.grn.generatedBarcode)
  const barcodesLoading = useSelector(store => store.inventory.loadingState.generateBarcode)
  const componentRef = useRef()
  const [batches, setBatches] = useState([])
  const [pageConfig, setPageConfig] = useState(initial_config_state)
  const [pageConfigInPixel, setPageConfigInPixel] = useState({
    width: Math.floor(+pageConfig.width * (RESOLUTION / CENTIMETERS_PER_INCH)),
    height: Math.floor(+pageConfig.height * (RESOLUTION / CENTIMETERS_PER_INCH))
  })
  const [error, setError] = useState({})
  const [selectedPageType, setSelectedPageType] = useState(pageTypeOptions[0])
  const [view, setView] = useState(SIDESHEET_VIEWS.PRINT_BARCODES)
  const [barcodeSetting, setBarcodeSetting] = useState(BARCODE_SETTING.SERIALISED)
  const skuConfigResponse = useSelector(store => store.inventory.skuConfigResponse)
  const [isSkuSerialised, setIsSkuSerialised] = useState(null)

  // handlers
  const manageHandle = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page {size: ${pageConfigInPixel.width}px ${pageConfigInPixel.height}px; margin: 0px; padding: 0}`
  })

  const clickManage = async ({ pageNumber = 1, barcodesQty = ASSEMBLY_BARCODES_BATCH_SIZE }) => {
    setCurrParam(pageNumber + 1)
    const newFilters = {}
    const newFiltersToDispatch = Object.keys(newFilters).reduce((acc, curKey) => {
      acc[curKey] = newFilters[curKey]?.value || null
      return acc
    }, {})

    newFiltersToDispatch['quantity'] = barcodesQty
    newFiltersToDispatch['page'] = pageNumber + 1

    const body = {
      ...newFiltersToDispatch,
      hub_id,
      seller_id: sellerId,
      seller_sku_id: currentKit?.sku.seller_sku_id,
      assembly_task_id: currentTask?.id
    }

    await dispatch(generateBarcode(body))
    await manageHandle()

  }

  const createBatches = () => {
    const total = currentKit?.quantity
    const batches = []
    for (let i = 0; i < total / ASSEMBLY_BARCODES_BATCH_SIZE; i++) {
      const batch = {}

      if (i === Math.floor(total / ASSEMBLY_BARCODES_BATCH_SIZE)) {
        batch.name = `${(i * ASSEMBLY_BARCODES_BATCH_SIZE)} - ${total}`
        batch.total = Math.abs((i * ASSEMBLY_BARCODES_BATCH_SIZE) - total)
        batch.id = i

      } else {
        batch.name = `${(i * ASSEMBLY_BARCODES_BATCH_SIZE)} - ${ASSEMBLY_BARCODES_BATCH_SIZE + (i * ASSEMBLY_BARCODES_BATCH_SIZE)}`
        batch.total = Math.abs((i * ASSEMBLY_BARCODES_BATCH_SIZE) - (ASSEMBLY_BARCODES_BATCH_SIZE + (i * ASSEMBLY_BARCODES_BATCH_SIZE)))
        batch.id = i
      }

      if (currParam === i + 1) {
        batch.barcodes = barcodes?.slice(0, 100)
      } else {
        batch.barcodes = []
      }
      batches.push(batch)
    }

    return batches
  }

  const pageTypeHandler = (value) => {
    if (value.label === pageTypeOptions[0].value) {
      setPageConfig(initial_config_state)
    }
    setSelectedPageType(value)
    setPageConfigInPixel({
      width: Math.floor(
        +initial_config_state.width * (RESOLUTION / CENTIMETERS_PER_INCH)
      ),
      height: Math.floor(
        +initial_config_state.height * (RESOLUTION / CENTIMETERS_PER_INCH)
      )
    })
  }

  const handleWidthAndHeight = (e) => {
    const { value, name } = e.target
    if (value <= 0) {
      setError({ ...error, [name]: { message: `Please enter valid ${name}` } })
    } else {
      delete error[name]
    }
    setPageConfig({ ...pageConfig, [name]: value })
    const convert_to_pixel = Math.floor(
      +value * (RESOLUTION / CENTIMETERS_PER_INCH)
    )
    setPageConfigInPixel({ ...pageConfigInPixel, [name]: convert_to_pixel })
  }

  const handleClosed = () => {
    dispatch(clearSkuConfigResponse())
    dispatch(setGeneratedBarcode(''))
    setBarcodeSetting(BARCODE_SETTING.SERIALISED)
    setIsSkuSerialised(null)
  }

  const handleOpened = () => {
    if (currentKit?.is_serialised === null || currentKit?.is_serialised === undefined) {
      setView(SIDESHEET_VIEWS.SET_SERIALISATION)
    } else {
      setView(SIDESHEET_VIEWS.PRINT_BARCODES)
      setIsSkuSerialised(currentKit?.is_serialised)
      if (currentKit?.quantity) {
        setBatches(createBatches())
      }
    }
  }

  const handleSetSerialisationStatus = () => {
    const body = {
      hub_id,
      seller_sku_id: currentKit?.sku.seller_sku_id,
      is_serialised: barcodeSetting === BARCODE_SETTING.SERIALISED
    }
    dispatch(setSkuConfig(body))
    setIsSkuSerialised(barcodeSetting === BARCODE_SETTING.SERIALISED)
  }

  // useEffects 
  useEffect(() => {
    if (skuConfigResponse.is_success) {
      setView(SIDESHEET_VIEWS.PRINT_BARCODES)
      if (currentKit?.quantity) {
        setBatches(createBatches())
      }
      dispatch(getAssembleTaskDetail({ taskId: currentTask?.id, hub_id }))
    }
  }, [skuConfigResponse])

  // columns config
  const columns = [
    {
      id: 1,
      name: t('File Name'),
      key: 'file_name',
      cell: (row) => (
        <div className="w-100 text-center d-flex justify-content-between">
          {row.name}
          {row.isDownloaded &&
            <Badge pill color="success">
              Downloaded
            </Badge>
          }
        </div>
      )
    },
    {
      id: 2,
      name: <div className="w-100 text-end">{t('No. Of Barcodes')}</div>,
      key: 'no_of_barcodes',
      cell: (row) => (
        <div className='w-100 text-end'>
          {row.total || 0}
        </div>
      )
    },
    {
      id: 3,
      name: 'Print',
      key: 'print',
      maxWidth: '50px',
      cell: (row, index) => (
        // const rowIndex = data.findIndex(item => item.id === row.id)
        <div className='d-flex justify-content-center align-items-center w-100 gap-2'>
          <Button onClick={() => clickManage({ pageNumber: row.id, barcodesQty: row.total })} disabled={barcodesLoading} ofStyle={'outlined'}>{!barcodesLoading ? <Printer size={14} /> : currParam - 1 === index ? <Spinner size={'sm'} /> : <Printer size={14} />}</Button>
        </div>
      )
    }
  ]

  return {
    columns,
    pageTypeHandler,
    handleWidthAndHeight,
    handleClosed,
    handleOpened,
    handleSetSerialisationStatus,
    batches,
    pageConfig,
    pageConfigInPixel,
    error,
    selectedPageType,
    view,
    barcodeSetting,
    isSkuSerialised,
    t,
    componentRef,
    setBarcodeSetting,
    pageTypeOptions,
    barcodes
  }
}

export default useBarcodeListModal
