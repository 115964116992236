// ** React Imports
import { Suspense, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

// ** Context Imports
import { bootApiToGetUserDetails } from '@src/redux/authentication'
import { AbilityContext } from '@src/utility/context/Can'
// import { handleVisibilityChange } from '@src/network/RealTime'
import { checkPermission } from '@layouts/utils'
import { NOT_APPLICABLE } from '@src/App.constants'
import usePrintersConfigurations from '@src/utility/hooks/usePrintersConfigurations'
import PageTitle from '../page-title/PageTitle'
import ComponentSpinner from '../spinner/Loading-spinner'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'
import { ROUTES_KEYS } from '@src/router/route-keys'


/**
 * Validates if the authorization result grants access
 * @param {any} authResult - The result from checkPermission
 * @returns {boolean} - true if authorized, false if not
 */
const validateAuthorization = (authResult) => {
  // If it's a boolean, respect its value
  if (typeof authResult === 'boolean') return authResult
  
  // If it's equal to NA return true
  if (authResult === NOT_APPLICABLE) return true

  // For any other case, consider it unauthorized
  return false
}

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('auth'))

  const bootResponse = useSelector((state) => state.auth)

  const { permissions, loading, userData, bootApiError } = bootResponse

  const ability = useContext(AbilityContext)

  const pageTitle = route?.title

  const {mutate: getPrintersConfigurations } = usePrintersConfigurations()

  if (route) {
    let action = null
    let resource = null
    let checkIsPermitted = null
    let restrictedRoute = true

    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      checkIsPermitted = route.meta.checkIsPermitted
      restrictedRoute = route.meta.restricted
    }

    try {
      const isRouteAuthorised = checkPermission({ action, resource, checkIsPermitted })

      if (!user?.access_token) {
        return <Navigate to={ROUTES_MAPPING[ROUTES_KEYS.LOGIN]} />
      }

      if (user?.access_token && restrictedRoute && !bootApiError) {
        if (!permissions?.length > 0 && !loading && !userData?.name) {
          dispatch(bootApiToGetUserDetails(ability))
          getPrintersConfigurations()
        }

        // Check permissions and authorization
        if (permissions && action && resource) {
          const isAuthorized = validateAuthorization(isRouteAuthorised)
          
          if (!isAuthorized) {
            return <Navigate to={ROUTES_MAPPING[ROUTES_KEYS.NOT_AUTHORIZED]} replace />
          }
        }
      }
    } catch (error) {
      console.error('Error checking route authorization:', error)
      return <Navigate to={ROUTES_MAPPING[ROUTES_KEYS.NOT_AUTHORIZED]} replace />
    }
  }

  if (permissions) {
    return (
      <Suspense 
        fallback={
          <div className='position-absolute left-0 top-0 right-0 bottom-0 w-100 flex-center-center'>
            <ComponentSpinner />
          </div>
        }
      >
        <>
          <PageTitle pageTitle={pageTitle} />
          {children}
        </>
      </Suspense>
    )
  }
}

export default PrivateRoute