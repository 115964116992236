import { useEffect, useRef, useState } from 'react';
import { TABLE_SCROLL_THRESHOLD } from './globalTable.constant';

/**
 * Custom hook to detect if a table element is scrolled and needs a sticky header
 * Also manages scroll behavior between parent (window) and child (table element)
 * 
 * @param {Object} options - Configuration options
 * @param {string} options.tableSelector - CSS selector for the table element (default: '.common_table')
 * @param {number} options.offsetThreshold - Threshold for considering the table as scrolled (default: 62.5)
 * @returns {Object} { isScrolled, tableRef } - Whether the table is scrolled and ref for the table element
 */
const useTableScrollVisibility = (options = {}) => {
  const { 
    tableSelector = '.common_table',
    offsetThreshold = TABLE_SCROLL_THRESHOLD
  } = options;

  const [isScrolled, setIsScrolled] = useState(false);
  const tableRef = useRef(null);

  const checkVisibility = () => {
    const element = tableRef.current || document.querySelector(tableSelector);
    if (!element) return;
    
    const rect = element.getBoundingClientRect();
    const inViewport = rect.top >= offsetThreshold;

    if (inViewport) {
      element.classList.remove('scroll-visible');
      element.scrollTo({
        top: 0
      });
      setIsScrolled(false);
    } else {
      element.classList.add('scroll-visible');
      setIsScrolled(true);
    }
  };

  useEffect(() => {
    // Find and set the table element reference if not already set
    if (!tableRef.current) {
      tableRef.current = document.querySelector(tableSelector);
    }

    const handleWheel = (e) => {
      const tableElement = tableRef.current;
      if (!tableElement) return;

      // Get the mouse position relative to the viewport
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      // Check if mouseX and mouseY are valid numbers
      let isMouseOverTable = false;

      if (Number.isFinite(mouseX) && Number.isFinite(mouseY)) {
        // Get the table element's position
        const tableRect = tableElement.getBoundingClientRect(); 
        
        try {
          // Get the element at the mouse position
          const elementAtPoint = document.elementFromPoint(mouseX, mouseY);
          isMouseOverTable = elementAtPoint && tableElement.contains(elementAtPoint);
        } catch (error) {
          // Fallback to bounding box check if elementFromPoint fails
          isMouseOverTable = (
            mouseX >= tableRect.left && 
            mouseX <= tableRect.right && 
            mouseY >= tableRect.top && 
            mouseY <= tableRect.bottom
          );
        }
      } else {
        // Fallback: check if the event target is within the table
        isMouseOverTable = tableElement.contains(e.target);
      }

      if (isMouseOverTable) {
        // Check if we can scroll the table
        const canScrollDown = tableElement.scrollTop < tableElement.scrollHeight - tableElement.clientHeight;
        const canScrollUp = tableElement.scrollTop > 0;

        if ((e.deltaY > 0 && canScrollDown) || (e.deltaY < 0 && canScrollUp)) {
          if (canScrollUp) {
            e.preventDefault();
          }
          tableElement.scrollTop += e.deltaY;
        }
      }
      
      checkVisibility();
    };

    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);
    window.addEventListener('wheel', handleWheel, { passive: false, capture: true });
    window.addEventListener('scroll', handleWheel, { passive: false, capture: true });

    // Check on mount
    checkVisibility();

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('scroll', checkVisibility);
      window.removeEventListener('resize', checkVisibility);
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('scroll', handleWheel);
    };
  }, [tableSelector, offsetThreshold]);

  return { isScrolled, tableRef };
};

export default useTableScrollVisibility; 