import { useForm } from 'react-hook-form';
import { EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES } from '../editCustomerShippingModal.constant';
import { iFormData } from '../types';
export const useEditDetailsForm = () => {
  return useForm<iFormData>({
    defaultValues: {
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.FIRST_NAME]: '',
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.LAST_NAME]: '',
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.EMAIL]: '',
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.MOBILE]: {
        [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY_CODE]: '',
        [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CONTACT_NUMBER]: ''
      },
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.AREA]: '',
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ADDRESS1]: '',
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ADDRESS2]: '',
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.CITY]: null,
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.COUNTRY]: null,
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.STATE]: null,
      [EDIT_CUSTOMER_SHIPPING_FORM_FIELD_NAMES.ZIP]: ''
    },
    mode: 'onChange'
  });
}; 