import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import Modal from '@src/@core/components/ui/modal/Modal'
import { MODAL_TYPES } from '@src/@core/components/ui/modal/modal.constant'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { Create } from '@src/assets/images/icons/Modal/GeneralIcons'
import ability from '@src/configs/acl/ability'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getOptionsFetcher } from '@src/utility/Utils'
import { loadCustomLabelsConfig } from '@src/views/sales/sales.apis'
import { ORDER_FREE_TAGS } from '@src/views/sales/sales.constant'
import { editCustomerAndShippingDetails } from '@src/views/sales/store/store'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import { UncontrolledTooltip } from 'reactstrap'

const AddFreeTags = ({ isOpen, toggle, orderDetails = {} }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { control, handleSubmit, watch, setValue, reset } = useForm({ defaultValues: { free_tags: [] } })
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const isOrderUpdated = useSelector(state => state.sales.success.editCustomerAndShippingDetails)
  const isOrderUpdating = useSelector(state => state.sales.loading.editCustomerAndShippingDetails)

  const isOrderTagViewAllowed = ability.can(abilityMap.order.view_tag.action, abilityMap.order.view_tag.resource)
  
  // loadOptions
  const loadCustomLabelsOptions = getOptionsFetcher(loadCustomLabelsConfig);

  const { mutate: addCustomTags, isPending: isAddCustomTagsPending } = useMutation({
    mutationKey: ['add-tags'],
    mutationFn: ({ body }) => axiosInstance.post('/api/v1/oms/custom_labels', body),
    onSuccess: ({ data }) => {
      setValue('free_tags', [...watch('free_tags'), { label: data.data?.name, value: data.data?.id }])
      CustomToast('Tag created successfully', { my_type: 'success' })
    }
  })

  const handleFreeTags = (e) => {
    const filteredOptions = e.filter(option => option.tag !== ORDER_FREE_TAGS.ADD_LABELS.value)
    const matchedOption = e.find(option => option.tag === ORDER_FREE_TAGS.ADD_LABELS.value)

    if (matchedOption?.tag === ORDER_FREE_TAGS.ADD_LABELS.value) {
      addCustomTags({ body: { name: matchedOption.value } })
      setValue('free_tags', [...filteredOptions])
    } else {
      setValue('free_tags', e)
    }
  }

  const onHandleSubmit = (data) => {
    const customLabels = orderDetails.custom_labels?.length > 0 ? [...orderDetails.custom_labels?.map(ele => ({ name: ele.name, id: ele.id }))] : []
    dispatch(editCustomerAndShippingDetails({
      order_id: orderDetails.id,
      body: {
        custom_labels: [...customLabels, ...data.free_tags.map(ele => ({ name: ele.label, id: ele.value }))],
        edit_entity_type: 'custom_labels'
      }
    }))
  }

  useEffect(() => {
    if (isOrderUpdated && isOpen) {
      toggle()
    }
  }, [isOrderUpdated])

  const FreeTagsCustomDropdownOption = (props) => {
    const { data: { label, color, value } } = props
    const { Option } = components

    return (
      <Option key={value} {...props}>
        <div className="custom-option flex-center-between" title={label}>
          <div className='d-flex gap-20px align-items-center w-50'>
            <div className={classNames('cursor-pointer text-truncate', { 'rounded px-4px py-2px': color })}>
              {label}
            </div>
          </div>
        </div>
      </Option>
    )
  }

  const getPrimaryButtonProps = () => {
    return {
      disabled: isAddCustomTagsPending || isOrderUpdating || !watch('free_tags')?.length,
      loading: isOrderUpdating
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClosed={() => reset()}
      type={MODAL_TYPES.GENERAL}
      closeModalHandler={toggle}
      icon={Create}
      title={t('Add Tags')}
      primaryButtonText={t('Done')}
      primaryButtonHandler={handleSubmit(onHandleSubmit)}
      primaryButtonProps={getPrimaryButtonProps()}
      secondaryButtonText={t('Cancel')}
      secondaryButtonHandler={toggle}
      isTranslationRequired={false}
    >
      <form className='pt-8px d-flex flex-column gap-24px' onSubmit={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}>
        <div className="d-flex flex-column gap-28px">
          <div id="orderTagsView">
            <FloatingDropDown
              name={'free_tags'}
              control={control}
              title='Tags'
              isAsync
              isMulti
              disabled={isAddCustomTagsPending || !isOrderTagViewAllowed}
              menuPortalTarget={document.body}
              cacheUniqs={[watch('free_tags')]}
              modifiedStyles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                option: (provided, state) => ({
                  ...provided,
                  cursor: 'pointer',
                  fontSize: 12,
                  color: state.isSelected ? '#FFF' : state.data.tag === ORDER_FREE_TAGS.ADD_LABELS.value ? 'var(--bs-primary)' : 'var(--bs-dark)'
                })
              }}
              loadOptions={loadCustomLabelsOptions}
              value={watch('free_tags')}
              additional={{ isCreationAllowed: true }}
              onChangeFunc={handleFreeTags}
              additionalComponents={{
                Option: FreeTagsCustomDropdownOption
              }}
              menuIsOpen={isDropDownOpen}
              onMenuOpenFunc={() => setIsDropDownOpen(true)}
              onMenuCloseFunc={() => setIsDropDownOpen(false)}
            />
            {
              !isOrderTagViewAllowed && <UncontrolledTooltip target="orderTagsView" offset={[0, 5]}>
                {t('You are not authorised to perform this action')}
              </UncontrolledTooltip>
            }
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddFreeTags
