// @ts-expect-error fix me
import checkCircle from '@src/assets/images/icons/check_circle.svg';
import { getFinalOrderTotal } from '@src/views/sales/components/transactions-sidesheet/utils';
import { PAYMENT_METHODS } from '@src/views/sales/create-order/create-order.constant';
import { ADDITIONAL_CHARGE_TYPES } from '@src/views/sales/sales.constant';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_LOG_TRANSITION_TYPE } from '../constants';
import { iAdditionalCharge, iOrderLog } from '../types';
import { getTransactionAction } from '../utils';
import { CardHeader } from './card-header';

interface iOrderSummaryCardProps {
  icon: string
  label: string
  value: string
  preloadData: iOrderLog | null
}

/**
 * Card component for displaying order summary information
 * Shows changes in order totals, taxes, and other summary values
 * Uses the common card header and details list components
 * @component
 */
const OrderSummaryCard: FC<iOrderSummaryCardProps> = ({ icon, label: title, value, preloadData }) => {
  const { t } = useTranslation();

  const invoiceTransition = preloadData!.metadata.order_transition!.invoice_transition;
  const currentMethod = invoiceTransition.updated.payment_method;
  const previousMethod = invoiceTransition.previous.payment_method;
  const transactionsTransition = invoiceTransition.transactions_transition;
  const isOrderSettled = transactionsTransition.length > 0;
  const codFee = invoiceTransition.updated.additional_charges.find((charge: iAdditionalCharge) => charge.type === ADDITIONAL_CHARGE_TYPES.COD_FEE);

  const invoiceDetails = useMemo(
    () => [
      {
        label: 'Previous Order Total',
        // TODO: null check on payment method is temporary, after discussion with BE team, we will handle it.
        subLabel: PAYMENT_METHODS[previousMethod.toUpperCase() as keyof typeof PAYMENT_METHODS]?.label,
        value: invoiceTransition.previous.total,
        currency: invoiceTransition.previous.currency,
      },
      {
        label: 'New Order Total',
        subLabel: codFee?.value ? 'Incl. of COD Fee' : '',
        value: invoiceTransition.updated.total,
        currency: invoiceTransition.updated.currency,
      },
      {
        label: codFee?.display_name || '',
        value: codFee?.value || 0,
        currency: invoiceTransition.updated.currency,
        omit: codFee?.value ? false : true,
        isChild: true,
      },
    ].filter((detail) => !detail.omit),
    [invoiceTransition, currentMethod]
  );

  const finalOrderTotal = useMemo(() => {
    const { updated, previous } = invoiceTransition;
    // The calculation is done based on the updated invoice details
    return getFinalOrderTotal({
      newTotal: updated.total,
      previousInvoice: previous,
    });

  }, [invoiceTransition]);

  return (
    <div className='details-card border border-light-4 rounded-10px'>
      <CardHeader icon={icon} title={title} value={value} status={ORDER_LOG_TRANSITION_TYPE.UPDATED} />
      {/* body */}
      <div className='p-16px m-16px rounded-12px border border-dark-1'>
        <div className='d-flex flex-column gap-16px'>
          {invoiceDetails.map((detail) => (
            <div key={detail.label} className='flex-start-between gap-8px'>
              <div className='d-flex gap-4px w-50'>
                <span className={classNames('txt-sub-md', {
                  'ms-12px': detail.isChild,
                })}>{t(detail.label)}</span>
                {detail.subLabel && <span className='txt-sub-rg'>({t(detail.subLabel)})</span>}
              </div>
              <span className='txt-sub-md text-dark text-break w-50 text-end'>
                {detail.value?.toFixed(2)}&nbsp;{detail.currency}
              </span>
            </div>
          ))}
          <hr className='m-0' />

          <div className='flex-start-between gap-8px txt-body-md text-dark-6 text-break'>
            <span className='w-50'>{t(isOrderSettled ? 'Difference' : finalOrderTotal.label)}</span>
            <span className='w-50 text-end text-dark'>
              {finalOrderTotal.value.toFixed(2)}&nbsp;
              {invoiceTransition.updated.currency}
            </span>
          </div>

          {isOrderSettled && (
            <div className='d-flex flex-column gap-16px rounded-8px border overflow-hidden'>
              <div className='flex-center-center gap-6px p-16px bg-success-light'>
                <img src={checkCircle} alt="check_circle" />
                <span className='text-success txt-body-md'>{t(getTransactionAction(transactionsTransition[0].type)!)}</span>
              </div>
              <div className='d-flex flex-column gap-16px p-12px pt-0'>
                <div className='d-flex gap-4px'>
                  <span className='text-dark-5 w-50'>{t('Payment Method')}</span>
                  <span className='text-dark-6 w-50 text-end text-break txt-body-md'>{transactionsTransition[0].payment_mode}</span>
                </div>
                {transactionsTransition[0].reference_number && <div className='d-flex gap-4px'>
                  <span className='text-dark-5 w-50'>{t('Payment Reference Number')}</span>
                  <span className='text-dark-6 w-50 text-end text-break txt-body-md'>{transactionsTransition[0].reference_number}</span>
                </div>}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryCard;
