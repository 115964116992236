import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import InputField from '@src/@core/components/ui/input-field'
import Modal from '@src/@core/components/ui/modal/Modal'
import Select from '@src/@core/components/ui/select'
import { API_ENDPOINTS, getApiUrl } from '@src/api.urls'
import { axiosInstance } from '@src/network/AxiosInstance'
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants'
import { useMutation } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form, ModalBody } from 'reactstrap'
import { CANCEL_ORDER_REASON } from '../constant/orders.constants'
import { useFetchReasons } from '../hooks/useFetchReasons'
import { updateIsOrderCancelledSuccessfully } from '../store/store'
import './CancelOrderPopup.scss'
import StripForReasonNotAvailable from './reasonsConfigrationRequired/StripForReasonNotAvailable'

const CancelOrderPopup = (prop) => {
  const { isCancelOrderPopupOpen, setIsCancelOrderPopupOpen, orderDetail, setOrderDetail, handleCancelOrderSuccess } = prop
  const [showInfoPopUp, setShowInfoPopUp] = useState({ open: false, message: null })
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { reasons } = useFetchReasons({
    isModalOpen: isCancelOrderPopupOpen,
    entity_type: OMS_REASON_CONFIG.cancel_order.key,
  })
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    register,
    watch
  } = useForm({
    mode: 'onChange'
  })

  const getModalTitle = () => {
    if (showInfoPopUp.open) {
      return `${orderDetail.sellerOrderId} cancelled successfully.`
    }
    return  <>
      <div>{t('Are you sure you want to cancel this order?')}</div>
      <div>{orderDetail?.sellerOrderId && <span>{t('Order ID')}: {orderDetail.sellerOrderId}</span>}</div>
    </>
  }

  const handleInfoPopUpClose = () => {
    handleCancelOrderSuccess()
    setShowInfoPopUp({ open: false, message: null })
    setOrderDetail(null)
  }

  const { mutate: cancelOrder, isPending: isCancelOrderPending } = useMutation({
    mutationKey: ['cancel-order'],
    mutationFn: (body) => axiosInstance.put(getApiUrl(API_ENDPOINTS.ORDERS.CANCEL_ORDER, { orderId: orderDetail.id }), body),
    onSuccess: (response) => {
      if (response.data.data.level === 'info') {
        setShowInfoPopUp({ open: true, message: response.data.data.message })
      } else {
        handleCancelOrderSuccess()
        CustomToast(response.data.data.message, { my_type: 'success' })
        setOrderDetail(null)
      }
      dispatch(updateIsOrderCancelledSuccessfully(true))
      reset()
    }
  })

  const handleTakeMeBack = () => {
    setIsCancelOrderPopupOpen((prev) => !prev)
    setOrderDetail(null)
    reset()
  }

  const handleCancelOrder = (data) => {
    const reason = data.cancel_reasons.value === CANCEL_ORDER_REASON.OTHER.key ? data.reason_description : data.cancel_reasons.value
    cancelOrder({ reason })
  }

  const handleToggle = () => {
    setIsCancelOrderPopupOpen(prev => !prev)
    reset()
  }

  return (
    <Modal
      icon={showInfoPopUp.open ? 'info' : 'warning'}
      isOpen={isCancelOrderPopupOpen}
      title={getModalTitle()}
      onClosed={reset}
      subTitle={showInfoPopUp.open ? showInfoPopUp.message : null}
      closeModalHandler={showInfoPopUp.open ? handleInfoPopUpClose : handleToggle}
      secondaryButtonText={showInfoPopUp.open ? t('Okay') : t('Take Me Back')}
      secondaryButtonHandler={showInfoPopUp.open ? handleInfoPopUpClose : handleTakeMeBack}
      primaryButtonText={!showInfoPopUp.open ? t('Yes, Cancel') : ''}
      primaryButtonHandler={handleSubmit(handleCancelOrder)}
      primaryButtonProps={{ loading: isCancelOrderPending, disabled: isCancelOrderPending }}
      isTranslationRequired={false}
    >
      {!showInfoPopUp.open ? <ModalBody className="p-0">
        <div className="txt-body-rg text-dark-6 mb-24px">{t('This action cannot be reversed.')}</div>
        <Form>
          <Controller
            name='cancel_reasons'
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Select
                {...field}
                errors={errors}
                options={reasons}
                isRequired
                label={t('Reason for Cancellation')}
                isDisabled={isCancelOrderPending || reasons?.length === 0}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 1100 })
                }}
              />
            )}
          />
          {reasons?.length === 0 && <StripForReasonNotAvailable entity_type={OMS_REASON_CONFIG.cancel_order.key} />}
          {watch('cancel_reasons')?.value === CANCEL_ORDER_REASON.OTHER && <div className="mt-24px">
            <InputField
              name="reason_description"
              type="textarea"
              label="Enter reason here"
              maxLength="3000"
              errors={errors}
              {...register('reason_description', {required: 'Reason is required'})}
              isRequired
              disabled={isCancelOrderPending}
            />
          </div>}
        </Form>
      </ModalBody> : null}
    </Modal>
  )
}

CancelOrderPopup.propTypes = {
  isCancelOrderPopupOpen: PropTypes.bool,
  setIsCancelOrderPopupOpen: PropTypes.func,
  orderDetail: PropTypes.object,
  setOrderDetail: PropTypes.func,
  handleCancelOrderSuccess: PropTypes.func
}

export default CancelOrderPopup
