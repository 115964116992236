import { useEffect, useState } from 'react';

/**
 * Custom hook to check if a table header is touching the navigation bar
 * 
 * @param {Object} options - Configuration options
 * @param {string} options.tableContainerSelector - CSS selector for the table container (default: '#table-container-wrapper')
 * @param {string} options.navbarSelector - CSS selector for the navbar (default: '.navbar')
 * @returns {boolean} isHeaderTouchingNav - Whether the table header is touching the navbar
 */
const useHeaderPositionCheck = (options = {}) => {
  const { 
    tableContainerSelector = '#table-container-wrapper',
    navbarSelector = '.navbar'
  } = options;

  const [isHeaderTouchingNav, setIsHeaderTouchingNav] = useState(false);

  useEffect(() => {
    const checkHeaderPosition = () => {
      const tableContainer = document.querySelector(tableContainerSelector);
      const navHeader = document.querySelector(navbarSelector);
      
      if (tableContainer && navHeader) {
        const tableRect = tableContainer.getBoundingClientRect();
        const navRect = navHeader.getBoundingClientRect();
        
        // Check if table header touches navbar
        setIsHeaderTouchingNav(tableRect.top <= navRect.bottom);
      }
    };

    // Initial check
    checkHeaderPosition();

    // Add event listener
    window.addEventListener('scroll', checkHeaderPosition);
    
    // Cleanup
    return () => window.removeEventListener('scroll', checkHeaderPosition);
  }, [tableContainerSelector, navbarSelector]);

  return isHeaderTouchingNav;
};

export default useHeaderPositionCheck; 