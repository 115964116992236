import BulkActions from '@src/@core/components/ui/bulk-actions/BulkActions'
import Button from '@src/@core/components/ui/button'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import PrintManualAwb from '@src/components/PrintManualAwb'
import { AbilityContext } from '@src/utility/context/Can'
import { printMultipleAwb } from '@src/utility/Utils'
import { SHIPPING_ENTITY } from '@src/views/sales/constant/orders.constants'
import { categorizeOrderDetailsButtons } from '@src/views/sales/sales.utils'
import { fetchAWB } from '@src/views/sales/store/store'
import { useContext, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { ACTION_BUTTONS_ID } from '../constant'

const ButtonSection = (props) => {
  const { returnOrderDetail, openCancelShipmentModal, openCreateReverseShipmentModal, handleStartProcessing, handleMarkOrderArrivedAtHub, isLocationInventoryEnabled, isExternalHub, openMarksAsRefundSideSheet, excludeButtons, setIsCancelReturnOrderPopupOpen } = props
  const ability = useContext(AbilityContext)
  const printManualAwbRef = useRef(null)

  const salesLoading = useSelector((state) => state.sales.loading)
  const returnsLoading = useSelector((state) => state.returns.loading)

  const [printAwbLoading, setPrintAwbLoading] = useState(false);
  
  const permissions = {
    createReverseShipment: ability.can(
      abilityMap.order.create_shipment.action,
      abilityMap.order.create_shipment.resource
    ),
    returnQc: ability.can(
      abilityMap.return.qc_order.action,
      abilityMap.return.qc_order.resource
    ),
    refundOrder: ability.can(
      abilityMap.return.refund_order.action,
      abilityMap.return.refund_order.resource
    ),
    cancelReturnOrder: ability.can(
      abilityMap.return.cancel_order.action,
      abilityMap.return.cancel_order.resource
    )
  }

  const handlePrintManualAwb = () => {
    printManualAwbRef.current.click()
  }

  const handlePrintAwbLabel = async () => {
    if (returnOrderDetail?.shipment?.awb_label) {
      printMultipleAwb(returnOrderDetail.shipment.awb_label)
    } else {
      setPrintAwbLoading(true)
      const params = {
        entity_id: returnOrderDetail.id,
        entity_type: SHIPPING_ENTITY.RETURN_ORDER.value
      }
      const res = await fetchAWB({ params })
      if (res?.is_success && res?.data?.awb_label) {
        printMultipleAwb(res.data.awb_label)
      }
      setPrintAwbLoading(false)
    }
  }

  // The order of buttons in the array is important; we arrange them in order of priority
  const buttonArray = useMemo(() => [
    {
      id: ACTION_BUTTONS_ID.CREATE_SHIPMENT,
      onClick: openCreateReverseShipmentModal,
      hasPermission: permissions.createReverseShipment,
    },
    {
      id: ACTION_BUTTONS_ID.RETURN_TO_ORIGIN,
      onClick: handleMarkOrderArrivedAtHub,
      hasPermission: true,
    },
    {
      id: ACTION_BUTTONS_ID.OMS_QC_PROCESSING,
      onClick: handleStartProcessing,
      hasPermission: !isLocationInventoryEnabled && permissions.returnQc && !isExternalHub,
    },
    {
      id: ACTION_BUTTONS_ID.PRINT_AWB,
      onClick: handlePrintAwbLabel,
      hasPermission: true,
    },
    {
      id: ACTION_BUTTONS_ID.PRINT_MANUAL_AWB,
      onClick: handlePrintManualAwb,
      hasPermission: true,
    },
    {
      id: ACTION_BUTTONS_ID.REFUND_ORDER,
      onClick: openMarksAsRefundSideSheet,
      hasPermission: permissions.refundOrder,
    },
    {
      id: ACTION_BUTTONS_ID.CANCEL_SHIPMENT,
      onClick: openCancelShipmentModal,
      hasPermission: true,
    },
    {
      id: ACTION_BUTTONS_ID.CANCEL_ORDER,
      onClick: () => setIsCancelReturnOrderPopupOpen(true),
      hasPermission: permissions.cancelReturnOrder,
    }
  ], [
    openCancelShipmentModal,
    openCreateReverseShipmentModal,
    handlePrintAwbLabel,
    handlePrintManualAwb,
    handleStartProcessing,
    handleMarkOrderArrivedAtHub,
    permissions,
    isLocationInventoryEnabled,
    isExternalHub,
  ])

  // Filter out excluded buttons from the buttonArray
  const filteredButtonArray = useMemo(() => {
    return buttonArray.filter(button => !excludeButtons.includes(button.id))
  }, [buttonArray, excludeButtons])

  const { actionButtons } = useMemo(
    () => categorizeOrderDetailsButtons(returnOrderDetail, filteredButtonArray),
    [returnOrderDetail, filteredButtonArray]
  )
  
  const primaryButtonLoading = useMemo(() => ({
    [ACTION_BUTTONS_ID.CREATE_SHIPMENT]: false,
    [ACTION_BUTTONS_ID.CANCEL_SHIPMENT]: salesLoading.cancelShipment,
    [ACTION_BUTTONS_ID.PRINT_AWB]: printAwbLoading,
    [ACTION_BUTTONS_ID.PRINT_MANUAL_AWB]: false,
    [ACTION_BUTTONS_ID.START_PROCESSING]: false,
    [ACTION_BUTTONS_ID.CANCEL_RETURN_ORDER]: false,
    [ACTION_BUTTONS_ID.MARK_RETURN_TO_ORIGIN]: returnsLoading.markOrdersAsReturnedTohHub,
  }), [salesLoading, printAwbLoading, returnsLoading])

  const primaryButtonAction = actionButtons.shift()
 
  return (
    <>
      {(primaryButtonAction || actionButtons.length > 0) && (
        <div className='d-flex gap-12px'>
          {primaryButtonAction.id === ACTION_BUTTONS_ID.PRINT_MANUAL_AWB ? (
            <PrintManualAwb entity_id={returnOrderDetail?.id} entity_type={SHIPPING_ENTITY.RETURN_ORDER.value} buttonRef={printManualAwbRef} />
          ) :
            <Button
              onClick={primaryButtonAction.onClick}
              disabled={primaryButtonLoading[primaryButtonAction.id]}
              icon={primaryButtonLoading[primaryButtonAction.id] && Spinner}
              iconSize='sm'
            >
              {primaryButtonAction.text}
            </Button>
          }

          {primaryButtonAction?.key !== ACTION_BUTTONS_ID.PRINT_MANUAL_AWB && (
            <div className='d-none'>
              <PrintManualAwb entity_id={returnOrderDetail?.id} entity_type={SHIPPING_ENTITY.RETURN_ORDER.value} buttonRef={printManualAwbRef} />
            </div>
          )}

          {actionButtons.length > 0 && <BulkActions actions={actionButtons} selectedButton={{ id: 'moreActions', text: 'More Actions' }} />}
        </div>
      )}
    </>
  )
}

export default ButtonSection
