import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { ROUTES_KEYS } from '@src/router/route-keys'
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping'
import { paramsSerializer } from '@src/utility/Utils'
import { INITIAL_TIME_REPORT_PREVIEW_PER_PAGE } from './reports.constant'

const ANALYTICS_PREFIX = '/api/v1/analytics'

export const getReportsCategories = createAsyncThunk('/analytics/get-reports-categories', async () => {
  const response = await axiosInstance.get(`${ANALYTICS_PREFIX}/reports`)
  return response.data
})

export const getReportPreview = createAsyncThunk('/analytics/get-reports-preview', async ({reportParam, reportCategoryParam, ...rest}) => {
  // const hub_id = store.getState().auth.userHubs?.[0].id
  const response = await axiosInstance.post(`${ANALYTICS_PREFIX}/reports/${reportCategoryParam}/${reportParam}?${paramsSerializer({ ...rest })}`, {})
  return response.data
})

export const reportDownload = createAsyncThunk('/analytics/reports-download', async ({reportParam, reportCategoryParam, body, ...rest}) => {
  // const hub_id = store.getState().auth.userHubs?.[0].id
  const response = await axiosInstance.post(`${ANALYTICS_PREFIX}/reports/${reportCategoryParam}/${reportParam}/download?${paramsSerializer({ ...rest })}`, body)
  return response.data
})

export const retryReportDownload = createAsyncThunk('/analytics/retry-reports-download', async ({report_id}) => {
  const response = await axiosInstance.put(`${ANALYTICS_PREFIX}/reports/${report_id}/retry`)
  return response.data
})

export const getUserReports = createAsyncThunk('/analytics/user-reports', async (params) => {
  const response = await axiosInstance.get(`${ANALYTICS_PREFIX}/reports/users/downloads`, {params})
  return response.data
})
export const getFirstFourUserReports = createAsyncThunk('/analytics/first-four-user-reports', async (params) => {
  const response = await axiosInstance.get(`${ANALYTICS_PREFIX}/reports/users/downloads`, {params})
  return response.data
})
export const addOrRemoveReportAsFavorite = createAsyncThunk('/analytics/mark-as-favorite', async ({report, reportCategory, method}) => {
  const response = await axiosInstance({
    method,
    url: `${ANALYTICS_PREFIX}${ROUTES_MAPPING[ROUTES_KEYS.REPORTS]}/${reportCategory}/${report}/favourite`
  })
  return response.data
})
const initialState = {
  loading: {},
  success: {},
  error: {},
  reportsCategories: [],
  reportsPreview: null,
  userReports: null,
  firstFourUserReports: null,
  moreCustomizationSheetOpen: false,
  reportPreviewTableColumn: [],
  isInitialReportPreviewRender: true
}
export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    resetAllApiStates: (state) => {
      state.loading = {}
      state.success = {}
      state.error = {}
      state.moreCustomizationSheetOpen = false
    },
    setReportPreview: (state, action) => {
      state.reportsPreview = action.payload
    },
    resetSuccessResponse: (state, action) => {
      state.success = {...state.success, ...action.payload}
    },
    setMoreCustomizationSheetOpen: (state, action) => {
      state.moreCustomizationSheetOpen = action.payload
    },
    setReportPreviewTableColumn: (state, action) => {
      state.reportPreviewTableColumn = action.payload
    },
    setInitialReportPreviewRender: (state, action) => {
      state.isInitialReportPreviewRender = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportsCategories.pending, (state) => {
        state.loading = {...state.loading, getReportsCategories: true}
        state.success = {...state.success, getReportsCategories: false}
        state.error = {...state.error, getReportsCategories: false}
        state.reportsCategories = []
      })
      .addCase(getReportsCategories.fulfilled, (state, action) => {
        state.loading = {...state.loading, getReportsCategories: false}
        state.success = {...state.success, getReportsCategories: true}
        state.error = {...state.error, getReportsCategories: false}
        if (action.payload.data?.length) {
          state.reportsCategories = action.payload.data
        } else {
          state.reportsCategories = []
        }
      })
      .addCase(getReportsCategories.rejected, (state) => {
        state.loading = {...state.loading, getReportsCategories: false}
        state.success = {...state.success, getReportsCategories: false}
        state.error = {...state.error, getReportsCategories: true}
        state.reportsCategories = []
      })
    // getReportPreview
      .addCase(getReportPreview.pending, (state) => {
        state.loading = {...state.loading, getReportPreview: true}
        state.success = {...state.success, getReportPreview: false}
        state.error = {...state.error, getReportPreview: false}
        state.reportsPreview = null
      })
      .addCase(getReportPreview.fulfilled, (state, action) => {
        state.loading = {...state.loading, getReportPreview: false}
        state.success = {...state.success, getReportPreview: true}
        state.error = {...state.error, getReportPreview: false}
        state.reportsPreview = action.payload
        if (action.payload.meta?.total < INITIAL_TIME_REPORT_PREVIEW_PER_PAGE) {
          state.isInitialReportPreviewRender = false
        }
      })
      .addCase(getReportPreview.rejected, (state) => {
        state.loading = {...state.loading, getReportPreview: false}
        state.success = {...state.success, getReportPreview: false}
        state.error = {...state.error, getReportPreview: true}
        state.reportsPreview = null
      })
    // reportDownload
      .addCase(reportDownload.pending, (state) => {
        state.loading = {...state.loading, reportDownload: true}
      })
      .addCase(reportDownload.fulfilled, (state, action) => {
        state.loading = {...state.loading, reportDownload: false}
        state.success = {...state.success, reportDownload: true}
        state.error = {...state.error, reportDownload: false}
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(reportDownload.rejected, (state) => {
        state.loading = {...state.loading, reportDownload: false}
        state.success = {...state.success, reportDownload: false}
        state.error = {...state.error, reportDownload: true}
      })
    // retryReportDownload
      .addCase(retryReportDownload.pending, (state) => {
        state.loading = {...state.loading, retryReportDownload: true}
      })
      .addCase(retryReportDownload.fulfilled, (state, action) => {
        state.loading = {...state.loading, retryReportDownload: false}
        state.success = {...state.success, retryReportDownload: true}
        state.error = {...state.error, retryReportDownload: false}
        const eventName = action.payload.data.event
        bindToChannelEvent(eventName)
      })
      .addCase(retryReportDownload.rejected, (state) => {
        state.loading = {...state.loading, retryReportDownload: false}
        state.success = {...state.success, retryReportDownload: false}
        state.error = {...state.error, retryReportDownload: true}
      })
    // getUserReports
      .addCase(getUserReports.pending, (state) => {
        state.loading = {...state.loading, getUserReports: true}
        state.success = {...state.success, getUserReports: false}
        state.error = {...state.error, getUserReports: false}
      })
      .addCase(getUserReports.fulfilled, (state, action) => {
        state.loading = {...state.loading, getUserReports: false}
        state.success = {...state.success, getUserReports: true}
        state.error = {...state.error, getUserReports: false}
        const page = action.meta.arg?.page || 1
        if (page === 1) {
          state.userReports = action.payload 
        } else {
          const prevReports = state.userReports?.data.downloaded_reports || []
          const currReports = action.payload.data?.downloaded_reports || []
          let allReports = []
          if (prevReports.length >= currReports.length) {
            allReports = prevReports.map((report) => {
              if (!currReports.length) {
                return report
              } else if (report.date === currReports[0].date) {
                const shiftedReport = currReports.shift()
                return {...report, reports: [...report.reports, ...shiftedReport.reports]}
              } else {
                return report
              }
            })
          } else {
            allReports = currReports.map((report) => {
              if (!prevReports.length) {
                return report
              } else if (report.date === prevReports[0]?.date) {
                const shiftedReport = prevReports.shift()
                return {...report, reports: [...report.reports, ...shiftedReport.reports]}
              } else {
                return report
              }
            })
          }
          state.userReports = {
            ...(state.userReports || {}),
            ...action.payload,
            data: {
              ...action.payload.data,
              downloaded_reports: allReports
            }
          }
        }
      })
      .addCase(getUserReports.rejected, (state) => {
        state.loading = {...state.loading, getUserReports: false}
        state.success = {...state.success, getUserReports: false}
        state.error = {...state.error, getUserReports: true}
        state.userReports = null
      })
    // getFirstFourUserReports
      .addCase(getFirstFourUserReports.pending, (state) => {
        state.loading = {...state.loading, getFirstFourUserReports: true}
        state.success = {...state.success, getFirstFourUserReports: false}
        state.error = {...state.error, getFirstFourUserReports: false}
        state.firstFourUserReports = null
      })
      .addCase(getFirstFourUserReports.fulfilled, (state, action) => {
        state.loading = {...state.loading, getFirstFourUserReports: false}
        state.success = {...state.success, getFirstFourUserReports: true}
        state.error = {...state.error, getFirstFourUserReports: false}
        state.firstFourUserReports = action.payload
      })
      .addCase(getFirstFourUserReports.rejected, (state) => {
        state.loading = {...state.loading, getFirstFourUserReports: false}
        state.success = {...state.success, getFirstFourUserReports: false}
        state.error = {...state.error, getFirstFourUserReports: true}
        state.firstFourUserReports = null
      })
    // addOrRemoveReportAsFavorite
      .addCase(addOrRemoveReportAsFavorite.pending, (state) => {
        state.loading = {...state.loading, addOrRemoveReportAsFavorite: true}
        state.success = {...state.success, addOrRemoveReportAsFavorite: false}
        state.error = {...state.error, addOrRemoveReportAsFavorite: false}
      })
      .addCase(addOrRemoveReportAsFavorite.fulfilled, (state, action) => {
        state.loading = {...state.loading, addOrRemoveReportAsFavorite: false}
        state.success = {...state.success, addOrRemoveReportAsFavorite: true}
        state.error = {...state.error, addOrRemoveReportAsFavorite: false}
        if (action.payload?.data?.message) {
          CustomToast(action.payload.data.message,  {my_type: 'success'})
        }
      })
      .addCase(addOrRemoveReportAsFavorite.rejected, (state) => {
        state.loading = {...state.loading, addOrRemoveReportAsFavorite: false}
        state.success = {...state.success, addOrRemoveReportAsFavorite: false}
        state.error = {...state.error, addOrRemoveReportAsFavorite: true}
      })
  }
})

export const {setReportPreview, resetAllApiStates, resetSuccessResponse, setMoreCustomizationSheetOpen, setReportPreviewTableColumn, setInitialReportPreviewRender} = reportsSlice.actions
export default reportsSlice.reducer

export const getTenantSuppliers = async (search, loadedOptions, { page }) => {
  try {
    const response = await axiosInstance.get('/api/v1/wms/suppliers', {
      params: { search_column: 'name', search_query: search, page, sort: 'name_asc' }
    })
    const data = response?.data
  
    return {
      options: data?.data?.map(ele => ({
        value: ele?.id,
        label: ele?.name,
        ...ele
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page
      }
    }
  }
}

export const getHubLocations = async (search, loadedOptions, { page, hub_id }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/wms/hubs/${hub_id}/locations`, {
      params: { search_column: 'barcode', search_query: search, page, sort: 'barcode_asc' }
    })
    const data = response?.data
  
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.barcode
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        hub_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        hub_id
      }
    }
  }
}

export const getSellerSkus = async (search, loadedOptions, { page, seller_id, status }) => {
  try {
    const response = await axiosInstance.get(`/api/v1/products/sellers/${seller_id}/skus`, {
      params: { query: search, page, status }
    })
    const data = response?.data
  
    return {
      options: data?.data?.map(ele => ({
        value: ele.id,
        label: ele.name,
        desc: `Code: ${ele.seller_sku_code}`,
        logo: ele.images?.[0]?.default
      })),
      hasMore: page < data?.meta?.last_page,
      additional: {
        page: page + 1,
        seller_id
      }
    }
  } catch (error) {
    console.warn(error)
    // Handle the 403 error or other errors that may occur during the API request
    return {
      options: [],
      hasMore: false,
      additional: {
        page,
        seller_id
      }
    }
  }
}