import classNames from 'classnames'
import PropTypes from 'prop-types'
import { MoreHorizontal, Plus } from 'react-feather'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import CustomTag from '../badge/CustomTag'
import './styles.scss'

/**
 * @component TagsMapper
 * @description Universal component for displaying tags with text or badge variants
 * @example
 *  For text display
 * <TagsMapper variant="text" tags={textArray} />
 * 
 *  For badge display with custom icon
 * <TagsMapper variant="badge" tags={tagArray} customIcon={MyIcon} />
 */

/**
 * Enhanced TagsMapper component for handling both text and badge style tags
 * @param {Object} props
 * @param {Array} props.tags - Array of tag objects
 * @param {number} props.viewItems - Number of visible items
 * @param {boolean} props.containerBody - Attach dropdown to body
 * @param {Object} props.toggleProps - Additional dropdown props
 * @param {boolean} props.showCount - Show count indicator
 * @param {string} props.size - Size variant ('sm' | 'md' | 'lg')
 * @param {string} props.variant - Display variant ('text' | 'badge')
 * @param {string} props.className - Additional classes
 */
const TagsMapper = ({
  tags = [],
  viewItems = 1,
  containerBody = false,
  toggleProps = {},
  showCount = false,
  size = 'md',
  variant = 'badge',
  className
}) => {
  if (!tags?.length) return '-'

  const sizeClasses = {
    sm: 'py-4px px-8px fs-12',
    md: 'py-6px px-12px fs-14',
    lg: 'py-8px px-16px fs-16'
  }

  // Render item based on variant
  const renderItem = (tag, index) => {
    const commonStyles = {
      maxWidth: '150px'
    }

    const commonClasses = classNames(
      'text-truncate',
      {
        [sizeClasses[size]]: variant === 'badge',
        'txt-sub-rg text-dark cursor-default': variant === 'text'
      }
    )

    if (variant === 'text') {
      return (
        <div 
          className={commonClasses} 
          style={commonStyles} 
          title={tag.name}
        >
          {tag.name}
        </div>
      )
    }

    return (
      <CustomTag
        key={tag.name}
        className={commonClasses}
        style={{
          ...commonStyles,
          color: tag.color || 'var(--bs-dark)',
          backgroundColor: tag.color ? `${tag.color}1A` : 'var(--bs-dark-2)'
        }}
        title={tag.name}
      >
        {tag.name}
      </CustomTag>
    )
  }

  // Render more indicator with consistent styling
  const renderMoreIndicator = () => {
    if (!showCount) {
      return (
        <div className="flex-center-center py-0 px-4px bg-light-3">
          <MoreHorizontal size={16} strokeWidth={1.2} />
        </div>
      )
    }

    return (
      <div className="cursor-pointer txt-asst-rg flex-center-center count-indicator">
        <div className="ps-6px pe-8px py-2px flex-center-center">
          <span className="flex-center-center">
            <Plus className="me-2px" color='var(--bs-primary)' size={12} />
            <span className="mt-2px">{tags.length - viewItems}</span>
          </span>
        </div>
      </div>
    )
  }

  const containerClass = classNames(
    'tags-mapper d-flex gap-8px align-items-center flex-wrap',
    {
      'flex-center-start': variant === 'text'
    },
    className
  )

  return (
    <div className={containerClass}>
      {/* Render all items using renderItem */}
      {tags.slice(0, viewItems).map((tag, index) => (
        <div key={tag.name || index} className="text-truncate">
          {renderItem(tag, index)}
        </div>
      ))}

      {/* Dropdown for additional items */}
      {tags.length > viewItems && (
        <UncontrolledDropdown className="tags-dropdown">
          <DropdownToggle
            tag="span"
            className="cursor-pointer"
            {...toggleProps}
          >
            {renderMoreIndicator()}
          </DropdownToggle>
          
          <DropdownMenu 
            container={containerBody ? 'body' : ''} 
            data-popper-placement="bottom-end"
            className={classNames(
              'bg-white max-height-220 width-100',
              {'overflow-y-scroll': tags.length > 4}
            )}
          >
            {tags.slice(viewItems).map((tag, idx) => (
              <DropdownItem key={tag.name || idx} text className="d-flex justify-content-left ps-8px pe-8px w-100">
                {renderItem(tag, idx + viewItems)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  )
}

TagsMapper.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string
    })
  ).isRequired,
  viewItems: PropTypes.number,
  containerBody: PropTypes.bool,
  toggleProps: PropTypes.object,
  showCount: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['text', 'badge']),
  className: PropTypes.string,
  customIcon: PropTypes.elementType
}

export default TagsMapper 