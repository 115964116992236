import Modal from '@src/@core/components/ui/modal/Modal'
import './confirmationPopup.scss'
const ConfiramtionPopup = (props) => {
  const { isConfirmationPopupOpen, handlePopupToggle, title, subTitle, bodyContent, loading, primaryButtonText, primaryButtonHandler, secondaryButtonText, secondaryButtonHandler, isToggle, children, rest } = props

  return (
    <Modal
      isOpen={isConfirmationPopupOpen}
      toggle={isToggle}
      closeModalHandler={handlePopupToggle}
      title={title}
      subTitle={subTitle}
      bodyContent={bodyContent}
      primaryButtonText={primaryButtonText}
      primaryButtonHandler={primaryButtonHandler}
      secondaryButtonText={secondaryButtonText}
      secondaryButtonHandler={secondaryButtonHandler}
      primaryButtonProps={{
        disabled: loading,
        loading: loading
      }}
      secondaryButtonProps={{
        disabled: loading
      }}
      isTranslationRequired={false}
      {...rest}
    >
      {children}
    </Modal>
  )
}
export default ConfiramtionPopup