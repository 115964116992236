// ** React Imports
import { Fragment, memo, useEffect } from 'react'

// ** Third Party Components
import classnames from 'classnames'

// ** Store & Actions
import { handleClearBottomFooter, handleContentWidth, handleMenuCollapsed, handleMenuHidden, handleSecondaryMenu } from '@store/layout'
import { useDispatch, useSelector } from 'react-redux'

// ** ThemeConfig
import themeConfig from '@configs/themeConfig'

// ** Styles
import 'animate.css/animate.css'
import Footer from '../footer'
import './styles.scss'
const LayoutWrapper = props => {
  // ** Props
  const { children, routeMeta } = props

  // ** Store Vars
  const dispatch = useDispatch()
  const layoutStored = useSelector(state => state.layout.layout)
  const menuCollapsed = useSelector(state => state.layout.menuCollapsed)
  const menuHidden = useSelector(state => state.layout.menuHidden)
  const contentWidth = useSelector(state => state.layout.contentWidth)
  const layoutStore = useSelector(state => state.layout)
  const isBottomFooterVisible = useSelector(state => state.layout.isBottomFooterVisible)
  const isLayoutReady = layoutStore.isLayoutReady

  // ** Vars
  const appLayoutCondition =
    (layoutStored.layout === 'horizontal' && !routeMeta) || (layoutStored.layout === 'horizontal' && routeMeta && !routeMeta.appLayout)
  const Tag = appLayoutCondition ? 'div' : Fragment

  // ** Clean Up Function
  const cleanUp = () => {
    if (routeMeta) {
      if (routeMeta.contentWidth && routeMeta.contentWidth === contentWidth) {
        dispatch(handleContentWidth(themeConfig.layout.contentWidth))
      }
      if (routeMeta.menuCollapsed && routeMeta.menuCollapsed === menuCollapsed) {
        dispatch(handleMenuCollapsed(!menuCollapsed))
      }
      if (routeMeta.menuHidden && routeMeta.menuHidden === menuHidden) {
        dispatch(handleMenuHidden(!menuHidden))
      }
    }
  }

  // ** ComponentDidMount
  useEffect(() => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth(routeMeta.contentWidth))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(routeMeta.menuHidden))
      } else {
        dispatch(handleMenuHidden(false))
      }
      dispatch(handleSecondaryMenu(routeMeta.hasSecondaryMenu))
    } else {
      dispatch(handleSecondaryMenu(false))
    }
    dispatch(handleClearBottomFooter())
    return () => cleanUp()
  }, [routeMeta])
  return (

    <div id="omniful-layout-container"  className={classnames('d-flex flex-column', {'show-bottom-footer': isBottomFooterVisible, 'omniful-layout-wrapper': !isBottomFooterVisible})}>
      <div
        className={classnames('px-0 position-relative d-flex flex-column flex-1', {
          [routeMeta ? routeMeta.className : '']: routeMeta && routeMeta.className
        })}
      >
        <div className="app-content content flex-fill">
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow" />
          <div
            className={classnames({
              'content-wrapper m-0': routeMeta && !routeMeta.appLayout && !isBottomFooterVisible,
              'content-area-wrapper': routeMeta && routeMeta.appLayout,
              'p-0': contentWidth === 'boxed',
              'content-wrapper-bottom-footer m-0': isBottomFooterVisible
            })}
          >
            <Tag {...(appLayoutCondition ? { className: 'content-body' } : {})}>{children}</Tag>
          </div>
        </div>
        {isLayoutReady && <Footer/> }
      </div>
    </div>
  )
}

export default memo(LayoutWrapper)
