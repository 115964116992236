// import HubList from "@src/views/hubs-management/pages/hubList"
import { memo, useContext, useEffect, useRef, useState } from 'react'
import { CheckCircle, ChevronDown, ChevronUp, RefreshCw } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import hubLogo from '../../../../assets/images/omniful/hub-logo.svg'

import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input
} from 'reactstrap'

import Button from '@src/@core/components/ui/button'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import {
  getGlobalHubsList,
  handleSelectedHub,
  resetUserHubsAndMeta
} from '@src/redux/authentication'
import { debounceAction, shouldNotDisplayGlobalHubDropdown } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import { useRTL } from '@src/utility/hooks/useRTL'
import useThemeSetup from '@src/utility/hooks/useThemeSetup'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import './styles.scss'

const NavbarHub = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isRTL] = useRTL()
  const location = useLocation()
  // const [hubs, setHubs] = useState([])
  const dropDownRef = useRef(null)
  const {
    userHubs,
    selectedGlobalHubId,
    globalHubMeta,
    loadingState,
    globalHubSearchResults,
    userData
  } = useSelector((state) => state.auth)
  const [selectedLocalHubId, setSelectedLocalHubId] = useState(null)
  const selectedGlobalHubObj = userHubs?.find((hub) => hub.id === selectedGlobalHubId)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useState({})
  const globalHubChangeCallback = useSelector((state) => state.auth.globalHubChangeCallback)
  const hubs = searchParams.search_query?.length > 2 ? globalHubSearchResults : userHubs
  const ability = useContext(AbilityContext)
  
  const canViewHubs = ability.can(abilityMap.hub.view.action, abilityMap.hub.view.resource)
  //Constants
  const isLastPage = globalHubMeta?.current_page === globalHubMeta?.last_page
  const hasHubs = hubs?.length !== 0
  const shouldRenderThatIsIt = hasHubs && canViewHubs && isLastPage && globalHubMeta?.current_page > 1
  const shouldRenderLoadMore = hasHubs && canViewHubs && !isLastPage

  useThemeSetup(userData)

  const onChange = (id) => {
    setSelectedLocalHubId(id)
  }

  useEffect(() => {
    if (selectedGlobalHubId) {
      setSelectedLocalHubId(selectedGlobalHubId)
    }
  }, [selectedGlobalHubId])

  useOnClickOutside(dropDownRef, () => {
    setIsOpen(false)
    if (searchParams.search_query?.length > 0) {
      dispatch(resetUserHubsAndMeta())
    }
    setSearchParams({})
    setSelectedLocalHubId(selectedGlobalHubId)
    // dispatch(resetUserHubsAndMeta())
  })

  const handleOnClick = () => {
    setIsOpen(false)
    dispatch(handleSelectedHub(selectedLocalHubId))
    globalHubChangeCallback(selectedLocalHubId)
  }

  const handleGlobalHubSearch = (e) => {
    const search_query = e.target.value.trim()
    if (search_query.length > 2) {
      setSearchParams({ search_column: 'name', search_query })
      dispatch(getGlobalHubsList({ search_column: 'name', search_query }))
    } else if (search_query.length === 0) {
      setSearchParams({})
      setSelectedLocalHubId(selectedGlobalHubId)
      dispatch(resetUserHubsAndMeta())
    } else {
      setSearchParams({})
    }
  }

  const handleLoadMoreHubs = () => {
    dispatch(
      getGlobalHubsList({
        ...searchParams,
        global_hub_id: selectedGlobalHubId,
        page: globalHubMeta.current_page + 1,
        per_page: 20
      })
    )
  }

  const handleScroll = (container) => {
    const { scrollTop, clientHeight, scrollHeight } = container
    const roundedSumOfScrollTopAndClientHeight = Math.round(scrollTop) + Math.round(clientHeight)
    const roundedScrollHeight = Math.round(scrollHeight)
    const isScrollReachBottom = (roundedSumOfScrollTopAndClientHeight === roundedScrollHeight || (roundedSumOfScrollTopAndClientHeight - 1) === roundedScrollHeight || (roundedSumOfScrollTopAndClientHeight + 1) === roundedScrollHeight)
    if (isScrollReachBottom && (clientHeight !== 0 && scrollTop !== 0) && (!loadingState.globalHubsList && !isLastPage) && globalHubMeta.total !== 0) {
      handleLoadMoreHubs()
    }
  }

  const handleDropDownToggle = () => {
    setSearchParams({})
    setSelectedLocalHubId(selectedGlobalHubId)
    dispatch(resetUserHubsAndMeta()) 
  }

  const shouldGlobalDropdownBeVisible = !selectedGlobalHubId || shouldNotDisplayGlobalHubDropdown({location})

  if (shouldGlobalDropdownBeVisible) {
    return null
  }

  return (
    <li>
      <div className="hub-dropdown">
        <div className="hub-logo">
          <img style={{ height: '20px', width: '20px' }} src={hubLogo} />
          <div className="m-0" style={{ marginLeft: '5px', color: '#999999' }}>
            {t('Hub')}
          </div>
        </div>
        <div className="dropdown-divider"></div>
        <div ref={dropDownRef} className="position-relative">
          <Dropdown isOpen={isOpen} toggle={handleDropDownToggle}>
            <DropdownToggle
              color="transparent"
              style={{ border: 'transparent', padding: '0px' }}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <div className="d-flex align-items-center">
                {' '}
                <h5
                  className="m-0 me-1 text-capitalize width-80px overflow-hidden text-truncate txt-body-rg"
                >
                  {selectedGlobalHubObj?.name}
                </h5>
                <div className='tw-h-[16px] tw-w-[16px]'>
                  {isOpen ? (
                    <ChevronUp className="chevron-bold" size={16} />
                  ) : (
                    <ChevronDown className="chevron-bold" size={16} />
                  )}
                </div>
              </div>
            </DropdownToggle>
            <DropdownMenu
              placement="bottom-center"
              className={
                isRTL
                  ? 'dropdown-menu rtl showRTLDropdown'
                  : 'withoutRTL handleWithoutRTL'
              }
            >
              {canViewHubs ? (
                <div className="hub-search-bar">
                  <Input
                    type="search"
                    placeholder={t('Search hub')}
                    onChange={debounceAction(
                      (e) => handleGlobalHubSearch(e),
                      300
                    )}
                    autoFocus
                  />
                </div>
              ) : (
                <div style={{ padding: '16px 16px 0px 16px' }}>
                  {t('Hubs Access to you')}
                </div>
              )}
              <PerfectScrollbar
                onYReachEnd={handleScroll}
                options={{ wheelPropagation: false }}
              >
                <div className="hubs-list">
                  {hubs.length > 0 ? (
                    hubs?.map((hub) => (
                      <div
                        className="hub-detail cursor-pointer"
                        key={hub.id}
                        onClick={() => onChange(hub.id)}
                      >
                        <input
                          type="radio"
                          className="input-radio-overrides hub-detail-select"
                          name={hub.name}
                          value={hub.name}
                          checked={hub.id === selectedLocalHubId}
                          onChange={() => onChange(hub.id)}
                        />
                        <div className="hub text-break">
                          <h4 className="text-capitalize txt-body-rg">{hub.name}</h4>
                          {hub.address && (
                            <div className='txt-sub-rg text-dark-5'>{`${hub.address.address_line1}, ${hub.address.address_line2}`}</div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="py-1 d-flex justify-content-center me-1">
                      {t('No Result Found')}
                    </div>
                  )}
                  {shouldRenderThatIsIt ? (
                    <div className="d-flex justify-content-center align-items-center py-1 mt-1 me-2">
                      <CheckCircle size={14} />{' '}
                      <span className="ms-50">{t('That\'s it')}</span>
                    </div>
                  ) : shouldRenderLoadMore ? (
                    <div className="w-100 flex-center-center py-16px">
                      <RefreshCw
                        className={classNames({
                          'sync-rotate-icon': loadingState.globalHubsList
                        })}
                        size={12}
                        color="var(--bs-dark)"
                      />
                      <span className="text-dark txt-asst-md ms-50">{t('Fetching more hubs')}</span>
                    </div>
                  ) : null}
                </div>
              </PerfectScrollbar>
              <div className="done-btn mt-2px">
                <Button
                  onClick={handleOnClick}
                  disabled={selectedGlobalHubId === selectedLocalHubId}
                  className="w-100 flex-center-center"
                >
                  {t('Done')}
                </Button>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </li>
  )
}

export default memo(NavbarHub)
