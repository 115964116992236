import { useTranslation } from 'react-i18next'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { TAX_TYPES } from './PriceBreakDownSection'
import './PriceBreakDownSection.scss'

const InclusiveTaxModal = (prop) => {
  const {
    taxModal,
    setTaxModal,
    subTotalInclusiveDiscount,
    subTotalInclusiveTax,
    shippingInclusiveTax,
    currency,
    taxType
  } = prop
  const { t } = useTranslation()
  return (
    <Modal
      isOpen={taxModal}
      toggle={() => setTaxModal(false)}
      centered
      className="height-200 width-400"
    >
      <ModalBody className="py-1">
        {taxType === TAX_TYPES.SUB_TOTAL.value && (
          <div className="d-flex flex-column gap-2">
            {(subTotalInclusiveDiscount === 0 || subTotalInclusiveDiscount > 0) && (
              <Row>
                <Col className="col-6">{t('Inclusive Discount')}:</Col>
                <Col className="col-6 text-end">
                  {subTotalInclusiveDiscount} {currency}
                </Col>
              </Row>
            )}
            {(subTotalInclusiveTax === 0 || subTotalInclusiveTax > 0) && (
              <Row>
                <Col className="col-6">{t('Inclusive Tax(%)')}:</Col>
                <Col className="col-6 text-end">
                  {subTotalInclusiveTax.toFixed(2)}%
                </Col>
              </Row>
            )}
          </div>
        )}
        {taxType === TAX_TYPES.SHIPPING_TAX.value && (
          <div>
            <Row>
              <Col className="col-6">{t('Tax Amount')}:</Col>
              <Col className="col-6 text-end">
                {shippingInclusiveTax} {currency}
              </Col>
            </Row>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default InclusiveTaxModal