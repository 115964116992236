import { commaSeparateInternational } from '@src/utility/Utils'
import { t } from 'i18next'
import Button from '../../button'

const AcrossSelectionBanner = (props) => {
  const { isAcrossPagesSelected, meta, selectedData, handleClearAllSelection, handleAcrossPageSelectionChange } = props

  const totalItems = meta?.total > 0 ? commaSeparateInternational(meta.total) : '-'
  
  const getSelectionMessage = ({ isAcrossPagesSelected, selectedData }) => {
    const totalSelectedItems = selectedData?.selectedCount > 0 ? commaSeparateInternational(selectedData?.selectedCount) : '-'
    return isAcrossPagesSelected 
      ? `All ${totalItems} items are selected.`
      : `All ${totalSelectedItems} items on this page are selected.`
  }

  return (
    <div className='flex-center-start bg-light-2 px-16px py-8px mb-2px gap-4px'>
      <span className='txt-body-rg text-dark-6'>{getSelectionMessage({ isAcrossPagesSelected, meta, selectedData })}</span>
      {isAcrossPagesSelected ? (
        <Button ofStyle='noBackground' ofType='compact' onClick={handleClearAllSelection}>
          {t('Clear Selection')}
        </Button>
      ) : (
        <Button
          ofStyle='noBackground'
          ofType='compact'
          onClick={() => {
            handleAcrossPageSelectionChange(true)
          }}
        >
          Select all {totalItems} items
        </Button>
      )}
    </div>
  )
}

export default AcrossSelectionBanner
