import { ROUTES_KEYS } from '@src/router/route-keys';
import { ROUTES_MAPPING } from '@src/router/route-keys-mapping';
import { iOrderRecreationParams } from '@src/views/sales/create-order/create-order.types';
import { useNavigate } from 'react-router-dom';

const useOrderRecreation = () => {
  const navigate = useNavigate();

  const handleOrderRecreation = ({ recreationType, orderId, hubId , previousOrderDisplayId }: iOrderRecreationParams) => {
    navigate(ROUTES_MAPPING[ROUTES_KEYS.CREATE_ORDER], {
      state: {
        orderId,
        recreationType,
        hubId,
        previousOrderDisplayId
      },
    });
  };

  const cleanOrderRecreationData = () => {
    navigate(ROUTES_MAPPING[ROUTES_KEYS.CREATE_ORDER]);
  };

  return { handleOrderRecreation, cleanOrderRecreationData };
};

export default useOrderRecreation;
